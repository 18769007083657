import React, { useState, useEffect } from 'react';

import Restaurants from './Restaurants'
import Users from './Users'
import RestaurantCategory from './RestaurantCategory'


import { Tabs } from 'antd'
import { useHistory, useLocation } from 'react-router';
import { push } from 'react-router-redux';

const queryString = require('query-string');

const { TabPane } = Tabs;

import { ConfigProvider } from 'antd';
import trLocale from 'antd/es/locale/tr_TR';



export default ({ history }) => {

    const [page, setPage] = useState("Restaurants")


    useEffect(() => {
        const parsedHash = queryString.parse(window.location.search);
        if (parsedHash.page) {
            setPage(parsedHash.page)
        }
    }, [])



    const onChangeTab = (key) => {
        setPage(key)

        history.push({
            pathname: '/master',
            search: "?" + new URLSearchParams({ page: key }).toString()
        })

    }

    return (
        <div style={{ padding: 24 }}>
            <ConfigProvider locale={trLocale}>
                <Tabs activeKey={page} onChange={(key) => onChangeTab(key)}>
                    <TabPane tab="Restoranlar" key="Restaurants">
                        <Restaurants />
                    </TabPane>
                    <TabPane tab="Kullanıcılar" key="Users">
                        <Users />
                    </TabPane>
                    <TabPane tab="Restoran Kategorileri" key="RestaurantCategory">
                        <RestaurantCategory />
                    </TabPane>
                </Tabs>
            </ConfigProvider>
        </div>
    )

}


