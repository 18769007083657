import React from "react";
import PropTypes from "prop-types";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import QRCode from "qrcode.react";

import {
    Tabs,
    Skeleton,
    Modal,
    PageHeader,
    Menu,
    Select,
    Row,
    Col,
    Button,
    Collapse,
    Form,
    Checkbox,
    Input,
    Spin,
    message,
    List,
    Card,
    Avatar,
    Tooltip,
    Divider,
    Switch,
    Dropdown,
    Descriptions,
    Typography,
    Radio,
    Drawer,
    Tag
} from 'antd';

import {
    ReloadOutlined,
    PlusOutlined,
    UserAddOutlined,
    QuestionCircleOutlined,
    ArrowLeftOutlined,
    CloseOutlined,
    MessageOutlined,
    SaveOutlined,
    InfoCircleOutlined,
    QrcodeOutlined,
    MoreOutlined,
    ShareAltOutlined,
    LinkOutlined,
    PrinterOutlined,
    CopyOutlined,
    DownloadOutlined
} from '@ant-design/icons';

import { siteInfo } from "../config";


function QrContainer(props) {
    const { text, download, copy, size } = props;
    const downloadQR = () => {
        const canvas = document.getElementById(text);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${Date.now()}-${text}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div style={{}}>
            <QRCode
                id={text}
                level="H"
                includeMargin={true}
                //  bgColor={bgColor ? bgColor : undefined}
                //   fgColor={fgColor ? fgColor : undefined}
                size={size} value={text} />
            <div>
                {
                    download
                    &&
                    <DownloadOutlined onClick={downloadQR} title="QR İndir" />
                }
                {
                    copy
                    &&
                    <>
                        <Divider type="vertical" />
                        <CopyToClipboard
                            text={text}
                            onCopy={() => {
                                message.success("Kopyalandı")
                            }}>
                            <CopyOutlined title="Kopyala" />
                        </CopyToClipboard>
                    </>
                }

            </div>
        </div>

    )
}

QrContainer.propTypes = {
    text: PropTypes.string.isRequired,
    size: PropTypes.number,
    download: PropTypes.bool
}
QrContainer.defaultProps = {
    size: 256,
    download: false,
    copy: false,
};

export default QrContainer;


/*
{
    text = siteInfo.clientUrl,
    width = 64,
    bgColor,
    fgColor
}
*/