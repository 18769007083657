import React, { Component, useRef } from "react";
import { Link, } from 'react-router-dom'; // Link,
import {
  Tabs,
  Skeleton,
  Modal,
  PageHeader,
  Menu,
  Select,
  Row,
  Col,
  Button,
  Collapse,
  Form,
  Checkbox,
  Input,
  Spin,
  message,
  List,
  Card,
  Avatar,
  Tooltip,
  Divider,
  Switch,
  Dropdown,
  Descriptions,
  Typography,
  Radio,
  Drawer,
  Tag
} from 'antd';

import ReactToPrint, { PrintContextConsumer, useReactToPrint } from "react-to-print";

import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios'

import _ from 'lodash';

import moment from "moment"

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  ReloadOutlined,
  PlusOutlined,
  UserAddOutlined,
  QuestionCircleOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  MessageOutlined,
  SaveOutlined,
  InfoCircleOutlined,
  QrcodeOutlined,
  MoreOutlined,
  ShareAltOutlined,
  LinkOutlined,
  PrinterOutlined,
  CopyOutlined
} from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import { goPage, goBack } from '../historyConfig'

import { withTranslation, } from 'react-i18next'

import { qrCodeB64 } from '../helper'

import QrCodeComponent from '../components/QrCode'
import ComponentToPrint from '../components/ToPrint'
import QrReader from '../components/QrReader'

import PrintHook from '../components/PrintHook'
//import Modal from "antd/lib/modal/Modal";

import { getPrintConfig, setPrintConfig, siteInfo } from "../config"


const queryString = require('query-string');

const { TextArea } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select
const { Panel } = Collapse;
const { SubMenu } = Menu;

@inject('QrCodeStore', 'GeneralStore')
@observer
class QrCodePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      initLoading: true,
      data: [],
      list: [],
      deskList: [],
      deskId: null,
      pageType: 'list',
      modalOpenCreate: false,
      showOtherQrCode: false,
      initPrint: false,
      _id: null,
      title: '',
      description: '',
      limit: 20,
      offset: 0,
      count: 0
    }
  }

  componentDidMount = async () => {
    this.getItems()
    this.initDeskQuery()
    this.initDesks()
  }

  initDesks = async () => {
    const { deskList } = this.state


    if (deskList.length == 0) {

      try {
        const desksServer = await this.props.GeneralStore.getDesks()
        const deskList = _.get(desksServer, "data.results", [])
        this.setState({ deskList })
      } catch (error) {

      }
      finally {

      }
    }
  }
  //// SONRA #!!!
  initDeskQuery = () => {
    const parsedHash = queryString.parse(location.search);
    const tableQuery = _.get(parsedHash, "table")

    if (tableQuery) {
      console.log({ tableQuery })
      const findCode = _.find(this.state.data, { deskId: tableQuery })
      if (findCode) {
        this.setState({ deskId: tableQuery })
      }
    }
  }


  getItems = async () => {
    console.log("getItems")
    const { limit, offset } = this.state
    this.setState({ loading: true })
    try {
      let serverData = await this.props.QrCodeStore.getItems(limit, offset)

      const data = _.get(serverData, "results")
      const list = _.get(serverData, "results")
      const count = _.get(serverData, "count", 0)
      this.setState({ data, list, count, initLoading: false })
      console.log({ serverData })
    } catch (error) {
      console.log(error)
    }
    finally {
      this.setState({ loading: false })
    }
  }


  getData = callback => {
    reqwest({
      url: fakeDataUrl,
      type: 'json',
      method: 'get',
      contentType: 'application/json',
      success: res => {
        callback(res);
      },
    });
  };

  onLoadMore = async () => {
    //   const {count} = this.state
    let count = this.state.limit
    this.setState({
      loading: true,
      list: this.state.data.concat([...new Array(count)].map(() => ({ loading: true, name: {} }))),
    });

    const { limit } = this.state
    let offset = limit + this.state.offset
    let serverData = await this.props.QrCodeStore.getItems(limit, offset)

    //   const data = _.get(serverData,"data.results")

    const list = _.get(serverData, "data.results")
    const data = this.state.data.concat(list);
    console.log("load more", { serverData: _.get(serverData, "data"), list, limit, offset })
    this.setState({
      data,
      list: data,
      loading: false,
      offset
    },
      () => {
        window.dispatchEvent(new Event('resize'));
      })


    /*
    this.getData(res => {
      const data = this.state.data.concat(res.results);
      this.setState(
        {
          data,
          list: data,
          loading: false,
        },
        () => {
          // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
          // In real scene, you can using public method of react-virtualized:
          // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
    */

  };

  //#ekle
  handleCreate = async () => {
    const { title, description, deskId, _id } = this.state
    let data = {
      title,
      description,
      deskId,
      id: _id
    }
    if (!deskId) {
      message.error()
      return
    }

    //yeni kayıt//////////////
    if (!_id) {
      try {
        const serverData = await this.props.QrCodeStore.insert(data)
        const item = _.get(serverData, "data")

        const desk = _.find(this.state.deskList, { _id: deskId })
        item.desk = desk

        this.setState({ data: [item, ...this.state.data], list: [item, ...this.state.list] })
        this.resetState()
        message.success()
      } catch (error) {
        message.error()
        console.log(error)
      }

    }
    ///////Güncelleme//////////////////
    else {
      try {
        data._id = _id
        const serverData = await this.props.QrCodeStore.update(_id, data)
        let finded = _.find(this.state.data, (o) => {
          return o._id == _id
        })
        if (finded) {
          finded.title = title
          finded.description = description
          finded.deskId = deskId
          const desk = _.find(this.state.deskList, { _id: deskId })
          finded.desk = desk
          this.setState({ data: this.state.data })
        }
        this.resetState()
        message.success()
      } catch (error) {
        console.log(error)
      }

    }
  }

  onChangeSwitchList = async (checked, item) => {
    message.destroy()
    try {
      const serverData = await this.props.QrCodeStore.setActive(item._id, checked)
      checked ? message.success(_.get(item, "title")) : message.error(_.get(item, "title"))
      console.log({ serverData })

      //
      let finded = _.find(this.state.data, (o) => {
        return o._id == item._id
      })
      if (finded) {
        finded.active = checked
      }
      this.setState({ data: this.state.data })

    } catch (error) {
      console.log(error)
      message.warn()
    }
    //  this.updateState(checked,item._id)
  }

  //tümünü aktif/pasif yap
  setActiveAll = async (active) => {
    try {
      const serverData = await this.props.QrCodeStore.setActiveAll("123456789658965874132541", active)
      this.state.data.map((v) => {
        v.active = active
      })
      this.setState({ data: this.state.data })

    } catch (error) {

    }
  }

  onClickDetail = (item) => {
    console.log({ item })
    this.setState({
      ...item,
      pageType: "detail",
      modalOpenCreate: true
    })
  }

  onClickNew = () => {
    this.setState({ modalOpenCreate: true, pageType: "new" })
    //  this.initdeskArees()
  }

  handleCancel = (e) => {
    this.resetState()
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  resetState() {
    this.setState({
      modalOpenCreate: false,
      pageType: 'list',
      _id: null,
      showOtherQrCode: false,
      title: '',
      description: ''
    })
  }
  onChangeDesk = (deskId) => {
    this.setState({ deskId })
  }
  onClickReload = () => {
    this.getItems()
  }
  onClickMore = (e, item) => {
    console.log({ e, item })
    if (e == "edit") {
      console.log("edit");
      this.setState({
        ...item,
        pageType: "edit",
        modalOpenCreate: true
      })
    }
    else if (e.includes("print")) {
      let width = 0
      switch (e) {
        case "print1":
          width = 128
          break;
        case "print2":
          width = 300
          break
        default:
          width = 420
      }
      this.setState({
        width: width
      },
        () => {

          this.props.QrCodeStore.setqr(this.state)
          this.setState({ initPrint: true })

          //  console.log(this.refOnclickPrintButton)
          //////  this.refOnclickPrintButton.current.click()
          //  useReactToPrint({ content:<div>Deneme</div> })
          // ReactToPrint.startPrint()
          //   PrintContextConsumer.

          //  useReactToPrint({content:this.componentRef})
          //componentRef

          //  this.componentPrintButtonRef.click()
          /*  const componentRef = useRef();
            useReactToPrint({
              content: () => componentRef.current,
            });
            */
        })
    }
    else if (e == "delete") {
      this.deleteItem(item)
      //  return
    }
    else {
      console.log("none")
    }
  }
  deleteItem = async (item) => {
    try {
      const serverData = await this.props.QrCodeStore.delete(item)
      console.log({ serverData })
      this.state.data = _.remove(this.state.data, (o) => {
        return o._id == item._id
      })

      this.setState({
        data: this.state.data
      })

    } catch (error) {
      console.log(error)
    }
  }

  //tümünü sil server 
  onClickDeleteAllServer = async () => {
    try {
      const serverData = await this.props.QrCodeStore.delete({ action: "deleteall" })
      console.log({ serverData })
      Modal.destroyAll()
      this.setState({
        data: [],
        list: [],
        count: 0
      })

    } catch (error) {
      console.log(error)
    }
  }

  onClickDeleteAll = () => {
    console.log("hepsini sil")

    Modal.confirm({
      title: ' Emin misiniz ?',
      okText: 'Sil',
      cancelText: 'İptal',
      content: <div>
        {`${this.state.count} tane veri silinecek`}
      </div>,
      onOk: () => {
        console.log("onOk")
        this.onClickDeleteAllServer()

      },
      onCancel: () => {
        console.log("onCancel")
        Modal.destroyAll()
      },
    })

  }

  onChangePrintSize = (e) => {
    console.log("onChangePrintSize", e)
    this.setState({ size: e })
  }
  // bütün masalar için qr kod oluştur
  onClickCreateAll = async () => {
    console.log("create all")
    //createAll
    try {
      const deskList = this.state.deskList
      const serverData = await this.props.QrCodeStore.createAll({ deskList: deskList })
      console.log({ serverData })
      this.getItems()
    } catch (error) {
      console.log(error)
    }
  }

  onClickCancelPrintAll = () => {
    const descriptionen = _.get(this.state, "descriptionen", "")
    const descriptiontr = _.get(this.state, "descriptiontr", "")
    const qrreadurl = _.get(this.state, "qrreadurl", "")
    const size = _.get(this.state, "size", "middle")
    const data = {
      descriptionen,
      descriptiontr,
      qrreadurl,
      size
    }
    setPrintConfig(data)
    this.setState({ printAction: null })
  }
  //hepsini yazdır tuşuna basılınca
  onClickPrintAll = () => {
    const configData = getPrintConfig();
    console.log("onclick Print", getPrintConfig())

    const descriptionen = _.get(configData, "descriptionen", "")
    const descriptiontr = _.get(configData, "descriptiontr", "")
    const qrreadurl = _.get(configData, "qrreadurl", "")
    const size = _.get(configData, "size", "middle")

    this.setState({
      printAction: "printAll",
      descriptionen,
      descriptiontr,
      qrreadurl,
      size
    })
  }

  onBeforePrint = () => {
    console.log("onBeforePrint")
  }
  onAfterPrint = () => {
    //  this.setState({printAction:null,width:null,initPrint:false})
    this.onClickCancelPrintAll()
    console.log("onAfterPrint")
  }

  onClickMenuMore = (e) => {
    //tümünü aktif yap
    if (e == "active") {
      this.setActiveAll(true)
    }
    //tümünü pasif yap
    else if (e == "passive") {
      this.setActiveAll(false)
    }
    //tümünü yazdır
    else if (e == "print") {
      this.onClickPrintAll()
    }
  }

  render() {
    const { t } = this.props;

    const { initLoading, loading, list, count, pageType, showOtherQrCode } = this.state



    const loadMore =
      (!initLoading && !loading) && (count > this.state.data.length) ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button shape="round" onClick={() => this.onLoadMore()}>Devamını Yükle</Button>
        </div>
      ) : null;


    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 0 },
      },
    };

    const otherCodes =
      showOtherQrCode ?
        (
          <div>
            <Divider />
            <Row justify="space-between" type="flex">
              <div>
                <b>{_.get(this.state, "desk.title")}</b> {` `} masasına ait karekodlar
              </div>
            </Row>


            <List
              itemLayout="horizontal"
              dataSource={_.filter(list, (o) => {
                return (o.deskId == _.get(this.state, "deskId")) && (o._id != _.get(this.state, "_id"))
              })}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Button
                      onClick={() => this.onClickDetail(item)}
                      shape="round"
                      icon={<InfoCircleOutlined />}>Detay</Button>,
                    <Dropdown overlay={menuMoreItem(item)}
                    >
                      <Button shape="circle" icon={<MoreOutlined />} />
                    </Dropdown>
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        icon={<QrcodeOutlined />}
                        // style={{ backgroundColor: '#87d068' }}
                        style={{ backgroundColor: 'rgba(57, 152, 147,0.8)' }}
                      //  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" 
                      />
                    }
                    title={_.get(item, "title")}
                    description={<span>

                      {_.get(item, "description", "")}
                      <small>
                        {`  `}
                        (<Tooltip title={new Date(item.created).toLocaleString()}>{moment(item.created).fromNow()}</Tooltip>)
                      </small>
                    </span>}

                  />
                  <div style={{ marginRight: 10 }}>
                    {_.get(item, "desk.title")}
                  </div>
                  <div>
                    <Switch
                      onChange={(e) => this.onChangeSwitchList(e, item)} defaultChecked={item.active}
                    />

                  </div>
                </List.Item>
              )}
            />
          </div>
        )
        :
        null

    const menuMoreItem = (record) => (
      <Menu onClick={(e) => this.onClickMore(e.key, record)}>
        <Menu.Item key="edit">
          Düzenle
        </Menu.Item>
        {
          /*
                    <SubMenu title="Yazdır">
                  <Menu.Item key="print1">Küçük</Menu.Item>
                  <Menu.Item key="print2">Orta</Menu.Item>
                  <Menu.Item key="print3">Büyük</Menu.Item>
              </SubMenu>
              */
        }
        <Menu.Divider />
        <Menu.Item key="delete">Sil</Menu.Item>
      </Menu>
    );

    const menuMore = (
      <Menu onClick={(e) => this.onClickMenuMore(e.key)} >
        <Menu.Item key="active">
          Tümünü Aktif Duruma Getir
        </Menu.Item>
        <Menu.Item key="passive">
          Tümünü Pasif Duruma Getir
        </Menu.Item>
        <Menu.Item key="print">
          Tümünü Yazdır
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => this.onClickDeleteAll()} key="delete">Tümünü Sil</Menu.Item>
      </Menu>
    );

    let drawerTitle = this.state._id ? "Düzenle" : "Yeni"
    if (pageType == "detail") drawerTitle = "Detay"

    return (
      <div>


        {

          // KOD OKUMA
          //<QrReader/>


        }

        <div
          style={{
            padding: '10px 50px 10px 50px'
          }}
        >

          <PageHeader
            style={{
              marginLeft: -20, marginRight: -20
            }}
            onBack={() => goBack()}
            title={`Karekod`}
            subTitle={`${this.state.count}`}
            extra={
              <span>

                <Button
                  shape="circle-outline"
                  title={t("reload")}
                  onClick={() => this.onClickReload()}
                  icon={<ReloadOutlined />}
                />
                <Dropdown overlay={menuMore}
                >
                  <Button
                    shape="circle-outline"
                    style={{ marginLeft: 5 }}
                    icon={<MoreOutlined />}
                  />
                </Dropdown>

                <Button
                  shape="circle-outline"
                  style={{ marginLeft: 5 }}
                  type="primary"
                  onClick={() => this.onClickNew()}
                  icon={<PlusOutlined />}
                />
              </span>}
          />



          {
            (this.state.count == 0 && !this.state.loading)
            &&
            <div>
              <Button size="large" onClick={() => this.onClickCreateAll()} block type="primary">
                Bütün Masalar İçin Karekod Oluştur
              </Button>
            </div>

          }


          {
            this.state.printAction == "printAll"
            &&
            <div>
              <Descriptions title="Yazdırma Ayarları">
                <Descriptions.Item>
                  <Button style={{ marginRight: 5 }} shape="circle" onClick={() => this.onClickCancelPrintAll()} icon={<CloseOutlined />} type="danger" />
                  <ReactToPrint
                    onBeforePrint={() => this.onBeforePrint()}
                    onAfterPrint={(e) => this.onAfterPrint(e)}
                    trigger={() => {
                      return <Button type="primary" title="Yazdır" icon={<PrinterOutlined />}>
                        YAZDIR
                      </Button>
                    }}
                    content={() => this.componentRef}
                  >
                  </ReactToPrint>
                </Descriptions.Item>
                <Descriptions.Item label="Boyut">
                  <Radio.Group onChange={(e) => this.onChangePrintSize(e.target.value)} defaultValue={_.get(this.state, "size", "middle")}>
                    <Radio.Button value="small">Küçük</Radio.Button>
                    <Radio.Button value="middle">Orta</Radio.Button>
                    <Radio.Button value="big">Büyük</Radio.Button>
                  </Radio.Group>
                </Descriptions.Item>
                <Descriptions.Item label="Açıklama">
                  <TextArea
                    onChange={(e) => this.onChange(e)}
                    placeholder="TR"
                    name="descriptiontr"
                    value={this.state.descriptiontr}
                    autosize={{ minRows: 3, maxRows: 6 }}
                  />
                  <TextArea
                    onChange={(e) => this.onChange(e)}
                    placeholder="EN"
                    name="descriptionen"
                    value={this.state.descriptionen}
                    autosize={{ minRows: 3, maxRows: 6 }}
                  />
                  <Input onChange={(e) => this.onChange(e)} value={this.state.qrreadurl} name="qrreadurl" placeholder="url" />
                </Descriptions.Item>

              </Descriptions>
            </div>
          }






          <List
            className="demo-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={list}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button
                    onClick={() => this.onClickDetail(item)}
                    shape="round"
                    icon={<InfoCircleOutlined />}>Detay</Button>,
                  <Dropdown overlay={menuMoreItem(item)}
                  >
                    <Button shape="circle" icon={<MoreOutlined />} />
                  </Dropdown>
                ]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        icon={<QrcodeOutlined />}
                        // style={{ backgroundColor: '#87d068' }}
                        style={{ backgroundColor: 'rgba(57, 152, 147,0.8)' }}
                      //  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" 
                      />
                    }
                    title={<a target="_blank" href={`${siteInfo.qrMenuUrl}?qr=${item._id}`} >{_.get(item, "title")}</a>}
                    description={<span>

                      {_.get(item, "description", "")}
                      <small>
                        {`  `}
                        (<Tooltip title={new Date(item.created).toLocaleString()}>{moment(item.created).fromNow()}</Tooltip>)
                      </small>
                    </span>}

                  />
                  <div style={{ marginRight: 10 }}>
                    {_.get(item, "desk.title")}
                  </div>
                  <div>
                    <Switch
                      onChange={(e) => this.onChangeSwitchList(e, item)} checked={item.active}
                    />

                  </div>
                </Skeleton>
              </List.Item>
            )}
          />


        </div>


        <Drawer
          title={drawerTitle}
          placement="left"
          width={720}
          visible={this.state.modalOpenCreate}
          onClose={(e) => this.handleCancel(e)}
          bodyStyle={{ paddingBottom: 80 }}
        >


          {
            pageType == "detail"
            &&
            <div>
              <div style={{ textAlign: "center" }}>
                <QrCodeComponent text={`${siteInfo.qrMenuUrl}?qr=${_.get(this.state, "_id")}`} width={256} />
              </div>
              <Descriptions>
                <Descriptions.Item label="İşlem">
                  <span>
                    <CopyToClipboard
                      text={`${siteInfo.qrMenuUrl}?qr=${_.get(this.state, "_id")}`}
                      //   documentTitle={_.get(this.state,"title","qr").toLowerCase()}
                      onCopy={() => this.setState({ copied: true })}>
                      <Button title="Kopyala" onClick={() =>
                        message.info("Kopyalandı")
                      }
                        icon={<ShareAltOutlined />} />
                    </CopyToClipboard>
                  </span>
                  <span>
                    <a
                      href={`${siteInfo.qrMenuUrl}?qr=${_.get(this.state, "_id")}`}
                      target="_blank">
                      <Button title="Aç" >
                        <LinkOutlined />
                      </Button>
                    </a>
                  </span>
                  <span>
                    <ReactToPrint
                      onBeforePrint={() => this.onBeforePrint()}
                      onAfterPrint={(e) => this.onAfterPrint(e)}
                      trigger={() => {
                        return <Button title="Yazdır" icon={<PrinterOutlined />} />
                      }}
                      content={() => this.componentRef}

                    >
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <button ref={el => (this.refOnclickPrintButton = el)} onClick={handlePrint}>Print</button>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                    {/*
                                   
                                   this.state.initPrint
                                   &&
                                   <PrintHook onAfterPrint={()=>this.onAfterPrint()} table={this.state} initFirst={true} />
                                   
                               */}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Başlık">
                  {_.get(this.state, "title")}
                </Descriptions.Item>
                <Descriptions.Item label="Açıklama">
                  {_.get(this.state, "description")}
                </Descriptions.Item>
                <Descriptions.Item label="Durumu">
                  {_.get(this.state, "active", false) ? <Tag color="success">Aktif</Tag> : <Tag color="error">Pasif</Tag>}
                </Descriptions.Item>
                <Descriptions.Item label="Oluşturma Tarihi">
                  {new Date(_.get(this.state, "created")).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Kod">
                  {_.get(this.state, "_id")}
                  <CopyToClipboard
                    text={`${_.get(this.state, "_id")}`}
                    onCopy={() => message.info("Kopyalandı")}>
                    <CopyOutlined title="Kopyala" />
                  </CopyToClipboard>
                </Descriptions.Item>
                <Descriptions.Item label="Masa">
                  <span>
                    <div>

                    </div>
                    <Button onClick={() => this.setState({ showOtherQrCode: !this.state.showOtherQrCode })} type={this.state.showOtherQrCode ? "primary" : "default"}>
                      {_.get(this.state, "desk.title")}
                    </Button>
                  </span>
                </Descriptions.Item>
              </Descriptions>
              {otherCodes}
            </div>
          }
          {
            (pageType == "edit" || pageType == "new")
            &&
            <div>
              <Form {...formItemLayout}>
                <Form.Item
                  required
                  label="Masa"
                >
                  <Select
                    value={this.state.deskId || undefined}
                    onChange={(e) => this.onChangeDesk(e)}
                    name="deskId"
                  >
                    {
                      this.state.deskList.map((v, i) => {
                        return (
                          <Option key={v._id} value={v._id}>{v.title}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Başlık"
                >
                  <Input
                    value={this.state.title}
                    onChange={(e) => this.onChange(e)}
                    name="title"
                  />
                </Form.Item>
                <Form.Item
                  label="Açıklama"
                >

                  <TextArea
                    onChange={(e) => this.onChange(e)}
                    name="description"
                    value={this.state.description}
                    autosize={{ minRows: 3, maxRows: 6 }}
                  />
                </Form.Item>

              </Form>

              <div
                style={{
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e9e9e9',
                  padding: '10px 0',
                  background: '#fff',
                  textAlign: 'right',
                }}
              >
                <Button onClick={(e) => this.handleCancel()} style={{ marginRight: 8 }}>
                  İptal
                </Button>
                <Button onClick={() => this.handleCreate()} type="primary">
                  Kaydet
                </Button>
              </div>
            </div>
          }
        </Drawer>



        <div style={{ display: "none" }}>
          <ComponentToPrint qrcode={this.state} ref={el => (this.componentRef = el)} />
        </div>

      </div>
    )
  }

}



export default withTranslation()(QrCodePage)