import { PageHeader } from "antd";
import React from "react";

export default ({ title, subTitle, noback, extra, nopadding }) => {
    return (
        <PageHeader
            style={{ paddingLeft: 0, paddingRight: 0, padding: nopadding ? 0 : undefined }}
            onBack={noback ? undefined : () => window.history.back()}
            title={title}
            subTitle={subTitle}
            extra={extra}
        />
    )
}