
const IS_DEV = false//process.env.NODE_ENV === "development"

const baseDomainURL = IS_DEV ? 'localhost:3000':`cafeotomasyonu.com`

export const apiBaseUrl = process.env.NODE_ENV !== "development" ? `https://api.${baseDomainURL}` : `http://localhost:4004`


export const GOOGLE_MAPS_API = 'AIzaSyCts3pUviTw-hVjQlSn_cbAB00yS_Lb0tc';



//export const cdnUrl = process.env.NODE_ENV !== "development" ? "https://cdn.piyanbot.com" : "http://localhost:4000"
export const cdnUrl = "https://cdn.cafeotomasyonu.com"

//facebook  //appp name : Piyan meet
export const facebookAppId = "1140265152979412";
export const facebookAppScreet = "1eee2c4d35044202193bae58c76fc0a3";

//google
export const googleClientId = "529245241781-r00lvd4c7o116t7ikjtk3t6r7klbueqi.apps.googleusercontent.com"
export const googlePrivateKey = "h8kECnD9Y7SK12_Ltln1Mxzv"


export const getToken = () => {
    return localStorage.getItem('token');
}


//dil ayarları

export const getDefaultLanguage = () => {
    const lang = localStorage.getItem('language');
    return lang ? lang : "tr";
}
export const setDefaultLanguage = async (lang) => {

}

//yazdırma ayarları

const defaultPrintData = {
    descriptionen: "Please enter the link below from your web browser and read the code",
    descriptiontr: "Lütfen web tarayıcınızdan aşağıdaki linke girip kodu okutun",
    qrreadurl: "menu.cafeotomasyonu.com",
    size: "middle"
}

export const getPrintConfig = () => {
    const printconfig = localStorage.getItem('printconfig');
    let data = null
    try {
        data = JSON.parse(printconfig)
    } catch (error) {
        data = defaultPrintData
    }
    return (data && data.descriptionen != "") ? data : defaultPrintData
}
export const setPrintConfig = (data) => {
    try {
        localStorage.setItem("printconfig", JSON.stringify(data))
    } catch (error) {
        data = defaultPrintData
        localStorage.setItem("printconfig", JSON.stringify(data))
    }

    return data
}

const applicationKey = "smartcafe";
const companycode = localStorage.getItem('companycode');
export const cdnConfig = {
    api: "https://pistatic.piyanos.com",
    prefix: "/upload/publicdata",
    full_url: "https://pistatic.piyanos.com/upload/publicdata",
    token: "basitguvenliksonra-dinamk-34",
    key: applicationKey,
    companycode
}

/// ürün ismi
//export const appName = pack.name;
export const appName = "smartcafe";

//exports
export const testtoken = "123123";
export const API_URL = `${apiBaseUrl}/api`; // "http://localhost:3232/api" //`${apiBaseUrl}/api`;


export const siteInfo = {
    siteName: 'Piyanos',
    //   copyright: `Yönetim Paneli © ${new Date().getFullYear()} Piyanos Technology`,
    copyright: `Yönetim Paneli © ${new Date().getFullYear()}`,
    clientUrl: `https://www.${baseDomainURL}`,
    contactUrl: `https://${baseDomainURL}/#contact`,
    panelUrl: IS_DEV ? `http://${baseDomainURL}` : `https://panel.${baseDomainURL}`,
    menuWebUrl: `https://menu.${baseDomainURL}`,
    panelRegisterUrl: `https://panel.${baseDomainURL}/register`,
    documentUrl: "https://www.piyanos.com/rest/doc/panel.pdf",
    privacyUrl: "https://www.privacypolicygenerator.info/live.php?token=v7t6qJr0kKs1waHPmsRyI9B1vtVJfmY7",
    appName: "Smartcafe",
    appFirstName: "smart",
    appSecondName: "cafe",
    logoWhite: "https://pistatic.piyanos.com/uploads/smartcafe/smartcafelogo-name-white.png",
    logo: "https://pistatic.piyanos.com/uploads/smartcafe/client/smartcafe-logo-v2.png",
    logoRedWname:"https://pistatic.piyanos.com/uploads/smartcafe/smartcafe-logo-name-red-noback.png",
    googlePlayUrl: "https://play.google.com/store/apps/details?id=com.piyanos.smartcafe",
    qrMenuUrl: `https://menu.${baseDomainURL}`
}


export const primaryColor = "#ff3f34";


/*
COLOR :
const topColor = "#33d9b2"
#ff3f34
#0fbcf9

module.exports ={
    API_URL,
    siteInfo

}
*/