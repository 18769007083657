import React,{Component} from 'react';
import {message,Table,Descriptions, Switch,Row,Button,Input,Icon,Radio,Modal,Popconfirm,Tag,Tooltip,notification,Drawer,Divider, Card, Col,Typography } from 'antd'

import _ from 'lodash';
import moment from 'moment';


const {Text} = Typography;



export default  class SalesTable extends Component{
    constructor(props){
        super(props);
        this.state = {
        data:[],
        loading:false,
        visibleEditedSales:false,
        modalVisible:false,
        detailItem:null,
        customer:null,
        paymentTypes:[
            {i:0,title:'Nakit'},
            {i:1,title:'Kredi Kartı'}
          ],
        priceTypes:[
            {i:1,title:'Parakende',key:'price1'},
            {i:2,title:'Toptan',key:'price2'},
            {i:3,title:'Toptan üstü',key:'price3'}
        ],
        page:'list',
        editedSales:[]
        }

        this.getItemList=this.getItemList.bind(this);
        this.reload=this.reload.bind(this);
        this.handleCancel=this.handleCancel.bind(this);
        this.handleOk=this.handleOk.bind(this);
        this.setPage = this.setPage.bind(this);
        
      }

      componentDidMount(){
          console.log("customer",this.props.customer)
          if(this.props.customer){
              this.setState({customer:this.props.customer},()=>{
                this.getItemList();
              })
          }
          else{
               this.getItemList();
          }
       
       
      }


      setPage(data){   //setPropsData
        console.log(data)
          this.setState({
            editPageData:null,
            page:'list'
          },()=>{
            //düzenlemeyi iptal ettiyse
            if(data.type==0){
            }
            if(data.type==1){
                message.info('Düzenleme işlemi başarılı')
            }
         //   this.resetStateData()
            this.getItemList();
          })
    }

    onClickEditSales(record){
        this.setState({
            editPageData:record
        },()=>{
            this.setState({page:'edit'})
        })
    }

    async getItemList(){
        if(this.state.customer){
            db.getAll(tableSales,{customerId:this.state.customer.id}).then(async(result)=>{
              this.setState({data:_.orderBy(result, ['created'],['desc'])});
            })
        }
        else{
            db.getAll(tableSales).then(async(result)=>{
                result.map((v)=>{
                       db.getItem(tableCustomer,{id:v.customerId}).then((customer)=>{
                       v.customer=customer;
                       })
                })
              this.setState({data:_.orderBy(result, ['created'],['desc'])});
            })
        }
    }

    handleOk(){
        this.setState({modalVisible:false})
    }
    handleCancel(){

    }
    reload(){
        this.getItemList();
        //  this.props.reload();
        ipcRenderer.send('ipc-refresh');
    }



    renderPaymentType(record){
        let paymenttType = _.find(this.state.paymentTypes,{i:Number.parseInt(record.paymentType)})
        if(paymenttType) return paymenttType.title;
        return ''
    }


    onClickCustomer(customer){
        customer.type = 'view'
        this.props.onClickCustomer(customer)
    }

    onClickEditedSales(record){
        console.log(record)
        db.get(tableSalesEdited,{salesId:record.id}).then((res)=>{
            console.log(res)
            this.setState({editedSales:res},()=>{
                this.setState({visibleEditedSales:true})
            })
        })
    }

    deleteSales(record){
        db.delete(tableSales,{id:record.id}).then(()=>{
            message.success('Başarılı');
            this.getItemList();
        })
    }

    onClickprintSales(record){
 
    }
    
    render() {
        const {data,loading} = this.state;

        const localeMain = {
            filterConfirm: 'Tamam',
            filterReset: 'Sıfırla',
            emptyText: 'Veri Yok' 
          }
        const columns = [
          {
            title: 'Zaman',
            key: 'created',
            render: (text,record)=><span>
                  <Tooltip title={new Date(record.created).toLocaleString()}>
                    <span>{moment(record.created).fromNow()}</span>
                </Tooltip>
          </span>
          },
          {
            title: 'Ödeme Tipi',
            key: 'priceType',
            render: (text,record)=>
            <span>
               {this.renderPaymentType(record)}
          </span>
          },
          {
                title: 'Tutar',
                key: 'totalPrice',
                render:(text,record)=>{
                    return(
                        <span style={{color:'#2F3640'}}>
                             <a> {record.totalPrice}{' '}TL</a> 
                          
                         
                        </span>
                    )
                }
         },
        ];

        const columnsGroupsCustomerIdDetailProduct=(record)=>[
            {
              title:'Adet',
              key:'count',
              dataIndex:'count'
            },
            {
              title:'Ürün adı',
              key:'title',
              dataIndex:'title'
            },
            {
              title:'Birim Fiyatı',
              key:'price',
              render:(text,v)=>
              <span>
                      {v.price.toFixed(2)}
              </span>
             },
             {
              title:'Tutar',
              key:'totalPrice',
              render:(text,v)=>
              <span>
                   {parseInt(v.count)*parseFloat(v.price)}
              </span>
             }
        ]

        

        return (
         <div>
             {
                 this.state.page=='list' &&
        <div className="card-container">
              <Table
                    size="small"
                    locale ={localeMain}
                    loading ={loading}
                    rowKey={'id'}
                    columns={columns} 
                    dataSource={data} 
                    expandRowByClick={true}
                    expandedRowRender={(record)=>
                      <span>
                            <Descriptions
                            style={{backgroundColor:'#FFF'}}
                            size="small"
                            title="Satış Detayları"
                             bordered
                             layout="vertical"
                              >
                            <Descriptions.Item label="Toplam Tutar"><span style={{fontWeight:'bold'}}>{` ${record.totalPrice} TL`}</span></Descriptions.Item>
                            <Descriptions.Item label="Ödeme Tipi">
                            {this.renderPaymentType(record)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Tarih">{new Date(record.created).toLocaleString()}</Descriptions.Item>
                            {
                                !this.state.customer
                                &&
                                <Descriptions.Item label="Müşteri">
                                {
                                    (record.customer)
                                    ?
                                    <div>
                                        <a onClick={()=>this.onClickCustomer(record.customer)}>
                                            {record.customer.name}
                                        </a>
                                    </div>
                                    :
                                    <span style={{textDecoration:"line-through"}}>Kayıtsız Müşteri</span>
                                }
                                </Descriptions.Item>
                            }
                            <Descriptions.Item label="İskonto">
                                {
                                    parseInt(record.discount)>0
                                     ?
                                    <div>
                                        <Text type="danger">
                                        % {record.discount}
                                        </Text>
                                    </div>
                                    :
                                    <div>
                                        Uygulanmadı
                                    </div>
                                }
                             </Descriptions.Item>
                             <Descriptions.Item label="Adres">
                                {record.address}
                             </Descriptions.Item>
                             <Descriptions.Item label="Diğer">
                                 <Button
                                       size='small'
                                       style={{marginBottom:5}}
                                       block
                                       onClick={()=>this.onClickprintSales(record)}
                                       >
                                            Yazdır
                                 </Button>
                                {
                                        (record.updated)
                                        &&
                                        <span>
                                            <Tooltip title= {`En son ${new Date(record.updated).toLocaleString()} tarihinde Düzenlendi`}>
                                                <Button
                                                size='small'
                                                style={{marginBottom:5,color:'purple'}}
                                                block
                                                onClick={()=>this.onClickEditedSales(record)}>
                                                  Düzenlendi
                                                </Button>
                                            </Tooltip>
                                        </span>
                                    }
                                <Button
                                        block
                                        size='small'
                                        type='primary'
                                        style={{marginBottom:5}}
                                        onClick={()=>this.onClickEditSales(record)}
                                   >
                                      Düzenle
                                    </Button>
                                    <Popconfirm okText="Evet İptal Et" cancelText="İptal" title={`Emin misiniz? ${record.totalPrice} TL tutarında satış iptali yapmak üzeresiniz.`}
                                        onConfirm={()=>{this.deleteSales(record)}}>
                                              <Button
                                               block
                                               style={{marginBottom:5}}
                                                size='small'
                                                type='danger'
                                                >
                                        Satışı İptal Et
                                    </Button>
                            </Popconfirm>  
                             </Descriptions.Item>

                             <Descriptions.Item label="Ürünler">
                             <Table 
                                rowKey={'id'}
                                style={{backgroundColor:'#fff'}}
                                columns={columnsGroupsCustomerIdDetailProduct(record)} 
                                loading={this.state.loading}
                                dataSource={record.items}
                                size='small'
                               />
                             </Descriptions.Item>
                         </Descriptions> 
                      </span>
                    }
                      />

                      <Row>
                          {
                              this.state.customer
                              &&
                              <Descriptions> 
                              <Descriptions.Item label="Toplam Tutar">
                                  {_.sumBy(this.state.data,(o)=>{
                                      return parseFloat(o.totalPrice)
                                  }).toFixed(2)}
                              </Descriptions.Item>
                              <Descriptions.Item label="Sipariş Sayısı">
                                  {this.state.data.length}
                              </Descriptions.Item>
                         </Descriptions> 
                          }
                      </Row>
          </div>
            }


             <Drawer
             title="Düzenleme Geçmişi"
             width={700}
             bodyStyle={{ paddingBottom: 80,margin:0 }}
             placement="right"
             visible={this.state.visibleEditedSales}
             onClose={()=>this.setState({visibleEditedSales:false,editedSales:[]})}
             >
             {
                 this.state.editedSales.map((record,i)=>{
                     return(
                         <div key={i}>
                 <p> Düzenleme Tarihi{` : ${new Date(record.updated).toLocaleString()}`}</p>
                 <p> Toplam Tutar{` : ${record.totalPrice} TL`}</p>
                      <Table
                        rowKey={'id'}
                        style={{backgroundColor:'#fff'}}
                        columns={columnsGroupsCustomerIdDetailProduct(record)} 
                        loading={this.state.loading}
                        dataSource={record.items}
                        size='small'
                       />
                         </div>
                     )
                 })
             }
             </Drawer>

     </div>
        );
    }
}
