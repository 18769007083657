

import React, { Component } from "react";
import { inject, observer } from 'mobx-react';
import Loading from "../../components/Loading"
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import DetailPage from './Detail'
import DesksPage from './Desks'

const queryString = require('query-string');

@inject('UserStore')
@observer
class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const { loading } = this.props.UserStore
        const parsedHash = queryString.parse(window.location.search);
        if (loading) {
            return (<Loading />)
        }
        else if (parsedHash.d) {
           return <DetailPage deskId={parsedHash.d}/>
        }
        else {
            return (
                <DesksPage/>
            )
        }
    }
}


export default Container