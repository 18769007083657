import React, { Component } from "react";

import {
  Form,
  Select,
  InputNumber,
  Switch,
  Radio,
  Slider,
  Button,
  Upload,
  Rate,
  Checkbox,
  Row,
  Col,
  Tooltip,
  Descriptions,
  Avatar,
  Input
} from 'antd';
import {
  UploadOutlined,
  InboxOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import PageHeader from "../components/PageHeader";
import Loading from "../components/Loading";
import { inject, observer } from 'mobx-react';


import _ from "lodash"
import { cdnConfig } from "../config";
import axios from "axios"

const { Option } = Select;
const { TextArea } = Input




@inject('UserStore')
@observer
export default class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      search: '',
      activeTabsKey: 'user',
      loading: false,
      hasMore: true,
      limit: 20,
      offset: 0
    }
  }


  componentDidMount = () => {
    this.props.UserStore.getProfile()
  }

  onChangeSwitch = async (e, key) => {
    //visiblePrice  |  deliveryService
    if (key == "visiblePrice") {
      this.props.UserStore.setPriceVisible(e);
    }
    else if (key == "deliveryService") {
      this.props.UserStore.setDeliveryService(e);
    }
    else if (key == "active") {
      this.props.UserStore.setCompanyActive(e);
    }
    else {
      console.log("!karşılıksız")
    }
  }

  onClickVisiblePrice = async (e) => {
    try {
      const serverData = await this.props.UserStore.setPriceVisible(e);
      console.log(serverData)
    } catch (error) {
      console.log(error)
    }
  }




  onSubmit = async () => {
    const company = _.get(this.props.UserStore, "user.company", {})
    const file = this.state.file
    let data = {
      name: this.state.companyName,
      phone: this.state.companyPhone,
      address: this.state.companyAddress,
      logo: company.logo

    }
    console.log("on submit updateCompany")
    try {
      //logo varsa
      if (file) {
        //            logo:this.state.companyLogo,
        let uImage = await this.uploadImageV1(file)
        console.log({ uImage })
        data.logo = uImage.data.path
      }
      const serverData = await this.props.UserStore.updateCompany(data);
      console.log(serverData)
      this.setState({ editCompany: false })
      this.props.UserStore.getProfile()
    } catch (error) {
      console.log(error)
    }
  }



  uploadImageV1 = (file) => {
    const url = cdnConfig.full_url
    const formData = new FormData()
    formData.append("file", file)
    formData.append("token", cdnConfig.token)
    formData.append("key", cdnConfig.key)
    formData.append("subkey", `product`)
    const headers = { 'content-type': 'multipart/form-data', }
    return axios.post(url, formData, { headers: headers })
  }


  onChangeImage = (e) => {
    let file = e.target.files[0]
    this.setState({
      file: file
    })
    this.getBase64(file, (img) => {
      this.setState({
        companyLogo: img
      })
    })
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {

    const { loading, user } = this.props.UserStore
    const { editCompany } = this.state


    const company = _.get(user, "company", {})

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };


    if (loading) {
      return (
        <Loading />
      )
    }



    return (
      <div>
        <PageHeader title="Ayarlar" />
        <Form
          name="validate_other"
          {...formItemLayout}
          initialValues={{
            'input-number': 3,
            'checkbox-group': ['A', 'B'],
            rate: 3.5,
          }}
        >

          <Form.Item label={<span>
            Paket Servisi&nbsp;
            <Tooltip title="Dışardan sipariş alma durumu">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>} valuePropName="checked">
            <Switch onChange={(e) => this.onChangeSwitch(e, "deliveryService")}
              defaultChecked={_.get(user, "company.deliveryService")}
            />
          </Form.Item>

          <Form.Item label={<span>
            Fiyat Görünürlüğü&nbsp;
            <Tooltip title="Kullanıcılar ürünlerinizi listelerken ürün fiyatını görür veya görmez">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>} valuePropName="checked">
            <Switch onChange={(e) => this.onChangeSwitch(e, "visiblePrice")}
              defaultChecked={_.get(user, "company.visiblePrice")}
            />
          </Form.Item>

          <Form.Item label={<span>
            Genel Görünürlük&nbsp;
            <Tooltip title="Ortak platformlarımızda görünme durumunuz">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>} valuePropName="checked">
            <Switch onChange={(e) => this.onChangeSwitch(e, "active")}
              defaultChecked={_.get(user, "company.active")}
            />
          </Form.Item>

        </Form>




        <div style={{ padding: '0 50px', paddingTop: 20 }}>
          <Descriptions title="Firma Bilgileri">
            <Descriptions.Item>
              {
                editCompany
                  ?
                  <Button onClick={() => this.onSubmit()} type="primary">
                    Kaydet
                  </Button>
                  :
                  <Button onClick={() => this.setState({
                    editCompany: true,
                    companyName: company.name,
                    companyPhone: company.phone,
                    companyAddress: company.address,

                  })}>
                    Düzenle
                  </Button>
              }
            </Descriptions.Item>
            <Descriptions.Item label="Logo">
              {
                editCompany
                  ?
                  <input name="companyLogo" onChange={(e) => this.onChangeImage(e)} accept="image/*" type="file" placeholder="Logo yükle" />
                  :
                  <Avatar src={company.logo} />
              }

            </Descriptions.Item>
            <Descriptions.Item label="Firma Adı">
              {
                editCompany
                  ?
                  <Input value={_.get(this.state, "companyName", "")} name="companyName" onChange={(e) => this.onChange(e)} />
                  :
                  _.get(company, "name", "")
              }
            </Descriptions.Item>
            <Descriptions.Item label="Adres">
              {
                editCompany
                  ?
                  <TextArea value={_.get(this.state, "companyAddress", "")} name="companyAddress" onChange={(e) => this.onChange(e)} />
                  :
                  _.get(company, "address", "")
              }
            </Descriptions.Item>
            <Descriptions.Item label="Telefon">
              {
                editCompany
                  ?
                  <Input value={_.get(this.state, "companyPhone", "")} name="companyPhone" onChange={(e) => this.onChange(e)} />
                  :
                  _.get(company, "phone", "")
              }
            </Descriptions.Item>
          </Descriptions>
        </div>

      </div>
    )
  }

}