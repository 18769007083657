import axios from "axios";
import { API_URL, testtoken, cdnConfig } from "../config";

export default class Service {
    constructor(token = testtoken) {
        // this.init()
        //   this.token = testtoken
        this.axiosConfig = { headers: { Authorization: token, 'Content-Type': 'application/json' } }
    }

    getLogs(date) {
        let url = `${API_URL}/logs${date ? `?date=${date}` : ""}`;
        return axios.get(url, this.axiosConfig);
    }
    //
    getDashboardChart() {
        const url = `${API_URL}/dashboard/chart`
        return axios.get(url, this.axiosConfig);
    }
    //dashboard kısayol sayıları
    getDashboardCount() {
        const url = `${API_URL}/dashboard/count`
        return axios.get(url, this.axiosConfig);
    }


    ////////////////  -home slider -//////////////////////////////////
    uploadHomeSlider(file) {
        const FormData = require('form-data')
        const url = `${cdnConfig.full_url}`;
        const formData = new FormData();
        formData.append('token', cdnConfig.token);
        formData.append('key', cdnConfig.key);
        formData.append('subkey', "slider");
        formData.append('file', file)
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
        return axios.post(url, formData, headers);
    }
    uploadScratchAndWinImage(file) {
        const FormData = require('form-data')
        const url = `${cdnConfig.full_url}`;
        const formData = new FormData();
        formData.append('token', cdnConfig.token);
        formData.append('key', cdnConfig.key);
        formData.append('subkey', "scratchandwin");
        formData.append('file', file)
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
        return axios.post(url, formData, headers);
    }
    uploadPunchCardImage(file) {
        const FormData = require('form-data')
        const url = `${cdnConfig.full_url}`;
        const formData = new FormData();
        formData.append('token', cdnConfig.token);
        formData.append('key', cdnConfig.key);
        formData.append('subkey', "punchcard");
        formData.append('file', file)
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
        return axios.post(url, formData, headers);
    }
    uploadImage(file, key) {
        const FormData = require('form-data')
        const url = `${cdnConfig.full_url}`;
        const formData = new FormData();
        formData.append('token', cdnConfig.token);
        formData.append('key', cdnConfig.key);
        formData.append('subkey', key);
        formData.append('file', file)
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
        return axios.post(url, formData, headers);
    }
    getHomeSliders() {
        const url = `${API_URL}/admin/slider`;
        return axios.get(url, this.axiosConfig);
    }
    createHomeSlider(data) {
        const url = `${API_URL}/admin/slider`;
        return axios.post(url, data, this.axiosConfig);
    }

    deleteHomeSlider(id) {
        const url = `${API_URL}/admin/slider/delete/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }
    updateHomeSliderStatus(id, data) {
        const url = `${API_URL}/admin/slider/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    updateHomeSliderSort(data) {
        const url = `${API_URL}/admin/slider/update-sort`;
        return axios.post(url, data, this.axiosConfig);
    }
    ////////////////  -home slider -end//////////////////////////////

    ////////////  SADAKAT  //////////////////////////////////////
    getLoyaltyList(type) {
        const url = `${API_URL}/admin/loyalty?type=${type}`;
        return axios.get(url, this.axiosConfig);
    }
    createLoyalty(data) {
        const url = `${API_URL}/admin/loyalty`;
        return axios.post(url, data, this.axiosConfig);
    }
    removeLoyalty(id) {
        const url = `${API_URL}/admin/loyalty/delete/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }
    updateLoyalty(id, data) {
        const url = `${API_URL}/admin/loyalty/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    ////////////  SADAKAT end  //////////////////////////////////////

    ///personel start////////////////////////////////////////////
    getUserStaffs() {
        const url = `${API_URL}/admin/userstaff`;
        return axios.get(url, this.axiosConfig);
    }
    createUserStaffs(data) {
        const url = `${API_URL}/admin/userstaff`;
        return axios.post(url, data, this.axiosConfig);
    }
    deleteUserStaffs(id) {
        const url = `${API_URL}/admin/userstaff/delete/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }
    ///personel end////////////////////////////////////////////

    getReservations() {
        const url = `${API_URL}/admin/reservation`;
        return axios.get(url, this.axiosConfig);
    }


    ////////////////////////////////////////



    ///MasterAdmin start////////////////////////////////////////////
    //RestaurantCategory
    getRestaurantCategory() {
        const url = `${API_URL}/v1/master/restaurant-category/list`;
        return axios.get(url, this.axiosConfig);
    }
    //RestaurantCategory
    createRestaurantCategory(data) {
        const url = `${API_URL}/v1/master/restaurant-category/create`;
        return axios.post(url, data, this.axiosConfig);
    }
    //RestaurantCategory
    updateRestaurantCategory(id, data) {
        const url = `${API_URL}/v1/master/restaurant-category/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    //RestaurantCategory Sil
    removeRestaurantCategory(id) {
        const url = `${API_URL}/v1/master/restaurant-category/remove/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }



    //Restaurant Listesi
    getRestaurantList() {
        const url = `${API_URL}/v1/master/restaurant/list`;
        return axios.get(url, this.axiosConfig);
    }
    //Restaurant Ekle
    createRestaurant(data) {
        const url = `${API_URL}/v1/master/restaurant/create`;
        return axios.post(url, data, this.axiosConfig);
    }
    //Restaurant Güncelle
    updateRestaurant(id, data) {
        const url = `${API_URL}/v1/master/restaurant/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }
    //Restaurant Sil
    removeRestaurant(id) {
        const url = `${API_URL}/v1/master/restaurant/remove/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }


    //Users
    getUsersForMaster() {
        const url = `${API_URL}/v1/master/user/list`;
        return axios.get(url, this.axiosConfig);
    }
    //Kullanıcı Güncelle
    updateUser(id, data) {
        const url = `${API_URL}/v1/master/user/update/${id}`;
        return axios.post(url, data, this.axiosConfig);
    }






    ///MasterAdmin end////////////////////////////////////////////


    getHomeRooms() {
        const url = `${API_URL}/home/rooms`;
        return axios.get(url, this.axiosConfig);
    }

    sendFeedback(data) {
        const url = `${API_URL}/home/feedback`;
        return axios.post(url, data, this.axiosConfig);
    }
    searchUsers(text) {
        const url = `${API_URL}/users/search`;
        return axios.post(url, { search: text }, this.axiosConfig);
    }

    getHomeItems() {
        return new Promise((resolve, reject) => {
            const url = `${API_URL}/home`;
            axios.post(url, this.axiosConfig).then(res => {
                return resolve(res.data)
            }).catch(err => {
                return reject(err)
            })
        })
    }




}