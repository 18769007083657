

import React, { Component } from 'react';
import {
    message,
    Table,
    Select, Drawer, Switch, Row, Button, Input, Form, Modal, Popconfirm, Tooltip, notification, Radio, Col, Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag

} from 'antd';

import { Link } from 'react-router-dom';

import { PlusOutlined, DeleteOutlined, ReloadOutlined, UploadOutlined, InfoOutlined } from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import axios from "axios"

import _ from "lodash"

import { withTranslation } from 'react-i18next';
import { cdnConfig, cdnUrl } from '../../config';

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;



// 
@inject('HomeStore')
@observer
class RestaurantCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            categoryList: [],
            file: null,
            avatar: null,
            title: '',
            description: '',
            _id: '',
            modalOpenCreate: false
        }
    }

    componentDidMount = () => {
        this.getItemList()
    }

    //#liste
    getItemList = async () => {
        this.props.HomeStore.getRestaurantCategory();
    }

    resetState() {
        this.setState({
            _id: '',
            title: '',
            description: '',
            modalOpenCreate: false,
            fileAvatar: null,
            avatar: null,
            rank: 0
        })
    }

    reload = () => {
        this.getItemList()
    }

    //#ekle
    handleCreate = async () => {
        const {
            title,
            description,
            fileAvatar,
            rank,
            _id,
            avatar,

        } = this.state

        console.log("handleCreate:state", this.state)
        if (!title) {
            message.error("Başlık zorunlu")
            return
        }

        let data = {
            title,
            description,
            fileAvatar,
            rank
        }

        //yeni kayıt//////////////
        if (!_id) {
            await this.props.HomeStore.createRestaurantCategory(data)
            this.resetState()
        }
        ///////Güncelleme//////////////////
        else {
            data.avatar = avatar
            await this.props.HomeStore.updateRestaurantCategory(_id, data)
            this.resetState()
        }
    }


    //#sil
    confirmDelete = (item) => {
        this.props.HomeStore.removeRestaurantCategory(item._id)
    }

    //düzenle click
    clickEdit = (item) => {
        //   this.titleInput.focus()
        console.log(item)
        this.setState({
            ...item
        }, () => {
            this.setState({ modalOpenCreate: true })
        })
    }






    handleCancel() {
        this.resetState()
    }



    onChangeImage = (e, key) => {
        console.log(e)
        console.log({ file: e.target.files })
        let file = e.target.files[0]
        this.setState({
            [key]: file
        })
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeSwitchList = async (active, item) => {
        this.props.HomeStore.updateRestaurantCategory(item._id, { type: "active", active })
    }

    updateState = (state, _id) => {
        console.log("updateState", { state, _id })
    }

    render() {
        const { t } = this.props

        //  const { data, loading } = this.state
        const {
            restaurantCategoryList: data,
            loading
        } = this.props.HomeStore


        const handleFocus = (event) => event.target.select();

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16, offset: 0 },
            },
        };


        const columns = [
            {
                title: '',
                key: 'avatar',
                render: (text, record) =>
                    <span>
                        {
                            record.avatar ? <Avatar shape="square" src={record.avatar} /> : <Avatar shape="square">{_.get(record, "title", "").charAt(0)}</Avatar>
                        }
                    </span>
            },
            {
                title: 'Kategori Adı',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Kategori Açıklaması',
                key: 'description',
                render: (text, record) => <span>
                    <Text type="secondary" >{record.description}</Text>
                </span>
            },
            {
                title: 'Oluşturulma',
                key: 'created',
                render: (text, record) => <span>
                    {new Date(record.created).toLocaleDateString()}
                </span>
            },
            {
                title: 'Durum',
                key: 'active',
                render: (text, record) => <span>
                    <Switch size={'small'} onChange={(e) => this.onChangeSwitchList(e, record)} defaultChecked={record.active} />
                </span>
            },
            {
                title: 'İşlem', key: 'action', render: (record) => {
                    return (
                        <span>
                            <Button onClick={() => this.clickEdit(record)} size="small">
                                Düzenle
                            </Button>
                            <Divider type="vertical" />

                            {
                                /**
                                 *                       <Button size="small" type="primary" icon={<InfoOutlined/>}/>
                                                  <Divider type="vertical"/>
                                 */
                            }
                            <Popconfirm okText="Evet" cancelText="Hayır" title={`Emin misiniz? ${_.get(record, "products", []).length > 0 ? `Kategoriye ait ${_.get(record, "products", []).length} adet ürün de silinecektir!!` : ""}`}
                                onConfirm={() => { this.confirmDelete(record) }}>
                                <Button type="danger" size="small">
                                    Sil
                                </Button>
                            </Popconfirm>
                        </span>
                    )
                }
            },
        ];



        return (
            <div>
                <Card
                    loading={loading}
                    title={'Kategori Listesi'}
                    extra={<span>

                        <Button
                            icon={<PlusOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => this.setState({ modalOpenCreate: true, modalTitle: "Yeni" })} />
                        <Button type="primary"
                            shape="circle"
                            icon={<ReloadOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => this.reload()} />
                    </span>}
                >


                    <div>

                        <Table
                            rowKey={'_id'}
                            columns={columns}
                            pagination={{ pageSize: 50 }}
                            size="small"
                            dataSource={data}
                            loading={loading}
                        />
                    </div>

                </Card>


                <Drawer
                    title={this.state._id ? "Düzenle" : "Yeni"}
                    placement="left"
                    width={720}
                    visible={this.state.modalOpenCreate}
                    onClose={(e) => this.handleCancel(e)}
                    bodyStyle={{ paddingBottom: 80 }}
                >

                    <Form {...formItemLayout}>
                        <Form.Item
                            label="Başlık"
                            required >
                            <Input
                                value={this.state.title}
                                onChange={(e) => this.onChange(e)}
                                name="title" />
                        </Form.Item>

                        <Form.Item
                            label="Açıklama"
                        >
                            <TextArea
                                onChange={(e) => this.onChange(e)}
                                name="description"
                                value={this.state.description}
                                autosize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Form.Item>


                        <Form.Item
                            label="Sıra"
                        >
                            <Input
                                onFocus={handleFocus}
                                value={this.state.rank}
                                onChange={(e) => this.onChange(e)}
                                name="rank"
                                type="number" />
                        </Form.Item>


                        <Form.Item
                            label="İcon Medya"
                            help=".png,svg yada webp uzantılı en fazla 256px çözünürlüğe sahip görsel tavsiye edilir."
                        >
                            <span>
                                {
                                    this.state.avatar ? <Button block type="danger" size="small" onClick={() => this.setState({ avatar: null })}>Sil <DeleteOutlined /></Button> :
                                        <input onChange={(e) => this.onChangeImage(e, "fileAvatar")} accept="image/*" type="file" placeholder="Fotoğraf seç" />
                                }
                            </span>
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                {this.state.avatar && <img src={this.state.avatar} style={{ maxWidth: "100%" }} alt="avatar" />}
                            </div>
                        </Form.Item>




                    </Form>

                    <div
                        style={{
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 0',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
                            İptal
                        </Button>
                        <Button onClick={() => this.handleCreate()} type="primary">
                            Kaydet
                        </Button>
                    </div>
                </Drawer>

            </div>
        )
    }
}

export default withTranslation()(RestaurantCategory)