


import PropTypes from 'prop-types';

import React from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Card,
    Carousel,
    Icon,
    PageHeader
} from 'antd';
import { Link } from 'react-router-dom';

import {
    EyeOutlined,
    PlusCircleTwoTone,
    PhoneOutlined,
    HomeOutlined,
    UserOutlined,
    FacebookOutlined,
    InstagramOutlined
} from '@ant-design/icons';

const {
    Header,
} = Layout;

const CompanyPageHeader = ({
    title,
    subTitle,
    noback,
    extra,
    nopadding = true
}) => {
    return (
        <PageHeader
            style={{ paddingLeft: 0, paddingRight: 0, padding: nopadding ? 0 : undefined }}
            onBack={noback ? undefined : () => window.history.back()}
            title={title}
            subTitle={subTitle}
            extra={extra}
        />
    )
}

export default CompanyPageHeader;