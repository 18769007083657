import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Input, Table, Dropdown, Button, Space, Drawer, Checkbox, InputNumber, Menu, Card, message, Modal, Tag, Divider, Radio, Descriptions, Select, Typography, Tabs } from 'antd'
import {
    ReloadOutlined,
    PlusOutlined,
    UserAddOutlined,
    QuestionCircleOutlined,
    ArrowLeftOutlined,
    CloseOutlined,
    MessageOutlined,
    SaveOutlined,
    InfoCircleOutlined,
    QrcodeOutlined,
    MoreOutlined,
    ShareAltOutlined,
    LinkOutlined,
    PrinterOutlined,
    CopyOutlined,
    SearchOutlined,
    EditOutlined,
    CheckOutlined,
    MinusOutlined,
    ScanOutlined,
    DeleteOutlined,
    RetweetOutlined
} from '@ant-design/icons';

import { useHistory, useLocation } from 'react-router';

import FullScreen from 'react-request-fullscreen';
import { v4 as uuidv4, v4 } from 'uuid';

import _ from 'lodash'
import { inject, observer } from 'mobx-react';
import { StickyContainer, Sticky } from 'react-sticky';
import { isNumeric } from '../../helper';

import Loading from "../../components/Loading"


import ReactToPrint from "react-to-print";
import ToPrintPos from '../../components/ToPrintPos';


import ObjectID, { toHexString } from 'bson-objectid';
import { siteInfo } from '../../config';

const queryString = require('query-string');

//var ObjectID = require("bson-objectid");

const { TabPane } = Tabs;
const { Text } = Typography;

@inject('UserStore', 'HomeStore', 'ProductStore', 'GeneralStore', 'OrderStore')
@observer
export default class Pos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            paymentTypes: [
                { i: 2, key: "online", title: 'Online' },
                { i: 0, key: "cashPayment", title: 'Nakit' },
                { i: 1, key: "creditCard", title: 'Kredi Kartı' },
                { i: 3, key: "other", title: 'Diğer' },
            ],
            modalVisible: false,
            modalProductVisible: false,
            noBarcodePrice: '',
            searchCustomer: '',
            productList: [],
            productListFiltered: [],
            customerList: [],
            categoryList: [],
            selectedCategory: 0,
            isTick: false,
            barcode: '',
            paid: 0,
            selectedCustomer: null,
            selectedAddress: null,
            isnewCustomer: false,
            newCustomerName: '',
            isBigText: false,
            selectedPaymentType: "cashPayment",
            selectedPriceType: "1",
            changedTotalPrice: null,
            discount: 0,
            temp: null,
            loadingCaller: false,
            isFullScreen: false
        }
    }



    componentDidMount = () => {
        this.initData()
    }

    initData = async () => {
        try {
            await this.getItemListCategory()
            await this.getDeskList()
            await this.getDeskOrder()

        } catch (error) {
            console.log("ERR", error)
        }
    }
    getDeskOrder = async () => {
        const paramsID = this.props.match.params.id
        this.setState({ loading: true })
        try {
            let serverData = await this.props.GeneralStore.getDeskOrder(paramsID);
            // _.get(serverData,"data.results").map(v=>this.onClickProduct(v.item,true))
            console.log("getDeskOrder", serverData.data.results)
            this.setState({
                data: _.get(serverData, 'data.results.currentState.data', []),
                paid : _.get(serverData, 'data.results.paid',0)
            })
            // _.orderBy(_.get(serverData,'data.results.currentState.data',[]) ['created'], ['desc'])
        } catch (error) {
            console.log(error)
        }
        finally {
            this.setState({ loading: false })
        }
    }
    postDeskOrder = async ({ item, type = null }) => {
        const paramsID = this.props.match.params.id
        try {
            let serverData = await this.props.GeneralStore.createDeskOrder({ item, deskId: paramsID, type: type });
            console.log("postDeskOrder", serverData.data)
        } catch (error) {
            console.log(error)
        }
        finally {
        }
    }
    postPaidDeskOprder= async(value)=>{
        const deskId = this.props.match.params.id
        try {
            let serverData = await this.props.GeneralStore.setPaidDeskOrder({deskId,value});
            console.log("postPaidDeskOprder",deskId,value,serverData.data)
            this.setState({
                paid : _.get(serverData, 'data.item.paid',0)
            })
        } catch (error) {
            console.log("ERR",error)
        }
    }
    removePreOrderByDesk = async () => {
        const paramsID = this.props.match.params.id
        try {
            let serverData = await this.props.GeneralStore.deletePreOrderByDesk(paramsID);
            console.log("removePreOrderByDesk", serverData.data)
        } catch (error) {
            console.log(error)
        }
        finally {
            this.setState({ loading: false })
        }
    }

    getDeskList = async () => {
        const deskList = _.get(this.state, "deskList", [])
        if (deskList.length > 0) return
        this.setState({ loading: true })
        try {
            let serverData = await this.props.GeneralStore.getDesks();

            console.log("getDeskList", _.get(serverData, "data.results"))
            let items = _.get(serverData, "data.results")
            for (let index = 0; index < items.length; index++) {
                const element = items[index];
                const paramsID = this.props.match.params.id
                if (this.props.deskId == element._id || paramsID == element._id) {
                    this.setState({
                        selectedDesk: element
                    })
                }
            }

            this.setState({
                deskList: items
            })

        } catch (error) {
            console.log(error)
        }
        finally {
            //  this.initdeskArees()
            this.setState({ loading: false })
        }
    }

    resetState = () => {
        this.setState({
            data: [],
            productListFiltered: [],
            barcode: '',
            discount: 0,
            selectedCategory: 0,
        })
    }

    getTotalPrice = () => {
        let total = 0;
        this.state.data.map((v) => {
            total = total + (parseFloat(v.price) * parseInt(v.count))
        })

        let resultTotal =  (parseInt(this.state.discount) > 0) ? this.getTotalPriceWithDiscount() : total.toFixed(2)
        resultTotal = resultTotal -this.state.paid
        return resultTotal
    }
    getTotalPriceNotDiscount() {
        let total = 0;
        this.state.data.map((v) => {
            total = total + (parseFloat(v.price) * v.count)
        })
        return total.toFixed(2)
    }

    getTotalPriceWithDiscount = () => {
        let total = 0;
        this.state.data.map((v) => {
            total = total + (parseFloat(v.price) * v.count)
        })
        return (parseFloat(total) - ((parseFloat(total) * this.state.discount) / 100)).toFixed(2)
    }
    onChangeCount(e, item) {
        let finded = _.find(this.state.data, { _id: item._id });
        finded.count = e;
        this.setState({ data: this.state.data }, () => {
            if (!isNumeric(e)) {
                setTimeout(() => {
                    finded.count = 1;
                    this.setState({ data: this.state.data })
                }, 3000)
            }
        })
    }


    setNewQrId = () => {
        const mongoObjectID = ObjectID().toHexString()
        // this.setState({ qrcode: uuidv4() })
        this.setState({ qrcode: mongoObjectID })
        console.log("---------------------------------")
        console.log(mongoObjectID);
        console.log("---------------------------------")
    }
    countPlusMinus = (item, type) => {
        const finded = _.find(this.state.data, { _id: item._id });
        let count = Number.parseInt(finded.count);
        if (type == '+') {
            count = count + 1;
        }
        if (type == '-') {
            count = count - 1
        }
        if (type == '-' && count < 1) {
            this.deleteItem(item);
            this.postDeskOrder({ item: finded, type: type }) // service sync
            return
        }
        finded.count = count
        this.setState({ data: this.state.data })
        this.postDeskOrder({ item: finded, type: type }) // service sync
    }

    onClickFeature = ({ feature, content }) => {
        content.picked = !content.picked
        this.setState({ product: this.state.product })

    }

    onClickProductWithFeatures = async (item) => {
        if (!this.state.qrcode) {
            this.setNewQrId()
        }
        const itemPluser = item ? item : this.state.product
        if (!itemPluser) return

        let modifyPrice = 0

        console.log("!!!!", itemPluser.itemFeatures)
        _.get(itemPluser, "itemFeatures", []).map(v => {
            v.contents = _.filter(v.contents, (o) => {
                return o.picked
            })
            if (v.contents.length == 0) {
                _.unset(v, "contents")
            }
        })
        _.get(itemPluser, "itemFeatures", []).map(v => {
            _.get(v, "contents", []).map(v => {
                modifyPrice = modifyPrice + parseFloat(v.price)
            })
        })
        itemPluser.price = parseFloat(itemPluser.price) + modifyPrice


        // itemPluser.itemFeatures = []
        this.createOrPlusser({ item: itemPluser })
        this.setState({ modalProductVisible: false })
    }

    //ürün ekleme kısmı
    onClickProduct = async (item) => {
        const itemFeatures = _.get(item, "itemFeatures", [])
        if (!this.state.qrcode) {
            this.setNewQrId()
        }

        if (itemFeatures.length == 0) {
            this.createOrPlusser({ item })
        }
        else {
            this.setState({
                modalProductVisible: true,
                product: JSON.parse(JSON.stringify(item))
                //product: Object.assign({}, item)
            })
        }

    }

    createOrPlusser = ({ item }) => {
        let finded = _.find(this.state.data, { _id: item._id });
        if (finded) {
            let count = finded.count;
            finded.count = parseInt(count) + 1
            this.setState({ data: this.state.data })
        }
        else {
            let oldData = this.state.data;
            item.count = 1
            item.created = new Date()
            oldData.push(item);
            this.setState({ data: _.orderBy(this.state.data, ['created'], ['desc']) })
        }
        this.postDeskOrder({ item }) // service sync
        this.setState({ barcode: '' })
    }

    deleteItem(item) {
        this.setState({
            data: _.filter(this.state.data, (o) => {
                return o._id !== item._id
            })
        })

        // _.remove(this.state.data, { _id: item._id });
        // this.setState({ data: this.state.data })
    }
    onChangeBarcode(e) {
        const value = e.target.value;
        this.setState({ barcode: value });
    }

    // # DEĞİŞTİMM
    barcodePressEnter() {
        const { barcode, productList } = this.state
        const finded = _.find(productList, { barcode });
        if (finded) {
            this.onClickProduct(finded);
            this.setState({ barcode: '' });
        } else {
            this.onSearchProduct()
        }
    }

    onSearchProduct = () => {
        const { barcode: text, productList } = this.state

        let filteredItems = productList.filter(book => {
            return (_.lowerCase(book.title).indexOf(_.lowerCase(text)) !== -1 || _.lowerCase(book.description).indexOf(_.lowerCase(text)) !== -1)
        })

        this.setState({
            productListFiltered: filteredItems,
        })

        console.log({ filteredItems })

        //   db.search(tableProduct, "title", this.state.barcode).then((result) => {
        //       this.setState({ productList: _.orderBy(result, ['created'], ['desc']) });
        //   })
    }


    //#liste
    getItemListProduct = async () => {
        this.setState({ loading: true })
        try {
            let serverData = await this.props.ProductStore.getProducts("withcategory");


            let dataList = _.get(serverData, "data.results")

            dataList = dataList.map((v, i) => {
                v.rank = dataList.length - i
                return {
                    ...v,
                    index: i
                }
            })

            console.log("getItemList", { dataList, results: _.get(serverData, "data.results") })

            this.setState({
                data: dataList
            })
        } catch (error) {
            console.log(error)
        }
        finally {
            this.setState({ loading: false })
        }
    }

    //#liste  // filtre
    getItemListCategory = async () => {
        this.setState({ loading: true })
        try {
            let serverData = await this.props.ProductStore.getCategories("withproduct");


            let productList = [];

            _.get(serverData, "data.results").map(v => {
                productList = productList.concat(_.get(v, "products", []))
            })

            console.log("!getItemListCategory!", productList, _.get(serverData, "data.results"))

            this.setState({
                categoryList: _.get(serverData, "data.results"),
                productList
            })
        } catch (error) {
            console.log(error)
        }
        finally {
            this.setState({ loading: false })
        }
    }

    getCustomers = () => {

        this.setState({ modalVisible: true })
        console.log("önce müşterileri getir state bastır sonra müşteriler listesi olacak drawer i aç")
    }
    handleCancel = () => {
        this.setState({ modalVisible: false })
    }


    handleProductCancel = () => {
        this.setState({ modalProductVisible: false, product: null })
    }


    onBeforePrint = () => {
        console.log("onBeforePrint")
    }
    onAfterPrint = () => {
        //  this.setState({printAction:null,width:null,initPrint:false})
        //  this.onClickCancelPrintAll()
        console.log("onAfterPrint")
    }



    onChangeDiscount(e) {
        const value = e.target.value;
        if (value >= 0 && value < 101) {
            let discountValue = this.getTotalPrice() - ((this.getTotalPrice() * value) / 100)
            this.setState({ discount: value, discountValue: discountValue })
        }

    }



    onFullScreenChange(isFullScreen) {
        this.setState({
            isFullScreen
        })
    }

    requestOrExitFullScreen() {
        this.fullScreenRef.fullScreen()
    }

    requestOrExitFullScreenByElement() {
        this.elFullScreenRef.fullScreen(this.elRef)
    }

    onChangeCategory = (key) => {
        this.setState({
            productListFiltered: _.filter(this.state.productList, (o) => {
                return o.categoryId == key
            }),
            selectedCategory: key
        })
    }




    submit = ({ action }) => {

        const { selectedDesk, data, selectedQr, selectedPaymentType } = this.state
        if (this.state.data.length == 0) {
            message.error("Ürün girişi zorunludur")
            return
        }
        else if (action == "submit") {
            const postData = {
                items: data,
                paymentType: selectedPaymentType,
                deskId: selectedDesk ? selectedDesk._id : undefined,
                qrId: selectedQr ? selectedQr._id : undefined,
                action: action
            }

            this.props.OrderStore.createOrderStaff(postData)
            message.success("Tamamlandı")
        } else if (action == "completed") {
            message.success("Hesap Kapandı")
        }
        this.resetDataDesk()
    }

    resetDataDesk = () => {
        this.setState({
            data: [],
            paid :0,
            modalVisiblePaid:false
        })
    }

    onClickPaid=()=>{
        this.setState({modalVisiblePaid:true})
    }
    onClickDoPaid=()=>{
        let paidInput = this.state.paidInput
        if(isNaN(parseFloat(paidInput)) ||parseFloat(paidInput)<=0 ){
            message.warning("Hatalı giriş. Lütfen geçerli bir sayı girip ödeme almayı deneyiniz.")
        }else{
            this.postPaidDeskOprder(paidInput)
        }
        this.setState({modalVisiblePaid:false,paidInput:null})
    }
    

    render() {

        const columns = [
            {
                title: 'Ürün Adı',
                key: 'title',
                width: 200,
                render: (text, record) =>
                    <span style={(this.state.isBigText) ? { fontWeight: 'bold', fontSize: '18px' } : {}}>
                        {

                            record.change
                                ? <span>
                                    <Input
                                        suffix={
                                            <Button shape='circle' size='small' onClick={() => this.setChangeProduct(record, false)} icon={<CloseOutlined />} />
                                        }
                                        value={record.title}
                                        onChange={(e) => this.onChangeProductTitle(e.target.value, record)}
                                        onKeyPress={(e) => {
                                            if (e.key == 'Enter') this.setChangeProduct(record, false)
                                        }}
                                    />
                                </span>
                                :
                                record.title
                        }
                        {
                            (record.noBarcode && !record.change) && <span style={{ marginLeft: 5 }}><Button shape='circle' size='small' onClick={() => this.setChangeProduct(record, true)} icon={<EditOutlined />} /></span>
                        }



                        {
                            _.get(record, "itemFeatures", []).map((v, i) => {
                                return (
                                    <div key={i}>
                                        {
                                            _.get(v, "contents", []).map((content, cIndex) => {
                                                return (
                                                    <Tag color="red" key={content.time}>
                                                        {v.title} - {content.title}

                                                    </Tag>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }


                    </span>
                ,
            },
            {
                title: 'Birim Fiyat',
                key: 'price',
                render: (text, record) => (
                    <span style={(this.state.isBigText) ? { fontWeight: 'bold', fontSize: '18px' } : {}}>
                        {record.price.toFixed(2)}
                    </span>
                )
            },
            {
                title: 'Miktar',
                key: 'count',
                render: (text, record) => (
                    <span style={{
                        alignItems: 'center',
                        display: 'flex'
                    }}>

                        <Button shape={"circle"} size="small" icon={record.count == 1 ? <DeleteOutlined /> : <MinusOutlined />}
                            onClick={() => this.countPlusMinus(record, '-')} />

                        <span style={{ marginLeft: 10, marginRight: 10 }}>
                            <Input
                                onFocus={handleFocus}
                                onKeyPress={(e) => {
                                    if (e.key == 'Enter') this.barcodeInput.focus()
                                }}
                                name="count"
                                style={(this.state.isBigText) ? { fontWeight: 'bold', fontSize: '18px', width: 40 } : { width: 40, textAlign: 'center' }}
                                value={record.count}
                                min={1}
                                onChange={(e) => this.onChangeCount(e.target.value, record)}
                            />
                        </span>
                        <Button shape={"circle"} size="small" icon={<PlusOutlined />}
                            onClick={() => this.countPlusMinus(record, '+')} />
                    </span>
                ),
            },
            {
                title: 'Tutar',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a style={(this.state.isBigText) ? { fontWeight: 'bold', fontSize: '18px' } : {}}>
                            {(Number.parseFloat(record.price) * Number.parseInt(record.count)).toFixed(2)}
                        </a>
                    </span>
                ),
            },
        ];


        const menu = (
            <Menu>
                <Menu.Item key="back">
                    <Link to="/desks">
                        Geri
                    </Link>
                </Menu.Item>
                <Menu.Item key="tohome">
                    <Link to="/">
                        Anasayfaya Git
                    </Link>
                </Menu.Item>
                <Menu.Item key="fullscreen"
                    onClick={this.requestOrExitFullScreen.bind(this)}>
                    Tam Ekran
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item key="removePreOrderByDesk"
                    onClick={() => this.removePreOrderByDesk()}>
                    Masaya ait tüm adisyon verilerini sil
                </Menu.Item>

                <Menu.Item key="refresh" onClick={() => this.initData()}>Yenile</Menu.Item>
                {
                    //   <Menu.Item key="4">Büyük / Küçük Yazı</Menu.Item>
                }
            </Menu>
        );


        const suffix = this.state.barcode ?
            <span>
                <Button
                    type='primary'
                    size="small"
                    shape='circle-outline'
                    icon={<SearchOutlined />}
                    onClick={() => this.onSearchProduct()}
                    style={{ marginRight: 10 }}
                />
                <Button
                    type='ghost'
                    size="small"
                    shape='circle-outline'
                    icon={<CloseOutlined />}
                    onClick={() => this.setState({ barcode: '', productListFiltered: [] },
                        () => this.barcodeInput.focus()
                    )}
                />
            </span>
            :
            <span />;

        const handleFocus = (event) => event.target.select();


        const renderTabBar = (props, DefaultTabBar) => (
            <Sticky bottomOffset={80}>
                {({ style }) => (
                    <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
                )}
            </Sticky>
        );


        const { categoryList, loading, selectedCategory } = this.state
        let productList = this.state.productListFiltered.length > 0 ? this.state.productListFiltered : this.state.productList;

        const { selectedDesk } = this.state

        const companyName = _.get(this.props, "UserStore.user.company.name", "")
        const companyLogo = _.get(this.props, "UserStore.user.company.logo", "")

        if (loading) {
            return (<Loading />)
        }

        return (
            <div style={{
                padding: '20px',
                height: `calc(100% - 100px)`,
                paddingBottom: 54
            }}>
                <FullScreen
                    ref={ref => { this.fullScreenRef = ref }}
                    onFullScreenChange={this.onFullScreenChange.bind(this)}
                // enabled={this.state.isFull}
                //onChange={isFull => this.setState({ isFull })}
                >
                    {
                        //sol kolon
                    }
                    <Row>
                        <Col style={{
                            paddingRight: 10
                            //    height:'500px'
                        }}
                            span={8}>
                            <Row>
                                <Col span={4}>
                                    <Link to={'/desks'}>
                                        <Button style={{ marginRight: 5 }} shape="circle" icon={<ArrowLeftOutlined />} />
                                    </Link>
                                </Col>
                                <Col span={20}>
                                    {
                                        selectedDesk
                                        &&
                                        <Button // size="small" 
                                            type='ghost'
                                            block
                                        >
                                            {_.get(selectedDesk, "title")}
                                        </Button>
                                    }
                                </Col>




                            </Row>

                            {
                                (!this.state.barcode && this.state.data.length < 1)
                                    ?
                                    <div>

                                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '140px' }}>
                                            <img style={{ height: '80px', width: 'auto', }}
                                                src={siteInfo.logoRedWname}
                                                alt="Logo" />
                                        </div>

                                        {
                                            // <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 0 }}>
                                            //     <h2 style={{ fontSize: 14 }}>
                                            //         <span style={{ color: 'rgb(47, 54, 64)' }}>{companyName}</span>
                                            //     </h2>
                                            // </div>
                                        }
                                    </div>

                                    :
                                    <div style={{ maxHeight: 400 }}>

                                        <Table
                                            //  scroll={{y:300}}
                                            columns={columns}
                                            rowKey={'_id'}
                                            //  bordered
                                            size="small"
                                            dataSource={this.state.data}
                                            pagination={false}
                                            bodyStyle={{ backgroundColor: '#fff' }}
                                        />
                                    </div>
                            }


                            <div
                                className="bottom-bar"
                            >
                                <Row align="middle">
                                    <Col span={12}>

                                        {
                                            //<span>
                                            //    İskonto : %
                                            //    <Input
                                            //        style={{ width: '80px', marginLeft: '4px' }}
                                            //        onFocus={handleFocus}
                                            //        type="number"
                                            //        onChange={(e) => this.onChangeDiscount(e)}
                                            //        min={0}
                                            //        value={this.state.discount}
                                            //    />
                                            //</span>
                                        }

                                        {
                                            (this.state.discount > 0 && this.state.data.length > 0)
                                            &&
                                            <span style={{ marginLeft: '6px', color: 'red', textDecoration: 'line-through' }}> {this.getTotalPriceNotDiscount()} TL</span>
                                        }
                                    </Col>
                                    <Col span={12}>
                                        <Row style={{ alignItems: 'center' }} type="flex" justify="end">
                                            <Button
                                                type='ghost'
                                                shape='round'
                                                onClick={() => this.onClickPaid()}
                                            >
                                                Ödeme
                                            </Button>

                                            {
                                                this.state.paid>0
                                                &&
                                                <div style={{color:'red', fontSize: '14px', marginLeft: 10, marginTop: '10px', marginBottom: '10px' }}>
                                                <span style={{}}> Ödenen:
                                                    <span>
                                                        <span>
                                                            <span
                                                                style={{ fontWeight: 'bold' }}>
                                                                {' '}
                                                                {
                                                                    this.state.paid
                                                                }
                                                                {' '}
                                                            </span>

                                                        </span>
                                                    </span>
                                                    ₺
                                                </span>
                                            </div>
                                            }



                                            <div style={{ fontSize: '20px', marginLeft: 10, marginTop: '10px', marginBottom: '10px' }}>
                                                <span style={{}}> Genel Toplam:
                                                    <span style={(this.state.isBigText) ? { fontWeight: 'bold', fontSize: '30px' } : { fontWeight: 'bold', fontSize: '22px' }} >
                                                        <span>

                                                            <span
                                                                style={{ fontWeight: 'bold' }}>
                                                                {' '}
                                                                {
                                                                    this.getTotalPrice()+this.state.paid
                                                                }
                                                                {' '}
                                                            </span>

                                                        </span>
                                                    </span>
                                                    ₺
                                                </span>
                                            </div>

                                        </Row>
                                    </Col>

                                </Row>

                            </div>

                        </Col>
                        {
                            //sağ kolon
                        }
                        <Col span={16}>
                            <Row style={{
                                marginBottom: 10
                            }}>
                                <Col span={20}>
                                    <Button
                                        type="danger"
                                        block
                                        size={(this.state.isBigText) ? 'large' : 'default'}
                                        onClick={() => this.submit({ action: "submit" })}
                                    >
                                        <span
                                            style={(this.state.isBigText) ? { fontWeight: 'bold' } : {}}
                                        >
                                            TAMAMLA
                                        </span>

                                    </Button>
                                </Col>
                                {/* <Col span={10}>
                                    <Button
                                        type="primary"
                                        block
                                        size={(this.state.isBigText) ? 'large' : 'default'}
                                        onClick={() => this.submit({ action: "completed" })}
                                    >
                                        <span
                                            style={(this.state.isBigText) ? { fontWeight: 'bold' } : {}}
                                        >
                                            HESABI KAPAT
                                        </span>

                                    </Button>
                                </Col> */}
                                {
                                    <Col span={4}>

                                        <ReactToPrint
                                            onBeforePrint={() => this.onBeforePrint()}
                                            onAfterPrint={(e) => this.onAfterPrint(e)}
                                            trigger={() => {
                                                return (
                                                    <Button
                                                        block
                                                        size={(this.state.isBigText) ? 'large' : 'default'}
                                                        // onClick={() => this.onClickPrinterProduct()}
                                                        title="Yazdır ve Kapat"
                                                        icon={<PrinterOutlined />}>
                                                        Yazdır
                                                    </Button>
                                                )
                                            }}
                                            content={() => this.componentRef}
                                        />


                                    </Col>
                                }
                            </Row>

                            <StickyContainer>

                                <Sticky relative={true}>
                                    {({ style }) => (
                                        <Row style={style} className="mbottom5" justify={"space-between"} align="middle">
                                            <Col span={18}>
                                                <Radio.Group title="Ödeme Türü" size={(this.state.isBigText) ? "large" : "small"}
                                                    onChange={(e) => this.setState({ selectedPaymentType: e.target.value })}
                                                    value={this.state.selectedPaymentType}
                                                >
                                                    {
                                                        this.state.paymentTypes.map((v) => {
                                                            return (
                                                                <Radio.Button key={v.key} value={v.key}>{v.title}</Radio.Button>
                                                            )
                                                        })
                                                    }
                                                </Radio.Group>


                                            </Col>
                                            <Col span={4}>
                                                <Row type="flex" justify="end">

                                                    <Button style={{ marginRight: 5 }} onClick={() => this.barcodeInput.focus()} shape="circle" icon={<ScanOutlined />} />
                                                    <Dropdown overlay={menu} trigger={['click']}>
                                                        <Button type="primary" shape="circle" icon={<MoreOutlined />} />
                                                    </Dropdown>
                                                </Row>

                                            </Col>
                                        </Row>
                                    )}
                                </Sticky>


                            </StickyContainer>



                            {
                                //                        <div style={{ marginBottom: 10 }}>
                                //                            <Input
                                //                                size='default'
                                //                                onKeyPress={this.onKeyPressEnter}
                                //                                value={this.state.noBarcodePrice}
                                //                                type="number"
                                //                                min={0}
                                //                                onChange={(e) => this.onChangeNoBarcodePrice(e.target.value)}
                                //                                name="noBarcodePrice"
                                //                                placeholder="Kayıtsız ürün fiyatı"
                                //                                addonAfter={<a style={{ width: '100px' }} onClick={() => this.addNoBarcodePrice()}>
                                //                                    <PlusOutlined />
                                //                                </a>}
                                //                            />
                            }

                            <div>
                                <Input
                                    prefix={<ScanOutlined />}
                                    suffix={suffix}
                                    ref={(Input) => { this.barcodeInput = Input }}
                                    size={(this.state.isBigText) ? 'large' : 'default'}
                                    placeholder="Ürün ara"
                                    value={this.state.barcode}
                                    onPressEnter={() => this.barcodePressEnter()}
                                    onChange={(e) => this.onChangeBarcode(e)}
                                    autoFocus={true}
                                />
                            </div>



                            <StickyContainer>
                                <Tabs onChange={(key) => this.onChangeCategory(key)}
                                    //defaultActiveKey={this.state.selectedCategory} 
                                    activeKey={this.state.selectedCategory}
                                    renderTabBar={renderTabBar}>
                                    {[{ title: 'Tümü', _id: 0 }].concat(categoryList).map((v, i) => (
                                        <TabPane tab={<div>
                                            {
                                                //  <img style={{ opacity: !v.avatar ? 0.4 : undefined }} className={"my-class-pr-img-tab"} alt={v.title} src={v.avatar ? v.avatar : defaultCategoryImage} />
                                            }
                                            <Button>
                                                {v.title}
                                            </Button>

                                        </div>} key={v._id} />
                                    ))}
                                </Tabs>
                            </StickyContainer>

                            <div style={{ height: 10 }} />
                            <div>
                                <Space //size={[8, 16]} 
                                    wrap>
                                    {productList.map((v, i) => (
                                        <div
                                            onClick={() => this.onClickProduct(v)}
                                            className={"my-class-pr-parent"} key={i}>
                                            <img className={"my-class-pr-img"} alt={v.title} src={v.avatar} />
                                            <div className={"my-class-pr-item"}>{v.title}</div>
                                        </div>
                                    ))}

                                </Space>
                            </div>
                            <div style={{ height: 10 }} />
                        </Col>
                    </Row>

                    <Modal
                        footer={<div>
                            <Button onClick={() => this.handleCancel()}>Kapat</Button>
                        </div>
                        }
                        title="Adresler"
                        visible={this.state.modalVisible}
                        maskClosable={true}
                        onCancel={() => this.handleCancel()}
                    >
                        {
                            (this.state.selectedCustomer)
                            &&
                            this.state.selectedCustomer.address.map((v, i) => {
                                let display = "Ev"
                                let color = "geekblue";
                                if (v.type == 1) {
                                    display = "İş"
                                    color = "magenta"
                                }
                                if (v.type == 2) {
                                    display = "Diğer"
                                    color = "gold"
                                }
                                return (
                                    <Row key={i} style={{ marginBottom: 5 }}>
                                        <Col span={2}>
                                            <Tag color={color}>
                                                {display}
                                            </Tag>
                                        </Col>
                                        <Col span={8}>
                                            <Text style={{ marginRight: 10 }}>{v.value}</Text>
                                        </Col>
                                        <Col span={4}>
                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() => this.selectedCustomerAddress(v)}
                                            >
                                                {(this.state.selectedAddress && this.state.selectedAddress.i == v.i) ? <CheckOutlined /> : `Seç`}
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                    </Modal>
                </FullScreen>

                <div style={{
                    display: "none"
                }}>

                    <div ref={el => (this.componentRef = el)}>
                        <ToPrintPos
                            totalPrice={this.getTotalPrice()}
                            data={this.state.data}
                            qrcode={this.state.qrcode}
                            companyName={companyName}
                            companyLogo={companyLogo}
                        />
                    </div>


                </div>


                <Modal
                    footer={<div>
                        <Button type="primary" onClick={() => this.onClickProductWithFeatures()}>Ekle</Button>
                        <Button onClick={() => this.handleProductCancel()}>Kapat</Button>
                    </div>
                    }
                    title={_.get(this.state, "product.title")}
                    visible={this.state.modalProductVisible}
                    maskClosable={true}
                    onCancel={() => this.handleProductCancel()}
                >
                    {
                        _.get(this.state, "product.itemFeatures", []).map((v, i) => {
                            return (
                                <div key={i}>
                                    <Typography.Text>{v.title}</Typography.Text>
                                    {
                                        _.get(v, "contents", []).map((content, cIndex) => {
                                            return (
                                                <div key={cIndex}>
                                                    <Button icon={content.picked ? <CheckOutlined /> : undefined} block onClick={() => this.onClickFeature({ feature: v, content })} type={content.picked ? "primary" : undefined} key={content.time}>
                                                        {content.title}
                                                        {
                                                            parseInt(content.price) > 0 ?
                                                                `+${content.price}₺` : ""
                                                        }
                                                    </Button>
                                                </div>
                                            )
                                        })
                                    }
                                    <Divider />
                                </div>
                            )
                        })
                    }

                </Modal>


                <Modal
                    footer={<div>
                        <Button onClick={()=>this.setState({modalVisiblePaid:false})}>İptal</Button>
                        <Button onClick={()=>this.submit({ action: "submit" })} type='danger'>HESABI KAPAT</Button>
                        <Button onClick={()=>this.onClickDoPaid()} type='primary'>ÖDEME YAP</Button>
                        </div>
                    }
                    title="Ödeme Al"
                    visible={this.state.modalVisiblePaid}
                    //maskClosable={false}
                    onCancel={()=>this.setState({modalVisiblePaid:false})}
                >
                    <Row
                        style={{ marginTop: '10px' }}>
                        <Col span={12}>
                            <span> Ödenmesi Gereken Tutar : <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{this.getTotalPrice()} TL</span></span>
                        </Col>
                        <Col span={12}>
                            {/* {
                                (this.getPaidUp() >= 0) ?
                                    <span>
                                        <span> Para Üstü : <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{this.getPaidUp()} TL</span></span>
                                    </span>
                                    :
                                    null
                            } */}
                        </Col>
                    </Row>

                    <div style={{ marginTop: '10px' }}>
                        Ödenen Tutar
                    </div>
                    <Input
                        value={this.state.paidInput}
                        type="number"
                        min={0}
                        onChange={(e)=>this.setState({paidInput:e.target.value})}
                        name="paid"
                        onFocus={handleFocus}
                        placeholder="Ödenen" /> 
                      
                    <div style={{marginTop:20}}>
                        <Button onClick={()=>this.onClickDoPaid()} type="primary" block>ÖDEME YAP</Button>
                    </div>
                 
                </Modal>


            </div>
        )
    }
}