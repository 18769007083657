import React,{Component} from 'react'
import {
    Form,
    Input,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    AutoComplete,
    message,
    Spin
 } from 'antd';
import {goPage} from '../historyConfig'

import { Link } from 'react-router-dom';

import _ from 'lodash'

import { withTranslation } from 'react-i18next';

import LanguageSelector from "../components/LanguageSelector"

import { QuestionCircleOutlined } from '@ant-design/icons';
import { inject} from 'mobx-react';
import { siteInfo } from '../config';

@inject('UserStore')
 class Register extends Component{
    constructor(props) {
        super(props);
        this.state = {
          name:'',
          email: '',
          password: '',
          username: '',
          captcha:'',
          loading:false,
          isLogin:false,
          randomNumber : Math.floor(Math.random() * 9999) + 1000
        };
      }


      componentDidMount(){
      }


      onChange=(e)=>{
        this.setState({ [e.target.name]: e.target.value });
      }

      newRandomNumber=()=>{
          this.setState({
            randomNumber: Math.floor(Math.random() * 9999) + 1000
          })
      }

       onFinish =async values => {

        this.setState({
            loading:true
        })
           //burdan apiye git
           // eğer formdan başarılı geçilirse
           if(this.state.captcha!=this.state.randomNumber){
               message.error("Doğrulama kodu hatalı")
               return
           }

           let data = {
               name : this.state.name,
               username : this.state.username,
               email : this.state.email,
               password : this.state.password,
           }

           this.props.UserStore.register(data).then((res)=>{
              message.success("Kayıt başarılı. Şimdi giriş yapabilirsiniz.")
              goPage("login")

           }).catch((err)=>{

               let errorMessage = _.get(err,"response.data.error.message","") || _.get(err,"response.data.message","")

               if(errorMessage){
                message.warning(errorMessage)
                return
            }
           }).finally(()=>this.setState({loading:false}))

                /*
        let data = {
            name,
            email,
            gender,
            password,
            username
          }
          */

      };

      onChange=(e)=>{
        this.setState({ [e.target.name]: e.target.value });
      //  this.props.init();
      }



//linear-gradient(87deg,#11cdef,#1171ef)!important;
    render(){

        const {t} = this.props

        const BackgroundImageUrl = 'https://source.unsplash.com/random/900*700/?technology,software'


        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };
          const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            },
          };

          const usernameRegex = /^[a-zA-Z0-9]+([a-zA-Z0-9][a-zA-Z0-9])*[a-zA-Z0-9]+$/;

        return(
            <div 
            
            style={{

            backgroundImage:`url(${BackgroundImageUrl})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
         
            }}
            
            >
            <div style={{position:"fixed",right:0,padding:20}}>
             <LanguageSelector/>

             </div>
                <div style={{
                     background: 'linear-gradient(87deg,rgba(17, 205, 239,0.5),rgba(17, 113, 239, 0.5))',
                    display: 'flex',  
                    justifyContent:'center', 
                    alignItems:'center', 
                    height: '100vh',
                }}
                    >
            <div style={
                {
                padding:'10px 40px',
                backgroundColor:'rgba(255,255,255,0.6)',
                borderRadius:10
                }}>

{
    this.state.loading &&
    <div style={{justifyContent:'center',textAlign:'center',padding:'0 10px'}}>
    <Spin/>
</div>
}

                     <Form
                     size="small"
                                {...formItemLayout}
                                name="register"
                                onFinish={()=>this.onFinish()}
                                scrollToFirstError
                                >
                                 <Form.Item
                                    name="name"
                                    label={t("name")}
                                    rules={[{ required: true, message: t("nameRequired") }]}
                                >
                                    <Input
                                     name="name"
                                    onChange={(e)=>this.onChange(e)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label={
                                        <span>
                                            E-mail&nbsp;
                                            <Tooltip title={t("emailTooltip")}>
                                            <QuestionCircleOutlined />
                                            </Tooltip>
                                        </span>
                                        }
                                    rules={[
                                    {
                                        type: 'email',
                                        message: t("isNotEmail"),
                                    },
                                    {
                                        required: true,
                                        message: t("requiredEmail"),
                                    },
                                    ]}
                                >
                                    <Input
                                      name="email"
                                      onChange={(e)=>this.onChange(e)}
                                     />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label={t("password")}
                                    rules={[
                                    {
                                        required: true,
                                        message: t("enterYourPassword"),
                                    },
                                    {
                                        min: 4,
                                        message: t("passwordLthan4"),
                                    },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                            name="password"
                                            onChange={(e)=>this.onChange(e)}
                                     />
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label={t("passwordConfirm")}
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                    {
                                        required: true,
                                        message: t("passwordConfirmEnter"),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(t("passwordsNotEqual"));
                                        },
                                    }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name="username"
                                    label={t("username")}
                                    rules={[
                                        { required: true, message: t("usernameEnter"), whitespace: true },
                                        {
                                            pattern : usernameRegex,
                                            message : t("usernameRegex")
                                        }
                                    ]}
                                >
                                    <Input
                                         name="username"
                                         onChange={(e)=>this.onChange(e)}
                                          />
                                </Form.Item>



                                <Form.Item label={t("captchaLabel")} extra={t("captchaExtra")}>
                                    <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item
                                        name="captcha"
                                        noStyle
                                        rules={[
                                            { 
                                                required: true, 
                                                message: t("enterCaptcha") 
                                            }
                                        ]}
                                        >
                                        <Input
                                          name="captcha"
                                         onChange={(e)=>this.onChange(e)}
                                         />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Button block onClick={()=>this.newRandomNumber()}>{this.state.randomNumber}</Button>
                                    </Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item
                                    name="agreement"
                                    valuePropName="checked"
                                    rules={[
                                    { validator:(_, value) => value ? Promise.resolve() : Promise.reject(t("youHaveToEccept")) },
                                    ]}
                                    {...tailFormItemLayout}
                                >
                                    <Checkbox
                                    >
                                        {
                                            /*
                                              Üyelik <Link target="_blank" to="/policy">şartlarını</Link> kabul ediyorum.
                                            */
                                        }
                                        
                                    {
                                        //  <Link target="_blank" to="/policy">{t("ecceptTerms")}</Link>
                                    }
                                    <a href={`${siteInfo.clientUrl}/privacy`} target="_blank">{t("ecceptTerms")}</a>
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item {...tailFormItemLayout}>
                                    <Button type="primary" htmlType="submit" block>
                                      {t("register")}
                                    </Button>
                                    <Link to="/login"> {t("alreadyAccount")}</Link>
                                </Form.Item>
                                </Form>  

            </div>
            </div>
        </div>
        )
    }
}


export default withTranslation()(Register)