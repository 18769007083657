
import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import HomeRouter from './router/Home'

@inject('UserStore', 'HomeStore', 'OrderStore')
@observer
export default class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        const socket = this.props.socket

        // stores.SocketStore.setSocket(socket)
        //   const apikey = "5EDF7B841598292E28D3F132"
        const apikey = this.props.UserStore.getCompanyCode
        socket.emit('joinRoom', { username: apikey, room: apikey });
        console.log("socket App", socket)
        console.log("componentDidMount App", { apikey })

        socket.on("test-broadcast", (arg) => {
            console.log(" socket.on test-broadcast", { arg })
        });

        //garson çağrıldığında
        socket.on("callWaiter", (arg) => {
            console.log(" socket.on callWaite ", { arg })
            if (arg.isCall) {
                this.props.UserStore.pushNotification(arg)
            }
            else {
                this.props.UserStore.removeNotification(arg)
            }

        });

        //yeni sipariş geldiğinde
        socket.on("newOrder", (arg) => {
            console.log(" socket.on newOrder ", { arg })
            this.props.OrderStore.pushOrder(arg)
        });


        ////////////////////////////////////////--- SOCKET  ----

        //garson çağrıldığında
        socket.on("callWaiter", (arg) => {
            console.log("ORDERRRRRRRRR", arg)

            ////  this.props.OrderStore.setIsCallStatus(arg.qr, arg.isCall)
            /* arg.id="temp"
             this.props.OrderStore.pushOrder(arg)
             this.props.OrderStore.deleteOrderTemp({id:"temp"})
             */
        });


        //durum değişince GARSON
        socket.on("statusWaiter", (arg) => {
            console.log("socket statusWaiter", arg)
            this.props.OrderStore.onChangeStatusFromSocket({ id: arg._id, value: arg.value, key: "statusWaiter" })
            //// this.props.OrderStore.onChangeStatus(arg)
        })

        //durum mutfak
        socket.on("statusKitchen", (arg) => {
            console.log("socket statusKitchen", arg)
            ////     this.props.OrderStore.onChangeStatus(arg)
            this.props.OrderStore.onChangeStatusFromSocket({ id: arg._id, value: arg.value, key: "statusKitchen" })
        })

        //siparişi kabul et
        socket.on("accept", (arg) => {
            console.log("socket accept", arg)
            this.props.OrderStore.onChangeStatusFromSocket({ id: arg._id, value: arg.value, key: "accept" })
        })
        //siparişi iptal et
        socket.on("reject", (arg) => {
            console.log("socket reject", arg)
            this.props.OrderStore.onChangeStatusFromSocket({ id: arg._id, value: arg.value, key: "reject" })
        })
        //sipariş teslim edildi (TAMAMLANDI)
        socket.on("done", (arg) => {
            console.log("socket reject", arg)
            this.props.OrderStore.onChangeStatusFromSocket({ id: arg._id, value: arg.value, key: "done" })
        })

        //sipariş tamamlandı
        // 
        socket.on("completed", (arg) => {
            console.log("socket completed", arg)

            const qrId = _.get(arg, "qrId")

            ////    let data = this.props.OrderStore.data
            ////    data = _.filter(data, (o) => {
            ////        return o.qrId == qrId
            ////    })
            ////    data.map(v => {
            ////        this.props.OrderStore.setCompleted(v._id, qrId)
            ////        //  this.props.OrderStore.deleteOrderById(v._id)
            ////    })

        })



    }

    render() {
        return (
            <HomeRouter />
        )
    }
}

