
import React, { Component } from 'react';
import {
  message,
  Table,
  Drawer, Switch, Row, Button, Input, Form, Modal, Popconfirm, Tooltip, notification, Radio, Col, Divider,
  Card,
  Upload,
  Avatar,
  Typography,
  Select,
  Tag,
  Dropdown,
  Menu,
  Descriptions
} from 'antd';
import { EyeOutlined, PlusCircleTwoTone, MenuOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

import PageHeader from '../components/PageHeader';


import { inject, observer } from 'mobx-react';
import { entries, toJS } from 'mobx';

import moment from 'moment'


import _ from "lodash"

import { withTranslation } from 'react-i18next';
import { cdnUrl } from '../config';
import { goBack } from '../historyConfig';

const queryString = require('query-string');

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text, Title } = Typography;


@inject('OrderStore', 'QrCodeStore', 'UserStore', 'ProductStore')
@observer
class Order extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categoryList: [],
      statusKitchens: [
        { title: 'Beklemede', i: 0, key: 'noproccess' },
        { title: 'Hazırlanıyor', i: 1, key: 'waiting' },
        { title: 'Hazırlandı', i: 2, key: 'prepared', },
      ],
      statusWaiters: [
        { title: 'Beklemede', i: 0, key: 'noproccess' },
        { title: 'Çağrıldı', i: 1, key: 'callWaiter' },
        { title: 'Tamamlandı', i: 2, key: 'completed', },
      ],
      modalVisible: false,
      item: null
    }
  }

  componentDidMount = () => {
    this.props.QrCodeStore.getItems()
  }


  onClickDetail = (item) => {
    console.log(item)
    this.setState({
      modalVisible: true,
      item
    })
  }

  handleCancel = () => {
    this.setState({ modalVisible: false, item: null })
  }

  ///qr toplam tutar
  renderTotalPriceWithQrId = (qrId) => {

    let data = this.props.OrderStore.data
    data = _.filter(data, (o) => {
      return o.qrId == qrId
    })

    let tPrice = 0;
    data.map(v => {
      tPrice = tPrice + this.props.OrderStore.getBasketTotalSum(v.items)
    })

    return tPrice
  }
  //qr ürünleri birleştir
  concatItemsWithQrId = (qrId) => {

    let data = this.props.OrderStore.data
    data = _.filter(data, (o) => {
      return o.qrId == qrId
    })

    let items = []
    data.map(v => {
      items = items.concat(_.get(v, "items", []))
    })

    return items
  }

  onClickCallWaiter = (item) => {
    console.log(item)
    if (item.isCall) {
      this.props.OrderStore.setIsCallStatus(item.qrId, false)
    }
  }

  //sepeti kapat
  onClickDeleteOrderByQrId = (qrId) => {

    this.props.OrderStore.setCompletedServer(qrId).then((res) => {
      console.log({ res })
    }).catch(err => {
      console.log({ err })
    })
    this.handleCancel()

  }


  //garson veya mutfak durumu güncellendiğinde
  onChangeStatus = (e, item, type) => {
    console.log("onChangeStatus order pages: ", { e, item, type })

    // this.props.OrderStore.onChangeStatus({e, item, type})

    this.props.OrderStore.onChangeStatusServer({ e, item, type }).then((res) => {
      // this.props.OrderStore.onChangeStatus({e, item, type})
    })

  }

  /*
   <span key={key}/>
          <li key={key}>
          {key} - {JSON.stringify(value)}
        </li>
        */
  renderFilters = () => {
    return entries(this.props.OrderStore.data).map(([key, value]) => {
      return (
        <span style={{ display: 'none' }} key={key}>
          <li key={key}>
            {key} - {JSON.stringify(value)}
          </li>
        </span>
      );
    })
  };




  render() {
    const { t } = this.props

    //  const { data, loading } = this.state
    // const { loading } = this.props.OrderStore
    const loading = false
    let data = this.props.QrCodeStore.data.results


    const handleFocus = (event) => event.target.select();

    const menuMoreItem = (record) => (
      <Menu onClick={(e) => this.onClickMore(e.key, record)}>
        <Menu.Item key="edit">
          {t("edit")}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="delete">{t("delete")}</Menu.Item>
      </Menu>
    );

    const columnsProduct = (v) => [
      {
        title: 'Başlık',
        key: 'titlem',
        render: (text, record) =>
          <span>
            {
              record.avatar && <Avatar shape="square" src={record.avatar} />
            }
            {` `}
            {
              record.title
            }
          </span>
      },
      {
        title: 'Açıklama',
        key: 'description',
        dataIndex: "description"
      },
      {
        title: 'Miktar',
        key: 'count',
        dataIndex: "count"
      },
      {
        title: 'Fiyat',
        key: 'price',
        render: (text, record) =>
          <span>
            {`${record.price} ₺`}
          </span>
      },
      {
        title: 'Tutar',
        key: 'tPrice',
        render: (text, record) =>
          <span>
            {`${record.price * record.count} ₺`}
          </span>
      },
    ]

    const columnsLiveOrder = (v) => [
      {
        title: '',
        key: 'titlem',
        render: (text, record) =>
          <span>
            <div className="blob green" />
            {
              record.flag == "new"
              &&
              <div className="blob green" />
            }
          </span>
      },
      {
        title: '',
        key: 'titlem',
        render: (text, record) =>
          <span>
            {moment(record.created).fromNow()}
          </span>
      },
      {
        title: '',
        key: 'titlem',
        fixed: 'right',
        render: (text, record) =>
          <span>
            <Link to={`/order/${record._id}`}>
              <Button size="small">Detay</Button>
            </Link>
          </span>
      },
    ]

    const columns = [
      {
        title: 'Zaman',
        key: 'created',
        render: (text, record) => <span>
          <Tooltip
            title={record.isCall ? t("callWaiter") : undefined}>
            <div
              onClick={() => this.onClickCallWaiter(record)}
              className={record.isCall ? "ant-btn-danger" : undefined}
            >{moment(record.created).fromNow()}
            </div>
          </Tooltip>
        </span>
      },
      {
        title: 'Masa',
        key: 'desk',
        render: (text, record) => <span>
          <Tag color="gold">{_.get(record, "title", "")}</Tag>
        </span>
      },
      {
        title: 'Tutar',
        key: 'totalPrice',
        render: (text, record) => <span>
          {`${this.props.OrderStore.getBasketTotalSum(record.items)} ₺`}
        </span>
      },
      {
        title: 'Mutfak',
        key: 'statusKitchen',
        render: (text, record) => <span>
          <Select
            value={_.get(record, "statusKitchen", 0)}
            onChange={(e) => this.onChangeStatus(e, record, "statusKitchen")}
          >
            {
              this.state.statusKitchens.map((v, i) => {
                return (
                  <Option key={i} value={v.i} >{v.title}</Option>
                )
              })
            }
          </Select>
        </span>
      },
      {
        title: 'Garson',
        key: 'statusWaiter',
        render: (text, record) => <span>
          <Select
            value={record.isCall ? 1 : _.get(record, "statusWaiter", 0)}
            // style={{minWidth:80}}
            onChange={(e) => this.onChangeStatus(e, record, "statusWaiter")}
          >
            {
              this.state.statusWaiters.map((v, i) => {
                return (
                  <Option key={i} value={v.i} >{v.title}</Option>
                )
              })
            }
          </Select>
        </span>
      },
      {
        title: '',
        key: 'detail',
        render: (text, record) =>
          <span>
            {
              !this.state.item
              &&
              <Button onClick={() => this.onClickDetail(toJS(record))}
                type="primary" icon={<EyeOutlined />}></Button>
            }

          </span>
      }
    ];


    return (
      <div>
        {
          this.renderFilters()
        }
        <div style={{ height: 20 }} />
        <Row>
          {
            data.map((v, i) => {
              return (
                <Col key={i} className="ordercol" span={12} md={8} xs={12}>
                  <Card
                    style={{ borderRadius: 10 }}
                    title={
                      <PageHeader
                        noback
                        nopadding
                        title={v.title}
                        subTitle={v.description}
                        extra={
                          <Button style={{ borderRadius: 3 }} size="small" icon={<MenuOutlined />} />
                        }
                      />
                    }

                  >
                    <Table
                      showHeader={false}
                      rowKey={'_id'}
                      columns={columnsLiveOrder()}
                      dataSource={[]}
                      size='small'
                      pagination={false}
                    />
                  </Card>
                </Col>
              )
            })
          }
        </Row>


        <div style={{ height: 120 }} />


        <Drawer
          title={undefined}
          //  placement="left"
          width={720}
          visible={this.state.modalVisible}
          onClose={(e) => this.handleCancel(e)}
          bodyStyle={{ paddingBottom: 80 }}
        >

          <div>
            <Title>
              {_.get(this.state, "item.title", "")}
            </Title>
          </div>

          <div>
            <Title level={4}>
              {this.renderTotalPriceWithQrId(_.get(this.state.item, "qrId"))}
              {` ₺`}
            </Title>
          </div>

          <div>
            <Button type="primary" block
              onClick={() => this.onClickDeleteOrderByQrId(_.get(this.state.item, "qrId"))}
            >
              HESABI KAPAT
            </Button>
          </div>

          <div>
            <div style={{ height: 20, backgroundColor: 'rgba(0,0,0,0)' }} />
            <Text>Ürünler</Text>
            <Table
              rowKey={'_id'}
              style={{ backgroundColor: '#fff' }}
              columns={columnsProduct(this.state.item)}
              dataSource={this.concatItemsWithQrId(_.get(this.state.item, "qrId"))}
              size='small'
              pagination={false}
            />
          </div>

          <div style={{ height: 20, backgroundColor: 'rgba(0,0,0,0)' }} />

          <div>
            <Text>Siparişler</Text>
            <Table
              rowKey={'_id'}
              //   rowClassName={"ant-btn-danger"}
              columns={columns}
              pagination={false}
              //   size="small"
              dataSource={_.filter(data, (o) => {
                return o.qrId == _.get(this.state, "item.qrId")
              })}
              loading={loading}
              //  defaultExpandedRowKeys={["5ee4954c912cb91404250ad7"]}
              //  expandRowByClick={true}
              expandedRowRender={(record) =>
                <div style={{ backgroundColor: '#FFF' }}>
                  <Descriptions
                    title="Sipariş Detayları"
                    layout="vertical"
                  >
                    <Descriptions.Item label="Toplam Tutar">
                      <span style={{ fontWeight: 'bold' }}>
                        {`${this.props.OrderStore.getBasketTotalSum(record.items)} ₺`}
                        {` / ${record.title} `}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Ürünler">

                      <Table
                        rowKey={'_id'}
                        style={{ backgroundColor: '#fff' }}
                        columns={columnsProduct(record)}
                        dataSource={record.items}
                        size='small'
                        pagination={false}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Kullanıcı Notu">
                      <Text type="danger">
                        {record.customNote}
                      </Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Sipariş tarihi">
                      <Tooltip
                        title={moment(record.created).fromNow()}>
                        {new Date(record.created).toLocaleString()}
                      </Tooltip>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              }
            />

          </div>

          <div
            style={{
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 0',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
              İptal
            </Button>

            <Button type="primary"
              disabled={loading}
              onClick={() => this.onClickDeleteOrderByQrId(_.get(this.state.item, "qrId"))}
            >
              HESABI KAPAT
            </Button>
          </div>
        </Drawer>

      </div>
    )
  }
}

export default withTranslation()(Order)