import React, { Component } from 'react'
import { Form, Spin, Input, Button, message, Checkbox, Row } from 'antd';
import { ReactSVG } from 'react-svg'
import { siteInfo, facebookAppId, googleClientId } from '../config'

import LanguageSelector from "../components/LanguageSelector"

import { UserOutlined, LockOutlined, GoogleCircleFilled, GoogleOutlined, GooglePlusCircleFilled } from '@ant-design/icons';

import { inject } from 'mobx-react';

import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

//import {joinRoom} from "../utils/socket"

import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';

const LoginWithTwitter = require('login-with-twitter')

const tw = new LoginWithTwitter({
  consumerKey: 'XOOpAjbFonX412tl5OiwlNWob',
  consumerSecret: 'rDDwVQ42IX8Js7erc5OclOAdcqrGvI5zDsoOvO7ImqpiFQSDfc',
  callbackUrl: 'https://www.sssosyal.com/twitter/callback'
})


// BENİ HATIRLA SONRA YAP
/*
rememberUser = async () => {
    try {
      await AsyncStorage.setItem('YOUR-KEY', this.state.username);
    } catch (error) {
      // Error saving data
    }
    };
    getRememberedUser = async () => {
    try {
      const username = await AsyncStorage.getItem('YOUR-KEY');
      if (username !== null) {
        // We have username!!
        return username;
      }
    } catch (error) {
      // Error retrieving data
    }
    };
    forgetUser = async () => {
      try {
        await AsyncStorage.removeItem('Longtail-User');
      } catch (error) {
       // Error removing
      }
    };
    */


@inject('UserStore')
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false,
      formtype: "login"
    };
  }


  async componentDidMount() {
    const token = await this.props.UserStore.getToken();
    if (token) {
      // this.props.history.push("/");
      message.warning("Zaten giriş yaptınız.")
      console.log("VAR")
    }
    else {
      //  console.log("YOK")
    }
    // console.log("token",token)
  }

  loginWithTwitter = () => {
    tw.login((err) => {
      console.log("err", err)
    })
  }

  loginWithTwitterCall = () => {
    tw.login((err, secret, url) => {
      console.log("twitter callback", {
        url,
        secret,
        err
      })
    })
  }


  responseFacebook = (response) => {
    this.setState({
      loading: true
    })
    const data = {
      name: _.get(response, "name", ""),
      email: _.get(response, "email", ""),
      avatar: _.get(response, "picture.data.url", ""),
      FBid: _.get(response, "id", ""),
      FBaccessToken: _.get(response, "accessToken", ""),
      FBuserID: _.get(response, "userID", ""),
    }
    const login = this.props.UserStore.login(data);
    login.then((res) => {
      console.log(res)
      const token = res.data._id;
      this.props.UserStore.saveToken(token);
      setTimeout(() => {
        this.setState({ loading: false })
        this.props.history.push("/");
      }, 2000)

    }).catch((err) => {
      console.log("login facebook catch", err.response)
    })

    /*

picture : data.url

name: "Ferdi Özer"
email: "pusat_ferdi_04@hotmail.com"
picture: {data: {…}}
id: "2809012995820635"
accessToken: "EAAQNEKtkodQBAJzQZBi7u1yyY5ZBYjoRT0cqcxM93zv2JPUinXu8i68ZBw8eXEKbBGDYspufysFHkvOE6dXpaNZAK6GPUTrZC5isqdL4RfqCxVsZAXOvKUE0PuzYAErbR6jAMTn7WKH9aPwaZCibtJxpZAx1U7ncz5bPk1uYYKfGOMAszSsObYlD80dmZCVFTWZA2rEBeUItyh6AZDZD"
userID: "2809012995820635"
expiresIn: 4919
signedRequest: "Hxnwm76kGvO8swCD1AlNaiZUU7GWcWA68uocc1Mva24.eyJ1c2VyX2lkIjoiMjgwOTAxMjk5NTgyMDYzNSIsImNvZGUiOiJBUUNDYTVLVkpqN1lseTBrb1FVejV3SlFINEE3VlB5WFdnTjZrSWQ2UWxwdmtGTGJ6TE9GVFBxWDUwTE9rQmpJRVJuWmZPZWVpQ2dGWTEyNzAwUGNHQ2tBc2hyTDNBSnJjS1dDLUM3QU9lNU5XaV9pcDl2WU9oRmJ3ZFRpS3NIYnpwSDFMalRYY0p0NmpwUk13NE15c01tbGcwaG9IT1hjWEJoSmlEbkpCWUZfdzAxcmVIazJiTFd6VTNFN0dtNHBndHhMdXNCMzRBbjVYSWRGS1lNUFpjZDhMSFpzb1Q5R2M0cE5IZEZiaDU1MGJUQ1ZUYUlEMFVBRU1SQjMwS2VyY1p6Z3Q1VGdrYkZ1YlpKX0FRVXRIcDdQbmsxdU9nV2pLTDRBbmEwWkRxQjJqeGdwSVlTSXpWekNKOFZpaTM1OUw4N3JvLWxxS0NrV1NGZ1h2dG1BUGlGdSIsImFsZ29yaXRobSI6IkhNQUMtU0hBMjU2IiwiaXNzdWVkX2F0IjoxNTg1ODc0MjgxfQ"
graphDomain: "facebook"
data_access_expiration_time: 1593650281

*/
    console.log(response);
  }

  responseGoogle = (response) => {
    console.log(response);
  }

  // Google ile başarılı giriş
  responseGoogleSuccess = (response) => {
    this.setState({ loading: true })

    const login = this.props.UserStore.login(response);
    login.then((res) => {
      console.log(res)
      const token = res.data._id;
      this.props.UserStore.saveToken(token);
      setTimeout(() => {
        this.setState({ loading: false })
        this.props.history.push("/");
      }, 2000)

    }).catch((err) => {
      message.error()
      this.setState({ loading: false })
      console.log("login google catch", err.response)
    })

  }

  responseFailedGoogle = (response) => {
    // "popup_closed_by_user"  // izin verilmedi
    console.log(response);
  }


  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    //  this.props.init();
  }

  onSubmit = async () => {

    if (this.state.formtype == "login") {
      this.setState({
        loading: true
      })
      const data = {
        password: this.state.password,
        email: this.state.email
      }

      if (!this.state.password || !this.state.email) {
        message.warn("Gerekli alanları doldurunuz")
        this.setState({
          loading: false
        })
      }
      else {
        const login = this.props.UserStore.login(data);

        login.then((res) => {
          // Giriş başarılı
          console.log(res)
          const token = res.data._id;
          this.props.UserStore.saveToken(token);
          this.props.UserStore.saveCompanyCode(_.get(res, "data.user.apikey"));
          message.loading("", 0)
          setTimeout(() => {
            this.setState({ loading: false })
            message.destroy()
            this.props.history.push("/");
            window.location.reload(false);
            //    joinRoom(token,res.data.user.apikey)
          }, 2000)

        })
          .catch((err) => {
            console.log(err)
            message.error(_.get(err, "response.data.message"))
            this.setState({ loading: false })
          })

        /*
        loading
        console.log(login)
        */
        ///    alert("sdfsaf")
        //  this.props.connectApiLogin(this.state.email,this.state.password)
        // this.connectApi();
      }
    }
    // şifre sıfırlama on click
    else {
      console.log("şifre sıfırlama on click")
      this.setState({
        loading: true
      })

      const { email } = this.state
      if (!email) {
        return
      }

      try {

        this.props.UserStore.forgotPassword(email).then((res) => {
          message.success()
          console.log(res)
        }).catch((err) => {
          message.error()
          console.log(err.response)
        })

      } catch (error) {
        console.log(error)

      }
      finally {
        this.setState({
          loading: false
        })
      }

    }

  }

  //linear-gradient(87deg,#11cdef,#1171ef)!important;
  render() {
    const { t } = this.props
    const BackgroundImageUrl = 'https://source.unsplash.com/random/900*700/?technology,software'
    return (
      <div

        style={{

          backgroundImage: `url(${BackgroundImageUrl})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',

          /* backgroundColor:'rgba(0,0,0,0.9)',*/

        }}

      >
        <div style={{ position: "fixed", right: 0, padding: 20 }}>
          <LanguageSelector />

        </div>
        <div style={{
          background: 'linear-gradient(87deg,rgba(17, 205, 239,0.5),rgba(17, 113, 239, 0.5))',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          //  background: 'rgba(47, 54, 64, 0)',
          //   backgroundColor:'#fff'
        }}
        >
          <div style={
            {
              //   height:400,
              width: 400,
              padding: 40,
              backgroundColor: 'rgba(255,255,255,0.6)',
              //   position: 'absolute', left: '50%', top: '50%',
              //  transform: 'translate(-50%, -50%)'
              borderRadius: 10
            }}>
            <div style={{ marginBottom: 10, alignItems: 'center', textAlign: 'center' }}>
              <div style={{ width: '40px' }}>
                {
                  /*
                     <ReactSVG src={siteInfo.logoSvg}/>
                 <img src={siteInfo.logoSvg}/>
                 */
                }
              </div>



            </div>


            <div
              style={{
                padding: '0 0 10px 0',
                // display :"none"
              }}
            >
              {
                /*
                                  <FacebookLogin
                                  buttonStyle={{
                                      padding:0
                                      ,margin:0,
                                      width:'100%',
                                      fontWeight:500
                                  }}
                                  textButton="Facebook ile giriş yap"
                                          appId={facebookAppId} //APP ID NOT CREATED YET
                                          fields="name,email,picture"
                                          callback={(res)=>this.responseFacebook(res)}
                                      />
                */
              }

              {
                /*
                                    <GoogleLogin
                           //   render={()=><div>45</div>}
                                style={{
                                    padding:0,margin:0,width:'100%'
                                }}
                                        clientId={googleClientId}//"" //CLIENTID NOT CREATED YET
                                        buttonText="Google ile giriş yap"
                                        callback={(res)=>this.responseGoogle(res)}
                                        onFailure={(res)=>this.responseFailedGoogle(res)}
                                    />
                                    */
              }

              <GoogleLogin
                cookiePolicy={"single_host_origin"}
                // eğer izin vermişse otomatik giriş yap yani geri callback fonk çağır  // KESİN KULLAN
                //   isSignedIn = { true }
                render={renderProps => (
                  <Button
                    icon={<GoogleOutlined />}
                    block
                    onClick={renderProps.onClick}
                  //   disabled={renderProps.disabled}
                  >{t("loginWithGoogle")}</Button>
                )}
                //   render={()=><div>45</div>}
                //      style={{ padding:0,margin:0,width:'100%' }}
                clientId={googleClientId}//"" //CLIENTID NOT CREATED YET
                buttonText="Google ile giriş yap"
                callback={(res) => this.responseGoogle(res)}
                onSuccess={(res) => this.responseGoogleSuccess(res)}
                onFailure={(res) => this.responseFailedGoogle(res)}
              />

            </div>

            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={() => this.onSubmit()}
            >

              <Input
                name="email"
                autoFocus={true}
                defaultValue={this.state.email}
                placeholder={this.state.formtype == "login" ? t("usernameOrMail") : t("email")}
                onChange={(e) => this.onChange(e)}
                prefix={<UserOutlined />}
                style={{ marginBottom: 10 }}
              />

              {
                (this.state.formtype == "login")
                &&
                <Form.Item>
                  <Input
                    name="password"
                    defaultValue={this.state.password}
                    placeholder={t("password")}
                    type="password"
                    onChange={(e) => this.onChange(e)}
                    onPressEnter={() => this.onSubmit()}
                    style={{ marginBottom: 10 }}
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              }

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>{t("rememberMe")}</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" onClick={() => this.setState({
                  formtype: this.state.formtype == "login" ? 'forgot' : 'login'
                })}>
                  {
                    this.state.formtype == "login"
                      ?
                      t("forgotPassword")
                      :
                      t("rememberPassword")
                  }
                </a>
              </Form.Item>

              <Form.Item>
                <Button
                  block={true}
                  type="primary"
                  onClick={() => this.onSubmit()}
                  disabled={this.state.loading}
                  loading={this.state.loading}
                >
                  {
                    this.state.formtype == "login"
                      ?
                      t("login")
                      :
                      t("resetPassword")
                  }
                </Button>
                {t("or")} <Link to="/register"> {t("register")} </Link>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}


export default withTranslation()(Login)