import React, { Component } from 'react';
import { Row, Switch, Popconfirm, message, Table, Select, Dropdown, Menu, Divider, Tooltip, Checkbox, Icon, Button, Modal, Form, Input, Tag, Col } from 'antd'
import PageHeader from '../../components/PageHeader';
import { inject, observer } from 'mobx-react';


@inject('HomeStore', 'UserStore')
@observer
export default class WinAsYouEat extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        const { loading } = this.props.HomeStore
        const active = _.get(this.props.UserStore, "user.company.activeWinAsYouEat")
        return (
            <div>
                <PageHeader
                    nopadding noback
                    subTitle={active ? "Aktif" : "Pasif"}
                    title='Yedikçe Kazan Durumu'
                    extra={
                        <span>
                            <Switch
                                loading={loading}
                                onChange={(e) => this.props.UserStore.setCompanyLoyaltyActive("activeWinAsYouEat", e)}
                                checked={active}
                            />
                        </span>

                    }
                />

            </div>
        )
    }

}