import React, { Component } from "react";

import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from "moment";

import { Card, Row, Col, Popconfirm, Tag, Button, Spin, Divider, Table } from 'antd'
import {
    ReloadOutlined,
    RightOutlined,
    ArrowRightOutlined,
} from '@ant-design/icons';

import { withTranslation } from 'react-i18next';
import PageHeader from "../components/PageHeader";

import { inject, observer } from 'mobx-react';

@inject('UserStore', 'HomeStore')
@observer
class Reservation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            data: [],
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        this.initItems()
    }
    initItems = () => {
        this.props.HomeStore.getReservation();
    }


    reloadHomeData = () => {
        this.initItems()
    }

    render() {
        const { t } = this.props

        const { reservations, loading } = this.props.HomeStore

        const columns = [
            {
                title: 'Zaman',
                key: 'created',
                render: (text, record) => (
                    <span>
                        <Tag color={'pink'}>{moment(record.created).fromNow()}</Tag>
                        <div>
                            Tarih :<span style={{ fontWeight: 'bold' }}> {record.date}</span> <br />
                            Saat : <span style={{ fontWeight: 'bold' }}>{record.time}</span>
                        </div>
                    </span>
                )
                ,
            },
            {
                title: 'Kullanıcı',
                key: 'userId',
                render: (text, record) => (
                    <span>
                        {`${record.user.name} ${record.user.surname}`}
                    </span>
                ),
            },
            {
                title: 'Kişi sayısı',
                dataIndex: 'personCount',
                key: 'personCount'
            },
            {
                title: 'Telefon',
                dataIndex: 'phone',
                key: 'phone'
            },
            {
                title: 'Not',
                dataIndex: 'detail',
                key: 'detail'
            },
            {
                title: 'İşlem',
                key: 'operation',
                render: (text, record) =>
                    <span>
                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?"
                            onConfirm={() => this.delete(record)}>
                            <a>Sil</a>
                        </Popconfirm>
                    </span>
                ,
            },
        ];

        return (
            <div>
                <PageHeader title="Rezervasyon" subTitle={reservations.length} />
                <Table
                    rowKey={'_id'}
                    columns={columns}
                    dataSource={reservations}
                    loading={loading}
                />
            </div>
        )
    }

}

export default withTranslation()(Reservation)