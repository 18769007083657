

import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { inject, observer } from 'mobx-react';

import Loading from "./Loading"

import { ConfigProvider, Row, Col } from 'antd';
import { Route, Link, Redirect } from 'react-router-dom';

import { getDefaultLanguage } from "../config";

import localeEN from 'antd/es/locale/en_US'
import localeTR from 'antd/es/locale/tr_TR'
import StaffLogin from "./StaffLogin";
import _ from "lodash";





@inject('UserStore')
@observer
class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: ''
        }
    }

    componentDidMount = () => {
        this.props.UserStore.getProfileStaff()
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSubmitLogin = async () => {
        const { email, password } = this.state
        if (!email || !password) return
        await this.props.UserStore.loginStaff({ email, password })
        this.props.UserStore.getProfileStaff()
    }
    onSubmitLogout = () => {
        this.props.UserStore.logout()
    }

    render() {
        const { user } = this.props.UserStore
        const mainLocale = getDefaultLanguage() == "en" ? localeEN : localeTR
        const { loading } = this.props.UserStore
        const token = this.props.UserStore.token ? this.props.UserStore.token : this.props.UserStore.tokenStaff
        if (loading) {
            return (<Loading />)
        }
        else if (!token) {
            return (<div style={{ padding: 50 }}>
                <h2>Giriş Yap</h2>
                <StaffLogin onChange={(e) => this.onChange(e)} onSubmit={() => this.onSubmitLogin()} t={this.props.t} />
            </div>)
        }
        else {
            return (
                <ConfigProvider locale={mainLocale} >
                    {
                        // (!token) && <Redirect to="/login" />
                    }
                    {this.props.children}

                    <div style={{ padding: 24 }}>
                        <Row justify="space-between">
                            <Col>
                                <div style={{ backgroundColor: 'rgba(255,255,255,0.6)', padding: 5 }}>
                                    {_.get(user, "name")}
                                </div>
                            </Col>
                            <Col>
                                <div style={{ backgroundColor: 'rgba(255,255,255,0.6)', padding: 5 }}>
                                    <a onClick={() => this.onSubmitLogout()}>Çıkış</a>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </ConfigProvider >
            )
        }
    }


}


export default withTranslation()(Container)