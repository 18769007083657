
import axios from "axios";
import { API_URL, testtoken, } from "../config";



export default class Service {
    constructor(token = testtoken, companycode = testtoken,) {
        this.token = token
        this.axiosConfig = { headers: { companycode, Authorization: this.token, 'Content-Type': 'application/json' } }
    }

    setOrderStatus(data) {
        const url = `${API_URL}/order/status`;
        return axios.post(url, data, this.axiosConfig)
    }

    createOrderStaff(data) {
        let url = `${API_URL}/admin/staff/order`;
        return axios.post(url, data, this.axiosConfig);
    }
    setCompletedServer({ qrId }) {
        const url = `${API_URL}/order/completed`;
        return axios.post(url, { qrId }, this.axiosConfig)
    }



}
