



import PropTypes from 'prop-types';
import React, { useEffect } from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Card,
    message,
    Button
} from 'antd';
import {
    EyeOutlined,
    PlusCircleTwoTone,
    PhoneOutlined,
    HomeOutlined,
    UserOutlined,
    FacebookOutlined,
    InstagramOutlined,
    PlusOutlined,
    MinusOutlined,
    DeleteOutlined,
    HeartOutlined

} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import shave from 'shave';



function renderPriceForFront(price) {
    return new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
    }).format(price)
}

const CompanyProductCard = ({
    title,
    data,
    visiblePrice,
    getItemCountInBasket,
    basketItemInc,
    basketItemDec,
    addBasket,
    deliveryService
}) => {

    useEffect(() => {
        shave(".custom-ant-card-meta-description", 25)
    })

    const renderItemOpAction = (v) => {
        const q = []
        //  let q = [
        //      visiblePrice ? <div>{renderPriceForFront(v.price)}</div> : undefined,
        //      <Button onClick={() => addBasket(v)} icon={<PlusOutlined />} shape="circle" />
        //  ]

        // if (getItemCountInBasket(v._id) > 0) {
        //     q.push(<div>{getItemCountInBasket(v._id)}</div>)
        // }

        if (visiblePrice) {
            q.push(<div>{renderPriceForFront(v.price)}</div>)
        }
        if (deliveryService) {
            q.push(<Button onClick={() => addBasket(v)} icon={<PlusOutlined />} shape="circle" />)
        }
        return q
    }

    return (
        <Row gutter={16}>
            {
                data.map((v, i) => {
                    return (
                        <Col key={i} span={6} md={6} sm={12} xs={24}>
                            <Card
                                actions={renderItemOpAction(v)}
                                hoverable
                                cover={
                                    <img title={v.description} alt={v.title} style={{ width: '100%' }} src={v.avatar} />
                                }
                            >
                                <Card.Meta

                                    title={v.title}
                                    description={v.clicked ? v.description : <span className={"custom-ant-card-meta-description"}>{v.description}</span>}

                                />
                            </Card>
                        </Col>
                    )
                })
            }
        </Row>
    )
}


export default CompanyProductCard;