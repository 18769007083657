
import React, { Component } from 'react';
import {
  message,
  Table,
  Drawer, Switch, Row, Button, Input, Form, Modal, Popconfirm, Tooltip, notification, Radio, Col, Divider,
  Card,
  Upload,
  Avatar,
  Typography,
  Select,
  Tag,
  Menu,
  Dropdown

} from 'antd';

import { Link } from 'react-router-dom';

import { PlusOutlined, MinusOutlined, DeleteOutlined, ReloadOutlined, UploadOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { inject, observer } from 'mobx-react';

import axios from "axios"

import _ from "lodash"

import { withTranslation } from 'react-i18next';
import { cdnConfig, cdnUrl } from '../config';

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;


const featureTypes = {
  single: 'single',
  multible: 'multible'
}

// 
@inject('ProductStore', 'UserStore')
@observer
class Product extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      categoryList: [],
      title: '',
      description: '',
      modalOpenCreate: false,
      images: [],
      imageName: '',
      isShortable: false,
      itemFeatures: []
    }
  }

  /*
   [
        {
          title: 'Başlık',
          selectType: 'single',
          contents: [
            {
              title: "Extra Mayonez", price: 0.5
            }
          ]
        },
        {
          title: 'Başlık',
          selectType: 'multible',
          contents: [
            { title: "Extra Mayonez", price: 0.5 },
            { title: "Extra Mayonez1", price: 0.5 },
            { title: "Extra Mayonez2", price: 5.5 },
          ]
        }
      ]
      */

  componentDidMount = () => {
    this.getItemList()
    // this.getProfile()

  }

  getProfile = async () => {
    const serverData = await this.props.UserStore.getProfile()
    this.setState({ user: _.get(serverData, "data.user") })
    console.log({ serverData })
  }


  //ürünleri sırala (rank a göre)

  sortableDataWithRank() {
    this.setState({ data: _.orderBy(this.state.data, ['rank'], ['desc']) })

  }



  //#liste
  getItemList = async () => {
    this.setState({ loading: true })
    try {
      let serverData = await this.props.ProductStore.getProducts("withcategory");


      let dataList = _.get(serverData, "data.results")

      dataList = dataList.map((v, i) => {
        v.rank = dataList.length - i
        return {
          ...v,
          index: i
        }
      })

      console.log("getItemList", { dataList, results: _.get(serverData, "data.results") })

      this.setState({
        data: dataList
      })
    } catch (error) {
      console.log(error)
    }
    finally {
      this.setState({ loading: false })
    }
  }

  getCategoryList = async () => {
    this.setState({ loading: true })
    try {
      let serverData = await this.props.ProductStore.getCategories();
      console.log("category list", _.get(serverData, "data.results"))
      this.setState({
        categoryList: _.get(serverData, "data.results")
      })
    } catch (error) {

    }
    finally {
      this.setState({ loading: false })
    }
  }

  resetState() {
    this.setState({
      _id: '',
      avatar: null,
      title: '',
      description: '',
      price: null,
      categoryId: undefined,
      images: [],
      modalOpenCreate: false,
      itemFeatures: []
    })
  }

  reload = () => {
    this.getItemList()
  }
  //#ekle
  handleCreate = async () => {




    const { itemFeatures, categoryId, images, price, title, description, _id, file, avatar } = this.state
    if (!title) {
      message.error("ürün adını girin")
      return
    }
    if (!categoryId) {
      message.error("ürünün ait olduğu kategoriyi belirtin")
      return
    }

    if (isNaN(parseFloat(price))) {
      message.error("geçerli ürün fiyatı girin")
      return
    }
    //////////////////////////////  --
    const errors = []
    // özellikler kontrolü
    itemFeatures.map(v => {
      if (v.contents.length == 0) {
        errors.push("Boş olan özellikleri tamamlayınız yada siliniz.")
      }
      v.contents.map(content => {
        if (content.length == 0) {
          errors.push("Boş olan özellik değerlerini tamamlayınız yada siliniz.")
        }
      })
    })
    //////////////////////////////  --
    if (errors.length > 0) {
      message.error(errors.join(","))
      return
    }

    let data = {
      title,
      description,
      price,
      images,
      categoryId,
      file,
      rank: this.state.data.length + 1,
      avatar,
      itemFeatures
    }

    //yeni kayıt//////////////
    if (!_id) {
      //fotoğraf varsa
      if (file && avatar) {
        message.loading(null, 0)
        try {
          //let uImage = await this.uploadImage(file)
          ////const avatarfullpath = _.get(uImage,"data.fullpath")
          //const avatarfullpath = `${cdnUrl}/uploads/images/product/${_.get(uImage, "data.name", "default.png")}`
          //console.log({ uImage, avatarfullpath })
          let { path } = (await this.uploadImageV1(file)).data
          //data.avatar = avatarfullpath
          data.avatar = path
          const serverData = await this.props.ProductStore.inserProduct(data)
          const item = _.get(serverData, "data.result")
          this.setState({ data: this.state.data.concat([item]) })
          this.resetState()
          message.success()
        } catch (error) {
          message.error()
          console.log(error)
        }
        finally {
          message.destroy()
          this.setState({
            loading: false
          })
        }
        return
      }
      //fotoğraf yoksa
      else {
        try {
          const serverData = await this.props.ProductStore.inserProduct(data)
          const item = _.get(serverData, "data.result")
          //  this.setState({data:this.state.data.push(_.get(serverData,"result"))})
          this.setState({ data: this.state.data.concat([item]) })
          this.resetState()
          message.success()
        } catch (error) {
          message.error()
          console.log(error)
        }
        finally {
          this.setState({ loading: false })
        }

      }
      ////////


    }
    ///////Güncelleme//////////////////
    else {
      if (file && avatar) {
        message.loading(null, 0)
        try {
          //let uImage = await this.uploadImage(file)
          //const avatarfullpath = `${cdnUrl}/uploads/images/product/${_.get(uImage, "data.name", "default.png")}`
          //data.avatar = avatarfullpath
          let { path } = (await this.uploadImageV1(file)).data
          data.avatar = path
          const serverData = await this.props.ProductStore.updateProduct(_id, data)
          let finded = _.find(this.state.data, (o) => {
            return o._id == _id
          })
          if (finded) {
            finded.title = title
            finded.description = description
            finded.avatar = path
            this.setState({ data: this.state.data })
          }
          this.resetState()
          message.success()
        } catch (error) {
          console.log(error)
        }
        finally {
          message.destroy()
          this.setState({ loading: false })
        }



      }
      else {
        try {
          const serverData = await this.props.ProductStore.updateProduct(_id, data)
          let finded = _.find(this.state.data, (o) => {
            return o._id == _id
          })
          if (finded) {
            finded.title = title
            finded.description = description
            this.setState({ data: this.state.data })
          }
          this.resetState()
          message.success()
        } catch (error) {
          console.log(error)
        }
        finally {
          //  this.getItemList()
        }
      }

    }
  }

  //#sil
  confirmDelete = async (item) => {
    console.log(item)
    try {
      const serverData = await this.props.ProductStore.deleteProduct(item._id)
      console.log(serverData)
      this.state.data = _.remove(this.state.data, (o) => {
        return o._id != item._id
      })
      //  console.log({items})
      //  const dd =[]
      //  dd.splice(1)
      this.setState({ data: this.state.data })

    } catch (error) {
      console.log(error)
    }

  }

  //düzenle click
  clickEdit = (item) => {
    if (this.state.categoryList.length == 0) {
      this.getCategoryList()
    }
    //   this.titleInput.focus()
    console.log(item)
    this.setState({
      ...item
    }, () => {
      this.setState({ modalOpenCreate: true })
    })
  }



  uploadImageV1 = (file) => {
    const url = cdnConfig.full_url
    const formData = new FormData()
    formData.append("file", file)
    formData.append("token", cdnConfig.token)
    formData.append("key", cdnConfig.key)
    formData.append("subkey", `product`)
    const headers = { 'content-type': 'multipart/form-data', }
    return axios.post(url, formData, { headers: headers })
  }

  uploadImage = async (file) => {
    let username = await this.props.UserStore.getUsername()

    let formData = new FormData()

    formData.append("image", file)
    formData.append("name", "ferdiozer")
    const url = `${cdnUrl}/upload/product-image/`
    const headers = {
      'content-type': 'multipart/form-data',
      token: "token",
      authorization: "tokencontent",
      imagename: username
    }
    return axios.post(url, formData, { headers: headers })
  }



  handleCancel() {
    this.resetState()
    /*
    this.setState({
      modalOpenCreate: false,
    })
  */
  }

  customRequest = (e) => {
    console.log(e)

    axios.post(e.action, e, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log(err)
    })
  }




  handleChangeImage = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {

      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
          //  avatar:imageUrl  //SONRA
        }),
      );
      // this.handleSubmit()
    }
    //   console.log(info)
    console.log(info.file.response.urly)
    this.setState({
      avatar: info.file.response.urly
    })

  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  uploadFile = (file) => {
    console.log(' eski file', file)

  }



  onChangeImage = (e) => {
    console.log(e)
    console.log({ file: e.target.files })
    let file = e.target.files[0]

    this.setState({
      file: file
    })

    this.getBase64(file, (img) => {
      this.setState({
        avatar: img
      })
    })

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  onChangeCategory = (e) => {
    console.log("on change category", e)
    this.setState({ categoryId: e })
  }

  onChangeSwitchList = async ({ checked, item, type }) => {
    console.log(checked);
    console.log(item);
    message.destroy()
    try {
      const serverData = await this.props.ProductStore.setProductActive(item._id, checked, type)
      checked ? message.success(_.get(item, "title")) : message.error(_.get(item, "title"))
      console.log(serverData)

    } catch (error) {
      console.log(error)
      message.warn()
    }

  }


  onChangeUpload = (info) => {
    console.log({ info })
    console.log({ response: _.get(info, "file.response") })

    const responseStatus = _.get(info, "file.response.status", "")
    if (responseStatus == "done") {
      const name = _.get(info, "file.response.name", "")
      const uid = _.get(info, "file.response.uid", "")
      const status = _.get(info, "file.response.status", "")
      const filename = _.get(info, "file.response.resFile.file.filename", "")
      const path = _.get(info, "file.response.resFile.file.path", "")
      const originalname = _.get(info, "file.response.resFile.file.originalname", "")
      const host = _.get(info, "file.response.resFile.host", "")

      const url = `${host}/uploads/images/product/${name}`
      const thumbUrl = `${host}/uploads/images/product/${name}`

      // eğer yoksa ekle
      let finded = _.find(this.state.images, { originalname })
      if (!finded) {
        this.setState({ images: this.state.images.concat([{ uid, name, status, url, thumbUrl, originalname, host }]) })
      }
      ////////////////////////////////////////////////////////"public\uploads\images\product\ferdiozer-1591907300346-1591907300540.jpg"
    }
    /*
                  uid: '-1',
                  name: 'xxx.png',
                  status: 'done',
                  url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                  thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    */
  }
  onRemove = (file) => {
    //# sonra
    console.log("onRemove file", file)

    console.log("this state image : ", this.state.images)

    const responseStatus = _.get(info, "file.response.status", "")
    if (responseStatus == "done") {
      const name = _.get(info, "file.response.name", "")
      this.state.images = _.remove(this.state.images, (o) => {
        return o.name != name
      })
      this.setState({ images: this.state.images })
    }
  }

  onClickDeleteUploadedImage = (item) => {
    console.log("onClickDeleteUploadedImage", { item })
    this.state.images = _.remove(this.state.images, (o) => {
      return o.uid != item.uid
    })
    this.setState({ images: this.state.images })
  }

  beforeUpload = (file, fileList) => {
    console.log("before upload", { file, fileList })
    //foto ismi set et
    const username = _.get(this.state, "user.username")
    const imageName = `${username}-${new Date().getTime()}`
    this.setState({ imageName })
  }

  onClickSaveSort = async () => {
    let data = this.state.data
    data = data.map(v => {
      return {
        _id: v._id,
        index: v.index,
        rank: v.rank
      }
    })
    console.log("onClickSaveSort", data)


    try {
      const serverData = await this.props.ProductStore.updateProductRank(data);
      console.log({ serverData })
      this.setState({ isShortable: false })
    } catch (error) {
      console.log(error)
    }

  }

  onClickMenuMore = (key) => {
    console.log("onClickMenuMore", key)
    if (key == "sortable") {
      this.setState({ isShortable: !this.state.isShortable })
    }
  }

  //sıralama 
  onSortEnd = ({ oldIndex, newIndex }, props) => {
    const dataSource = this.state.data;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      console.log('Sorted items: ', newData);
      this.setState({ data: newData });
    }

  };

  render() {
    const { t } = this.props

    const { data, loading } = this.state

    const handleFocus = (event) => event.target.select();

    const DragHandle = sortableHandle(() => (
      <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
    ));

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 0 },
      },
    };


    const columns = [
      {
        title: 'Sıra',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: (text, record) => <span>
          {
            this.state.isShortable
              ?
              <DragHandle />
              :
              record.rank
          }
        </span>,
        sorter: this.state.isShortable ? undefined : (a, b) => a.rank - b.rank,
      },
      {
        title: '',
        key: 'avatar',
        render: (text, record) =>
          <span>
            {
              record.avatar ? <Avatar shape="square" src={record.avatar} /> : <Avatar shape="square">{_.get(record, "title", "").charAt(0)}</Avatar>
            }
          </span>
      },
      {
        title: 'Ürün Adı',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Ürün Açıklaması',
        key: 'description',
        render: (text, record) => <span>
          <Text type="secondary" >{record.description}</Text>
        </span>
      },
      {
        title: 'Kategori',
        key: 'category',
        render: (text, record) => <span>
          <Tag color="purple">
            {
              _.get(record, "category.title", "")
            }
          </Tag>
        </span>
      },
      {
        title: 'Eklenme Tarihi',
        key: 'created',
        render: (text, record) => <span>
          <Tooltip title={new Date(record.created).toLocaleString()}>
            {new Date(record.created).toLocaleDateString()}
          </Tooltip>
        </span>,
        sorter: (a, b) => a.created > b.created,
        //   sorter: (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
      },
      {
        title: 'Fiyat',
        key: 'price',
        dataIndex: 'price',
        sorter: (a, b) => a.price - b.price,
      },
      {
        title: 'Durum',
        key: 'active',
        render: (text, record) => <span>
          <Switch size={'small'} onChange={(e) => this.onChangeSwitchList({ checked: e, item: record, type: "active" })} defaultChecked={record.active} />
        </span>
      },
      {
        title: 'Favori',
        key: 'infavorite',
        sorter: (a, b) => a.infavorite || b.infavorite,
        render: (text, record) => <span>
          <Switch size={'small'} onChange={(e) => this.onChangeSwitchList({ checked: e, item: record, type: "infavorite" })} defaultChecked={record.infavorite} />
        </span>
      },
      {
        title: 'İşlem', key: 'action', render: (record) => {
          return (
            <span>
              <Button onClick={() => this.clickEdit(record)} size="small">
                Düzenle
              </Button>
              <Divider type="vertical" />

              {
                /**
                 *                       <Button size="small" type="primary" icon={<InfoOutlined/>}/>
                                  <Divider type="vertical"/>
                 */
              }
              <Popconfirm okText="Evet" cancelText="Hayır" title="Emin misiniz?"
                onConfirm={() => { this.confirmDelete(record) }}>
                <Button type="danger" size="small">
                  Sil
                </Button>
              </Popconfirm>
            </span>
          )
        }
      },
    ];



    //${cdnUrl}/upload/category-image
    //  action: `https://www.piyanos.com/`,

    const props = {
      action: `${cdnUrl}/upload/product-image/`,
      name: "image",
      headers: {
        authorization: 'authorization-text',
        "Access-Control-Allow-Origin": 'http://localhost:4000',
        'imagename': this.state.imageName
      },
      //   fileList : this.state.images,
      beforeUpload: (file, fileList) => this.beforeUpload(file, fileList),
      listType: 'picture',
      //   listType: 'picture-card',
      accept: "image/*",
      //   defaultFileList: [...fileList],
      defaultFileList: this.state._id ? [] : [...this.state.images],
      onChange: (info) => this.onChangeUpload(info),
      onRemove: (file) => this.onRemove(file),
      disabled: this.state.images.length > 5 ? true : false
    };



    const SortableItem = sortableElement(props => <tr {...props} />);
    const SortableContainer = sortableContainer(props => <tbody {...props} />);
    const DragableBodyRow = ({ index, className, style, ...restProps }) => (
      <SortableItem index={restProps['data-row-key']} {...restProps} />
    );


    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={(e) => this.onSortEnd(e, props)}
        {...props}
      />
    );


    const menuMore = (
      <Menu onClick={(e) => this.onClickMenuMore(e.key)} >
        <Menu.Item key="sortable">
          Sırala
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Card
          loading={loading}
          title={'Ürün Listesi'}
          extra={<span>

            {
              this.state.isShortable
                ?
                <>
                  <Button onClick={() => this.onClickSaveSort()} type="primary">
                    Sıralamayı Kaydet
                  </Button>
                  <Divider type="vertical" />
                </>
                :
                null
            }

            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                if (this.state.categoryList.length == 0) {
                  this.getCategoryList()
                }
                this.setState({ modalOpenCreate: true, modalTitle: "Yeni" })
              }} />
            <Divider type="vertical" />
            <Button type="primary"
              shape="circle"
              icon={<ReloadOutlined />}
              onClick={() => this.reload()} />
            <Divider type="vertical" />
            <Dropdown overlay={menuMore}
            >
              <Button
                shape="circle-outline"
                style={{ marginLeft: 5 }}
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </span>}
        >


          <div>
            {
              this.state.isShortable
                ?
                <Table
                  rowKey="index"
                  //  rowKey={'rank'}
                  //  rowKey={'_id'}

                  //   rowKey="index"
                  columns={columns}
                  //  pagination={{ pageSize: 50 }}
                  pagination={false}
                  size="small"
                  dataSource={data}
                  loading={loading}
                  components={{
                    body: {
                      wrapper: DraggableContainer,
                      row: DragableBodyRow,
                    },
                  }}
                />

                :
                <Table
                  rowKey="_id"
                  columns={columns}
                  pagination={{ pageSize: 50 }}
                  size="small"
                  dataSource={data}
                  loading={loading}
                />
            }


          </div>

        </Card>


        <Drawer
          title={this.state._id ? "Düzenle" : "Yeni"}
          placement="left"
          width={720}
          visible={this.state.modalOpenCreate}
          onClose={(e) => this.handleCancel(e)}
          bodyStyle={{ paddingBottom: 80 }}
        >

          <Form {...formItemLayout}>
            <Form.Item
              label="Medya"
            >
              <div style={{ width: 200 }}>
                <div style={{ marginBottom: 10 }}>
                  {this.state.avatar && <img src={this.state.avatar} style={{ maxWidth: "100%" }} alt="avatar" />}
                </div>
                <span>
                  {
                    this.state.avatar ? <Button block type="danger" size="small" onClick={() => this.setState({ avatar: null, file: null })}>Sil <DeleteOutlined /></Button> :
                      <input onChange={(e) => this.onChangeImage(e)} accept="image/*" type="file" placeholder="Fotoğraf seç" />
                  }
                </span>

              </div>
            </Form.Item>

            <Form.Item
              label="Başlık"
              required
            >
              <Input
                value={this.state.title}
                onChange={(e) => this.onChange(e)}
                name="title"
                ref={(Input) => { this.titleInput = Input }}
                placeholder="Ürün Adı" />
            </Form.Item>

            <Form.Item
              label="Açıklama"
            >

              <TextArea
                onChange={(e) => this.onChange(e)}
                name="description"
                value={this.state.description}
                placeholder="Ürün detayı"
                autosize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>

            <Form.Item
              label="Kategori"
              required
            >
              <Select
                value={this.state.categoryId}
                onChange={(e) => this.onChangeCategory(e)}
                name="categoryId"
                placeholder="Kategori seçimi yapın">
                {
                  this.state.categoryList.map((v, i) => {
                    return (
                      <Option key={v._id} value={v._id}>{v.title}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>

            <Form.Item
              required
              label="Fiyat"
            >
              <Input
                value={this.state.price}
                type="number"
                min={0}
                onChange={(e) => this.onChange(e)}
                //  onFocus={handleFocus} 
                name="price"
                placeholder="Ürün Fiyatı" />
            </Form.Item>



            <Form.Item label="Özellikler">
              <div onClick={() => {
                this.setState({
                  itemFeatures: [...this.state.itemFeatures, {
                    i: this.state.itemFeatures.length,
                    time: new Date().getTime(),
                    title: '',
                    contents: [],
                    selectType: featureTypes.single
                  }]
                })
              }}>
                <Button type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  style={{ marginBottom: 10 }}
                />
              </div>
              {
                this.state.itemFeatures.map((v, i) => {
                  return (
                    <div key={i}
                      style={{ marginBottom: 10, padding: 5, borderWidth: 0.2, borderColor: 'red', borderRadius: 5 }}>
                      <Row justify="space-between">
                        <Col>
                          <Radio.Group onChange={(e) => {
                            const value = e.target.value;
                            const finded = _.find(this.state.itemFeatures, (o) => {
                              return o.time == v.time
                            })
                            if (finded) {
                              finded.selectType = value;
                              this.setState({ itemFeatures: this.state.itemFeatures })
                            }
                            console.log({ value, finded })
                          }}
                            value={v.selectType}>
                            <Radio.Button value={featureTypes.single}>Tekli seçim</Radio.Button>
                            <Radio.Button value={featureTypes.multible}>Çoklu seçim</Radio.Button>
                          </Radio.Group>
                        </Col>
                        <Col>
                          <Button type="danger" size="small" icon={<MinusOutlined />}
                            onClick={() => {
                              this.setState({
                                itemFeatures: _.filter(this.state.itemFeatures, (o) => {
                                  return o.time != v.time
                                })
                              })
                            }}
                          />
                          <Divider type="vertical" />
                          <Button size="small" icon={<PlusOutlined />}
                            onClick={() => {
                              const finded = _.find(this.state.itemFeatures, (o) => {
                                return o.time == v.time
                              })
                              if (finded) {
                                finded.contents = [...finded.contents, {
                                  title: '',
                                  price: 0,
                                  time: new Date().getTime(),
                                }]
                                this.setState({ itemFeatures: this.state.itemFeatures })
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <Input
                        value={v.title}
                        placeholder="Özellik başlığı"
                        onChange={(e) => {
                          const finded = _.find(this.state.itemFeatures, (o) => {
                            return o.time == v.time
                          })
                          if (finded) {
                            finded.title = e.target.value
                            this.setState({ itemFeatures: this.state.itemFeatures })
                          }
                        }}
                      />
                      <div style={{ height: 10 }} />
                      {
                        v.contents.map((content, i) => {
                          return (
                            <Row key={i}>
                              <Col span={10}>
                                <Input
                                  value={content.title}
                                  placeholder="Özellik adı"
                                  onChange={(e) => {
                                    const finded = _.find(v.contents, (o) => {
                                      return o.time == content.time
                                    })
                                    if (finded) {
                                      finded.title = e.target.value
                                      this.setState({ itemFeatures: this.state.itemFeatures })
                                    }
                                  }}
                                />
                              </Col>
                              <Col span={10}>
                                <Input
                                  value={content.price}
                                  type="number" placeholder="Fiyat"
                                  onChange={(e) => {
                                    const finded = _.find(v.contents, (o) => {
                                      return o.time == content.time
                                    })
                                    if (finded) {
                                      finded.price = e.target.value
                                      this.setState({ itemFeatures: this.state.itemFeatures })
                                    }
                                  }}
                                />
                              </Col>
                              <Col span={4}>
                                <Row justify="end" type="flex" align="middle">
                                  <Col>
                                    <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <MinusOutlined style={{ display: 'inline-block', verticalAlign: 'middle' }}
                                        onClick={() => {
                                          const finded = _.find(this.state.itemFeatures, (o) => {
                                            return o.time == v.time
                                          })
                                          if (finded) {
                                            finded.contents = _.filter(finded.contents, (o) => {
                                              return o.time != content.time
                                            })
                                            this.setState({ itemFeatures: this.state.itemFeatures })
                                          }
                                          console.log({ finded, content })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )
                        })
                      }


                    </div>
                  )
                })
              }
            </Form.Item>

          </Form>

          <div
            style={{
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 0',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
              İptal
            </Button>
            <Button disabled={loading} onClick={() => this.handleCreate()} type="primary">
              Kaydet
            </Button>
          </div>
        </Drawer>

      </div >
    )
  }
}

export default withTranslation()(Product)