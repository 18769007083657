


import React, { Component } from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Card,
    Carousel,
    Icon,
    Typography,
    notification,
    Button
} from 'antd';
import { Link, Redirect } from 'react-router-dom';

import {
    EyeOutlined,
    PlusCircleTwoTone,
    PhoneOutlined,
    HomeOutlined,
    UserOutlined,
    FacebookOutlined,
    InstagramOutlined
} from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import LoadingPage from './Loading'
import CompanyHeader from './CompanyHeader';

import _ from "lodash"
import CompanyFooter from './CompanyFooter';
//import HomeSlider from './HomeSlider';


import CompanyHomeCategory from './CompanyHomeCategories';
import CompanyProductCard from './CompanyProductCard';
import CompanyPageHeader from './CompanyPageHeader';
import CompanyContainer from './CompanyContainer';
import { toJS } from 'mobx';

const {
    Content
} = Layout;

@inject('CompanyStore')
@observer
class CompanyHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: ''
        }

    }

    componentDidMount() {
        const { categoryid, companykey } = this.props;
        this.props.CompanyStore.getCompanyByApikey(companykey)
        //  this.props.CompanyStore.getHomeSliders()
    }
    componentWillUnmount() {
        notification.destroy()
    }


    addBasket = (item) => {
        this.openNotification({ title: item.title, description: item.description })
        this.props.CompanyStore.addBasket(item)
    }
    close = () => {
        console.log(
            'Notification was closed. Either the close button was clicked or duration time elapsed.',
        );
    };
    openNotification = ({ title, description }) => {
        const companykey = this.props.CompanyStore.getApikey
        const key = `open${Date.now()}`;
        const btn = (
            <Button onClick={() => {
                notification.close(key)
                this.setState({ redirect: `/company/${companykey}/basket` })
            }} type="primary" size="small">
                Sepete Git
            </Button>
        );
        notification.success({
            message: `${title} Sepetinize eklendi`,
            description: description,
            btn,
            key,
            placement: 'bottomRight',
            onClose: close,
        });
    };


    render() {
        const { categoryid } = this.props
        const { loading, companyInfo, homeSliders, homeCategoryWithProducts, getApikey } = this.props.CompanyStore

        console.log("!category:deliveryService:", companyInfo.deliveryService)
        console.log("!category:deliveryService11:", toJS(companyInfo))
        let findedCategory = _.find(homeCategoryWithProducts, (o) => {
            return o._id == categoryid
        })


        if (loading) {
            return (
                <LoadingPage />
            )
        }
        return (
            <CompanyContainer>
                {this.state.redirect && <Redirect to={this.state.redirect} />}
                <CompanyPageHeader
                    companykey={getApikey}
                    title={_.get(findedCategory, "title", "")}
                    subTitle={_.get(findedCategory, "description", "")}
                />
                <div style={{ height: 10 }} />
                <CompanyProductCard
                    data={_.get(findedCategory, "products", [])}
                    visiblePrice={_.get(companyInfo, "company.visiblePrice", true)}
                    deliveryService={_.get(companyInfo, "company.deliveryService", true)}
                    getItemCountInBasket={this.props.CompanyStore.getItemCountInBasket}
                    addBasket={(props) => this.addBasket(props)}
                />
            </CompanyContainer>
        )
    }
}


export default CompanyHome