import React, { Component } from 'react';


import { Row, Switch, Popconfirm, message, Table, Select, Dropdown, Menu, Divider, Tooltip, Checkbox, Icon, Button, Modal, Form, Input, Tag, Col } from 'antd'
import PageHeader from '../../components/PageHeader';

import { inject, observer } from 'mobx-react';
import _ from 'lodash';

const { Option } = Select;



@inject('HomeStore', 'UserStore')
@observer
export default class ScratchAndWin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalNewVisible: false,
            selectedFile: null,
            promotionId: null
        }
    }

    onClickButton = () => {
        this.setState({ modalNewVisible: !this.state.modalNewVisible }, () => {
            this.setState({ detailItem: null })
        })
    }
    onChangeFile = (event) => {
        this.setState({
            selectedFile: event.target.files[0]
        })
    }


    delete = (record) => {
        this.props.HomeStore.removeLoyalty(record._id)
    }
    onChangeSwitchList = (checked, item) => {
        this.updateState(checked, item._id)
    }
    updateState = async (state, _id) => {
        this.props.HomeStore.updateLoyalty(_id, { action: "update-status", active: state })
    }
    submit = async () => {

        const { selectedFile, promotionId } = this.state

        if (!selectedFile || !promotionId) {
            message.error("Gerekli alanların dolu olduğuna emin olunuz")
            return;
        }

        try {
            await this.props.HomeStore.createScratchAndWinImage({ promotionId, file: selectedFile })
            message.success()
            this.setState({ selectedFile: null, modalNewVisible: false })
        } catch (error) {
            console.log({ error })
        }
    }

    render() {
        const { modalNewVisible, selectedFile, promotionId } = this.state
        const { loading } = this.props.HomeStore
        const active = _.get(this.props.UserStore, "user.company.activeScratchAndWin")

        let loyalties = _.get(this.props, "HomeStore.loyalties", [])

        const data = _.filter(loyalties, (o) => {
            return o.loyaltyType == "scratchAndWin"
        })

        const columns = [
            {
                key: 'img_url',
                render: (text, record) =>
                    <span>
                        <img style={{ height: 50 }} src={record.img_url} alt="" />
                    </span>,
            },
            {
                key: 'parent',
                render: (text, record) =>
                    <span>
                        {
                            _.get(
                                _.find(loyalties, (o) => {
                                    return o._id == record.promotionId
                                })
                                , "title", "")
                        }
                        /
                        <b>
                            {
                                _.get(
                                    _.find(loyalties, (o) => {
                                        return o._id == record.promotionId
                                    })
                                    , "coupon", "")
                            }
                        </b>
                    </span>,
            },
            {
                title: '',
                key: 'action',
                render: (text, record) =>
                    <span>
                        <Switch //ref={re => this.sw = re}
                            loading={loading}
                            onChange={(e) => this.onChangeSwitchList(e, record)}
                            checked={record.active}
                        />

                        <Divider type="vertical" />
                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?" onConfirm={() => this.delete(record)}>
                            <Button size='small' type='danger'>
                                Sil
                            </Button>
                        </Popconfirm>
                    </span>
                ,
            },
        ];


        return (
            <div>
                <PageHeader
                    nopadding noback
                    subTitle={active ? "Aktif" : "Pasif"}
                    title='Kazı Kazan Durumu'
                    extra={
                        <span>
                            <Switch
                                loading={loading}
                                onChange={(e) => this.props.UserStore.setCompanyLoyaltyActive("activeScratchAndWin", e)}
                                checked={active}
                            />
                            <Divider type="vertical" />
                            <Button
                                disabled={loading}
                                onClick={() => this.onClickButton()}
                                type={modalNewVisible ? 'danger' : 'ghost'} >
                                {
                                    modalNewVisible ? 'KAPAT' : 'YENİ'
                                }
                            </Button>
                        </span>

                    }
                />


                {
                    modalNewVisible
                    &&
                    <div style={{ paddingBottom: 20 }}>
                        <input type="file" onChange={this.onChangeFile} />
                        <Select
                            value={promotionId}
                            onChange={(e) => this.setState({ promotionId: e })}
                            name="promotionId"
                            placeholder="Promosyon seçimi yapın">
                            {
                                _.filter(loyalties, (o) => {
                                    return o.loyaltyType == "promotion"
                                }).map((v, i) => {
                                    return (
                                        <Option key={v._id} value={v._id}>{v.title}</Option>
                                    )
                                })
                            }
                        </Select>

                        {(selectedFile && promotionId) && <Button type="primary" loading={loading} onClick={() => this.submit("submit")}>Kaydet</Button>}
                    </div>
                }

                {
                    <Table
                        rowKey={'_id'}
                        pagination={false}
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                    />
                }

            </div>
        )
    }

}