import React,{ Component } from "react";
import {Link,} from 'react-router-dom'; // Link,
import { Tabs, Layout,Menu,Row, Col, Button, Badge,Modal,Checkbox,Input,Spin, message, List, Card,Avatar } from 'antd';

import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios'

import _ from 'lodash';

import Header from '../components/Header'
import Footer from '../components/Footer'

import { UserOutlined, SearchOutlined, UserAddOutlined, CloseCircleOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import {goPage, goBack} from '../historyConfig'

const fakeDataUrl = 'https://randomuser.me/api/?results=15&inc=name,gender,email,nat&noinfo';

const { TabPane } = Tabs;


@inject('UserStore')
@observer
export default class Search extends Component{
constructor(props){
  super(props)

  this.state = {
    data:[],
    search:'',
    activeTabsKey:'user',
    loading: false,
    hasMore: true,
    limit:20,
    offset:0
  }
}

getItems=()=>{
    const search = this.state.search
    this.props.UserStore.search(search).then((res)=>res.data)
    .then((data)=>{
      console.log("search getItems",data)
        this.setState({
            data:data
        })

    })
}

componentDidMount=async()=>{
  //  this.getItems()

/*
  if(data.data){
    this.setState({
      data:data.data
    })
  }
  */

  /*

  this.fetchData(res => {
    console.log(res)
    console.log("res")
  this.setState({
    data: res.data.results,
  });
});

*/

}

onClickMessage=(item)=>{
    const url = `message/${item._id}`
    goPage(url)
}


fetchData = callback => {
 axios.get(fakeDataUrl).then((result)=>{
  console.log(result)
  callback(result)
 
 })


};


handleInfiniteOnLoad = () => {
  let { data } = this.state;
  this.setState({
    loading: true,
  });
  if (data.length > 14) {
 //   message.warning('tümü yüklendi');
    this.setState({
      hasMore: false,
      loading: false,
    });
    return;
  }

  this.fetchData(res => {
      data = this.state.data.concat(res.data.results);
      this.setState({
        data,
        loading: false,
      });
    });
  };

  onChangeTabs=(activeTabsKey)=>{
      console.log(activeTabsKey)
      this.setState({
        activeTabsKey
      })
  }


render(){
    
  return(
      <div>

        <Header/>

        <Row
        style={{
            padding : '10px 50px 0 50px'
        }}
        >
            <Col span={24}>
                <Input
                value={this.state.search}
                prefix={
                    this.state.search.length>0 && <Button shape="circle" onClick={()=>this.setState({search:''})}>
                        <CloseOutlined/>
                    </Button>
                }
                onPressEnter={()=>this.getItems()}
                placeholder="Aramak istediğiniz kelimeyi buraya giriniz"
                onChange={e => this.setState({search:e.target.value})}
                autoFocus={true}
                style={{
                    borderRadius:21
                }}
                suffix={<Button 
                    onClick={()=>this.getItems()}
                shape="circle-outline">
                    <SearchOutlined/>
                </Button>}
                />
           </Col>
        </Row>

        <Row
                style={{
                    padding : '0 50px'
                }}
        >
            <Tabs
            onChange={(e)=>this.onChangeTabs(e)}
            tabPosition="bottom"
            style={{
                width:'100%'
            }}
            activeKey={this.state.activeTabsKey}
            >
                <TabPane tab="Kullanıcı" key="user"/>
                <TabPane tab="Grup" key="group"/>
                <TabPane tab="Oda" key="room"/>
         </Tabs>
       </Row>

        <div className="infinite-container" style={{
            padding:'0 50px',
            height:400,
            scrollbarTrackColor:'red',
            }}>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
            //  loadMore={()=>this.handleInfiniteOnLoad()}
              hasMore={!this.state.loading && this.state.hasMore}
              useWindow={false}
            >
              <List
              loading={this.state.loading}
                dataSource={this.state.data}
                renderItem={item => (
                  <List.Item key={item._id}>
                    <List.Item.Meta
                      avatar={
                        <Avatar src={item.avatar}/>
                      }
                      title={<Link to={`/user/${item._id}`}>{item.name}</Link>}
                      description={item.bio}
                    />
                    <Link to={`/user/${item._id}`}>
                        <Button><UserOutlined/></Button>
                    </Link>
                    
                    {
                        this.state.activeTabsKey =="user"
                        &&
                      <Button
                      onClick={()=>this.onClickMessage(item)}
                      ><MessageOutlined/>
                      </Button>
                    }


                  </List.Item>
                )}
              >
                {this.state.loading && this.state.hasMore && (
                  <div className="loading-container">
                    <Spin />
                  </div>
                )}
              </List>
            </InfiniteScroll>
        </div>

        <Footer/>

      </div>
  )
}

}