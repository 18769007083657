import React, { Component } from "react";

import { Row, Col, message, Table, Popconfirm, Divider, Upload, Switch, Button, Menu, Dropdown } from 'antd'
import { inject, observer } from 'mobx-react';

import PageHeader from "../components/PageHeader";


import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
    DeleteOutlined,
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
    MessageOutlined,
    EditOutlined,
    CheckOutlined,
    CameraOutlined,
    StopOutlined,
    CloseOutlined,
    MoreOutlined,
    MenuOutlined,
} from '@ant-design/icons';
import _ from "lodash";


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

@inject('HomeStore')
@observer
export default class HomeSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            show: false,
            isShortable: false
        };
    }


    componentDidMount = () => {
        this.itemList();
    }


    itemList = () => {
        this.props.HomeStore.getHomeSliders()
    }



    delete = (item) => {
        this.props.HomeStore.deleteHomeSlider(item._id)
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    handleChangeImage = info => {
        console.log("handleChangeImage:type:", info.file.type)
        console.log("handleChangeImage:name:", info.file.name)
        console.log("handleChangeImage:uri:", info.file.uri)
        console.log("handleChangeImage::", info.file.originFileObj)
        getBase64(info.file.originFileObj, imageUrl =>
            this.setState({
                imageUrl,
                loading: false,
                postImage: {
                    uri: "file://" + imageUrl,
                    name: info.file.name,
                    type: info.file.type
                }
                //  avatar:imageUrl  //SONRA
            }),
        );

    };

    submit = async (action) => {
        if (action == "save-sort") {
            const sortableDataIds = this.state.dataSource.map(v => v._id);
            console.log({ sortableDataIds })
            this.props.HomeStore.updateHomeSliderSort(sortableDataIds)
            this.setState({
                isShortable: false
            })
        }
        if (action == "submit") {
            const file = this.state.selectedFile; //this.state.postImage //this.state.imageUrl
            try {
                await this.props.HomeStore.uploadHomeSlider(file)
                this.props.HomeStore.setHomeSliders()
                this.setState({ selectedFile: null, show: false })
            } catch (error) {
                console.log({ error })
            }
        }

    }

    onChangeFile = (event) => {
        this.setState({
            selectedFile: event.target.files[0]
        })
    }

    onChangeSwitch = (status, item) => {
        this.props.HomeStore.updateHomeSliderStatus(item._id, status)
    }

    onSortItems = (items) => {
        console.log(items);
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { dataSource } = this.state;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            console.log('Sorted items: ', newData);
            this.setState({ dataSource: newData });
        }
    };




    render() {
        const { show, selectedFile, isShortable } = this.state;

        const data = _.get(this.props, "HomeStore.homeSliders", [])
        const { loading } = this.props.HomeStore

        const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);


        const SortableItem = sortableElement(props => <tr {...props} />);
        const SortableContainer = sortableContainer(props => <tbody {...props} />);
        const DragableBodyRow = ({ index, className, style, ...restProps }) => (
            <SortableItem index={restProps['data-row-key']} {...restProps} />
        );
        const DraggableContainer = props => (
            <SortableContainer
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd({ oldIndex, newIndex })}
                //={(e)=>this.onSortEnd(e)}
                //onSortEnd={(e) => this.onSortEnd(e, props)}
                {...props}
            />
        );

        const menuMore = (
            <Menu>
                <Menu.Item key="0"
                    onClick={() => { this.setState({ show: !show }) }}
                >
                    Yeni
                </Menu.Item>
                <Menu.Item key="1" onClick={() => {
                    this.setState({
                        isShortable: !isShortable,
                        dataSource: data.map((v, i) => {
                            return {
                                ...v,
                                index: _.parseInt(_.get(v, "index", i))
                            }
                        })
                    })
                }}>
                    Sırala
                </Menu.Item>
            </Menu>
        );



        const columns = [
            {
                title: ' ',
                dataIndex: '1',
                key: '1',
                className: 'drag-visible',
                render: (text, record) =>
                    <span>
                        <img style={{ height: 50 }} src={record.img_url} alt="" />
                    </span>,
            },
            {
                title: '',
                key: 'action',
                render: (text, record) =>
                    <span>
                        <Switch defaultChecked={record.active} onChange={(e) => this.onChangeSwitch(e, record)} checkedChildren="Aktif" unCheckedChildren="Pasif" />
                        <Divider type="vertical" />
                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?" onConfirm={() => this.delete(record)}>
                            <Button size='small' type='danger'>
                                Sil
                            </Button>
                        </Popconfirm>
                    </span>
                ,
            },
        ];

        if (isShortable) {
            columns.push({
                title: 'Sıra',
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
            })
        }


        const columns1 = [
            {
                title: ' ',
                dataIndex: 'name',
                className: 'drag-visible',
                render: (text, record) =>
                    <span>
                        <img style={{ height: 50 }} src={record.img_url} alt="" />
                    </span>,
            },
            {
                title: '',
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
        ];

        return (
            <div>

                <PageHeader subTitle={data.length} title="Slider Listesi"
                    extra={
                        <Dropdown trigger={"click"} overlay={menuMore}
                        >
                            <Button
                                shape="circle-outline"
                                style={{ marginLeft: 5 }}
                                icon={<MoreOutlined />}
                            />
                        </Dropdown>
                    }
                />

                {
                    show
                    &&
                    <div style={{ paddingBottom: 20 }}>
                        <input type="file" onChange={this.onChangeFile} />
                        {selectedFile && <Button loading={loading} onClick={() => this.submit("submit")}>Kaydet</Button>}
                    </div>
                }

                {isShortable && <Button loading={loading} onClick={() => this.submit("save-sort")}>Sıralamayı Kaydet</Button>}


                <div style={{ height: 20 }} />
                {
                    isShortable
                        ?
                        <Table
                            pagination={false}
                            dataSource={this.state.dataSource}
                            columns={columns1}
                            rowKey="index"
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DragableBodyRow,
                                },
                            }}
                        />
                        :
                        <Table
                            rowKey={'_id'}
                            pagination={false}
                            columns={columns}
                            dataSource={data}
                            loading={loading}
                        />
                }



            </div>
        )
    }

}







