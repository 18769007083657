import React, { Component } from 'react';
import { message, Table, Switch, Row, Button, Descriptions, Icon, Modal, Popconfirm, Tag, Tooltip, Typography, Progress, Collapse, Statistic, Card, Col, DatePicker } from 'antd'

import _ from 'lodash';
import moment from 'moment';
moment.locale("tr")


import { EyeOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import ReactToPrint, { PrintContextConsumer, useReactToPrint } from "react-to-print";

const { RangePicker } = DatePicker;
const { Text } = Typography

const { Panel } = Collapse;

@inject('OrderStore')
@observer

export default class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modalVisible: false,
            data: [],
            dataGroupCustomerId: [],
            detailItem: null,
            groups: [],
            paymentTypes: [
                { i: 0, title: 'Nakit' },
                { i: 1, title: 'Kredi Kartı' }
            ],
            priceTypes: [
                { i: 0, title: 'Maliyet', key: 'price0', totalPrice: 0 },
                { i: 1, title: 'Parakende', key: 'price1', totalPrice: 0 },
                { i: 2, title: 'Toptan', key: 'price2', totalPrice: 0 },
                { i: 3, title: 'Toptan üstü', key: 'price3', totalPrice: 0 }
            ],
            startOfListSelected: 'day',
            startOfList: [
                {
                    key: 'year',
                    title: 'Yıl'
                },
                {
                    key: 'month',
                    title: 'Ay'
                },
                {
                    key: 'week',
                    title: 'Hafta'
                },
                {
                    key: 'day',
                    title: 'Gün'
                },
                {
                    key: 'hour',
                    title: 'Saat'
                }
            ],
            startOfListFrom: '',
            totalTurnover: 0,
            totalTurnoverTick: 0,
            cashPriceTotal: 0,
            cashPriceTotal1: 0,
            dataBestSallerProduct: [],
            dataBestSallerProductGeneralTotalCount: 0,
            totalPriceCustomerSaved: 0,
            totalPriceCustomerNoSaved: 0,
            totalPricePriceType0: 0,
            totalPricePriceType1: 0,
            totalPriceCustomerTick0: 0,
            totalPriceCustomerTick1: 0,
            customerCount: { type0: 0, type1: 0 }

        }

        this.getItemList = this.getItemList.bind(this);
        this.reload = this.reload.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);

    }



    componentDidMount() {
        this.getItemList()

        /*
        const today = new Date();
   
        let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
   
        console.log(today.toLocaleDateString())
        console.log("geçen hafta,",new Date(lastWeek).toLocaleDateString())
        */
    }

    handleOpenChangeDatePicker(e) {
        console.log("handleOpenChangeDatePicker", e)
    }
    onChangeDate = (dates, dateStrings) => {
        console.log(dateStrings)
        this.getItemList(dateStrings)
        /*
            if(!isNaN(new Date(dateStrings[0])) && !isNaN(new Date(dateStrings[1]))){
                this.getItemList(dateStrings)
              }
              else{
                this.getItemList()
              }
        */
    }

    //# maleef bu fonksiyonum çöp gibi oldu
    getItemList = async (dateStrings = null) => {
        this.setState({ loading: true, data: [] });

        //Tarih olayları
        var date = new Date();
        var yesterday = date - 1000 * 60 * 60 * 24 * 1;   // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
        yesterday = new Date(yesterday);
        console.log({ yesterday });

        let dString = `${yesterday},${new Date()}`

        if (dateStrings) {
            dString = `${new Date(dateStrings[0])},${new Date(dateStrings[1])}`
        }


        try {
            const serverData = await this.props.OrderStore.getItemListAll(`date=${dString}`) // "date=01-01-2019,01-01-2029"

            console.log({ serverData })

            let result = _.get(serverData, "data", [])



            /*
            if (dateStrings) {
                result = _.filter(result, (item) => {
                    return new Date(item.created).getTime() >= new Date(dateStrings[0]).getTime() && new Date(item.created).getTime() <= new Date(dateStrings[1]).getTime()
                })
            }
            */


            //toplam ciro  //tümü
            let totalTurnover = _.sumBy(result, (item) => {
                return parseFloat(item.totalPrice)
            })


            let dataBestSallerProduct = []
            let dataBestSallerProductGeneralTotalCount = 0

            result.map((v) => {
                v.items.map((v2) => {
                    v2.count = isNaN(parseInt(v2.count)) ? 0 : parseInt(v2.count)
                    dataBestSallerProduct.push(v2)
                })
            })

            dataBestSallerProduct = _(_.groupBy(dataBestSallerProduct, (o) => {
                return o._id
            })).map((v, i) => {
                return {
                    '_id': i,
                    'count': _.sumBy(v, (o) => {
                        return parseInt(o.count)
                    }),
                    'title': _.find(v, { _id: i }).title

                }
            }).value();

            this.setState({
                data: _.orderBy(result, ['created'], ['desc']),
                loading: false,
                dataBestSallerProduct: _.orderBy(dataBestSallerProduct, ['count'], ['desc']),
                dataBestSallerProductGeneralTotalCount,
                totalTurnover
            });

        } catch (error) {
            console.log({ error })
        }

    }


    reload() {
        this.getItemList();
    }


    renderPaymentPriceTypes(record) {
        let returnText = "";
        let paymenttType = _.find(this.state.paymentTypes, { i: Number.parseInt(record.paymentType) })
        let priceeType = _.find(this.state.priceTypes, { i: Number.parseInt(record.priceType) })
        if (paymenttType) returnText = paymenttType.title;
        if (priceeType) returnText = `${returnText} / ${priceeType.title}`

        return returnText;
    }

    renderPaymentType(record) {
        let paymenttType = _.find(this.state.paymentTypes, { i: Number.parseInt(record.paymentType) })
        if (paymenttType) return paymenttType.title;
        return ''
    }
    renderPriceType(record) {
        let priceeType = _.find(this.state.priceTypes, { i: Number.parseInt(record.priceType) })
        if (priceeType) return priceeType.title
        else {
            return 'Cari'
        }
    }

    render() {

        const localeMain = {
            filterConfirm: 'Tamam',
            filterReset: 'Sıfırla',
            emptyText: 'Veri Yok'
        }


        const columnsBestSallerProduct = [
            {
                title: 'Ürün Adı',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'Toplam Satılan',
                dataIndex: 'count',
                key: 'count',
            }
        ];




        return (
            <div>
                <Card title="Rapor"
                    extra={
                        <Button type="primary"
                            shape="circle"
                            title="Yenile"
                            icon={<ReloadOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => this.reload()}
                        />
                    }
                >
                    <div style={{ marginBottom: '10px' }}>

                        <Collapse bordered={false} defaultActiveKey={['1', '2']}>
                            <Panel header="Genel (Satış)" key="1">
                                <div>
                                    <Row gutter={16} style={{ marginBottom: 10 }}>
                                        <Col span={12}>
                                            <Card>
                                                <Row style={{ marginBottom: 14 }}>
                                                    <Statistic
                                                        title="Satış tutarı"
                                                        value={this.state.totalTurnover}
                                                        precision={2}
                                                        valueStyle={{ color: '#3f8600' }}
                                                        prefix={<EyeOutlined />}
                                                        suffix="TL"
                                                    />
                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col span={12}>
                                            <Card>
                                                <div>
                                                    <div>Tarih aralığı</div>
                                                    <RangePicker
                                                        style={{ width: '100%' }}
                                                        onChange={this.onChangeDate}
                                                        onOpenChange={this.handleOpenChangeDatePicker}
                                                        showTime={{
                                                            hideDisabledOptions: true,
                                                            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                                                        }}
                                                        format="YYYY-MM-DD HH:mm:ss"
                                                    />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>

                                </div>
                            </Panel>

                            <Panel header="Ürün Bazlı İstatistik (En çok satılanlar)" key="2">
                                <Table
                                    rowKey={'_id'}
                                    columns={columnsBestSallerProduct}
                                    pagination={false}
                                    loading={this.state.loading}
                                    dataSource={this.state.dataBestSallerProduct}
                                />
                            </Panel>
                        </Collapse>
                    </div>



                    <div>
                        <ReactToPrint
                            //  onBeforePrint={() => this.onBeforePrint()}
                            //   onAfterPrint={(e) => this.onAfterPrint(e)}
                            trigger={() => {
                                return <Button title="Yazdır" icon={<PrinterOutlined />}>Yazdır</Button>
                            }}
                            content={() => this.componentRef}
                        />


                        <div style={{ display: "none" }}>

                            <div ref={el => (this.componentRef = el)}  >
                                <Table
                                    rowKey={'_id'}
                                    columns={columnsBestSallerProduct}
                                    pagination={false}
                                    loading={this.state.loading}
                                    dataSource={this.state.dataBestSallerProduct}
                                />
                                <Row style={{paddingLeft:20,marginTop:5}}>
                                    <Col span={12}><b>{`${this.state.totalTurnover} ₺`}</b></Col>
                                    <Col span={12}>{new Date().toLocaleDateString()}</Col>

                                </Row>
                                <div style={{textAlign:'center'}}>@piyanrest</div>
                            </div>
                        </div>

                    </div>

                </Card>
            </div>
        );
    }
}
