


import React, { Component } from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Card,
    Carousel,
    Icon
} from 'antd';
import { Link } from 'react-router-dom';

import {
    EyeOutlined,
    PlusCircleTwoTone,
    PhoneOutlined,
    HomeOutlined,
    UserOutlined,
    FacebookOutlined,
    InstagramOutlined
} from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import LoadingPage from './Loading'
import CompanyHeader from './CompanyHeader';

import _ from "lodash"
import CompanyFooter from './CompanyFooter';
//import HomeSlider from './HomeSlider';
import HomeSlider from './CompanyHomeSlider';
import CompanyHomeCategory from './CompanyHomeCategories';
import { toJS } from 'mobx';

const {
    Content
} = Layout;

@inject('CompanyStore')
@observer
class CompanyContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    componentDidMount() {
        // const apikey = this.props.companykey;
        // this.props.CompanyStore.getCompanyByApikey(apikey)
        //  this.props.CompanyStore.getHomeSliders()
    }




    render() {
        const { loading, companyInfo, homeSliders, homeCategoryWithProducts, getApikey } = this.props.CompanyStore

        let basketItems = this.props.CompanyStore.basketItems || []
        basketItems = _.uniqBy(basketItems, (o) => {
            return o._id;
        });

        if (loading) {
            return (
                <LoadingPage
                // title={"yükleniyor..."}
                />
            )
        }
        return (
            <Layout>
                <CompanyHeader
                    title={_.get(companyInfo, "company.name")}
                    logo={_.get(companyInfo, "company.logo")}
                    basketItems={basketItems}
                    companykey={getApikey}
                    deliveryService={_.get(companyInfo, "company.deliveryService")}
                //phone={_.get(companyInfo, "company.phone")}
                />

                <Row justify="space-around" type="flex">
                    <Col span={20} style={{
                        paddingBottom: '30px',
                    }}>
                        <Content style={{ minHeight: 'calc(100vh - 190px)' }}>
                            {this.props.children}
                        </Content>
                    </Col>
                </Row>

                <CompanyFooter
                    productName={"smartcafe."}
                    version={"v1.0.3"}
                    phone={_.get(companyInfo, "company.phone")}
                />
            </Layout>
        )
    }
}


export default CompanyContainer