

import QRCode from 'qrcode'


export const qrb64 = async (text, width = 128) => {
    // color:{dark:"#293F68",light:"#FFF"}
    return new Promise((resolve, reject) => {
        QRCode.toDataURL(text, { width }).then(url => {
            return resolve(url)
        }).catch(err => {
            return reject(err)
        })
    })
}

export const isNumeric = (value) => {
    return /^-{0,1}\d+$/.test(value);

}

export const qrCodeB64 = async (text, width = 128) => {
    const data = await QRCode.toDataURL(text, { width })
    return data
}

const serialize = function (obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}


function renderPriceForFront(price) {
    return new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
    }).format(price)
}

export default renderPriceForFront