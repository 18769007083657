


import PropTypes from 'prop-types';

import React from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Card,
    Carousel,
    Icon
} from 'antd';
import { Link } from 'react-router-dom';

import {
    EyeOutlined,
    PlusCircleTwoTone,
    PhoneOutlined,
    HomeOutlined,
    UserOutlined,
    FacebookOutlined,
    InstagramOutlined
} from '@ant-design/icons';

const {
    Footer,
} = Layout;

const CompanyFooter = ({
    productName,
    version,
    phone
}) => {
    return (
        <Footer style={{ background: '#f9f9f9' }}>
            <Row justify="space-around" type="flex" >
                <Col md={8} xs={20} style={{ height: '40px', display: 'flex' }}>
                    <div style={{ margin: 'auto 0px' }}>
                        &copy; {new Date().getFullYear()}{productName}
                        {
                            version
                            &&
                            ` ${version}`
                        }
                    </div>
                </Col>
                <Col md={8} xs={20}>
                    {
                        // version
                        // &&
                        // <div style={{ margin: 'auto  0px', height: '40px', display: 'flex', justifyContent: 'flex-end' }}>
                        //     {version}
                        // </div>

                    }
                    {
                        phone
                        &&
                        <span className="ml-30 float-right">
                            <PhoneOutlined /> {phone}
                        </span>
                    }
                </Col>
            </Row>
        </Footer>
    )
}

//CompanyFooter.propTypes = {
//    title: PropTypes.string,
//    phone: PropTypes.string,
//    logo: PropTypes.string
//};

export default CompanyFooter;