



import PropTypes from 'prop-types';
import React, { useEffect } from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Card,
    Carousel,
    Icon
} from 'antd';
import { Link } from 'react-router-dom';
import shave from 'shave';

const CompanyHomeCategory = ({
    title,
    data,
    apikey
}) => {

    useEffect(() => {


        shave(".custom-ant-card-meta-description", 25)
    })
    return (
        <Row gutter={16}>
            {
                data.map((v, i) => {
                    return (
                        <Col key={i} span={6} md={6} sm={12} xs={24}>
                            <Link to={`/company/${apikey}/category/${v._id}`}>
                                <Card
                                    hoverable
                                    cover={
                                        <img title={v.description} alt={v.title} style={{ width: '100%' }} src={v.avatar} />
                                    }
                                >
                                    <Card.Meta
                                        title={v.title}
                                        description={<span className={"custom-ant-card-meta-description"}>{v.description}</span>}

                                    />
                                </Card></Link>
                        </Col>
                    )
                })
            }
        </Row>
    )
}

CompanyHomeCategory.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    apikey: PropTypes.string,
};

export default CompanyHomeCategory;