


import React, { Component } from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Card,
    Carousel,
    Icon,
    Typography,
    Button,
    Alert,
    List,
    Avatar,
    Steps,
    Input,
    message
} from 'antd';
import { Link } from 'react-router-dom';

import {
    EyeOutlined,
    PlusCircleTwoTone,
    PhoneOutlined,
    HomeOutlined,
    UserOutlined,
    PlusOutlined,
    MinusOutlined,
    DeleteOutlined,
    HeartOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
    CheckOutlined
} from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import LoadingPage from './Loading'
import CompanyHeader from './CompanyHeader';

import _ from "lodash"
import CompanyFooter from './CompanyFooter';
//import HomeSlider from './HomeSlider';


import CompanyHomeCategory from './CompanyHomeCategories';
import CompanyProductCard from './CompanyProductCard';
import CompanyPageHeader from './CompanyPageHeader';
import CompanyContainer from './CompanyContainer';
import renderPriceForFront from '../helper';

const { Step } = Steps;
const { TextArea } = Input;

@inject('CompanyStore')
@observer
class CompanyBasket extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 0,
            sendStatus: ''
        }

    }

    componentDidMount() {
        this.setState({ sendStatus: '' })
        const { categoryid, companykey } = this.props;
        this.props.CompanyStore.getCompanyByApikey(companykey)
        //  this.props.CompanyStore.getHomeSliders()
    }

    submit = async () => {
        const { name, phone, address, customNote } = this.state;
        if (!name || !phone || !address) {
            message.error("Lütfen gerekli alanları doldurun");
            return
        }

        let basketItems = this.props.CompanyStore.basketItems
        basketItems = _.uniqBy(basketItems, (o) => {
            return o._id;
        });
        basketItems = basketItems.map((v, i) => {
            return {
                ...v,
                count: this.props.CompanyStore.getItemCountInBasket(v._id)
            }
        })

        const postData = {
            name, phone, address, customNote,
            items: basketItems
        }
        try {
            await this.props.CompanyStore.createOrder(postData)
            this.setState({ step: this.state.step + 1, sendStatus: 'send-success' })
            message.success()
            this.props.CompanyStore.clearBasketItems()
        } catch (error) {
            console.log(error)
        }


    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        const { step } = this.state
        const { categoryid } = this.props
        const {
            loading,
            companyInfo,
            homeSliders,
            homeCategoryWithProducts,
            getApikey,
            getBasketTotalSum
        } = this.props.CompanyStore

        let basketItems = this.props.CompanyStore.basketItems

        basketItems = _.uniqBy(basketItems, (o) => {
            return o._id;
        });


        const renderHeaderExtra = (
            <div>
                {((step == 0 || step == 1) && basketItems.length > 0) && <Button onClick={() => {
                    if (step == 1) {
                        this.submit()
                    } else {
                        this.setState({ step: this.state.step + 1 })
                    }
                }}>Devam</Button>}
                {(basketItems.length == 0) && <Link to={`/company/${getApikey}`}>Anasayfa</Link>}
            </div>
        )


        if (loading) {
            return (
                <LoadingPage />
            )
        }
        return (
            <CompanyContainer>
                <CompanyPageHeader
                    noback={step > 0 ? true : undefined}
                    title={"Sepetim"}
                    //extra={step == 0 ? <Button onClick={() => this.setState({ step: this.state.step + 1 })}>Devam</Button> : (step == 1 ? <Button>111</Button> : <Button>2</Button>)}
                    extra={renderHeaderExtra}
                />

                <Steps current={step}>
                    <Step key={0} title="Ürünler" description="Sepetinizdeki ürünler" />
                    <Step key={1} title="Özet" subTitle={renderPriceForFront(getBasketTotalSum())} description="Sipariş özetiniz" />
                    <Step key={2} title="Sonuç" description="Bilgilendirme" />
                </Steps>


                <div style={{ height: 10 }} />

                {
                    (step == 0 || step == 1) && basketItems.length == 0
                    &&
                    (
                        <div>
                            <Alert
                                message="Sepetiniz Boş"
                                description="Devam edebilmek için sepetinizde ürün olmalıdır."
                                type="warning"
                                showIcon
                            //  closable
                            />
                            <div style={{ height: 20 }} />
                            <Link to={`/company/${getApikey}`}>
                                <Button block type="primary" icon={<ArrowLeftOutlined />}>
                                    Ürünlere Göz At
                                </Button>
                            </Link>

                        </div>
                    )
                }

                {
                    step == 0 && (basketItems.length > 0)
                    &&

                    <div>


                        <Row gutter={16}>
                            {
                                basketItems.map((v, i) => {
                                    return (
                                        <Col key={i} span={6} md={6} sm={12} xs={24}>
                                            <Card
                                                actions={[
                                                    <div>
                                                        <Button onClick={() => this.props.CompanyStore.basketItemDec(v._id)} shape="circle" icon={<MinusOutlined />}>

                                                        </Button>
                                                    </div>,
                                                    <div>{this.props.CompanyStore.getItemCountInBasket(v._id)}</div>,
                                                    <div>
                                                        <Button onClick={() => this.props.CompanyStore.basketItemInc(v._id)} shape="circle" icon={<PlusOutlined />}>

                                                        </Button>
                                                    </div>,
                                                ]}
                                                hoverable
                                                cover={
                                                    <img title={v.description} alt={v.title} style={{ width: '100%' }} src={v.avatar} />
                                                }
                                            >
                                                <Card.Meta
                                                    title={v.title}
                                                    description={v.clicked ? v.description : <span className={"custom-ant-card-meta-description"}>{v.description}</span>}

                                                />
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                        <div>
                            <div style={{ height: 20 }} />
                            <Button block type="primary" onClick={() => this.setState({ step: this.state.step + 1 })}>
                                Devam <ArrowRightOutlined />
                            </Button>
                        </div>
                    </div>
                }
                {
                    step == 1
                    &&
                    <div style={{ padding: 24, backgroundColor: '#FFF' }}>
                        <div style={{ marginBottom: 10 }}>
                            <span className={"ant-list-header"}>Adınız*</span>
                            <Input onChange={(e) => this.onChange(e)} name="name" value={this.state.name} placeholder="Adınızı buraya girin. (ZORUNLU ALAN)" />
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <span className={"ant-list-header"}>Telefon*</span>
                            <Input onChange={(e) => this.onChange(e)} name="phone" value={this.state.phone} placeholder="Telefon Numarasını buraya girin. (ZORUNLU ALAN)" />
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <span className={"ant-list-header"}>Adres*</span>
                            <TextArea onChange={(e) => this.onChange(e)} name="address" value={this.state.address} placeholder="Adresinizi detaylı yazınız. (ZORUNLU ALAN)" />
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            <span className={"ant-list-header"}>Not</span>
                            <TextArea onChange={(e) => this.onChange(e)} name="customNote" value={this.state.customNote} placeholder="Varsa  eklemek istediklriniz." />
                        </div>
                        <List
                            header={"Ürünler"}
                            itemLayout="horizontal"
                            dataSource={basketItems}
                            renderItem={item => (
                                <List.Item actions={[
                                    <div>{this.props.CompanyStore.getItemCountInBasket(item._id)}</div>
                                ]}>
                                    <List.Item.Meta

                                        avatar={<Avatar src={item.avatar} />}
                                        title={item.title}
                                        description={item.description}
                                    />
                                </List.Item>
                            )}
                        />
                        <div>
                            <div style={{ height: 20 }} />
                            <div style={{ margin: 'auto  0px', height: '40px', display: 'flex', justifyContent: 'flex-end' }}>
                                Toplam Tutar : <span style={{ fontWeight: 'bold' }}>{` : ${renderPriceForFront(getBasketTotalSum())}`}</span>
                            </div>

                            <Button icon={<CheckOutlined />} block type="primary" onClick={() => this.submit()}>
                                Gönder
                            </Button>
                        </div>
                    </div>
                }

                {
                    this.state.sendStatus == "send-success"
                    &&
                    <div>
                        <Alert message="Başarılı" description="Siparişinizi aldık. Teşekkürler." type="success" showIcon />
                        <div style={{ height: 20 }} />
                        <Link to={`/company/${getApikey}`}>
                            <Button block type="primary" icon={<ArrowLeftOutlined />}>
                                Anasayfaya Dön
                            </Button>
                        </Link>
                    </div>

                }
            </CompanyContainer>
        )
    }
}


export default CompanyBasket

