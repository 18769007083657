
import React from 'react'

import { Typography, Timeline } from 'antd'

import moment from 'moment';

const { Text } = Typography
export default ({
    data = []
}) => {
    return (
        <Timeline>
            {
                data.map((v, i) => {
                    return (
                        <Timeline.Item
                            key={i}
                            color={(v.subType == 1 && 'green') || (v.subType == 0 && 'red') || undefined}
                        >
                            {v.title}
                            {` `}
                            <Text style={{ fontSize: 10 }} type="secondary">({moment(v.created).fromNow()})</Text>
                        </Timeline.Item>
                    )
                })
            }
        </Timeline>
    )
}



