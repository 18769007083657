
import React,{ Component } from "react";

import { Card, Avatar,Row,Col } from 'antd';

import QRCodeComponent from "./QrCode";

import _ from "lodash"


const { Meta } = Card;


class ComponentToPrint extends Component {
    constructor(props){
        super(props)
        this.state={
            
        }
    }
    render() {
        const {qrcode} = this.props
        const width = qrcode.width || 512
        const multible = _.get(qrcode,"printAction")==="printAll"
        const withlogo = qrcode.withlogo || false
        const codes = qrcode.data || []
        console.log("ComponentToPrint. props",this.props)

        let cardSpan = 8
        if(qrcode.size=="small"){
            cardSpan = 6
        }
        if(qrcode.size=="big"){
            cardSpan = 12
        }

      return (
          <div>
              {
                  multible
                  ?
              
              <Row>
                  {
                      codes.map((v,i)=>{
                          return(
                            <Col key={i} span={cardSpan}>
                            <Card
                                 // style={{ width: width }}
                                  cover={
                                      <QRCodeComponent 
                                      text={`https://menu.cafeotomasyonu.com/?qr=${_.get(v,"_id")}`} 
                                      width={width}
                                      />
                                  }
                                  actions={[
                                      <span>
                                          {_.get(qrcode,"extra")}
                                      </span>,
                                  ]}
                              >
                                  <Meta
                                  avatar={withlogo ?<Avatar src="https://www.piyanos.com/logo/logo1024.png" /> : undefined}
                                  title={_.get(v,"title")}
                                  description={<span>{_.get(qrcode,"descriptiontr","")}<br/>{_.get(qrcode,"descriptionen","")}<br/><b>{_.get(qrcode,"qrreadurl","")}</b></span>}
                                  />
                              </Card>
                            </Col>
                          )
                      })
                  }
              </Row>
              :
              <Row>
                  <Col span={cardSpan}>
                        <Card
                    //   style={{ width: width }}
                        cover={
                            <QRCodeComponent 
                            text={`https://menu.cafeotomasyonu.com/?qr=${_.get(qrcode,"_id")}`} 
                            width={512}
                            />
                        }
                        actions={[
                            <span>
                                {_.get(qrcode,"extra")}
                            </span>,
                        ]}
                    >
                        <Meta
                        avatar={withlogo ?<Avatar src="https://www.piyanos.com/logo/logo1024.png" /> : undefined}
                        title={_.get(qrcode,"title")}
                        description={<span>{_.get(qrcode,"descriptiontr","")}<br/>{_.get(qrcode,"descriptionen","")}<br/><b>{_.get(qrcode,"qrreadurl","")}</b></span>}
                        />
                    </Card>
                  </Col>
              </Row>
    }
          </div>
      );
    }
  }
  

  export default ComponentToPrint