
import { observable, action, computed, toJS } from 'mobx';

import _ from "lodash"
import moment from 'moment';

import { getToken as token } from "../services/Auth";
import HomeService from "../services/Home"

export default class Home {

    @observable loading = false
    @observable loadingSwitch = false
    @observable lastLogs = []
    @observable logList = []
    @observable homeChartData = {}
    @observable homeDashCountList = []
    @observable homeSliders = []
    @observable reservations = []
    @observable staffTypes = [
        { "title": "Kurye", "key": "ride" },
        { "title": "Garson", "key": "waiter" },
        { "title": "Mutfak", "key": "kitchen" },
    ]
    @observable staffList = []

    @observable loyalties = []
    ////master admin
    @observable restaurantCategoryList = []
    @observable masterUsers = []
    @observable restaurantList = []
      ////master admin END


    //logları listele
    @action
    getLogItems = async (date) => {
        this.loading = true
        try {
            const service = (await new HomeService(token).getLogs(date)).data;
            this.logList = service.results;
            if (!date) {
                this.lastLogs = service.results;
            }

        } catch (error) {
            console.log("[ERROR]", error)
        } finally {
            this.loading = false
        }
    }

    //anasayfa grafik verileri set et
    @action
    getDashboardChart = async () => {
        this.loading = true
        try {
            const serverData = (await new HomeService(token).getDashboardChart());
            const data = _.get(serverData, "data", [])
            //////
            const xaxisData = data.map(v => {
                var dt = moment(v.firstOrderDate, "YYYY-MM-DD HH:mm:ss")
                return dt.format('dddd')
            })
            //////
            const seriesData = data.map(v => {
                return v.totalPrice
            })

            const mySeries = [
                {
                    name: "Tutar (₺)",
                    data: []
                }
            ]
            const myOptions = {
                xaxis: {
                    categories: []
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#ff3f34',
                        shadeTo: 'light',
                        shadeIntensity: 0.65
                    }
                }
            }
            /////
            Object.assign(myOptions.xaxis, { categories: xaxisData })

            myOptions.xaxis.categories = xaxisData
            mySeries[0].data = seriesData
            this.homeChartData = {
                series: mySeries,
                options: myOptions
            }
            this.homeChartData = JSON.parse(JSON.stringify(this.homeChartData))
        } catch (error) {
            console.log("[ERROR]", error)
        } finally {
            this.loading = false
        }
    }
    //anasayfa dashboard kısayolları
    @action
    getDashboardCount = async () => {
        try {
            const service = (await new HomeService(token).getDashboardCount()).data;
            console.log("[LOG]:getDashboardCount:Homestore", service)
            this.homeDashCountList = service;
        } catch (error) {
            console.log("[ERROR]", error)
        } finally {
            this.loading = false
        }
    }

    //////////////////////////////////      --  HOME SLİDER   --       ///////////
    @action
    setHomeSliders = (data) => {
        if (!data) {
            data = this.homeSliders
        }
        this.homeSliders = JSON.parse(JSON.stringify(data))
    }
    @action
    uploadHomeSlider = async (file) => {
        try {
            const service = new HomeService(token)
            this.loading = true;
            const upload = (await service.uploadHomeSlider(file)).data;
            console.log({ upload })
            const insertData = {
                img_url: upload.path,
            }
            const created = (await service.createHomeSlider(insertData)).data;
            console.log({ created })
            this.homeSliders.push(created);
            this.setHomeSliders()

        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    getHomeSliders = async () => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const sliders = (await service.getHomeSliders()).data;
            this.homeSliders = sliders
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    deleteHomeSlider = async (id) => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            service.deleteHomeSlider(id).then(() => {
                this.homeSliders = _.remove(this.homeSliders, (o) => {
                    return o._id != id
                })
                this.loading = false;
            })
        } catch (error) {
            console.log(error)

        } finally {
            this.loading = false;
        }
    }
    @action
    updateHomeSliderStatus = (id, status) => {
        try {
            const service = new HomeService(token)
            service.updateHomeSliderStatus(id, { action: "update-status", active: status })
        } catch (error) {
            console.log(error)
        }
    }
    @action
    updateHomeSliderSort = async (itemIds) => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            await service.updateHomeSliderSort({ action: "update-sort", itemIds })
            this.getHomeSliders()
        } catch (error) {
            console.log(error)
        } finally {
            this.loading = false;
        }
    }
    //////////////////////////////////      --  HOME SLİDER   --end       ///////////

    //////////////////////////////////      --  LOYALTY   --       ///////////
    @action
    getLoyaltyList = async () => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const serverData = (await service.getLoyaltyList("promotion")).data;
            console.log({ serverData })
            this.loyalties = serverData
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    createLoyalty = async (insertData) => {
        try {
            this.loading = true
            const service = new HomeService(token)
            const serverData = (await service.createLoyalty(insertData)).data;
            console.log({ serverData })
            this.loyalties = [serverData, ...this.loyalties]
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    @action
    updateLoyalty = async (id, insertData) => {
        try {
            this.loading = true
            const service = new HomeService(token)
            const serverData = (await service.updateLoyalty(id, insertData)).data;
            console.log({ serverData })
            let findedIndex = _.findIndex(this.loyalties, (o) => {
                return o._id == id
            });
            if (findedIndex != -1) {
                this.loyalties[findedIndex] = {
                    ...this.loyalties[findedIndex],
                    ...serverData
                }
            }

            this.loyalties = JSON.parse(JSON.stringify(this.loyalties))
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    @action
    removeLoyalty = async (id) => {
        try {
            this.loading = true
            const service = new HomeService(token)
            const serverData = (await service.removeLoyalty(id)).data;
            console.log({ serverData })
            this.loyalties = _.filter(this.loyalties, (o) => {
                return o._id != id
            })
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }


    @action
    createScratchAndWinImage = async ({ promotionId, file }) => {
        try {
            const service = new HomeService(token)
            this.loading = true;
            const upload = (await service.uploadScratchAndWinImage(file)).data;
            console.log({ upload })
            const insertData = {
                img_url: upload.path,
                promotionId,
                loyaltyType: "scratchAndWin"
            }
            const serverData = (await service.createLoyalty(insertData)).data;
            console.log({ serverData })
            this.loyalties = [serverData, ...this.loyalties]
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    createPunchCard = async (insertData) => {
        try {
            const service = new HomeService(token)
            this.loading = true;
            const upload = (await service.uploadPunchCardImage(insertData.file)).data;
            console.log({ upload })
            insertData.img_url = upload.path
            const serverData = (await service.createLoyalty(insertData)).data;
            console.log({ serverData })
            this.loyalties = [serverData, ...this.loyalties]
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    //////////////////////////////////      -- LOYALTY   --end       ///////////

    ////////////////--  PERSONEL   start////////////////////////////////////////////
    @action
    getUserStaffs = async () => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const serverdata = (await service.getUserStaffs()).data;
            console.log(":getUserStaffs:store:", { serverdata })
            this.staffList = serverdata
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    @action
    createUserStaffs = async (postData) => {
        try {
            const service = new HomeService(token)
            this.loading = true;
            const created = (await service.createUserStaffs(postData)).data;
            console.log({ created })
            this.getUserStaffs()
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    @action
    deleteUserStaffs = async (id) => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            await service.deleteUserStaffs(id)
            this.staffList = _.remove(this.staffList, (o) => {
                return o._id != id
            })
            this.loading = false;

        } catch (error) {
            console.log(error)

        } finally {
            this.loading = false;
        }
    }
    ////////////////--  PERSONEL   end////////////////////////////////////////////

    @action
    getReservation = async () => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const reservations = (await service.getReservations()).data;
            this.reservations = reservations
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }


     ////////////////--  MASTER ADMIN  [start] ////////////////////////////////////////////

    
    @action
    getRestaurantCategory = async () => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const { result } = (await service.getRestaurantCategory()).data;
            console.log({ result })
            this.restaurantCategoryList = result
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    getUsersForMaster = async () => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const { result } = (await service.getUsersForMaster()).data;
            this.masterUsers = result
            console.log({ result })
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    getRestaurantList = async () => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const { result } = (await service.getRestaurantList()).data;
            console.log({ result })
            this.restaurantList = result
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    createRestaurant = async (postData) => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            if (postData.fileCoverImage) {
                const { path } = (await service.uploadImage(postData.fileCoverImage, "companycover")).data;
                console.log("createRestaurant:fileCoverImage", { path })
                postData.coverImage = path
            }
            if (postData.fileLogo) {
                const { path } = (await service.uploadImage(postData.fileLogo, "companylogo")).data;
                console.log("createRestaurant:fileLogo", { path })
                postData.logo = path
            }

            const { result } = (await service.createRestaurant(postData)).data;
            console.log({ result })
            this.restaurantList = [result, ...this.restaurantList]
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }

    @action
    updateRestaurant = async (id, postData) => {
        this.loadingSwitch = true;
        try {
            const service = new HomeService(token)
            if (postData.fileCoverImage) {
                const { path } = (await service.uploadImage(postData.fileCoverImage, "companycover")).data;
                console.log("updateRestaurant:fileCoverImage", { path })
                postData.coverImage = path
            }
            if (postData.fileLogo) {
                const { path } = (await service.uploadImage(postData.fileLogo, "companylogo")).data;
                console.log("updateRestaurant:fileLogo", { path })
                postData.logo = path
            }
            const { result } = (await service.updateRestaurant(id, postData)).data;


            const findIndex = this.restaurantList.findIndex(v => v._id === id)
            console.log("updateRestaurant", { result, findIndex })
            if (findIndex != -1) {

                this.restaurantList[findIndex] = {
                    ...  this.restaurantList[findIndex],
                    ...result
                }
                this.restaurantList = [...this.restaurantList]
            }
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loadingSwitch = false;
        }
    }
    @action
    removeRestaurant = async (id) => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const { result } = (await service.removeRestaurant(id)).data;
            this.restaurantList = this.restaurantList.filter(v => v._id != id)
            //  this.restaurantList = [...this.restaurantList]

        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }


    @action
    updateUser = async (id, postData) => {
        //   this.loading = true;
        try {
            const service = new HomeService(token)
            const { result } = (await service.updateUser(id, postData)).data;

            const findIndex = this.masterUsers.findIndex(v => v._id === id)
            console.log("updateUser", { result, findIndex })
            if (findIndex != -1) {
                this.masterUsers[findIndex] = {
                    ...  this.masterUsers[findIndex],
                    ...result
                }
                this.masterUsers = [...this.masterUsers]
            }
        } catch (error) {
            console.log({ error })
        }
        finally {
            //    this.loading = false;
        }
    }


    @action
    createRestaurantCategory = async (postData) => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            if (postData.fileAvatar) {
                const { path } = (await service.uploadImage(postData.fileAvatar, "restaurantcategory")).data;
                console.log("createRestaurantCategory:fileAvatar", { path })
                postData.avatar = path
            }

            const { result } = (await service.createRestaurantCategory(postData)).data;
            console.log({ result })
            this.restaurantCategoryList = [result, ...this.restaurantCategoryList]
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }
    @action
    updateRestaurantCategory = async (id, postData) => {
        this.loadingSwitch = true;
        try {
            const service = new HomeService(token)
            if (postData.fileAvatar) {
                const { path } = (await service.uploadImage(postData.fileAvatar, "restaurantcategory")).data;
                console.log("updateRestaurantCategory:fileAvatar", { path })
                postData.avatar = path
            }
            const { result } = (await service.updateRestaurantCategory(id, postData)).data;

            const findIndex = this.restaurantCategoryList.findIndex(v => v._id === id)
            if (findIndex != -1) {

                this.restaurantCategoryList[findIndex] = {
                    ...  this.restaurantCategoryList[findIndex],
                    ...result
                }
                this.restaurantCategoryList = [...this.restaurantCategoryList]
            }
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loadingSwitch = false;
        }
    }
    @action
    removeRestaurantCategory = async (id) => {
        this.loading = true;
        try {
            const service = new HomeService(token)
            const { result } = (await service.removeRestaurantCategory(id)).data;
            this.restaurantCategoryList = this.restaurantCategoryList.filter(v => v._id != id)
        } catch (error) {
            console.log({ error })
        }
        finally {
            this.loading = false;
        }
    }


    ////////////////--  MASTER ADMIN   [end]////////////////////////////////////////////

}

