
import React, { Component } from 'react';
import {
    message,
    Table,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Avatar,
    Typography,
    Select,
    Tag,
    Dropdown,
    Menu,
    Descriptions,
    DatePicker
} from 'antd';

import { Link } from 'react-router-dom';


import { EyeOutlined, PlusCircleTwoTone, MoreOutlined, ReloadOutlined } from '@ant-design/icons';




import moment from 'moment'


import _ from "lodash"


const { Option } = Select;
const { Text, Title } = Typography;


export default ({
    visible,
    handleCancel,
    item,
    totalPrice,
    getProductsByQrId,
    getBasketTotalSum,
    onClickCompleted,
    data,
    statusKitchens,
    statusWaiters,
    onClickCallWaiter,
    onChangeStatus,
    onClickDetail,
    placement = "left" || "right"
}) => {





    const columns = [
        {
            title: 'Zaman',
            key: 'created',
            render: (text, record) => <span>
                <Tooltip
                    title={record.isCall ? t("callWaiter") : undefined}>
                    <div
                        onClick={() => onClickCallWaiter(record)}
                        className={record.isCall ? "ant-btn-danger" : undefined}
                    >{moment(record.created).fromNow()}
                    </div>
                </Tooltip>
            </span>
        },
        {
            title: 'Özet',
            key: 'desk',
            render: (text, record) => <span>
                <Tag color="gold">{_.get(record, "title", "")}</Tag>
            </span>
        },
        {
            title: 'Tutar',
            key: 'totalPrice',
            render: (text, record) => <span>
                {`${getBasketTotalSum(record.items)} ₺`}
            </span>
        },
        {
            title: 'Mutfak',
            key: 'statusKitchen',
            render: (text, record) => <span>
                <Select
                    value={_.get(record, "statusKitchen", 0)}
                    onChange={(e) => onChangeStatus(e, record, "statusKitchen")}
                >
                    {
                        statusKitchens.map((v, i) => {
                            return (
                                <Option key={i} value={v.i} >{v.title}</Option>
                            )
                        })
                    }
                </Select>
            </span>
        },
        {
            title: 'Garson',
            key: 'statusWaiter',
            render: (text, record) => <span>
                <Select
                    value={record.isCall ? 1 : _.get(record, "statusWaiter", 0)}
                    // style={{minWidth:80}}
                    onChange={(e) => onChangeStatus(e, record, "statusWaiter")}
                >
                    {
                        statusWaiters.map((v, i) => {
                            return (
                                <Option key={i} value={v.i} >{v.title}</Option>
                            )
                        })
                    }
                </Select>
            </span>
        },
        {
            title: '',
            key: 'detail',
            render: (text, record) =>
                <span>
                    {
                        !item
                        &&
                        <>
                            <Button.Group>
                                <Button key="accept" onClick={() => onChangeStatus(true, record, "accept")}
                                    type={record.accept ? "primary" : "default"}
                                >Kabul</Button>
                                <Button key="reject" onClick={() => onChangeStatus(true, record, "reject")}
                                    type={record.reject ? "danger" : "default"}>Red</Button>
                            </Button.Group>
                            <Divider type="vertical" />
                            <Divider type="vertical" />
                            <Button onClick={() => onClickDetail(record)}
                                type="primary" icon={<EyeOutlined />}></Button>
                        </>
                    }

                </span>
        }
    ];


    const columnsProduct = (v) => [
        {
            title: 'Başlık',
            key: 'titlem',
            width: 160,
            render: (text, record) =>
                <span>
                    {
                        record.avatar && <Avatar shape="square" src={record.avatar} />
                    }
                    {` `}
                    {
                        record.title
                    }
                </span>
        },
        {
            title: 'Açıklama',
            key: 'description',
            dataIndex: "description"
        },
        {
            title: 'Miktar',
            key: 'count',
            dataIndex: "count"
        },
        {
            title: 'Fiyat',
            key: 'price',
            render: (text, record) =>
                <span>
                    {`${record.price} ₺`}
                </span>
        },
        {
            title: 'Tutar',
            key: 'tPrice',
            render: (text, record) =>
                <span>
                    {`${record.price * record.count} ₺`}
                </span>
        },
    ]



    return (
        <Drawer
            placement={placement ? placement : "left"}
            //placement={"right"}
            title={undefined}
            width={720}
            visible={visible}
            onClose={(e) => handleCancel(e)}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <div>
                <Title>
                    {_.get(item, "title", "")}
                </Title>
            </div>

            <div>
                <Title level={4}>
                    {totalPrice}
                    {` ₺`}
                </Title>
            </div>

            <div>
                <Button type="primary" block
                    //onClick={() => onClickCompleted(item)}
                    onClick={() => onClickCompleted(_.get(item, "qrId"))}
                >
                    HESABI KAPAT
                </Button>
                {
                    //  <div style={{ height: 10 }} />
                    //  <Link to={`/order/${_.get(item, "_id")}`}>
                    //      <Button size="small">Daha FazlaDetay</Button>
                    //  </Link>
                }

                <div style={{ height: 10 }} />
                {
                    item &&
                    (
                        (item.accept && !item.done) && <Button
                            onClick={() => onChangeStatus(true, item, "done")}>Bitti</Button>

                    )
                }

                {
                    item &&
                    (
                        (!item.accept && !item.reject && !item.done) && <Button
                            onClick={() => onChangeStatus(true, item, "accept")} >Kabul</Button>

                    )
                }


                {
                    item &&
                    item.channel == "everyone" &&
                    <div>
                        <Descriptions
                            title={"Paket Sipariş"}
                        >
                            <Descriptions.Item label="İsim">
                                {item.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Telefon">
                                {item.phone}
                            </Descriptions.Item>
                            <Descriptions.Item label="Adres">
                                {item.address}
                            </Descriptions.Item>
                        </Descriptions>

                    </div>



                }

            </div>

            <div>
                <div style={{ height: 20, backgroundColor: 'rgba(0,0,0,0)' }} />
                <Text>Ürünler</Text>
                <Table
                    bordered
                    rowKey={'_id'}
                    style={{ backgroundColor: '#fff' }}
                    columns={columnsProduct(item)}
                    dataSource={getProductsByQrId(_.get(item, "qrId"))}
                    size='small'
                    pagination={false}
                />
            </div>

            <div style={{ height: 20, backgroundColor: 'rgba(0,0,0,0)' }} />

            <div>
                <Text>Siparişler</Text>
                <Table
                    rowKey={'_id'}
                    columns={columns}
                    pagination={false}
                    //   size="small"
                    dataSource={_.filter(data, (o) => {
                        return o.qrId == _.get(item, "qrId")
                    })}
                    //  defaultExpandedRowKeys={["5ee4954c912cb91404250ad7"]}
                    //  expandRowByClick={true}
                    expandedRowRender={(record) =>
                        <div style={{ backgroundColor: '#FFF' }}>
                            <Descriptions
                                title="Sipariş Detayları"
                                layout="vertical"
                            >
                                <Descriptions.Item label="Toplam Tutar">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {`${getBasketTotalSum(record.items)} ₺`}
                                        {` / ${record.title} `}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Ürünler">

                                    <Table
                                        rowKey={'_id'}
                                        style={{ backgroundColor: '#fff' }}
                                        columns={columnsProduct(record)}
                                        dataSource={record.items}
                                        size='small'
                                        pagination={false}
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label="Kullanıcı Notu">
                                    <Text type="danger">
                                        {record.customNote}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="Sipariş tarihi">
                                    <Tooltip
                                        title={moment(record.created).fromNow()}>
                                        {new Date(record.created).toLocaleString()}
                                    </Tooltip>
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    }
                />

            </div>

            <div
                style={{
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 0',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={(e) => handleCancel(e)} style={{ marginRight: 8 }}>
                    İptal
                </Button>

                <Button type="primary"
                    onClick={() => onClickCompleted(_.get(item, "qrId"))}
                >
                    HESABI KAPAT
                </Button>
            </div>
        </Drawer>
    )
}