import React, { Component } from "react";

import { HashRouter, Route, Link } from 'react-router-dom';

import { Layout, Menu, Row, Col } from 'antd';

import { appName, cdnConfig, siteInfo } from "../config";

import { withTranslation } from "react-i18next";


class Footer extends Component {


  render() {

    const { t } = this.props

    //  backgroundColor:'#2d3436'
    return (

      <div style={{ backgroundColor: '#399893', marginTop: 200, bottom: 0 }}>
        <div style={{ backgroundColor: '#474747', padding: ' 10px 50px' }}>
          <Row
            //title="Yakında.."
            style={{ marginBottom: 10 }}>
            <Col span={12}>
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col span={6} style={{ marginLeft: 5 }}>
                  <img style={{ width: '100%' }} alt="Desktop" src="https://www.piyanos.com/assets/imgs/get_it_in_windows.png" />
                </Col>
                <Col span={6} style={{ marginLeft: 5 }}>
                  <img style={{ width: '100%' }} alt="App Store" src="https://www.piyanos.com/assets/imgs/apple270.png" />
                </Col>
                <Col span={6} style={{ marginLeft: 5 }}>
                  <img style={{ width: '100%' }} alt="Google Play Store" src="https://www.piyanos.com/assets/imgs/android272.png" />
                </Col>
              </Row>
            </Col>

          </Row>
        </div>

        <div style={{ padding: ' 10px 50px' }}>
          <Row style={{ marginBottom: 10, display: 'none' }}>
            <Col span={12}>
              {
                //   <p><Link to ="/">{appName}</Link> {t("footerBrand",{appName})}</p>
              }
            </Col>
            <Col span={12}>
              <Row type="flex" justify="end">
                <Col span={6} style={{ marginLeft: 5 }}>
                  <p><a target="_blank"
                    // href ={`${siteInfo.clientUrl}/privacy`}
                    href={siteInfo.privacyUrl}
                  >{t("policy")}</a></p>
                  {
                    //  <p><Link to ="/policy">{t("policy")}</Link></p>
                  }
                </Col>
                <Col span={6} style={{ marginLeft: 5 }}>
                  {
                    // <p><Link to ="/doc">{t("documentation")}</Link></p>
                    //  <p><a target="_blank" href ={`${siteInfo.clientUrl}/doc`}>{t("documentation")}</a></p>
                  }
                  <p><a target="_blank" href={siteInfo.documentUrl}>{t("documentation")}</a></p>
                  <p><a target="_blank" href={siteInfo.contactUrl}>{t("contact")}</a></p>
                </Col>
              </Row>
            </Col>

          </Row>
        </div>


        <Row style={{
          justifyContent: 'center',
          color: 'rgba(0, 0, 0,0.6)',
          padding: ' 10px 50px'
        }}>
          <div style={{

          }}>
            {`${new Date().getFullYear()} ${appName} ©`}
            {/* {`${new Date().getFullYear()} ${appName} © Powered by `}<a rel="author" href="https://www.piyanos.com/" target="_blank">Piyanos Technology</a> */}
          </div>
        </Row>
      </div>
    )
  }
}

export default withTranslation()(Footer)





/***
 *                 <div style={{backgroundColor:'#00FEEF',marginTop:200,bottom:0}}>
                  <div style={{backgroundColor:'#474747',padding:' 10px 50px'}}>
                      <Row
                      //title="Yakında.."
                       style={{marginBottom:10 }}>
                          <Col span={12}>
                          </Col>
                          <Col span={12}>
                            <Row type="flex" justify="end">
                            <Col span={6} style={{marginLeft:5}}>
                            <img style={{width:'100%'}} alt="Desktop" src="https://www.piyanos.com/assets/imgs/get_it_in_windows.png"/>
                          </Col>
                          <Col span={6} style={{marginLeft:5}}>
                            <img style={{width:'100%'}} alt="App Store" src="https://www.piyanos.com/assets/imgs/apple270.png"/>
                          </Col>
                          <Col span={6} style={{marginLeft:5}}>
                          <img style={{width:'100%'}} alt="Google Play Store" src="https://www.piyanos.com/assets/imgs/android272.png"/>
                          </Col>
                            </Row>
                          </Col>

                    </Row>
                  </div>

                  <div style={{padding:' 10px 50px'}}>
                      <Row style={{marginBottom:10 }}>
                          <Col span={12}>
                        {
                          //   <p><Link to ="/">{appName}</Link> {t("footerBrand",{appName})}</p>
                        }
                          </Col>
                          <Col span={12}>
                            <Row type="flex" justify="end">
                            <Col span={6} style={{marginLeft:5}}>
                            <p><a target="_blank" href ={`${siteInfo.clientUrl}/privacy`}>{t("policy")}</a></p>
                          {
                            //  <p><Link to ="/policy">{t("policy")}</Link></p>
                          }
                          </Col>
                          <Col span={6} style={{marginLeft:5}}>
                             {
                               // <p><Link to ="/doc">{t("documentation")}</Link></p>
                             }
                              <p><a target="_blank" href ={`${siteInfo.clientUrl}/doc`}>{t("documentation")}</a></p>
                              <p><a target="_blank" href ={`${siteInfo.clientUrl}/contact`}>{t("contact")}</a></p>
                          </Col>
                            </Row>
                          </Col>

                    </Row>
                  </div>


                <Row style={{
                  justifyContent:'center',
                  color:'rgba(0, 0, 0,0.6)',
                  padding:' 10px 50px'
                  }}>
                <div style={{

                }}>
                {`${new Date().getFullYear()} ${appName} © Powered by `}<a rel="author" href="https://www.piyanos.com/" target="_blank">Piyanos Technology</a>
                </div>
                </Row>
              </div>
 */