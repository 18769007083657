import React from "react";
import { siteInfo } from "../config";

var QRCode = require('qrcode.react');

export default ({
    text = siteInfo.clientUrl,
    width = 64,
    bgColor,
    fgColor
}) => {
    return (
        <QRCode
            bgColor={bgColor ? bgColor : undefined}
            fgColor={fgColor ? fgColor : undefined}
            size={width} value={text} />
    )
}



/*
import React, { Component } from "react";

import QRCode from 'qrcode'

export default class QRCodeComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            text: "",
            width: 128,
            qrBase64: null
        }

    }

    componentDidMount() {
        this.createQrCode()
    }


    createQrCode = async () => {
        const text = this.props.text || "https://cafeotomasyonu.com"
        const width = this.props.width || 128
        // color:{dark:"#293F68",light:"#FFF"}
        const res = await QRCode.toDataURL(text, { width })
        this.setState({
            qrBase64: res
        })
    }
    render() {
        const { qrBase64 } = this.state

        return (
            <img src={qrBase64}
                style={{ maxWidth: '100%' }}
            />
        )
    }

}

*/






/*





  const generateQR=async(text,width)=>{
    const codeData = await  QRCode.toDataURL(text,{width})
    console.log({codeData})
    return codeData
    }

    const text = props.text || "ferdiozer.com"
    const width = props.width || 128
    const qrBase64=  generateQR(text,width)

    return(
            <img src={qrBase64}
              style={{maxWidth:'100%'}}
            />
         )


         */