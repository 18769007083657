import React from 'react'
import { Card, Row, Col, Statistic, Typography, Timeline, Button, Spin } from 'antd'

import { Link } from 'react-router-dom';


import {
  ReloadOutlined,
  WalletOutlined,
  ArrowRightOutlined,
  SnippetsOutlined,
  UserOutlined,
  ArrowUpOutlined,
  RightOutlined,
  TabletOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';


export default ({
  c1,
  c2,
  c3,
  c4,
  loading: loadingStatistic

}) => {
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Row gutter={16}>
          <Col span={6}>
            <Link to="/product">
              <div style={{ backgroundColor: '#5cdbd3', borderRadius: 10, padding: 10 }}
              //  onClick={()=>this.props.setPage('2')}
              >
                <Statistic
                  valueStyle={{ color: '#FFF' }}
                  title={<span style={{ color: 'rgba(255,255,255,0.8)', fontWeight: 'lighter' }}>Ürün</span>}
                  value={c1}
                  prefix={loadingStatistic ? <Spin /> : <WalletOutlined />}
                />
                <Row type="flex" justify="end">
                  <ArrowRightOutlined
                    type="button"
                  //style={{color:'#fff'}}
                  />
                </Row>
              </div>
            </Link>
          </Col>

          <Col span={6}>
            <Link to="/category">
              <div style={{ backgroundColor: '#08979c', borderRadius: 10, padding: 10 }}
              >
                <Statistic
                  valueStyle={{ color: '#FFF' }}
                  title={<span style={{ color: 'rgba(255,255,255,0.8)', fontWeight: 'lighter' }}>Kategori</span>}
                  value={c2}
                  prefix={loadingStatistic ? <Spin /> : <SnippetsOutlined />}
                />
                <Row type="flex" justify="end">
                  <ArrowRightOutlined />
                </Row>
              </div>
            </Link>
          </Col>

          <Col span={6}>
            <Link to="/table">
              <div style={{ backgroundColor: '#399893', borderRadius: 10, padding: 10 }}
              >
                <Statistic
                  valueStyle={{ color: '#FFF' }}
                  title={<span style={{ color: 'rgba(255,255,255,0.8)', fontWeight: 'lighter' }}>Masa</span>}
                  value={c3}
                  prefix={loadingStatistic ? <Spin /> : <TabletOutlined />}
                />
                <Row type="flex" justify="end">
                  <ArrowRightOutlined />
                </Row>
              </div>
            </Link>
          </Col>
          <Col span={6}>
            <Link to="/qrcode">
              <div style={{ backgroundColor: '#006d75', borderRadius: 10, padding: 10 }}
              >

                <Statistic
                  valueStyle={{ color: '#FFF' }}
                  title={<span style={{ color: 'rgba(255,255,255,0.8)', fontWeight: 'lighter' }}>Qr Kod</span>}
                  value={c4}
                  prefix={loadingStatistic ? <Spin /> : <QrcodeOutlined />}
                />
                <Row type="flex" justify="end">
                  <ArrowRightOutlined />
                </Row>
              </div>
            </Link>
          </Col>
        </Row>

      </div>
    </div>
  )
}


