





import PropTypes from 'prop-types';

import React from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Card,
    Carousel,
    Icon
} from 'antd';

import { Link } from 'react-router-dom';



const CompanyHomeSlider = ({
    data,
}) => {
    data = data || []
    if (data.length == 0) {
        return (
            <div />
        )
    }
    return (
        <div>
            <Carousel dotPosition="right" autoplay>
                {
                    data.map((v, i) => {
                        return (
                            <div key={i}>
                                <img src={v.img_url} />
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}


export default CompanyHomeSlider;




