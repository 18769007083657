import React, { useState, useEffect } from 'react';

import { Button, Tabs } from 'antd'

import { inject, observer } from 'mobx-react';

import { ConfigProvider } from 'antd';
import trLocale from 'antd/es/locale/tr_TR';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

const Screen = ({ GeneralStore }) => {

    const [activeTab, setActiveTab] = useState("all")
    const [loading, setLoading] = useState(false)
    const [cancelAt, setCancelAt] = useState(false)
    const [desks, setDesks] = useState([])
    const [areas, setAreas] = useState([])
    const [areaId, setAreaId] = useState(null)


    useEffect(() => {
        if(!cancelAt) getItemList()
        return () => {
            setCancelAt(false)
        };
    }, [])
    
    const getItemList=async()=>{
        setLoading(true)
        const tempAreas = []
        try {
            let serverData = await GeneralStore.getDesksForPos();
            let items =  _.get(serverData,"data.results")
            for (let index = 0; index < items.length; index++) {
                const element = items[index];
                if(!tempAreas.find(v=>v._id==element.areaId)){
                    tempAreas.push(element.area)
                }
            }
            console.log("items",items)
            console.log("tempAreas",tempAreas)
            setDesks(items)
            setAreas(tempAreas)
        } catch (error) {
           console.log(error) 
        }
        finally{
          setLoading(false)
        }
    }

    const onChangeTab = (key) => {
        setActiveTab(key)
    }

    // {"/desks?d="+v._id}
    const RenderDeskCard=(v,i)=><Link key={i} to={"/desks/"+v._id}><Button type={v.inProgress ? 'danger': 'default'} >{v.title}</Button></Link>  

    const tabItems = [
        {
          label: `Tümü`,
          key: 'all',
          children: <div>
          {desks.map(RenderDeskCard)}
         </div>,
        }]

        areas.map(v=>{
            tabItems.push({
                label:v.title,
                key:v._id,
                children:<div>
                {desks.filter(desk=> desk.areaId==v._id).map(RenderDeskCard)}
               </div>
            }) 
        })

    return (
        <div style={{ padding: 24 }}>
                <Tabs activeKey={activeTab} onChange={(key) => onChangeTab(key)}
                 items={tabItems}
                />
        </div>
    )

}

export default inject((stores) => (stores))(observer(Screen));


