
import React, { Component, Suspense } from 'react';


import { Provider } from 'mobx-react';
import stores from './stores';

import Loading from './components/Loading';

import MainApp from "./MainApp";

import io from 'socket.io-client'

import { apiBaseUrl } from "./config"


let mydebugger = localStorage.getItem('pidebugger');
if (process.env.NODE_ENV === "development") {
  mydebugger = true
}
if (!mydebugger) {
  console.log = () => { };
}
else {
  console.log("DEV: ", process.env.NODE_ENV)
  // console.log = () => {};
}


var socket = io.connect(apiBaseUrl, { upgrade: false });

export default () => {
  return (
    <Provider {...stores}>
      <Suspense fallback={(<Loading />)}>
        <MainApp socket={socket} />
      </Suspense>
    </Provider>
  )
}
