
import React, { Component } from 'react';
import {
    message,
    Table,
    Select,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag,
    Slider,
    InputNumber,
    Badge,
    Skeleton,
    Descriptions
} from 'antd';

import { Link } from 'react-router-dom';

import {
    PlusOutlined,
    DeleteOutlined,
    ReloadOutlined,
    InfoOutlined,
    CheckOutlined,
    CloseOutlined,
    AlertOutlined,
    EyeInvisibleOutlined,
    MoreOutlined,
    ArrowRightOutlined,
    UserOutlined,

} from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import _ from "lodash"

import { withTranslation } from 'react-i18next';



import { toJS } from 'mobx';
import { formatMyMoney } from '../helper';
import PageHeader from '../components/PageHeader';
import { defaultImages } from '../config';


const queryString = require('query-string');

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;



@inject('UserStore', 'OrderStore')
@observer
export default class OrderDetail extends Component {

    componentDidMount = () => {
        const paramsID = _.get(this.props, "match.params.id");
        console.log("paramsID:", paramsID)
    }
    render() {
        return (
            <div>
                <h1>Detay gelecek</h1>
            </div>
        )
    }
}