
import React, { Component } from 'react';
import {
  message,
  Table,
  Select, Drawer, Switch, Row, Button, Input, Form, Modal, Popconfirm, Tooltip, notification, Radio, Col, Divider,
  Card,
  Upload,
  Avatar,
  Typography,
  Tag

} from 'antd';

import { Link } from 'react-router-dom';

import { PlusOutlined, DeleteOutlined, ReloadOutlined, UploadOutlined, InfoOutlined } from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import axios from "axios"

import _ from "lodash"

import { withTranslation } from 'react-i18next';
import { cdnConfig, cdnUrl } from '../config';

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;



// 
@inject('ProductStore')
@observer
class Category extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      categoryList: [],
      file: null,
      avatar: null,
      title: '',
      description: '',
      modalOpenCreate: false
    }
  }

  componentDidMount = () => {
    this.getItemList()
  }

  //#liste
  getItemList = async () => {
    this.setState({ loading: true })
    try {
      let serverData = await this.props.ProductStore.getCategories("withproduct");
      console.log(_.get(serverData, "data.results"))
      this.setState({
        data: _.get(serverData, "data.results")
      })
    } catch (error) {

    }
    finally {
      this.setState({ loading: false })
    }
  }

  resetState() {
    this.setState({
      id: '',
      _id: '',
      title: '',
      description: '',
      modalOpenCreate: false,
      file: null,
      avatar: null,
    })
  }

  reload = () => {
    this.getItemList()
  }
  //#ekle
  handleCreate = async () => {
    // eğer fotoğraf varsa
    const { file, avatar, title, description, _id, rank } = this.state
    if (!title) {
      message.error()
      return
    }

    let data = {
      title,
      description,
      rank: !_id ? this.state.data.length + 1 : rank,
      avatar
    }

    //yeni kayıt//////////////
    if (!_id) {
      //fotoğraf varsa
      if (file && avatar) {
        message.loading(null, 0)
        try {
          let { path } = (await this.uploadImageV1(file)).data
          // let uImage = await this.uploadImage(file)

          //const avatarfullpath = _.get(uImage,"data.fullpath")
          // const avatarfullpath = `${cdnUrl}/uploads/images/category/${_.get(uImage,"data.filename","default.png")}`
          // console.log({ uImage})

          data.avatar = path
          //  data.avatar=avatarfullpath
          const serverData = await this.props.ProductStore.inserCategory(data)
          const item = _.get(serverData, "data.result")
          //  this.setState({data:this.state.data.push(_.get(serverData,"result"))})
          this.setState({ data: this.state.data.concat([item]) })
          this.resetState()
          message.success()
        } catch (error) {
          message.error()
          console.log(error)
        }
        finally {
          this.setState({
            loading: false
          })
          message.destroy()
        }
        return
      }
      //fotoğraf yoksa
      else {
        this.props.ProductStore.inserCategory(data).then((res) => {
          // const afterPush = this.state.data
          const item = _.get(res, "data.result")
          //    this.state.data.push(item)
          console.log({ data: this.state.data, item })
          //  this.setState({data:afterPush})
          this.setState({ data: this.state.data.concat([item]) })
          this.resetState()
          message.success()
        }).catch((err) => {
          console.log(err)
          message.error()
        })

      }
      //  this.getItemList()
    }
    ///////Güncelleme//////////////////
    else {
      //foto varsa
      if (file && avatar) {
        message.loading(null, 0)
        try {
          // let uImage = await this.uploadImage(file)
          // const avatarfullpath = `${cdnUrl}/uploads/images/category/${_.get(uImage, "data.filename", "default.png")}`
          // console.log({ uImage, avatarfullpath })
          // data.avatar = avatarfullpath
          let { path } = (await this.uploadImageV1(file)).data
          data.avatar = path
          await this.props.ProductStore.updateCategory(_id, data)
          let finded = _.find(this.state.data, (o) => {
            return o._id == _id
          })
          if (finded) {
            finded.title = title
            finded.description = description
            finded.avatar = path
            this.setState({ data: this.state.data })
          }
          this.resetState()
          message.success()
        } catch (error) {
          message.error()
          console.log(error)
        }
        finally {
          this.setState({
            loading: false
          })
          message.destroy()
        }
        return
      }
      //foto yoksa
      else {
        try {
          const serverData = await this.props.ProductStore.updateCategory(_id, data)
          let finded = _.find(this.state.data, (o) => {
            return o._id == _id
          })
          if (finded) {
            finded.title = title
            finded.description = description
            this.setState({ data: this.state.data })
          }
          this.resetState()
          message.success()
        } catch (error) {
          console.log(error)
        }
        finally {
          //  this.getItemList()
        }
      }

    }
  }

  //#sil
  confirmDelete = async (item) => {
    console.log(item)
    try {
      const serverData = await this.props.ProductStore.deleteCategory(item._id)
      console.log(serverData)
      this.state.data = _.remove(this.state.data, (o) => {
        return o._id != item._id
      })
      //  console.log({items})
      //  const dd =[]
      //  dd.splice(1)
      this.setState({ data: this.state.data })

    } catch (error) {
      console.log(error)
    }

  }

  //düzenle click
  clickEdit = (item) => {
    //   this.titleInput.focus()
    console.log(item)
    this.setState({
      ...item
    }, () => {
      this.setState({ modalOpenCreate: true })
    })
  }



  uploadImageV1 = (file) => {
    const url = cdnConfig.full_url
    const formData = new FormData()
    formData.append("file", file)
    formData.append("token", cdnConfig.token)
    formData.append("key", cdnConfig.key)
    formData.append("subkey", `category`)
    const headers = { 'content-type': 'multipart/form-data', }
    return axios.post(url, formData, { headers: headers })
  }


  uploadImage = (file) => {
    // let file = this.state.file
    let formData = new FormData()

    formData.append("image", file)
    formData.append("name", "ferdiozer")
    const url = `${cdnUrl}/upload/category-image/`
    //  const url =  `http://localhost:4000/upload/category-image/`
    const headers = {
      'content-type': 'multipart/form-data',
      token: "token",
      authorization: "tokencontent"
    }
    return axios.post(url, formData, { headers: headers })
  }



  handleCancel() {
    this.resetState()
    /*
    this.setState({
      modalOpenCreate: false,
    })
  */
  }

  customRequest = (e) => {
    console.log(e)

    axios.post(e.action, e, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log(err)
    })
  }




  handleChangeImage = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {

      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
          //  avatar:imageUrl  //SONRA
        }),
      );
      // this.handleSubmit()
    }
    //   console.log(info)
    console.log(info.file.response.urly)
    this.setState({
      avatar: info.file.response.urly
    })

  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  uploadFile = (file) => {
    console.log(' eski file', file)

  }



  onChangeImage = (e) => {
    console.log(e)
    console.log({ file: e.target.files })
    let file = e.target.files[0]

    this.setState({
      file: file
    })

    this.getBase64(file, (img) => {
      this.setState({
        avatar: img
      })
    })

  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeSwitchList = async (checked, item) => {
    console.log(checked);
    console.log(item);
    message.destroy()
    try {
      const serverData = await this.props.ProductStore.setCategoryActive(item._id, checked)
      checked ? message.success(_.get(item, "title")) : message.error(_.get(item, "title"))
      console.log(serverData)

    } catch (error) {
      console.log(error)
      message.warn()
    }
    //  this.updateState(checked,item._id)
  }


  updateState = (state, _id) => {
    console.log("updateState", { state, _id })
    /*
    
          const Durum =state ? 'Aktif' : 'Pasif';
            console.log("api çağrıldı")
            const api = `${API_URL}/api/category/updatestate`;
            const token =  localStorage.getItem('token');
      
          axios.post(api, {state,_id},{ headers: {"Authorization" : `${token}`,'Content-Type': 'application/json'} })
                .then(res => {
                //  message.info(res.data.message);
                  console.log(res.data);
                  message.info(`Durum ${Durum} olarak güncellendi`) 
          })
          */
  }

  render() {
    const { t } = this.props

    const { data, loading } = this.state

    const handleFocus = (event) => event.target.select();

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 0 },
      },
    };


    const columns = [
      {
        title: '',
        key: 'avatar',
        render: (text, record) =>
          <span>
            {
              record.avatar ? <Avatar shape="square" src={record.avatar} /> : <Avatar shape="square">{_.get(record, "title", "").charAt(0)}</Avatar>
            }
          </span>
      },
      {
        title: 'Kategori Adı',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Kategori Açıklaması',
        key: 'description',
        render: (text, record) => <span>
          <Text type="secondary" >{record.description}</Text>
        </span>
      },
      {
        title: 'Ürün',
        key: 'products',
        render: (text, record) =>
          <span>
            {
              _.get(record, "products", []).length > 0
              &&
              <Tag color="purple">{_.get(record, "products", []).length}</Tag>
            }
          </span>
      },
      {
        title: 'Eklenme Tarihi',
        key: 'created',
        render: (text, record) => <span>
          {new Date(record.created).toLocaleDateString()}
        </span>
      },
      {
        title: 'Durum',
        key: 'active',
        render: (text, record) => <span>
          <Switch size={'small'} onChange={(e) => this.onChangeSwitchList(e, record)} defaultChecked={record.active} />
        </span>
      },
      {
        title: 'İşlem', key: 'action', render: (record) => {
          return (
            <span>
              <Button onClick={() => this.clickEdit(record)} size="small">
                Düzenle
              </Button>
              <Divider type="vertical" />

              {
                /**
                 *                       <Button size="small" type="primary" icon={<InfoOutlined/>}/>
                                  <Divider type="vertical"/>
                 */
              }
              <Popconfirm okText="Evet" cancelText="Hayır" title={`Emin misiniz? ${_.get(record, "products", []).length > 0 ? `Kategoriye ait ${_.get(record, "products", []).length} adet ürün de silinecektir!!` : ""}`}
                onConfirm={() => { this.confirmDelete(record) }}>
                <Button type="danger" size="small">
                  Sil
                </Button>
              </Popconfirm>
            </span>
          )
        }
      },
    ];


    const fileList = [
      {
        uid: '-1',
        name: 'xxx.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      },
      {
        uid: '-2',
        name: 'yyy.png',
        status: 'error',
      },
    ];


    //${cdnUrl}/upload/category-image
    //  action: `https://www.piyanos.com/`,

    const props = {
      action: `${cdnUrl}/upload/category-image/`,
      name: "file",
      headers: {
        authorization: 'authorization-text',
        "Access-Control-Allow-Origin": 'http://localhost:4000'
      },
      listType: 'picture',
      //   listType: 'picture-card',
      accept: "image/*",
      defaultFileList: [...fileList],
      //    withCredentials:true,
      //   customRequest :(e)=>this.customRequest(e),
      //  directory:true
    };

    //  <Icon type={this.state.loading ? 'loading' : 'plus'} />
    const uploadButton = (
      <div>

        <div className="ant-upload-text">Yükle</div>
      </div>
    );

    const { avatar } = this.state;

    return (
      <div>
        <Card
          loading={loading}
          title={'Kategori Listesi'}
          extra={<span>

            <Button
              icon={<PlusOutlined />}
              style={{ marginLeft: '8px' }}
              onClick={() => this.setState({ modalOpenCreate: true, modalTitle: "Yeni" })} />
            <Button type="primary"
              shape="circle"
              icon={<ReloadOutlined />}
              style={{ marginLeft: '8px' }}
              onClick={() => this.reload()} />
          </span>}
        >


          <div>

            <Table
              rowKey={'_id'}
              columns={columns}
              pagination={{ pageSize: 50 }}
              size="small"
              dataSource={data}
              loading={loading}
            />
          </div>

        </Card>


        <Drawer
          title={this.state._id ? "Düzenle" : "Yeni"}
          placement="left"
          width={720}
          visible={this.state.modalOpenCreate}
          onClose={(e) => this.handleCancel(e)}
          bodyStyle={{ paddingBottom: 80 }}
        >

          <Form {...formItemLayout}>
            <Form.Item
              label="Başlık"
              required
            >
              <Input
                value={this.state.title}
                onChange={(e) => this.onChange(e)}
                name="title"
                ref={(Input) => { this.titleInput = Input }}
                placeholder="Kagetori Adı" />
            </Form.Item>

            <Form.Item
              label="Açıklama"
            >

              <TextArea
                onChange={(e) => this.onChange(e)}
                name="description"
                value={this.state.description}
                placeholder="Kategori detayı"
                autosize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
            {
              this.state._id
              &&
              <Form.Item
                label="Sıra"
              >
                <Input
                  value={this.state.rank}
                  onChange={(e) => this.onChange(e)}
                  name="rank"
                  type="number"
                  placeholder="Kagetori Sırası" />
              </Form.Item>
            }


            <Form.Item
              label="Medya"
            >
              {
                /*
                                             <Upload {...props}>
                                         <Button>
                                            <UploadOutlined /> Upload
                                        </Button>
                                         </Upload>
            
            
            
                          <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${cdnUrl}/upload/category-image/`}
                            //    beforeUpload={(file, fileList)=>this.beforeUpload(file, fileList)}
                                onChange={this.handleChangeImage}
                             //   label="Yükle"
                                data={this.uploadFile}
                                accept="image/*"
                              >
                                { avatar ? <img src={avatar} alt="avatar" /> : uploadButton }
                           </Upload>
            
            
            
                   */
              }

              <span>
                {
                  this.state.avatar ? <Button block type="danger" size="small" onClick={() => this.setState({ avatar: null })}>Sil <DeleteOutlined /></Button> :
                    <input onChange={(e) => this.onChangeImage(e)} accept="image/*" type="file" placeholder="Fotoğraf seç" />
                }
              </span>


              <div style={{ marginTop: 10 }}>
                {this.state.avatar && <img src={this.state.avatar} style={{ maxWidth: "100%" }} alt="avatar" />}
              </div>



            </Form.Item>
          </Form>

          <div
            style={{
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 0',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
              İptal
            </Button>
            <Button onClick={() => this.handleCreate()} type="primary">
              Kaydet
            </Button>
          </div>
        </Drawer>

      </div>
    )
  }
}

export default withTranslation()(Category)