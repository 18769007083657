import React, { Component } from "react";

import { Link } from 'react-router-dom';
import _ from 'lodash';

import { Card, Row, Col, Typography, Timeline, Button, Spin, Divider } from 'antd'
import {
  ReloadOutlined,
  RightOutlined,
  ArrowRightOutlined,
  ShopOutlined,
  ShoppingOutlined,
  BarcodeOutlined
} from '@ant-design/icons';

import { withTranslation } from 'react-i18next';

import { inject, observer } from 'mobx-react';

import DashboardTopItems from "../components/DashboardTopItems"
import HomeLogo from "../components/HomeLogo"
import MyHomeChart from "../components/MyChart"
import MyTimeLine from "../components/MyTimeLine"



@inject('UserStore', 'HomeStore')
@observer
class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      data: [],
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.initItems()
  }
  initItems = () => {
    this.props.HomeStore.getLogItems();
    this.props.HomeStore.getDashboardChart();
    this.props.HomeStore.getDashboardCount();
  }


  reloadHomeData = () => {
    this.initItems()
  }

  render() {
    const { t } = this.props

    const { lastLogs, homeChartData, loading, homeDashCountList } = this.props.HomeStore
    //    const tables = [{ name: "product" }, { name: "category" }, { name: "desk" }, { name: "qrcode" }]
    const homeDashCount = {
      c1:
        _.get(_.find(homeDashCountList, (o) => {
          return o.name == "product"
        })
          , "count", 0),
      c2:
        _.get(_.find(homeDashCountList, (o) => {
          return o.name == "category"
        })
          , "count", 0),
      c3: _.get(_.find(homeDashCountList, (o) => {
        return o.name == "desk"
      })
        , "count", 0),
      c4:
        _.get(_.find(homeDashCountList, (o) => {
          return o.name == "qrcode"
        })
          , "count", 0),
    }
    return (
      <div>
        <div style={{
          //
          //     padding:'0 50px',
          paddingTop: '64px',
          position: 'relative'
        }}>
          <Card
            bodyStyle={{
              padding: '20px 50px 10px 50px'
            }}
            headStyle={{
              paddingLeft: 50,
              paddingRight: 50
            }}
            //  contentEditable={true}
            title={
              <span style={{ fontWeight: 'normal' }}>
                Kontrol Paneli
              </span>
            }
            extra={<span>
              {
                loading ? <Spin /> : <ReloadOutlined onClick={() => this.reloadHomeData()} />
              }
            </span>}
          >
            <DashboardTopItems
              loading={loading}
              {...homeDashCount}
            />

            <div style={{ marginBottom: 10 }}>

              <Row gutter={16}>
                <Col span={6}>
                  <Link to={"/ride"}>
                    <Button icon={<BarcodeOutlined />} block>Kurye</Button>
                  </Link>
                </Col>
                <Col span={6}>
                  <Link to={"/waiter"}>
                    <Button icon={<BarcodeOutlined />} block>Garson</Button>
                  </Link>
                </Col>
                <Col span={6}>
                  <Link to={"/kitchen"}>
                    <Button icon={<BarcodeOutlined />} block>Mutfak</Button>
                  </Link>
                </Col>
                <Col span={6}>
                  <Link to={"/pos"}>
                    <Button icon={<ShoppingOutlined />} block>Pos</Button>
                  </Link>
                </Col>
                {/* <Col span={6}>
                  <Link to={"/desks"}>
                    <Button icon={<ShoppingOutlined />} block>Pos</Button>
                  </Link>
                </Col> */}
              </Row>
            </div>

            <div style={{ marginBottom: 10 }}>
              <Row gutter={16}>
                <Col span={18} md={18} xs={24}>
                  <Card
                    title="Siparişler"
                    //   loading={this.state.loading}
                    style={{ borderRadius: 10 }}
                    extra={
                      <span>
                        {
                          // <Link to="/create">
                          //   Oluştur
                          // </Link>
                        }
                        <Link to="/live" title="Canlı Sipariş Takibi">
                          <div style={{ flexDirection: 'row', display: 'flex' }}>
                            Canlı
                            {` `}
                            <div className="blob red" />
                          </div>
                        </Link>
                        {
                          //  <Divider type="vertical" />
                        }
                        <Link to="/order" title="Siparişler">
                          <div style={{ flexDirection: 'row', display: 'flex' }}>
                            Tümünü Görüntüle
                            {` `}
                            <ArrowRightOutlined />
                          </div>
                        </Link>
                      </span>
                    }
                  >
                    {
                      (homeChartData.options && homeChartData.series)
                      &&
                      <MyHomeChart options={homeChartData.options} series={homeChartData.series} />
                    }
                  </Card>
                </Col>
                <Col span={6} md={6} xs={0}>
                  <Card
                    loading={loading}
                    className="ant-card-body-homelogs"
                    style={{ borderRadius: 10 }}
                    bodyStyle={{
                      maxHeight: 300,
                      overflowY: 'auto',
                    }}
                    title='Son Hareketler'
                    extra={
                      <span>
                        <Link to="/logs" title="Tümünü Görüntüle">
                          <RightOutlined />
                        </Link>
                      </span>
                    }
                  >
                    <MyTimeLine data={lastLogs} />
                  </Card>
                </Col>
              </Row>
            </div>
          </Card>

          <div style={{ marginTop: 100 }}>
            <HomeLogo />
          </div>


        </div>
      </div>
    )
  }

}

export default withTranslation()(Home)