
import React, { Component } from 'react';
import {
    message,
    Table,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Avatar,
    Typography,
    Select,
    Tag,
    Dropdown,
    Menu,
    Descriptions,
    DatePicker
} from 'antd';

import { Link } from 'react-router-dom';


import { EyeOutlined, PlusCircleTwoTone, MoreOutlined, ReloadOutlined } from '@ant-design/icons';


import { inject, observer } from 'mobx-react';
import { entries, toJS } from 'mobx';

import moment from 'moment'


import _ from "lodash"

import { withTranslation } from 'react-i18next';
import { cdnUrl } from '../config';
import { goBack } from '../historyConfig';
import PageHeader from '../components/PageHeader';

const queryString = require('query-string');

const { Search, TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text, Title } = Typography;


@inject('OrderStore', 'UserStore')
@observer
class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categoryList: [],
            statusKitchens: [
                { title: 'Beklemede', i: 0, key: 'noproccess' },
                { title: 'Hazırlanıyor', i: 1, key: 'waiting' },
                { title: 'Hazırlandı', i: 2, key: 'prepared', },
            ],
            statusWaiters: [
                { title: 'Beklemede', i: 0, key: 'noproccess' },
                { title: 'Çağrıldı', i: 1, key: 'callWaiter' },
                { title: 'Tamamlandı', i: 2, key: 'completed', },
            ],
            modalVisible: false,
            item: null,
            filteredKey: "accept",
            from: null,
            to: null
        }
    }

    componentDidMount = () => {
        //sayfayı yukarı kaydır 
        window.scrollTo(0, 0)
        this.props.OrderStore.getItemListForStaff()
    }

    onChangeFiltered = (e) => {
        this.setState({ filteredKey: e })
    }

    //////////////


    onClickDetail = (item) => {
        console.log(item)
        this.setState({
            modalVisible: true,
            item
        })
    }

    handleCancel = () => {
        this.setState({ modalVisible: false, item: null })
    }

    ///qr toplam tutar
    renderTotalPriceWithQrId = (qrId) => {

        let data = this.props.OrderStore.data
        data = _.filter(data, (o) => {
            return o.qrId == qrId
        })

        let tPrice = 0;
        data.map(v => {
            tPrice = tPrice + this.props.OrderStore.getBasketTotalSum(v.items)
        })

        return tPrice
    }
    //qr ürünleri birleştir
    concatItemsWithQrId = (qrId) => {

        let data = this.props.OrderStore.data
        data = _.filter(data, (o) => {
            return o.qrId == qrId
        })

        let items = []
        data.map(v => {
            items = items.concat(_.get(v, "items", []))
        })

        return items
    }

    onClickCallWaiter = (item) => {
        console.log(item)
        if (item.isCall) {
            this.props.OrderStore.setIsCallStatus(item.qrId, false)
        }
    }

    //sepeti kapat
    onClickDeleteOrderByQrId = (qrId) => {

        this.props.OrderStore.setCompletedServer(qrId).then((res) => {
            console.log({ res })
        }).catch(err => {
            console.log({ err })
        })


        this.handleCancel()

        /*
    
        this.handleCancel()
    
        let data = this.props.OrderStore.data
        data = _.filter(data, (o) => {
          return o.qrId == qrId
        })
        data.map(v => {
          this.props.OrderStore.setCompleted(v._id,qrId)
          //  this.props.OrderStore.deleteOrderById(v._id)
        })
    */



        /*
        this.setState({ modalVisible: false, item: null },()=>{
          this.props.OrderStore.deleteOrderByQrId(qrId)
        })
        */

    }


    //garson veya mutfak durumu güncellendiğinde
    //yada sipariş kabul et veya red et durumu değiştiğinde
    onChangeStatus = (e, item, type) => {
        console.log("onChangeStatus order pages: ", { e, item, type })

        const postData = {
            key: type,
            value: e,
            _id: item._id
        }
        this.props.OrderStore.onChangeStatus(postData)
    }

    /*
     <span key={key}/>
            <li key={key}>
            {key} - {JSON.stringify(value)}
          </li>
          */
    renderFilters = () => {
        return entries(this.props.OrderStore.data).map(([key, value]) => {
            return (
                <span style={{ display: 'none' }} key={key}>
                    <li key={key}>
                        {key} - {JSON.stringify(value)}
                    </li>
                </span>
            );
        })
    };



    onChangeRangePicker = (dates, dateStrings) => {
        console.log({ dates, dateStrings })
        if (!isNaN(new Date(dateStrings[0])) && !isNaN(new Date(dateStrings[1]))) {
            this.props.OrderStore.getItemList({ from: dateStrings[0], to: dateStrings[1] })
        }
        else {
            this.props.OrderStore.getItemList()
        }

    }

    onClickMore = (key, record) => {
        console.log("onClickMenuMore", key)
        switch (key) {
            case "sortable":
                this.setState({ isShortable: !this.state.isShortable })
                break;
            case "preview":
                this.onClickDetail(record)
                break;

            default:
                break;
        }
    }

    render() {
        const { user } = this.props.UserStore
        const { t } = this.props

        const { loading, orderStatusList } = this.props.OrderStore
        let data = this.props.OrderStore.data

        if (this.state.filteredKey == "new") {
            data = _.filter(data, (o) => {
                return (!o.reject && !o.accept)
            })
        }
        if (this.state.filteredKey == "accept") {
            data = _.filter(data, (o) => {
                return o.accept
            })
        }
        if (this.state.filteredKey == "reject") {
            data = _.filter(data, (o) => {
                return o.reject
            })
        }


        const columnsProduct = (v) => [
            {
                title: 'Başlık',
                key: 'titlem',
                render: (text, record) =>
                    <span>
                        {
                            record.avatar && <Avatar shape="square" src={record.avatar} />
                        }
                        {` `}
                        {
                            record.title
                        }
                    </span>
            },
            {
                title: 'Açıklama',
                key: 'description',
                dataIndex: "description"
            },
            {
                title: 'Miktar',
                key: 'count',
                dataIndex: "count"
            },
            {
                title: 'Fiyat',
                key: 'price',
                render: (text, record) =>
                    <span>
                        {`${record.price} ₺`}
                    </span>
            },
            {
                title: 'Tutar',
                key: 'tPrice',
                render: (text, record) =>
                    <span>
                        {`${record.price * record.count} ₺`}
                    </span>
            },
        ]

        const columns = [
            {
                title: 'Zaman',
                key: 'created',
                render: (text, record) => <span>
                    <Tooltip
                        title={record.isCall ? t("callWaiter") : undefined}>
                        <div
                            onClick={() => this.onClickCallWaiter(record)}
                            className={record.isCall ? "ant-btn-danger" : undefined}
                        >{moment(record.created).fromNow()}
                        </div>
                    </Tooltip>
                </span>
            },
            {
                title: 'Özet',
                key: 'desk',
                render: (text, record) => <span>
                    <Tag color="gold">{_.get(record, "title", "")}</Tag>
                </span>
            },
            {
                title: 'Tutar',
                key: 'totalPrice',
                render: (text, record) => <span>
                    {`${this.props.OrderStore.getBasketTotalSum(record.items)} ₺`}
                </span>
            },
            {
                title: 'Mutfak',
                key: 'statusKitchen',
                render: (text, record) => <span>
                    <Select
                        value={_.get(record, "statusKitchen", 0)}
                        style={{ minWidth: 100 }}
                        onChange={(e) => this.onChangeStatus(e, record, "statusKitchen")}
                    >
                        {
                            this.state.statusKitchens.map((v, i) => {
                                return (
                                    <Option key={i} value={v.i} >{v.title}</Option>
                                )
                            })
                        }
                    </Select>
                </span>
            },
            {
                title: 'Garson',
                key: 'statusWaiter',
                render: (text, record) => <span>
                    <Select
                        value={record.isCall ? 1 : _.get(record, "statusWaiter", 0)}
                        style={{ minWidth: 100 }}
                        onChange={(e) => this.onChangeStatus(e, record, "statusWaiter")}
                    >
                        {
                            this.state.statusWaiters.map((v, i) => {
                                return (
                                    <Option key={i} value={v.i} >{v.title}</Option>
                                )
                            })
                        }
                    </Select>
                </span>
            },
            {
                title: '',
                key: 'detail',
                render: (text, record) =>
                    <span>
                        {
                            !this.state.item
                            &&
                            <>
                                <Button onClick={() => this.onClickDetail(record)}
                                    type="primary" icon={<EyeOutlined />}></Button>
                            </>
                        }

                    </span>
            }
        ];

        if (_.get(user, "userType") && _.get(user, "userType") != "kitchen")
            return (
                <div><Alert type="error" message="Bu sayfayı görüntüleme yetkiniz bulunmamakta" /></div>
            )



        return (
            <div
                style={{
                    padding: '10px 50px 10px 50px'
                }}
            >
                {this.renderFilters()}

                <PageHeader
                    title="Siparişler"
                    subTitle={`Toplam ${data.length} kayıt bulundu`}

                    extra={
                        <span>
                            <Button
                                size="small"
                                type='ghost'
                                shape='circle-outline'
                                icon={<ReloadOutlined />}
                                onClick={() => { }}
                            />
                        </span>
                    }
                />


                <div>

                    <Table
                        rowKey={'_id'}
                        //   rowClassName={"ant-btn-danger"}
                        columns={columns}
                        pagination={false}
                        //   size="small"
                        dataSource={data}
                        loading={loading}
                        //  defaultExpandedRowKeys={["5ee4954c912cb91404250ad7"]}
                        //  expandRowByClick={true}
                        expandedRowRender={(record) =>
                            <div style={{ backgroundColor: '#FFF' }}>
                                <Descriptions
                                    title="Sipariş Detayları"
                                //  layout="vertical"
                                >
                                    <Descriptions.Item label="Toplam Tutar">
                                        <span
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            {`${this.props.OrderStore.getBasketTotalSum(record.items)} ₺`}
                                            {
                                                /*
                                                <a target="_blank" href={`https://menu.cafeotomasyonu.com?qr=${record.qrId}`} >
                                                {` `}
                                                <PlusCircleTwoTone />
                                                {` `}
                                                {_.get(record, "title")}
                                            </a>
                                            */
                                            }

                                        </span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Kullanıcı Notu">
                                        <Text type="danger">
                                            {record.customNote}
                                        </Text>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Sipariş tarihi">
                                        <Tooltip
                                            title={moment(record.created).fromNow()}>
                                            {new Date(record.created).toLocaleString()}
                                        </Tooltip>
                                    </Descriptions.Item>
                                </Descriptions>


                                <div style={{ width: '100%' }}>
                                    <p>Ürünler{` :`}</p>
                                    <Table
                                        rowKey={'_id'}
                                        style={{ backgroundColor: '#fff' }}
                                        columns={columnsProduct(record)}
                                        dataSource={record.items}
                                        size='small'
                                        pagination={false}
                                    />
                                </div>

                            </div>
                        }
                    />

                </div>


                <Drawer
                    placement="left"
                    title={undefined}
                    width={720}
                    visible={this.state.modalVisible}
                    onClose={(e) => this.handleCancel(e)}
                    bodyStyle={{ paddingBottom: 80 }}
                >

                    <div>
                        <Title>
                            {_.get(this.state, "item.title", "")}
                        </Title>
                    </div>

                    <div>
                        <Title level={4}>
                            {this.renderTotalPriceWithQrId(_.get(this.state.item, "qrId"))}
                            {` ₺`}
                        </Title>
                    </div>



                    <div>
                        <div style={{ height: 20, backgroundColor: 'rgba(0,0,0,0)' }} />
                        <Text>Ürünler</Text>
                        <Table
                            bordered
                            rowKey={'_id'}
                            style={{ backgroundColor: '#fff' }}
                            columns={columnsProduct(this.state.item)}
                            dataSource={this.concatItemsWithQrId(_.get(this.state.item, "qrId"))}
                            size='small'
                            pagination={false}
                        />
                    </div>

                    <div style={{ height: 20, backgroundColor: 'rgba(0,0,0,0)' }} />

                    <div>
                        <Text>Siparişler</Text>
                        <Table
                            rowKey={'_id'}
                            //   rowClassName={"ant-btn-danger"}
                            columns={columns}
                            pagination={false}
                            //   size="small"
                            dataSource={_.filter(data, (o) => {
                                return o.qrId == _.get(this.state, "item.qrId")
                            })}
                            loading={loading}
                            //  defaultExpandedRowKeys={["5ee4954c912cb91404250ad7"]}
                            //  expandRowByClick={true}
                            expandedRowRender={(record) =>
                                <div style={{ backgroundColor: '#FFF' }}>
                                    <Descriptions
                                        title="Sipariş Detayları"
                                        layout="vertical"
                                    >
                                        <Descriptions.Item label="Toplam Tutar">
                                            <span style={{ fontWeight: 'bold' }}>
                                                {`${this.props.OrderStore.getBasketTotalSum(record.items)} ₺`}
                                                {` / ${record.title} `}
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Ürünler">

                                            <Table
                                                rowKey={'_id'}
                                                style={{ backgroundColor: '#fff' }}
                                                columns={columnsProduct(record)}
                                                dataSource={record.items}
                                                size='small'
                                                pagination={false}
                                            />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Kullanıcı Notu">
                                            <Text type="danger">
                                                {record.customNote}
                                            </Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Sipariş tarihi">
                                            <Tooltip
                                                title={moment(record.created).fromNow()}>
                                                {new Date(record.created).toLocaleString()}
                                            </Tooltip>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>
                            }
                        />

                    </div>

                    <div
                        style={{
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 0',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
                            İptal
                        </Button>


                    </div>
                </Drawer>

            </div>
        )
    }
}

export default withTranslation()(Order)