


import PropTypes from 'prop-types';

import React from 'react'
import {
    Breadcrumb,
    Row,
    Col,
    Layout,
    Divider,
    Button,
    Badge,
    Dropdown,
    Menu
} from 'antd';
import { Link } from 'react-router-dom';

import {
    EyeOutlined,
    PlusCircleTwoTone,
    PhoneOutlined,
    HomeOutlined,
    UserOutlined,
    FacebookOutlined,
    InstagramOutlined,
    ShoppingOutlined,
} from '@ant-design/icons';

const {
    Header,
} = Layout;

const CompanyHeader = ({
    companykey,
    title,
    phone,
    logo,
    basketItems = [],
    deliveryService
}) => {

    const menuBasket = (
        <Menu>
            {
                basketItems.map((v, i) => {
                    return (
                        <Menu.Item key={i}>
                            {v.title}
                        </Menu.Item>
                    )
                })
            }
            {
                basketItems.length > 0 && <Menu.Item>
                    <Link to={`/company/${companykey}/basket`}><Button type="primary" block>Sepetime Git</Button></Link>
                </Menu.Item>
            }
            {
                // basketItems.length == 0 && <Menu.Item>
                //     <Button type="danger" block>Sepetiniz Boş</Button>
                // </Menu.Item>
            }
        </Menu>
    )


    return (
        <Header style={{ backgroundColor: '#FFF' }}>
            <Row justify="space-around" type="flex">
                <Col span={12} md={12} xs={24}>
                    {
                        logo && <img style={{ maxHeight: 40, marginRight: 10 }} src={logo} />
                    }
                    <span >{title}</span>
                </Col>
                <Col span={12} md={12} xs={0}>
                    {
                        phone
                        &&
                        <span className="ml-30 float-right">
                            <PhoneOutlined /> {phone}
                        </span>
                    }


                    {
                        deliveryService &&
                        <span className="ml-30 float-right">
                            <Dropdown overlay={menuBasket}>
                                <Badge count={basketItems.length}>
                                    <Button shape={"circle"} icon={<ShoppingOutlined />} title={"Sepetim"} />
                                </Badge>
                            </Dropdown>
                            {
                                //  <Divider type="vertical" />
                                //  <Button shape={"circle"} icon={<UserOutlined />} title={"Hesabım"} />
                            }
                        </span>
                    }




                </Col>
            </Row>
        </Header>
    )
}

CompanyHeader.propTypes = {
    title: PropTypes.string,
    phone: PropTypes.string,
    logo: PropTypes.string
};

export default CompanyHeader;