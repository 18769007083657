
import React, { Component } from 'react';
import {
    message,
    Table,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Select,
    Tag,
    Dropdown,
    Menu,
    Descriptions
} from 'antd';
import { EyeOutlined, PlusCircleTwoTone } from '@ant-design/icons';

import { Link } from 'react-router-dom';

import PageHeader from '../components/PageHeader';


import { inject, observer } from 'mobx-react';
import { entries, toJS } from 'mobx';

import moment from 'moment'


import _ from "lodash"

import { withTranslation } from 'react-i18next';

import OrderDetailDrawer from '../components/OrderDetailDrawer';



//  'ProductStore', 'UserStore',
@inject('OrderStore')
@observer
class Order extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categoryList: [],
            modalVisible: false,
            item: null
        }
    }

    componentDidMount = () => {
        //sayfayı yukarı kaydır 
        window.scrollTo(0, 0)
        this.props.OrderStore.getItemList()
    }
    //////////////


    onClickDetail = (item) => {
        this.props.OrderStore.renderTotalPriceWithQrId(item.qrId)

        console.log(item)
        this.setState({
            modalVisible: true,
            item
        })
    }

    handleCancel = () => {
        this.setState({ modalVisible: false, item: null })
    }

    ///qr toplam tutar
    renderTotalPriceWithQrId = (qrId) => {
        let data = this.props.OrderStore.data
        data = _.filter(data, (o) => {
            return o.qrId == qrId
        })
        let tPrice = 0;
        data.map(v => {
            tPrice = tPrice + this.props.OrderStore.getBasketTotalSum(v.items)
        })
        return tPrice
    }
    //qr ürünleri birleştir
    concatItemsWithQrId = (qrId) => {

        let data = this.props.OrderStore.data
        data = _.filter(data, (o) => {
            return o.qrId == qrId
        })

        let items = []
        data.map(v => {
            items = items.concat(_.get(v, "items", []))
        })

        return items
    }

    onClickCallWaiter = (item) => {
        console.log(item)
        if (item.isCall) {
            this.props.OrderStore.setIsCallStatus(item.qrId, false)
        }
    }

    //sepeti kapat
    onClickDeleteOrderByQrId = (qrId) => {

        this.props.OrderStore.setCompletedServer(qrId).then((res) => {
            console.log({ res })
        }).catch(err => {
            console.log({ err })
        })


        this.handleCancel()

        /*
    
        this.handleCancel()
    
        let data = this.props.OrderStore.data
        data = _.filter(data, (o) => {
          return o.qrId == qrId
        })
        data.map(v => {
          this.props.OrderStore.setCompleted(v._id,qrId)
          //  this.props.OrderStore.deleteOrderById(v._id)
        })
    */



        /*
        this.setState({ modalVisible: false, item: null },()=>{
          this.props.OrderStore.deleteOrderByQrId(qrId)
        })
        */

    }



    /*
     <span key={key}/>
            <li key={key}>
            {key} - {JSON.stringify(value)}
          </li>
          */
    renderFilters = () => {
        return entries(this.props.OrderStore.data).map(([key, value]) => {
            return (
                <span style={{ display: 'none' }} key={key}>
                    <li key={key}>
                        {key} - {JSON.stringify(value)}
                    </li>
                </span>
            );
        })
    };


    onChangeStatus = (e, item, type) => {
        const postData = {
            key: type,
            value: e,
            _id: item._id
        }
        this.props.OrderStore.onChangeStatus(postData)
        this.setState({ modalVisible: false })
    }


    render() {
        const { t } = this.props
        //  const { data, loading } = this.state
        const { loading } = this.props.OrderStore
        let data = this.props.OrderStore.data

        const { statusWaiters, statusKitchens } = this.props.OrderStore

        //   data = _.filter(data, (o) => {
        //       return !o.completed
        //   })



        const newOrders = _.filter(data, (o) => {
            return !o.accept && !o.reject && !o.done
        })

        const acceptOrders = _.filter(data, (o) => {
            return o.accept && !o.done
        })

        const completedOrders = _.filter(data, (o) => {
            return o.done
        })



        const columnsLiveOrder = [
            {
                title: '',
                key: 'titlem1',
                render: (text, record) =>
                    <span>
                        {
                            (!record.accept && !record.reject) && <div className="blob green" />
                        }
                        {record.channel === "everyone" && <Tag color="lime">Paket Sipariş</Tag>}
                        {record.channel === "mobilapp" && <Tag color="volcano">Paket Sipariş</Tag>}
                    </span>
            },
            {
                title: '',
                key: 'titlem2',
                render: (text, record) =>
                    <span>
                        {`${moment(record.created).fromNow()} | ${record.title}`}
                        <br />
                        <b>#{_.get(record, "publicId", new Date().getTime())}</b>
                        {
                            //  _.get(record, "items", []).map(v => { return v.title }).join("-")
                            // <p key={i}>
                            //     {v.title}
                            // </p>
                        }
                        {
                            _.get(record, "items", []).map((v, i) => {
                                return (
                                    <div key={i}>
                                        {v.title}
                                    </div>
                                )
                            })
                        }
                    </span>
            },
            {
                title: '',
                key: 'titlem3',
                fixed: 'right',
                render: (text, record) =>
                    <span>
                        <Button onClick={() => this.onClickDetail(record)} size="small">Detay</Button>
                    </span>
            },
        ]



        return (
            <div>
                {
                    this.renderFilters()
                }
                <div style={{ height: 20 }} />
                <Row>
                    <Col className="ordercol" span={12} md={8} xs={12}>
                        <Card
                            style={{ borderRadius: 10 }}
                            title='Yeni Siparişler'
                        >
                            <Table
                                showHeader={false}
                                rowKey={'_id'}
                                columns={columnsLiveOrder}
                                dataSource={newOrders}
                                size='small'
                                pagination={false}
                            />
                        </Card>
                    </Col>
                    <Col className="ordercol" span={12} md={8} xs={12}>
                        <Card
                            style={{ borderRadius: 10 }}
                            title='Kabul Edildi'
                        >

                            <Table
                                showHeader={false}
                                rowKey={'_id'}
                                columns={columnsLiveOrder}
                                dataSource={acceptOrders}
                                size='small'
                                pagination={false}
                            />
                        </Card>
                    </Col>
                    <Col className="ordercol" span={12} md={8} xs={12}>
                        <Card
                            style={{ borderRadius: 10 }}
                            title='Bitti'
                        >
                            <Table
                                showHeader={false}
                                rowKey={'_id'}
                                columns={columnsLiveOrder}
                                dataSource={completedOrders}
                                size='small'
                                pagination={false}
                            />
                        </Card>
                    </Col>
                </Row>
                <OrderDetailDrawer
                    placement={"right"}
                    item={this.state.item}
                    visible={this.state.modalVisible}
                    statusKitchens={statusKitchens}
                    statusWaiters={statusWaiters}
                    handleCancel={() => this.handleCancel()}
                    getProductsByQrId={(qrId) => this.concatItemsWithQrId(qrId)}
                    getBasketTotalSum={(items) => this.props.OrderStore.getBasketTotalSum(items)}
                    // totalPrice={(qrId) => this.renderTotalPriceWithQrId(qrId)}
                    totalPrice={this.props.OrderStore.totalPrice}
                    onClickCompleted={(qrId) => this.onClickDeleteOrderByQrId(qrId)}
                    data={data}
                    onClickCallWaiter={(item) => this.onClickCallWaiter(item)}
                    onChangeStatus={(value, item, key) => this.onChangeStatus(value, item, key)}
                    onClickDetail={(item) => this.onClickDetail(item)}
                />
            </div>
        )
    }
}

export default withTranslation()(Order)