import React from 'react';

import { Button, Table, Modal,PageHeader, message, Popconfirm, Divider,Form ,Input, Icon,Radio ,Tooltip,Tag,Row,Col,Card,Typography,Drawer} from 'antd';

import SalesListPage from '../components/salesTable'
import _ from 'lodash'

import { 
    UserOutlined, 
    PlusOutlined, 
    UserAddOutlined, 
    QuestionCircleOutlined, 
    ArrowLeftOutlined, 
    CloseOutlined, 
    MessageOutlined,
    SaveOutlined,
    DeleteOutlined,
    EditOutlined,
    InfoOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
    MinusCircleOutlined
 } from '@ant-design/icons';


const { Text } = Typography;
const {TextArea,Search} = Input;



// Helper Functions

const ColProps = {
    xs: 24,
    sm: 24,
    style: {
      marginBottom: 16,
    },
  }
  

export default class Customer extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            name:'',
            phone:'',
            address:[
                {i:0,value:'',type:0}
            ],
            id:'',
            search:'',
            modalOpenCreate: false,
            modalOpenDetail: false,
            detailContent:{name:'',phone:'',created:'',address:[]},
            customerList:[],
            modalTitle:'Yeni Müşteri',
            page:'list',
            visibleSalesList:false,
            salesList:[]
            
        };

    }

    componentDidMount(){
      //  this.getItemList();
    }

    newOrder(v){
        let data =this.state.detailContent;
            data.selectedAddress = v;
        console.log(data)
    }



    modalOpenDetailClick(item){
        this.setState({detailContent:item},()=>{
            this.setState({modalOpenDetail:true})  
        })
      
    }
    modalOpenDetailClose(){
        this.setState({modalOpenDetail:false})
    }

    //arama inputu tetiklendiğinde
    handleSearch(e,v=null){
        let search='';
        if(v){
            search = e;
        }
        else{
             search =e.target.value;
        }
        this.setState({search},()=>{
            db.search(tableCustomer,"name",search).then((result)=>{
                this.setState({customerList:_.orderBy(result, ['created'],['desc'])});

                if(result.length==0){
                    db.search(tableCustomer,"phone",search).then((result)=>{
                        this.setState({customerList:this.state.customerList.concat(result)});
                       
                    })
                }

            })
        })
      }

      //arama inputu silindiğinde 
      removeSearch(){
        this.setState({search:''});
        this.getItemList();
  
      }

    clickEdit(item){
        this.setState({id:item.id,name:item.name,phone:item.phone,address:item.address},()=>{
            this.setState({modalOpenCreate:true,modalTitle:'Müşteri Güncelle'})
        })
        
    }

    deleteCustomer(item){
        console.log(item)
        this.getItemList();
    }
    onChangeAddressType(e,item){
        let addressItem = _.find(this.state.address,{i:item.i});
        addressItem.type = e.target.value;
    }


    onChangeAddress(e,v){
        let address = _.find(this.state.address,{i:v.i});
        address.value = e.target.value;
        this.setState({address:this.state.address})

    }

    onChange(e){
        this.setState({[e.target.name]:e.target.value})
    }


    getItemList(){
        db.getAll(tableCustomer).then((result)=>{
            console.log(result)
            this.setState({customerList:_.orderBy(result, ['created'],['desc'])});
        })
    }

    updateApi(){
        const {id} = this.state;

        db.update(tableCustomer,{id},
            {
                name: this.state.name,
                phone :this.state.phone ,
                address:this.state.address
            }
            )
        message.success("Güncelleme işlemi başarılı");
        this.setState({address:[ {i:0,value:''}],name:'',phone:'',modalOpenCreate:false,modalTitle:'Yeni Müşteri',isExist:false});
        this.getItemList();
    }
    

    createApi(){
        let data = {
            name: this.state.name,
            phone :this.state.phone ,
            address:this.state.address,
            created: new Date()
        }
        db.insert(tableCustomer,data);
        message.success("Ekleme işlemi başarılı");
        this.setState({address:[ {i:0,value:''}],name:'',phone:'',modalOpenCreate:false,isExist:false});
        this.getItemList()
    }

    handleCreate() {
        if(!this.state.name || !this.state.phone){
            message.error("Boş kayıt oluşturulamaz.")
        }
        if(this.state.phone.length!=10){
            message.error("Telefon numarası 10 haneli olmalıdır. ÖR:(xxxxxxxxxx)")
        }
        if(this.state.isExist){
            message.error("Bu telefon numarasına ait kayıt bulunmakta. Lütfen kaydı güncelleyiniz")
        }
        if(this.state.name && this.state.phone.length==10){
            if(this.state.modalTitle=="Yeni Müşteri" && !this.state.isExist){
                this.createApi();
            }
            if(this.state.modalTitle=="Müşteri Güncelle"){
                this.updateApi();
            }
          
        }


    }


    handleCancel() {
        this.setState({
            modalOpenCreate: false,
            isExist:false,
            address:[ {i:0,value:''}],
            name:'',
            phone:'',
            id:'',
            modalTitle:'Yeni Müşteri'
        });
        this.form = null;
      
    }

    handleReload() {
        
       // this.state.wallets.forEach(w => w.update());
       this.getItemList();
    }

    render() {


        const columnsCustomer = [
            { title: 'İsim', dataIndex: 'name', key: 'name' },
            { title: 'Telefon', dataIndex: 'phone', key: 'phone' },
            { title: 'Adres', key: 'action1', render: (record) => {
                return (
                    record.address.map((v,i)=>{
                        let display="Ev"
                        let color ="geekblue";
                        if(v.type==1){
                            display="İş"
                            color="magenta"
                        }
                        if(v.type==2){
                            display="Diğer"
                            color="gold"
                        }
                        return(
                            <span key={i}>
                            <Tooltip title={v.value}>
                            <Tag color={color}>
                            {display}
                                </Tag>
                            </Tooltip>
                            </span>
                        )
                    })
                    );
                }
            },
            { title: 'İşlem', key: 'action', render: (record) => {
                return (
                    <span>
                        <Button size="small" onClick={()=>this.clickEdit(record)}>
                            <EditOutlined/>
                            Düzenle
                        </Button>
                    <Divider type="vertical"/>
                        <Popconfirm okText="Evet" cancelText="Hayır" title="Emin misiniz?"
                                    onConfirm={()=>{this.deleteCustomer(record)}}>
                            <Button size="small" type="danger">
                                <DeleteOutlined/>
                            Sil
                        </Button>
                        </Popconfirm>
                        <Divider type="vertical"/>
                        <Button size="small" type="primary" onClick={()=>this.modalOpenDetailClick(record)}>
                            <InfoOutlined/> DETAY
                        </Button>
                       
                    </span>
                    )
                }
            },
          
        ];

        return (
            <div
            style={{
                padding : '10px 50px 10px 50px'
            }}
            >
                <PageHeader
   // className="site-page-header"
   style={{
       marginLeft:-20,marginRight:-20
    }}
    onBack={() => goBack()}
    title="Müşteriler"
    subTitle=  {_.get(this.state.operator,"info.me.user")}
    extra={<span>
                                <Button
                            onClick={()=>this.onClickCardSave()}
                            >
                                <SaveOutlined />
                        </Button>
    </span>}
  />

                { !this.state.modalOpenDetail
                &&
                <div style={{ marginBottom: '12px' }}>
                   <Row gutter={24}>
                                <Col
                            {...ColProps}
                        xl={{ span: 12 }}
                        md={{ span: 12 }}>
                        <Search
                        autoFocus={true}
                        addonAfter={
                        this.state.search.length>0 &&
                        <DeleteOutlined onClick={()=>this.removeSearch()} />
                        }
                        placeholder={`İsme göre ara`}
                        name="search"
                        onSearch={this.handleSubmit}
                        onChange={this.handleSearch}
                        defaultValue={this.state.search}
                        value={this.state.search}
                        />
                        </Col>
                        <Col
                        xl={{ span: 10,offset: 2  }}
                        md={{ span: 6, offset:6 }}
                        sm={{ span: 24 }}
                        >
                        <Row type="flex" justify="end">
                        <Button
                                    type="primary"
                                   
                                    style={{ marginLeft: '8px' }}
                                    onClick={() => this.setState({ modalOpenCreate: true, })}>
                                        <PlusOutlined/>
                                        Yeni
                                    </Button>
                                    <Button type="primary"
                                            shape="circle"
                                            icon={<ReloadOutlined/>}
                                            style={{ marginLeft: '8px' }}
                                            onClick={()=>this.handleReload()} />
                        </Row>
                        </Col>
                    </Row>
                </div>
                }
               
    {
    this.state.modalOpenDetail
       ?
        <Card title={this.state.detailContent.name} 
        extra={<Icon style={{color:'red',fontSize:28}} 
        onClick={()=>this.modalOpenDetailClose()}
         type="close-square" />}
         >
        <div>
        <div><Text type="danger">Adres</Text></div>
        <div>
            {
                 this.state.detailContent.address.map((v,i)=>{
                        let display="Ev"
                        let color ="geekblue";
                        if(v.type==1){
                            display="İş"
                            color="magenta"
                        }
                        if(v.type==2){
                            display="Diğer"
                            color="gold"
                        }
                        return(
                            <Row  key={i} style={{marginBottom:5}}>
                                <Col span={2}>
                                <Tag color={color}>
                            {display}
                                </Tag>
                                </Col>
                                <Col span={8}>
                                <Text style={{marginRight:10}}>{v.value}</Text>
                                </Col>
                                <Col span={4}>
                                <Button
                                type="primary"
                                size="small"
                                onClick={()=>this.newOrder(v)}
                                >
                                    Yeni Sipariş
                                </Button>
                                </Col>
                            </Row>
                         
                        )
                    })
            }
        </div>
        <div><Text type="danger">Telefon</Text>  {this.state.detailContent.phone}</div>
        <div><Text type="danger">Kayıt Tarihi</Text> {new Date(this.state.detailContent.created).toLocaleString()}</div>

         <Row justify='end'>
               <Button size="small" onClick={()=>this.onClickNewPhone()}>
                   Örnek Çağrı
                 </Button>
        </Row>
        </div>

        <div>
                       <Row
                       style={{
                           paddingBottom:10,
                           paddingTop:10
                       }}
                       >
                            <Button
                            type={this.state.visibleSalesList ? 'primary' : 'default'}
                                      onClick={()=>this.setState({visibleSalesList:!this.state.visibleSalesList})}>
                                       Geçmiş Satışlar
                                      </Button>
                        </Row>

                        <Row>
                            {
                                this.state.visibleSalesList
                                &&
                                <SalesListPage
                                customer={this.state.detailContent}
                                />
                            }
                        </Row>
        </div>
        </Card>
       :
                <Table columns={columnsCustomer}
                       pagination={{ pageSize: 20 }}
                       size="small"
                       rowKey={'id'}
                       dataSource={this.state.customerList}
                       pagination={true}
                       style={{backgroundColor: '#fff' }} />
        }
                <Drawer
                  title={this.state.modalTitle}
                  placement="left"
                  visible={this.state.modalOpenCreate}
                  onClose={()=>this.handleCancel()}
                  width={720}
                  bodyStyle={{ paddingBottom: 80 }}
                  >

                <Form layout="vertical">
                <Form.Item label="Müşteri Adı">
                  <Input value={this.state.name}  onChange={(e)=>this.onChange(e)} name="name" placeholder="Müşteri Adı" />
                </Form.Item>
                <Form.Item label="Telefon">
                  <Input
                //   onBlur={()=>(this.state.modalTitle=="Yeni Müşteri") ? this.isExist() : undefined}
                   suffix="başında sıfır kullanmadan 10 haneli olarak girin" 
                    onChange={(e)=>this.onChange(e)}
                      name="phone" 
                      type='number'
                      value={this.state.phone}
                      placeholder="Telefon numarası"
                      suffix={
                        <Tooltip title="başında sıfır kullanmadan 10 haneli olarak girin">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                       />
                </Form.Item>

                <Form.Item label="Adres">
                  <div onClick={()=>{
                      this.state.address.push({"i":this.state.address.length,value:'',type:0})
                      this.setState({address:this.state.address})
                  }}
                  style={{
                      paddingBottom:20
                  }}
                  >
                            <Button type="primary"
                            shape="circle"
                            icon={<PlusOutlined/>}
                            style={{ marginLeft: '8px' }}
                            />
                  </div>
                    {
                        this.state.address.map((v,i)=>{
                            return(
                <div style={{marginBottom:10}}  key={i}>
                    <Row justify="space-between" >

                        <Radio.Group onChange={(e)=>this.onChangeAddressType(e,v)} defaultValue={v.type}>
                            <Radio.Button value={0}>Ev</Radio.Button>
                            <Radio.Button value={1}>İş</Radio.Button>
                            <Radio.Button  value={2}>Diğer</Radio.Button>
                        </Radio.Group>

                        <MinusCircleOutlined
                        onClick={()=>{
                            _.remove(this.state.address,{i:v.i})
                            this.setState({address:this.state.address})
                        }}
                        />

                    </Row>



                            <TextArea
                                onChange={(e)=>this.onChangeAddress(e,v)}
                                name={v.i}
                                value={v.value}
                                placeholder="Adres detayı"
                                autosize={{ minRows: 2, maxRows: 6 }}
                            />
                                </div>
                            )
                        })
                    }
                </Form.Item>

            </Form>
            <div
                style={{
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 0',
                background: '#fff',
                textAlign: 'right',
                }}
            >
                <Button onClick={()=>this.handleCancel()} style={{ marginRight: 8 }}>
                İptal
                </Button>
                <Button onClick={()=>this.handleCreate()} type="primary">
                Kaydet
                </Button>
          </div>
                </Drawer>
            </div>
        );
    }
}

