
import { observable, action, computed, toJS, autorun } from 'mobx';

import _ from "lodash"


import axios from 'axios';

import { API_URL } from '../config'



export default class Product {

    constructor() {
        this.getToken();
        autorun(() => {
            //#	console.log(`User token : ${this.token}  `);
        })
    }

    @observable token = null
    @observable newOperator = false
    @observable categories = []



    //kategori ekle
    @action async inserCategory(data) {
        const url = `${API_URL}/category`
        const headers = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
        return axios.post(url, data, headers);
    }

    //kategorileri getir
    @action async getCategories(listType) {
        //  let url = `${API_URL}/category`;
        let url = `${API_URL}/category${listType ? `?listType=${listType}` : ""}`;
        const headers = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
        return axios.get(url, headers);

    }

    //kategori sil
    @action async deleteCategory(id) {
        let api = `${API_URL}/category/delete/${id}`;
        const headers = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
        return axios.post(api, null, headers);
    }
    // kategori durumunu aktif pasif durumu
    @action async setCategoryActive(id, active) {
        let api = `${API_URL}/category/update/${id}`;
        return axios.post(api, { type: "active", active }, this.getHeader());
    }

    // kategori güncelle
    @action async updateCategory(id, data) {
        let api = `${API_URL}/category/update/${id}`;
        return axios.post(api, data, this.getHeader());
    }


    //////////-- ürün --///////////


    //herkes için ürünleri listele PUBLİC



    // ekle
    @action async inserProduct(data) {
        const url = `${API_URL}/product`
        const headers = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
        return axios.post(url, data, headers);
    }

    // getir
    @action async getProducts(listType) {
        let url = `${API_URL}/product${listType == "withcategory" ? "?listType=withcategory" : ""}`;
        const headers = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
        return axios.get(url, headers);

    }

    // sil
    @action async deleteProduct(id) {
        let api = `${API_URL}/product/delete/${id}`;
        const headers = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
        return axios.post(api, null, headers);
    }
    // aktif pasif durumu
    @action async setProductActive(id, active, type) {
        let api = `${API_URL}/product/update/${id}`;
        return axios.post(api, { active, type }, this.getHeader());
    }

    //  güncelle
    @action async updateProduct(id, data) {
        let api = `${API_URL}/product/update/${id}`;
        return axios.post(api, data, this.getHeader());
    }

    //sıralama güncelle
    @action async updateProductRank(data) {
        let api = `${API_URL}/product/update-rank`;
        return axios.post(api, { data }, this.getHeader());
    }



    // TOKEN İŞLEMLERİ
    @action async saveToken(token) {
        this.token = token
        return localStorage.setItem('token', token);
    }

    @action async removeToken() {
        this.token = null;
        return localStorage.removeItem('token');
    }

    @action async getToken() {
        const token = localStorage.getItem('token');
        this.token = token;
        return token;

    }

    getHeader() {
        return { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
    }


}
