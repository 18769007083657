import React from 'react'
import { Spin } from 'antd';
import { siteInfo } from '../config';



export default ({
    title
}) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'rgba(47, 54, 64, 0.2)' }}>
            <Spin size="large" />
            {
                title
                    ?
                    <p>Yükleniyor</p>
                    :
                    <h1 style={{ marginLeft: 10 }}><span style={{ color: '#293F68' }}>
                        {siteInfo.appFirstName}</span><span style={{ color: 'rgb(47, 54, 64)' }}>{siteInfo.appSecondName}</span>
                    </h1>
            }

        </div>
    )
}
