

import React from 'react';

import CompanyCategory from '../components/CompanyCategory'

export default ({ match }) => {
    console.log("companykey:CompanyCategory:page", match.params.companykey)
    return (
        <CompanyCategory
            companykey={match.params.companykey}
            categoryid={match.params.categoryid}
        />
    )
}