
import { observable, action, computed, toJS, autorun } from 'mobx';

import _ from "lodash"

// import {joinRoom} from "../utils/socket"

import axios from 'axios';

import { API_URL } from '../config'
import UserServices from '../services/User';



const myKeys = {
  token: "token",
  tokenstaff: "tokenstaff",
  tokencompanycode: "companycode"
}

export default class User {

  constructor() {
    this.getToken();
    autorun(() => {
      //#	console.log(`User token : ${this.token}  `);
    })
  }

  @observable token = null
  @observable loading = false
  @observable notifications = []
  @observable user = null


  //yeni bildirim ekle
  @action pushNotification(item) {
    this.notifications.push(item)
    /*
       const fI = this.notifications.findIndex(v => v.qr === item.qr_id)
    if(fI===-1){
      this.notifications.push(item)
    }
    */
    //////////
    //  this.OrderStore = new OrderState(this)
    //  this.OrderStore.setIsCallStatus(item.qr,true)
    //////////
    return this.notifications
  }
  //bildirim sil
  @action removeNotification(item, type = null) {
    if (!type) {
      const fI = this.notifications.findIndex(v => v.qr === item.qr_id)
      this.notifications.splice(fI, 1);
      ////////////////
      //   this.OrderStore = new OrderState(this)
      //    this.OrderStore.setIsCallStatus(item.qr,false)
      ////////////////
    }
    else if (type == "deleteAll") {
      this.notifications = []
    }
    // this.notifications=this.notifications
    return
  }

  //belirlenen id bildirimlerde içeriyor mu ?
  @action isIncludesInNotication(qrId) {
    const finded = _.find(this.notifications, { qr: qrId })
    console.log("isIncludesInNotication", { finded })
    return finded
  }


  //sisteme giriş
  @action async login(data) {
    const url = `${API_URL}/users/login`
    return axios.post(url, data);
  }


  //sisteme giriş PERSONEL
  @action
  loginStaff = async (postData) => {
    try {
      const service = new UserServices()
      const serverData = (await service.loginStaff(postData)).data
      const { user } = serverData
      console.log("loginStaff", serverData)
      this.saveToken(serverData._id)
      this.token = serverData._id
      this.saveCompanyCode(user.apikey)
      this.user = user
    } catch (error) {
      console.log(error.response.data)
    }
  }

  //profilimi getir
  @action
  getProfileStaff = async () => {
    this.loading = true
    try {
      const service = new UserServices(this.token)
      const serverData = (await service.myProfileStaff()).data
      console.log("getProfileStaff", serverData)
      this.user = serverData.user
    } catch (error) {
      // this.removeToken(myKeys.tokenstaff)

    } finally {
      this.loading = false
    }

  }


  //apikey almak
  @computed get getCompanyCode() {
    const companycode = localStorage.getItem('companycode');
    return companycode
  }

  //şifremi unuttum #1
  @action async forgotPassword(email) {
    const url = `${API_URL}/users/forgot`
    return axios.post(url, { email });
  }

  //şifre kurtarma #2
  //
  @action async getUserWithResetPasswordKey(resetPasswordKey) {
    const url = `${API_URL}/users/forgot/${resetPasswordKey}`
    return axios.get(url);
  }
  //şifre kurtarma set #3
  @action async recoveryPassword(data) {
    const url = `${API_URL}/users/recoverypassword`
    return axios.post(url, data);
  }


  //kayıt ol
  @action async register(data) {
    const url = `${API_URL}/users`
    return axios.post(url, data);
  }

  //sistemden çıkış
  @action async logout() {
    this.removeToken()
    //  this.props.history.push("/login");
  }

  //profilimi getir
  @action
  getProfile = async () => {
    //  this.loading = true
    let api = `${API_URL}/users/me`;
    // if (userId) api = `${API_URL}/users/${userId}`;
    try {
      const userProfile = await axios.get(api, { headers: { Authorization: this.token } });
      console.log("!getProfile!", userProfile)
      this.user = JSON.parse(JSON.stringify(_.get(userProfile, "data.user")))
      return Promise.resolve(userProfile)
      //  if (!this.user) {
      //    const userProfile = await axios.get(api, { headers: { Authorization: this.token } });
      //    this.user = _.get(userProfile, "data.user")
      //    return Promise.resolve(userProfile)
      //  }

    } catch (error) {
      // this.removeToken()
      return Promise.reject(error)

    } finally {
      //  this.loading = false
    }

  }
  @action
  checkProfile = () => {
    if (this.user) return
    this.getProfile()

  }




  //kullanıcı adını al
  @action async getUsername() {
    let api = `${API_URL}/users/me`;
    return new Promise((resolve, reject) => {
      axios.get(api, this.getHeader()).then((res) => {
        let username = _.get(res, "data.user.username")
        return resolve(username)
      }).catch((err) => {
        return reject(err)
      })
    })
  }

  //fiyat görünürlüğü güncelle
  @action async setPriceVisible(active) {
    console.log("!setPriceVisible!", active)
    // let api = `${API_URL}/users/update`;
    // return axios.post(api, { type: "visible-price", active }, this.getHeader());
    try {
      let api = `${API_URL}/users/update`;
      const server = (await axios.post(api, { type: "visible-price", active }, this.getHeader())).data;
      console.log({ server })
      this.user.company.visiblePrice = active
    } catch (error) {
      console.log(error)
    }
  }
  //pakett servisi aktifliğini güncelle
  @action async setDeliveryService(active) {
    //    let api = `${API_URL}/users/update`;
    //   return axios.post(api, { type: "delivery-service", active }, this.getHeader());
    try {
      let api = `${API_URL}/users/update`;
      const server = (await axios.post(api, { type: "delivery-service", active }, this.getHeader())).data
      console.log({ server })
      //   let finded = _.find(this.data, (o) => {
      //     return o._id == _.get(data, "_id")
      // })
      this.user.company.deliveryService = active
    } catch (error) {
      console.log(error)
    }

  }
  //firma bilgilerini güncelle
  @action async updateCompany(data) {
    data.type = "update-company"
    let api = `${API_URL}/users/update`;
    return axios.post(api, data, this.getHeader());
  }

  //firma aktif durumunu güncelle
  @action
  setCompanyActive = async (active) => {
    try {
      let api = `${API_URL}/users/update`;
      const server = (await axios.post(api, { type: "active-company", active }, this.getHeader())).data
      console.log({ server })
      this.user.company.active = active
    } catch (error) {
      console.log(error)
    }
  }

  @action
  setCompanyLoyaltyActive = async (key, value) => {
    try {
      // this.loading = true
      let api = `${API_URL}/users/update`;
      const server = (await axios.post(api, { type: key, active: value }, this.getHeader())).data
      this.user.company[key] = value
      console.log({ server })
    } catch (error) {
      console.log(error)
    }
    finally {
      //  this.loading = false
    }
  }


  //iki kişi arasındaki mesajları getir
  @action async getPrivateMessage(userId = null) {
    //userId  : karşı kişinin id si
    let api = `${API_URL}/privatemessages`; ///api/privatemessages/:id'
    //  let api = `${API_URL}/channels/5d4ebce32a6a5825c81ca20f`; ///api/privatemessages/:id'
    if (userId) api = `${API_URL}/privatemessages/${userId}`;
    return axios.get(api, { headers: { Authorization: this.token } });
  }

  // mesajlarımı getir
  @action async getMessagesList(limit = 20, offset = 0) {
    let api = `${API_URL}/me/privatemessages`;
    if (limit != 20 && offset != 0) api = `${API_URL}/me/privatemessages?limit=${limit}&offset=${offset}`;
    return axios.get(api, { headers: { Authorization: this.token } });
  }

  //benim kullanıcı kimliği (id)
  @action getMyId = async () => {
    const api = `${API_URL}/users/me/id`;
    let resData = await axios.get(api, { headers: { Authorization: this.token } });
    return _.get(resData, "data._id")

  }
  // kullanıcı dilini set et
  @action setLanguage = async (lang) => {
    const api = `${API_URL}/users/me/lang`;
    return axios.post(api, { lang }, { headers: { Authorization: this.token } });
  }




  // kullanıcı ara
  @action async search(search) {
    const url = `${API_URL}/users/search`
    return axios.post(url, { search });
  }



  getHeader() {
    this.getToken();
    return { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
  }




  @action async saveCompanyCode(token) {
    return localStorage.setItem('companycode', token);
  }

  // TOKEN İŞLEMLERİ
  @action
  saveToken = async (token, value = null) => {
    if (value) {
      return localStorage.setItem(token, value);
    } else {
      this.token = token
      return localStorage.setItem('token', token);
    }

  }

  @action
  removeToken = async (key) => {
    this.token = null;
    localStorage.removeItem('companycode');
    localStorage.removeItem('token');
    localStorage.removeItem(myKeys.tokenstaff);
    if (key) localStorage.removeItem(key);
    return
  }

  @action async getToken() {
    let token = localStorage.getItem('token');
    if (!token) {
      token = localStorage.getItem(myKeys.tokenstaff);
    }
    //#   console.log("User store getToken() :",token)
    this.token = token;
    if (!this.user && token) {
      //  console.log("USER STORE user YOK!!!!!!!!!!!!!!!!!")
      // setTimeout(() => {
      //   this.getProfile()
      // }, 5000)
    }
    return token;

  }



}

/*
{"userId":"5d9cedefadc4e60028be935a","created":"2020-03-29T16:57:22.391Z","_id":"5e80d372ab2bb600287c0a91","user":{"_id":"5d9cedefadc4e60028be935a","name":"ferdi","email":"info@ferdiozer.com","gender":true,"username":"","avatar":"https://api.adorable.io/avatars/100/$2b$10$Q.rMwJbX6ckPTWzF3YN6LujedDU97AKnsEvnl4q6NfHUAi0MBq1wi.png","gift":[],"level":0,"flower":0,"year":null,"location":"Türkiye","created":"2019-10-08T20:13:35.471Z"}}

*/

