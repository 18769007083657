import React from 'react'

import Chart from "react-apexcharts";
/**
 *{type} bar | line
 * width="500"
 */
export default ({
    options,
    series,
    type = "bar"
}) => {
    return (
        <Chart
            options={options}
            series={series}
            type={type}
        />
    )
}


