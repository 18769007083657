import React, { Component } from 'react';
import { Button, Table, Input, Select, Tag, Row, Col, Card, DatePicker, Tooltip, Typography } from 'antd'

import { PlusOutlined, MinusOutlined, ReloadOutlined } from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import _ from 'lodash';
import moment from 'moment';
import PageHeader from '../components/PageHeader';
//moment.locale('tr')
//import 'moment/locale/tr';


const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;


@inject('HomeStore')
@observer
export default class Logs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalVisible: false,
      modalOpenCreate: false,
      data: [],
      logsTypes: [
        { i: 10, title: 'Tümü' },
        { i: 1, title: 'Müşteri' },
        { i: 2, title: 'Kategori' },
        { i: 3, title: 'Ürün' },
        { i: 4, title: 'Sipariş' },
        { i: 5, title: 'Qr Kod' },
      ],
      logsType: 10
    }

  }

  // type (int)  :::::  0.....n : müşterü ürün
  // subType (int)  :::::  0.....n : ekleme çıkarma


  componentDidMount() {
    this.getItemList();
    window.scrollTo(0, 0)
  }

  getItemList = async (date = undefined) => {
    this.props.HomeStore.getLogItems(date);

    // V0 eksi
    // this.setState({ loading: true })
    // try {
    //   let serverData = await this.props.GeneralStore.getLogs(date);
    //   console.log(_.get(serverData, "data.results"))
    //   this.setState({
    //     data: _.get(serverData, "data.results")
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
    // finally {
    //   this.setState({ loading: false })
    // }

    // 
  }


  onChangeRangePicker = (dates, dateStrings) => {
    // dateStrings
    console.log({ dates, dateStrings })

    if (!isNaN(new Date(dateStrings[0])) && !isNaN(new Date(dateStrings[1]))) {
      this.getItemList(dateStrings)
    }
    else {
      this.getItemList()
    }

  }

  onChangelogsType(e) {
    this.setState({ logsType: e }, () => {
      this.getItemList()
    })
  }

  render() {
    //değişkenler vs

    const data = this.props.HomeStore.logList
    const loading = this.props.HomeStore.loading

    const columns = [
      { title: 'Başlık', dataIndex: 'title', key: 'title' },
      {
        title: 'Açıklama',
        key: 'detail',
        render: (text, record) =>
          <span>
            <Text type="secondary">{record.description}</Text>
          </span>
      },
      {
        title: 'Zaman',
        key: 'date',
        render: (text, record) =>
          <span>
            <Tooltip title={new Date(record.created).toLocaleDateString()}>
              {moment(record.created).fromNow()}
            </Tooltip>
          </span>

      },
      {
        title: '',
        key: 'action',
        render: (text, record) =>
          <span>
            {(record.subType == 0 || record.subType == 1) && <Tag color={record.subType == 0 ? 'red' : 'green'}>{record.subType == 1 ? <PlusOutlined /> : <MinusOutlined />}</Tag>}
          </span>
      },
    ];


    return (
      <div>
        <Card
          title={
            <PageHeader
              title="Hareketler"
              subTitle={data.length}
              extra={
                <span>
                  <Select size={"small"} style={{ marginRight: 10, minWidth: 120 }} value={this.state.logsType} onChange={(e) => this.onChangelogsType(e)} >
                    {
                      this.state.logsTypes.map((v, i) => {
                        return (
                          <Option key={i} value={v.i}>{v.title}</Option>
                        )
                      })
                    }
                  </Select>
                  <RangePicker
                    size="small"
                    style={{ marginRight: 10 }}
                    onChange={(dates, dateString) => this.onChangeRangePicker(dates, dateString)}
                  />
                  <Button
                    size="small"
                    type='ghost'
                    shape='circle-outline'
                    icon={<ReloadOutlined />}
                    onClick={() => this.setState({ logsType: 10 }, () => {
                      this.getItemList()
                    })}
                  />
                </span>
              }
            />
          }
          bordered={false}

        >
          <Table
            rowKey={'_id'}
            loading={loading}
            columns={columns}
            pagination={{ pageSize: 100 }}
            dataSource={data}
            size='small'
            bordered={false}
          />

        </Card>

      </div>
    )
  }
}