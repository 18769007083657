
import { observable, action, computed, toJS, autorun, runInAction, intercept } from 'mobx';

import _, { reject } from "lodash"


import axios from 'axios';

import { API_URL } from '../config'
import { companycode, getToken } from "../services/Auth";
import OrderService from "../services/Order"

export default class Order {

    constructor() {
        //    this.init();
        autorun(() => {
            //  this.init();
            //#	console.log(`User token : ${this.token}  `);
            this.data = this.data
        })
    }

    @observable loading = false
    @observable data = []
    @observable newNotification = ''
    @observable isChanged = false
    @observable orderStatusList = [
        { title: "Yeni", key: "new" },
        { title: "Kabul Edilen", key: "accept" },
        { title: "İptal Edilen", key: "reject" },
    ]
    @observable statusKitchens = [
        { title: 'Beklemede', i: 0, key: 'noproccess' },
        { title: 'Hazırlanıyor', i: 1, key: 'waiting' },
        { title: 'Hazırlandı', i: 2, key: 'prepared', },
    ]
    @observable statusWaiters = [
        { title: 'Beklemede', i: 0, key: 'noproccess' },
        { title: 'Çağrıldı', i: 1, key: 'callWaiter' },
        { title: 'Tamamlandı', i: 2, key: 'completed', },
    ]
    @observable totalPrice = 0;



    //sipariş durum server
    @action
    onChangeStatus = async (data) => {
        console.log("!onChangeStatus!", { data })
        try {
            const serverData = (await new OrderService(getToken).setOrderStatus(data)).data;

            let finded = _.find(this.data, (o) => {
                return o._id == _.get(data, "_id")
            })
            console.log("!onChangeStatus!", { data, serverData, finded })
            if (finded && data.key == "accept") {
                finded.reject = false
                finded.accept = true
            }
            if (finded && data.key == "reject") {
                finded.reject = true
                finded.accept = false
            }
            if (finded && (data.key == "statusKitchen" || data.key == "statusWaiter")) {
                finded[data.key] = data.value
                const setData = {
                    [data.key]: data.value
                }
            }
            this.data = JSON.parse(JSON.stringify(this.data))
            return Promise.resolve(this.data)
        } catch (error) {
            console.log("[ERROR]", error)
            return Promise.reject(error)
        }
    }

    @action
    onChangeStatusFromSocket = ({ id, key, value }) => {

        ///güncelle
        const finded = _.find(this.data, (o) => {
            return o._id == id
        })
        console.log("!!!!!:onChangeStatusFromSocket", { id, key, value, f: toJS(finded) })
        if (finded) {
            finded[key] = value

        }
        if (finded && key == "accept") {
            finded.reject = false
            finded.accept = true
        }
        if (finded && key == "reject") {
            finded.reject = true
            finded.accept = false
        }
        if (finded && key == "done") {
            finded.done = value
        }
        this.data = JSON.parse(JSON.stringify(this.data))
    }

    //qr id ye göre toplam fiyat bilgisi
    @action
    renderTotalPriceWithQrId = (qrId) => {
        let tPrice = 0;
        _.filter(this.data, (o) => {
            return o.qrId == qrId
        }).map(v => {
            tPrice = tPrice + this.getBasketTotalSum(v.items)
        })
        this.totalPrice = tPrice
        return tPrice
    }

    //yeni sipariş geldiğinde (socket için)
    @action async pushOrder(item) {
        this.data.push(item)
        this.data = _.orderBy(this.data, ['created'], ['desc'])
        return item
    }

    //yeni sipariş geldiğinde (socket için)
    @action async deleteOrderTemp(item) {
        const findedIndex = _.find(this.data, (o) => {
            return o.id = "temp"
        })
        this.data.splice(findedIndex, 1);
        //  this.data = _.orderBy(this.data, ['created'], ['desc'])
        return item
    }


    //#liste
    @action
    getItemList = (query) => {
        try {
            let url = `${API_URL}/order`;
            const { from, to } = query || {}
            if (from && to) {
                url = `${API_URL}/order?from=${from}&to=${to}`;
            }
            axios.get(url, this.getHeader()).then((res) => {
                const items = _.get(res, "data")
                // this.data = items

                runInAction(() => {
                    //  Object.assign(store.observableObject, response.data);
                    this.data = items
                });

                console.log("Order store getItemList", items)
            })

        } catch (error) {
            console.log(error)
        }
    }
    //#liste
    @action
    getItemListForStaff = () => {
        try {
            let url = `${API_URL}/admin/staff/order`;
            axios.get(url, this.getHeader()).then((res) => {
                const items = _.get(res, "data")
                // this.data = items

                runInAction(() => {
                    //  Object.assign(store.observableObject, response.data);
                    this.data = items
                });

                console.log("Order store getItemList", items)
            })

        } catch (error) {
            console.log(error)
        }
    }

        //#liste by desk ID
        @action
        getItemListForStaffByDeskId = async (deskId) => {
            try {
                let url = `${API_URL}/admin/staff/order/by-desk/${deskId}`;
                const serverData =await axios.get(url, this.getHeader())
                    console.log("Order store getItemListForStaffByDeskId", serverData.data)
            } catch (error) {
                console.log(error)
            }
        }

    // siparişi gönder
    @action
    createOrderStaff = async (postData) => {
        this.loading = true;
        try {
            const service = await new OrderService(getToken, companycode)
            const serverData = (await service.createOrderStaff(postData)).data
            console.log("!createOrderStaff:store:!", serverData)
            return Promise.resolve(serverData)
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        } finally {
            this.loading = false;
        }
    }


    @action async getItemListAll(query = "") {
        const url = `${API_URL}/order?${query}`;
        return axios.get(url, this.getHeader())
    }

    //qr id ye göre silme işlemi
    @action async deleteOrderByQrId(qrId) {
        /*
                const filtered = _.filter(this.data, (o) => {
                    return o.qrId = qrId
                })
                filtered.map((v, i) => {
                    const fIndex = _.findIndex(filtered, (o) => {
                        return o._id = v._id
                    })
                    this.data.splice(fIndex, 1);
                })
                */
        const fIndex = _.findIndex(filtered, (o) => {
            return o._id = v._id
        })
        this.data.splice(0, 1);
        this.data = this.data
        return
    }

    //id ye göre sil
    @action async deleteOrderById(_id) {
        const fIndex = _.findIndex(this.data, { _id: _id })
        this.data.splice(fIndex, 1);
        this.data = this.data
        return this.data
    }

    //sipariş tamamlandı
    @action async setCompleted(_id, qrId) {
        const finded = _.find(this.data, (o) => {
            return o._id == _id
        })
        if (finded) {
            finded.completed = true
        }

        return
    }

    //  //sipariş durumunu güncelleme işlemi
    //  @action async onChangeStatus({ e, item, type }) {

    //      //  item = toJS(item)
    //      const finded = _.find(this.data, (o) => {
    //          return o._id == item._id
    //      })
    //      console.log("onChangeStatus", finded)
    //      if (finded) {
    //          finded[type] = e

    //          // this.data=this.data

    //          //server işlemi
    //          /*
    //          this.onChangeStatusServer({e,item,type}).then((res)=>{
    //              console.log("başarılı onChangeStatusServer",res)
    //          }).catch((err)=>{
    //              console.error(err)
    //          })
    //          */
    //      }
    //      return

    //  }

    //   //sipariş durum server
    //   @action async onChangeStatusServer(data) {
    //       const url = `${API_URL}/order/status`;
    //       return axios.post(url, data, this.getHeader())
    //   }
    //
    //sipariş KAPAT server
    @action
    setCompletedServer = async (qrId) => {
        try {
            const serverData = (await new OrderService(getToken).setCompletedServer({ qrId })).data;
            console.log({ serverData })
            this.data = _.filter(this.data, (o) => {
                return o.qrId != qrId
            })
        } catch (error) {
            console.log(error)
        }

        //   const url = `${API_URL}/order/completed`;
        //   return axios.post(url, { qrId }, this.getHeader())
    }


    //siparişleri getir
    @action async get() {
        //   let url = `${API_URL}/order`;
        const url = `${API_URL}/order`;
        return axios.get(url, this.getHeader());

    }

    @computed get getThisData() {
        return this.data
    }

    //siparişteki toplam tutarı getir
    @action getBasketTotalSum(items) {
        let totalPrice = _.sumBy(items, (o) => {
            return o.price * o.count;
        })
        return totalPrice
    }


    //garson çağırma yaşandığında ilgili qr kod durumunu güncelle
    @action async setIsCallStatus(qrId, isCall = true) {
        const finded = _.find(this.data, { qrId: qrId })
        console.log("Order Store : setIsCallStatus", { qrId, isCall, finded })
        if (finded) {
            finded.isCall = isCall

            finded.id = new Date().getTime()

            //   abc.push({})
            //  this.isChanged=!this.isChanged
            //  this.data.splice(0, 1);
            //   Object.assign(finded,{isCall:isCall})
            runInAction(() => {
                this.data = this.data
            })

            console.log("finded", toJS(this.data))
        }

        //   return this.data
        /*

      //  this.data=[]
        
        //  this.init()
        //    const finded = _.find(this.getThisData,{qrId:qrId})
        const myJs = toJS(this.data)
        const finded = _.find(myJs, (o) => {
            return o.qrId == qrId
        })
        console.log("setIsCallStatus", {
            qrId, finded, data: this.data, myJs,
            length: this.data.length
        })
        if (finded) {
            finded.isCall = isCall
            this.data = this.data
        }
        return this.data
        */

    }





    // TOKEN İŞLEMLERİ


    @action async init() {
        const token = localStorage.getItem('token');
        this.token = token;
        if (this.data.length == 0) {
            this.getItemList()
        }
        return token;

    }

    getHeader() {
        return { headers: { Authorization: localStorage.getItem('token'), 'Content-Type': 'application/json' } }
    }


}
