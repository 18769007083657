

import React, { Component } from 'react';
import {
    message,
    Table,
    Select,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag

} from 'antd';

import { Link } from 'react-router-dom';

import { PlusOutlined, DeleteOutlined, ReloadOutlined, UploadOutlined, InfoOutlined } from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import axios from "axios"

import _ from "lodash"


import { cdnConfig, cdnUrl } from '../../config';

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;



// 
@inject('HomeStore')
@observer
class MasterUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            categoryList: [],
            file: null,
            avatar: null,
            title: '',
            description: '',
            _id: '',
            modalOpenCreate: false
        }
    }

    componentDidMount = () => {
        this.getItemList()
    }

    //#liste
    getItemList = async () => {
        this.props.HomeStore.getUsersForMaster();

        const {
            restaurantList
        } = this.props.HomeStore
        if (restaurantList.length == 0) {
            this.props.HomeStore.getRestaurantList();
        }
    }

    resetState() {
        this.setState({
            id: '',
            _id: '',
            title: '',
            description: '',
            modalOpenCreate: false,
            file: null,
            avatar: null,
        })
    }

    reload = () => {
        this.getItemList()
    }

    setNewPassword = () => {
        this.props.HomeStore.updateUser(this.state._id, { type: "set-password", newPassword: this.state.newPassword })
    }
    onChangeSwitchList = async (active, item) => {
        this.props.HomeStore.updateUser(item._id, { type: "active", active })
    }


    createRestaurantFromUser = async () => {
        const data = _.get(this.state, "company", {})
        data._id = this.state.apikey
        data.title = _.get(this.state, "company.name", this.state.name)
        data.userIds = [this.state._id]
        await this.props.HomeStore.createRestaurant(data)
        this.resetState()
    }


    //#ekle
    handleCreate = async () => {
        const {
            title,
            address,
            phone,
            description,
            fileCoverImage,
            fileAvatar,
            rank,
            _id,
            avatar,
            coverImage,
            userIds
        } = this.state

        console.log("handleCreate:state", this.state)
        if (!title) {
            message.error("Başlık zorunlu")
            return
        }

        let data = {
            title,
            address,
            phone,
            description,
            fileCoverImage,
            fileAvatar,
            userIds,
        }

        //yeni kayıt//////////////
        if (!_id) {
            await this.props.HomeStore.createRestaurant(data)
            this.resetState()
        }
        ///////Güncelleme//////////////////
        else {
            data.rank = rank
            data.avatar = avatar
            data.coverImage = coverImage
            await this.props.HomeStore.updateRestaurant(_id, data)
            this.resetState()
        }
    }

    //#sil
    confirmDelete = async (item) => {
        console.log(item)
        try {
            const serverData = await this.props.HomeStore.deleteCategory(item._id)
            console.log(serverData)
            this.state.data = _.remove(this.state.data, (o) => {
                return o._id != item._id
            })
            //  console.log({items})
            //  const dd =[]
            //  dd.splice(1)
            this.setState({ data: this.state.data })

        } catch (error) {
            console.log(error)
        }

    }

    //düzenle click
    clickEdit = (item) => {
        //   this.titleInput.focus()
        console.log(item)
        this.setState({
            ...item
        }, () => {
            this.setState({ modalOpenCreate: true })
        })
    }



    handleCancel() {
        this.resetState()
    }



    handleChangeImage = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                    //  avatar:imageUrl  //SONRA
                }),
            );
            // this.handleSubmit()
        }
        //   console.log(info)
        console.log(info.file.response.urly)
        this.setState({
            avatar: info.file.response.urly
        })

    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }


    uploadFile = (file) => {
        console.log(' eski file', file)

    }



    onChangeImage = (e) => {
        console.log(e)
        console.log({ file: e.target.files })
        let file = e.target.files[0]

        this.setState({
            file: file
        })

        this.getBase64(file, (img) => {
            this.setState({
                avatar: img
            })
        })

    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }


    updateState = (state, _id) => {
        console.log("updateState", { state, _id })
    }

    render() {
        const {
            masterUsers: data,
            restaurantList,
            loading
        } = this.props.HomeStore


        const handleFocus = (event) => event.target.select();

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16, offset: 0 },
            },
        };


        const columns = [
            {
                title: '',
                key: 'avatar',
                render: (text, record) =>
                    <span>
                        {
                            record.avatar ? <Avatar shape="square" src={record.avatar} /> : <Avatar shape="square">{_.get(record, "name", "").charAt(0)}</Avatar>
                        }
                    </span>
            },
            {
                title: 'İsim',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
            },
            {
                title: 'Kullanıcı Adı',
                key: 'username',
                dataIndex: 'username',
            },
            {
                title: 'Oluşturma',
                key: 'created',
                render: (text, record) => <span>
                    {new Date(record.created).toLocaleDateString()}
                </span>
            },
            {
                title: 'Durum',
                key: 'active',
                render: (text, record) => <span>

                    {
                        _.includes(restaurantList.map(v => v._id), record.apikey)
                        &&
                        <Tag color="success">EKLENDİ</Tag>
                    }


                    {
                        record.channel === "google"
                        &&
                        <Tag color="orange">GOOGLE</Tag>
                    }

                    {
                        record.channel === "facebook"
                        &&
                        <Tag color="purple">FACEBOOK</Tag>
                    }
                    <Switch size={'small'} onChange={(e) => this.onChangeSwitchList(e, record)} defaultChecked={record.active} />
                </span>
            },
            {
                title: 'İşlem', key: 'action', render: (record) => {
                    return (
                        <span>
                            <Button onClick={() => {
                                this.clickEdit(record)
                            }} size="small">
                                Detay
                            </Button>
                            <Divider type="vertical" />
                            <Popconfirm okText="Evet" cancelText="Hayır" title={`Emin misiniz?`}
                                onConfirm={() => { this.confirmDelete(record) }}>
                                <Button type="danger" size="small">
                                    Sil
                                </Button>
                            </Popconfirm>
                        </span>
                    )
                }
            },
        ];



        return (
            <div>
                <Card
                    loading={loading}
                    title={'Kullanıcı Listesi'}

                    extra={<span>
                        <Button type="primary"
                            shape="circle"
                            icon={<ReloadOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => this.reload()} />
                    </span>}
                >


                    <div>

                        <Table
                            rowKey={'_id'}
                            columns={columns}
                            pagination={false}
                            size="small"
                            dataSource={data}
                            loading={loading}
                        />
                    </div>

                </Card>


                <Drawer
                    title={this.state._id ? "Düzenle" : "Yeni"}
                    placement="left"
                    width={720}
                    visible={this.state.modalOpenCreate}
                    onClose={(e) => this.handleCancel(e)}
                    bodyStyle={{ paddingBottom: 80 }}
                >

                    <Form {...formItemLayout}>
                        <Form.Item
                            label="İsim"
                        >
                            <Input
                                value={this.state.name}
                                onChange={(e) => this.onChange(e)}
                                name="name" />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                        >
                            <Input
                                value={this.state.email}
                                onChange={(e) => this.onChange(e)}
                                name="email" />
                        </Form.Item>

                        {
                            !_.includes(restaurantList.map(v => v._id), this.state.apikey)
                            &&
                            <Form.Item
                                label="Yetkilendir"
                            >
                                <Button block onClick={() => this.createRestaurantFromUser()} type="primary">
                                    Restoranı Sisteme Ekle
                                </Button>
                            </Form.Item>
                        }

                        {
                            this.state._id
                            &&
                            <Form.Item
                                label="Şifre"
                            >
                                <Input
                                    value={this.state.newPassword}
                                    onChange={(e) => this.onChange(e)}
                                    name="newPassword"
                                    placeholder="Yeni Şifre" />
                                <Button block onClick={() => this.setNewPassword()}>
                                    Şifreyi güncelle
                                </Button>
                            </Form.Item>
                        }


                    </Form>

                    <div
                        style={{
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 0',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
                            Kapat
                        </Button>

                    </div>
                </Drawer>

            </div>
        )
    }
}

export default MasterUsers