
import React from 'react'
import { Typography } from 'antd'


import QrCode from './QrCode'
import { siteInfo } from '../config'

const { Text } = Typography

export default function HomeLogo({
    title = "Restaurant Sistemleri & Yazılımları"
}) {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <QrCode text={siteInfo.clientUrl} bgColor="#FFFFFF" fgColor={"#ff3f34"} width={46} />
                <h1 style={{ marginBottom: 0, marginLeft: 5 }}>
                    <span style={{ color: '#c23616' }}>
                        {siteInfo.appFirstName}
                    </span>
                    <span style={{ color: 'rgb(47, 54, 64)' }}>
                        {siteInfo.appSecondName}
                    </span>
                </h1>

            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <Text type="secondary">{title}</Text>
                </div>
            </div>
        </div>
    )
}