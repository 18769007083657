

import React from 'react';

import CompanyBasket from '../components/CompanyBasket'

export default ({ match }) => {
    console.log("companykey:CompanyBasket:page", match.params.companykey)
    return (
        <CompanyBasket
            companykey={match.params.companykey}
        />
    )
}