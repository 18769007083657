


class API {
  static baseURL = 'http://localhost:3001'

  static endpoints = {
    rides: 'rides',
    ride: 'ride/{id}',
  }

  static headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  static getRides() {
    const url = `${this.baseURL}/${this.endpoints.rides}`;
    return fetch(url);
  }

  static getRide(id) {
    const endpoint = this.endpoints.ride.replace('{id}', id);
    const url = `${this.baseURL}/${endpoint}`;

    return fetch(url);
  }
}

export default API;