import React, { Component } from "react";

import {
    Form,
    Select,
    InputNumber,
    Switch,
    Radio,
    Slider,
    Button,
    Upload,
    Rate,
    Checkbox,
    Row,
    Col,
    Tooltip
} from 'antd';
import {
    UploadOutlined,
    InboxOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';

import { inject, observer } from 'mobx-react';
import { toJS } from "mobx";

const { Option } = Select;




@inject('UserStore')
@observer
export default class Settings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            search: '',
            activeTabsKey: 'user',
            loading: false,
            hasMore: true,
            limit: 20,
            offset: 0
        }
    }




    onClickVisiblePrice = async (e) => {
        try {
            const serverData = await this.props.UserStore.setPriceVisible(e);
            console.log(serverData)
        } catch (error) {
            console.log(error)
        }
    }

    render() {

        const formItemLayout = {
            labelCol: {
                span: 6,
            },
            wrapperCol: {
                span: 14,
            },
        };

        const normFile = (e) => {
            console.log('Upload event:', e);

            if (Array.isArray(e)) {
                return e;
            }

            return e && e.fileList;
        };


        const onFinish = (values) => {
            console.log('Received values of form: ', values);
        };

        const { loading, user } = this.props.UserStore

        console.log(toJS(user))


        return (
            <div>
                <div>
                    1- Bildirim sesi
                    2- Firma ayarları
                    3- personel ayarları

                </div>

                <Form
                    name="validate_other"
                    {...formItemLayout}
                    onFinish={onFinish}
                    initialValues={{
                        'input-number': 3,
                        'checkbox-group': ['A', 'B'],
                        rate: 3.5,
                    }}
                >
                    <Form.Item name="switch" label={<span>
                        Fiyat Görünürlüğü&nbsp;
                        <Tooltip title="Kullanıcılar ürünlerinizi listelerken ürün fiyatını görür veya görmez">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>} valuePropName="checked">
                        <Switch onChange={(e) => this.onClickVisiblePrice(e)}
                            defaultChecked={_.get(user, "company.visiblePrice")}
                        />
                    </Form.Item>

                    <Form.Item
                        name="select"
                        label="Select"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please select your country!',
                            },
                        ]}
                    >
                        <Select placeholder="Please select a country">
                            <Option value="china">China</Option>
                            <Option value="usa">U.S.A</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="select-multiple"
                        label="Select[multiple]"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your favourite colors!',
                                type: 'array',
                            },
                        ]}
                    >
                        <Select mode="multiple" placeholder="Please select favourite colors">
                            <Option value="red">Red</Option>
                            <Option value="green">Green</Option>
                            <Option value="blue">Blue</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="InputNumber">
                        <Form.Item name="input-number" noStyle>
                            <InputNumber min={1} max={10} />
                        </Form.Item>
                        <span className="ant-form-text"> machines</span>
                    </Form.Item>

                    <Form.Item name="switch" label="Switch" valuePropName="checked">
                        <Switch />
                    </Form.Item>

                    <Form.Item name="slider" label="Slider">
                        <Slider
                            marks={{
                                0: 'A',
                                20: 'B',
                                40: 'C',
                                60: 'D',
                                80: 'E',
                                100: 'F',
                            }}
                        />
                    </Form.Item>

                    <Form.Item name="radio-group" label="Radio.Group">
                        <Radio.Group>
                            <Radio value="a">item 1</Radio>
                            <Radio value="b">item 2</Radio>
                            <Radio value="c">item 3</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="radio-button"
                        label="Radio.Button"
                        rules={[
                            {
                                required: true,
                                message: 'Please pick an item!',
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio.Button value="a">item 1</Radio.Button>
                            <Radio.Button value="b">item 2</Radio.Button>
                            <Radio.Button value="c">item 3</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="checkbox-group" label="Checkbox.Group">
                        <Checkbox.Group>
                            <Row>
                                <Col span={8}>
                                    <Checkbox
                                        value="A"
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                    >
                                        A
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox
                                        value="B"
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        disabled
                                    >
                                        B
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox
                                        value="C"
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                    >
                                        C
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox
                                        value="D"
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                    >
                                        D
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox
                                        value="E"
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                    >
                                        E
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox
                                        value="F"
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                    >
                                        F
                                    </Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item name="rate" label="Rate">
                        <Rate />
                    </Form.Item>

                    <Form.Item
                        name="upload"
                        label="Upload"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        extra="longgggggggggggggggggggggggggggggggggg"
                    >
                        <Upload name="logo" action="/upload.do" listType="picture">
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item label="Dragger">
                        <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                            <Upload.Dragger name="files" action="/upload.do">
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>


            </div>
        )
    }

}



/*


  <div style={{ padding: '0 50px', paddingTop: 20 }}>
                        <Descriptions title="Firma Bilgileri">
                            <Descriptions.Item>
                                {
                                    editCompany
                                        ?
                                        <Button onClick={() => this.onSubmit()} type="primary">
                                            Kaydet
                                        </Button>
                                        :
                                        <Button onClick={() => this.setState({ editCompany: true })}>
                                            Düzenle
                                        </Button>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Logo">
                                {
                                    editCompany
                                        ?
                                        <input name="companyLogo" onChange={(e) => this.onChangeImage(e)} accept="image/*" type="file" placeholder="Logo yükle" />
                                        :
                                        <Avatar src={this.state.companyLogo} />
                                }

                            </Descriptions.Item>
                            <Descriptions.Item label="Firma Adı">
                                {
                                    editCompany
                                        ?
                                        <Input value={_.get(this.state, "companyName", "")} name="companyName" onChange={(e) => this.onChange(e)} />
                                        :
                                        _.get(this.state, "companyName", "")
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Adres">
                                {
                                    editCompany
                                        ?
                                        <TextArea value={_.get(this.state, "companyAddress", "")} name="companyAddress" onChange={(e) => this.onChange(e)} />
                                        :
                                        _.get(this.state, "companyAddress", "")
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Telefon">
                                {
                                    editCompany
                                        ?
                                        <Input value={_.get(this.state, "companyPhone", "")} name="companyPhone" onChange={(e) => this.onChange(e)} />
                                        :
                                        _.get(this.state, "companyPhone", "")
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </div>

                    <div style={{ padding: '0 50px', paddingTop: 20 }}>
                        <Descriptions title="Genel Ayarlar">
                            <Descriptions.Item label={
                                <span>
                                    Fiyat Görünürlüğü&nbsp;
                                    <Tooltip title="Kullanıcılar ürünlerinizi listelerken ürün fiyatını görür veya görmez">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }>
                                <Button onClick={() => this.onClickVisiblePrice()}>
                                    {
                                        this.state.visiblePrice ? "Göster" : "Gizle"
                                    }
                                </Button>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>



                    */