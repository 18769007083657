
import React from "react";
import { Form, Spin, Input, Button, message, Checkbox, Row } from 'antd';
import { UserOutlined, LockOutlined, GoogleCircleFilled, GoogleOutlined, GooglePlusCircleFilled } from '@ant-design/icons';



export default ({
    onChange = () => { },
    onSubmit = () => { },
    t = () => { },
    formtype = 'login',
    loading = false,
    email,
    password
}) => {
    return (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={() => onSubmit()}
        >

            <Input
                name="email"
                autoFocus={true}
                defaultValue={email}
                placeholder={formtype == "login" ? t("usernameOrMail") : t("email")}
                onChange={(e) => onChange(e)}
                prefix={<UserOutlined />}
                style={{ marginBottom: 10 }}
            />

            {
                (formtype == "login")
                &&
                <Form.Item>
                    <Input
                        name="password"
                        defaultValue={password}
                        placeholder={t("password")}
                        type="password"
                        onChange={(e) => onChange(e)}
                        onPressEnter={() => onSubmit()}
                        style={{ marginBottom: 10 }}
                        prefix={<LockOutlined />}
                    />
                </Form.Item>
            }

            <Form.Item>
                <Button
                    block={true}
                    type="primary"
                    onClick={() => onSubmit()}
                    disabled={loading}
                    loading={loading}
                >
                    {
                        formtype == "login"
                            ?
                            t("login")
                            :
                            t("resetPassword")
                    }
                </Button>
            </Form.Item>
        </Form>
    )
}
