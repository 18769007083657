import React,{ Component } from "react";
import {Link,} from 'react-router-dom'; // Link,
import { Tabs, Table, Divider, PageHeader,Menu,Select,Popconfirm, Typography, Row, Col, Button, Collapse, Drawer,Form,Checkbox,Input,Spin, message, List, Card,Avatar, Tooltip, Dropdown } from 'antd';

import _ from 'lodash';

import { FilterOutlined, PlusOutlined, ReloadOutlined,QrcodeOutlined, DeleteOutlined, ArrowLeftOutlined, CloseOutlined,SaveOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';

import {goPage, goBack} from '../historyConfig'

import {withTranslation} from 'react-i18next'


const {TextArea} = Input;
const { TabPane } = Tabs;
const {Option} = Select
const { Panel } = Collapse;
const { Text } = Typography;

@inject('QrCodeStore','GeneralStore')
 class Intent extends Component{
constructor(props){
  super(props)

  this.state = {
    data:[],
    loading: false,
    deskAreas:[],
    modalOpenCreate:false,
    areaName:'',
    areaId:null,
    showFilter:false
  }
}


componentDidMount=async()=>{
    window.scrollTo(0, 0)
    this.getItemList()
}

initdeskArees=()=>{
    const {deskAreas} = this.state
    if(deskAreas.length==0){
        this.getIDeskAreaList()
    }
}

getItemList=async(filter=null)=>{
    this.setState({loading:true})
    try {
        let serverData = await this.props.GeneralStore.getDesks();
        console.log(_.get(serverData,"data.results"))
        let items =  _.get(serverData,"data.results")
        this.setState({
            data: filter ? _.filter(items,{areaId:filter}) : items
        })
        if(!filter) this.setState({areaId:null})
    } catch (error) {
       console.log(error) 
    }
    finally{
      //  this.initdeskArees()
        this.setState({loading:false})
    }
}

getIDeskAreaList=async()=>{
    try {
        let serverData = await this.props.GeneralStore.getDeskArea();
        console.log(_.get(serverData,"data.results"))
        this.setState({
            deskAreas: _.get(serverData,"data.results")
        })
    } catch (error) {
        console.log(error)
    }
}




resetState(){
    this.setState({
        _id:'',
        title:'',
        modalOpenCreate:false,
        areaName:''
      })
}

    //#ekle
    handleCreate=async()=>{
        const {title,areaId,_id} = this.state
        if(!title){
            message.error("Masa numarası")
            return
        }

          let data = {
            title,
            areaId,
        }
              
        //yeni kayıt//////////////
        if(!_id){
            try {
               const serverData = await this.props.GeneralStore.insertDesk(data)
               const item = _.get(serverData,"data.result")
               item.area =_.find(this.state.deskAreas,(o)=>{
                   return o._id==item.areaId
               })
             this.setState({data:[item,...this.state.data]})
               this.resetState()
               message.success()
            } catch (error) {
                message.error()
                console.log(error)
            }

        }
        ///////Güncelleme//////////////////
        else{
          try {
              data._id = _id
            const serverData = await this.props.GeneralStore.updateDesk(data)
            let finded = _.find(this.state.data,(o)=>{
              return o._id==_id
            })
            if(finded){
              finded.title = title
              finded.areaId = areaId
              finded.area =_.find(this.state.deskAreas,(o)=>{
                return o._id==areaId
            })
              this.setState({data:this.state.data})
            }
            this.resetState()
            message.success()
          } catch (error) {
              console.log(error)
          }

        }
    }

    //#sil
    confirmDelete=async(item)=>{
        console.log(item)
        try {
            const serverData = await this.props.GeneralStore.deleteDesk(item)
            console.log(serverData)
            this.state.data = _.remove(this.state.data,(o)=>{
                return o._id!=item._id
            })
          //  console.log({items})
          //  const dd =[]
          //  dd.splice(1)
            this.setState({data:this.state.data})
            
        } catch (error) {
            console.log(error)
        }

    }

handleCancel=(e)=>{
    this.resetState()
}
onChange(e){
    this.setState({[e.target.name]:e.target.value})
}

addAreaItem=()=>{

        let toData = {
            title:this.state.areaName
        }
         this.props.GeneralStore.insertDeskArea(toData).then((response)=>{
             const item = _.get(response,"data.result")
             console.log({response,item})
            this.setState({
                deskAreas: [...this.state.deskAreas,item]
            },()=>{
                this.setState({areaId : item._id,areaName:''})
                message.info("Masa bölgesi eklendi ve seçili hale getirildi")
            })
         }) 
}

onKeyPressEnterArea(event){
    if(event.key === 'Enter'){
      this.addAreaItem()
    }
  }

onClickFilter=()=>{
    this.initdeskArees()
    this.setState({showFilter:!this.state.showFilter})
}

onChangeArea=(e)=>{
    this.setState({areaId:e})
    this.getItemList(e)
    /*
    this.setState({
        areaId:e
    },()=>{
        this.setState({
            data:_.filter(this.state.data,{areaId:e})
        })
    })
    */
}

onClickNew=()=>{
    this.setState({ modalOpenCreate: true,modalTitle:"Yeni"})
    this.initdeskArees()
}

onClickDeleteDeskArea=async(item)=>{
    console.log(item)
    try {
       const resServer = await this.props.GeneralStore.deleteDeskArea(item)
       const outDeleted  = _.remove(this.state.deskAreas,(o)=>{
                                return item._id!=o._id
                            })
       this.setState({deskAreas:outDeleted,areaId:null})
    } catch (error) {
        console.log(error)
    }
}

    //düzenle click
    clickEdit=(item)=>{
        this.getIDeskAreaList()
        this.setState({
            ...item
        },()=>{
            this.setState({modalOpenCreate:true})
        })
    }

    

render(){
    const { t } = this.props;
    const { loading,data,areaName,showFilter } = this.state;



    const columns = [
        {
          title: 'Masa Numarası',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Masa Bölgesi',
          key: 'description',
          render: (text,record)=><span>
              <Text type="warning" >{_.get(record,"area.title")}</Text>
          </span>
        },
        {
        title: 'Eklenme Tarihi',
        key: 'created',
        render:(text,record)=><span>
             {new Date(record.created).toLocaleDateString()}
        </span>
        },
        {title: 'İşlem', key: 'action', render: (record) => {
              return (
                  <span>
                      <Link to={`/qrcode?table=${record._id}`}>
                          <Button  size="small" type="primary" icon={<QrcodeOutlined/>}/>
                      </Link>
                      <Divider type="vertical"/>
                        <Button onClick={()=>this.clickEdit(record)} size="small">
                        Düzenle 
                        </Button>
                  <Divider type="vertical"/>
                      <Popconfirm okText="Evet" cancelText="Hayır" title={`Emin misiniz?`}
                                  onConfirm={()=>{this.confirmDelete(record)}}>
                            <Button type="danger" size="small">
                            Sil 
                        </Button>
                      </Popconfirm>
                  </span>
                  )
              }
        },
      ];

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16,offset: 0 },
        },
      };

      const menuDeskArea=(item)=>(
          <Menu>
                <Menu.Item onClick={(e)=>this.onClickDeleteDeskArea(item)} key="1"><DeleteOutlined/> Sil</Menu.Item>
          </Menu>
      )

  return(
        <div
        style={{
            padding : '10px 50px 10px 50px'
        }}
        >

<PageHeader
   // className="site-page-header"
   style={{
       marginLeft:-20,marginRight:-20
    }}
    onBack={() => goBack()}
    title="Masa"
    subTitle="Masa listesi"
    extra={
    <span>
                      <Button
                      onClick={()=>this.getItemList()}
                      >
                      <ReloadOutlined />
                     </Button>

                      <Button
                      onClick={()=>this.onClickFilter()}
                      >
                      <FilterOutlined />
                     </Button>
                     {
                         showFilter
                         &&
                         <Select 
                         value={this.state.areaId || undefined}
                         onChange={(e)=>this.onChangeArea(e)} 
                         name="categoryId" 
                        // style={{ width: 240 }}
                         placeholder="Bögeye göre filtreleyin">
                                {
                                  this.state.deskAreas.map((v,i)=>{
                                    return(
                                        <Option key={v._id} value={v._id}>{v.title}</Option>
                                    )
                                  })
                                }
                         </Select>
                     }
                        <Button
                        title={t("addMenu")}
                        type="primary"
                      //  onClick={()=>this.onClickCardPlus()}
                        onClick={() =>this.onClickNew()}
                        >
                            <PlusOutlined />
                    </Button>
      </span>}
    />


    <div>
             <Table
                  rowKey={'_id'}
                  columns={columns} 
                  pagination={{ pageSize: 50 }}
                  size="small"
                  dataSource={data} 
                  loading ={loading}
                    />
    </div>


    <Drawer
                  title={this.state._id ? "Düzenle" : "Yeni"}
                  placement="left"
                  width={720}
                  visible={this.state.modalOpenCreate}
                  onClose={(e)=>this.handleCancel(e)}
                  bodyStyle={{ paddingBottom: 80 }}
                  >
                  <Form {...formItemLayout}>
                  <Form.Item 
                           label="Bölge"
                         >
                              <Select
                            placeholder="bölge adı"
                            value={this.state.areaId || undefined}
                            onChange={(e)=>this.setState({areaId:e})} 
                            dropdownRender={menu => (
                            <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                <Input 
                                style={{ flex: 'auto' }} 
                                name="areaName" 
                                value={areaName} 
                                onChange={(e)=>this.onChange(e)} 
                                onKeyPress={(event)=>this.onKeyPressEnterArea(event)}
                                />
                                <a
                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                    onClick={()=>this.addAreaItem()}
                                >
                                    <PlusOutlined title="Bölge Ekle"/>
                                </a>
                                </div>
                            </div>
                            )}
                        >
                            {this.state.deskAreas.map(item => (
                            <Option key={_.get(item,"_id")}>
                                <div>
                                    <Dropdown overlay={menuDeskArea(item)} trigger={['contextMenu']}>
                                         <div>
                                         {_.get(item,"title")} 
                                         </div>
                                    </Dropdown>
                                </div>
                                </Option>
                            ))}
                        </Select>
                        </Form.Item>
                        <Form.Item 
                        label="Başlık"
                        required
                        >
                          <Input 
                          value={this.state.title} 
                          onChange={(e)=>this.onChange(e)} 
                          name="title"
                          ref={(Input)=>{this.titleInput=Input}}
                          placeholder="Masa Numarası" />
                        </Form.Item>
                  </Form>
                 
                  <div
                style={{
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 0',
                background: '#fff',
                textAlign: 'right',
                }}
            >
                <Button onClick={(e)=>this.handleCancel(e)} style={{ marginRight: 8 }}>
                İptal
                </Button>
                <Button onClick={()=>this.handleCreate()} type="primary">
                Kaydet
                </Button>
          </div>
        </Drawer>


   
       </div>
  )
}

}

export default withTranslation()(Intent)