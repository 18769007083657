/*
  _____                .___.__                             
  _/ ____\___________  __| _/|__|   ____________ ___________ 
  \   __\/ __ \_  __ \/ __ | |  |  /  _ \___   // __ \_  __ \
   |  | \  ___/|  | \/ /_/ | |  | (  <_> )    /\  ___/|  | \/
   |__|  \___  >__|  \____ | |__|  \____/_____ \\___  >__|   
             \/           \/                  \/    \/       
*/
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./App.css"
//dil
import './i18n'
import App from './App';
//import Main from "./Main"
import './i18n';

ReactDOM.render(
  <App />
  ,
  document.getElementById('root')
);