

import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { inject, observer } from 'mobx-react';



import Header from "./Header";
import Footer from "./Footer"

import Loading from "./Loading"

import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';

import { getDefaultLanguage } from "../config";

import localeEN from 'antd/es/locale/en_US'
import localeTR from 'antd/es/locale/tr_TR'





@inject('UserStore')
@observer
class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount = () => {
        console.log("cmd Container")
        //  this.props.UserStore.getProfile()
    }





    //  getProfile = async () => {
    //      try {
    //          this.setState({ loading: true })
    //          let profile = await this.props.UserStore.getProfile()
    //          /////////// odaya gir
    //          const apikey = _.get(profile, "data.user.apikey")
    //          const token = _.get(profile, "data._id")
    //          //soket odaya koy
    //          //   joinRoom(token,apikey)
    //          this.setState({ logged: true })
    //      } catch (error) {
    //          console.log({ error })
    //          //   this.props.UserStore.removeToken()
    //          // goPage("login")
    //          this.setState({ logged: false })
    //      }
    //      finally {
    //          this.setState({ loading: false })
    //      }
    //  }


    //  (!token || !user) && <Redirect to="/login" />
    render() {
        const mainLocale = getDefaultLanguage() == "en" ? localeEN : localeTR
        const { loading } = this.props.UserStore
        const token = this.props.UserStore.token
        const { user } = this.props.UserStore;
        if (loading) {
            return (<Loading />)
        }
        else {
            return (
                <ConfigProvider locale={mainLocale} >
                    {
                        (!token) && <Redirect to="/login" />
                    }
                    <Header fixed={this.props.headType || undefined} />
                    <div style={{ minHeight: 500 }}>
                        {this.props.children}
                    </div>
                    <Footer />
                </ConfigProvider >
            )
        }
    }


}


export default withTranslation()(Container)