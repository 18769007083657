
import React, { Component } from 'react';
import { Result, Button, Affix, Row, Col, Avatar, Descriptions, message, Input, Tooltip } from 'antd';

import { Link } from 'react-router-dom';

import { WhatsAppOutlined, ArrowLeftOutlined, QuestionCircleOutlined, HomeOutlined, CopyFilled, CopyOutlined } from '@ant-design/icons';

import { goBack } from '../historyConfig'
import { inject, observer } from 'mobx-react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import moment from 'moment'

//dinamik yap
/*
moment.locale=require('moment/locale/tr')
*/


import axios from "axios"
import { cdnUrl, siteInfo } from '../config';

import Footer from '../components/Footer'
import Header from '../components/Header'
import { withTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import QrCode from '../components/QrCode';


// 
@inject('UserStore')
@observer
class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            avatar: "",
            name: '',
            _id: '',
            displayName: "",
            me: false,
            loading: false,
            editCompany: false,
            isCancel: false
        }
    }

    componentWillUnmount = () => {
        this.setState({ isCancel: true })
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0)
        // this.props.UserStore.getProfile()
        //  console.log(this.props)

        try {
            if (this.state.isCancel) return
            const response = (await this.props.UserStore.getProfile()).data
            const company = {
                companyName: _.get(response, "user.company.name"),
                companyPhone: _.get(response, "user.company.phone"),
                companyAddress: _.get(response, "user.company.address"),
                companyLogo: _.get(response, "user.company.logo"),
                visiblePrice: _.get(response, "user.company.visiblePrice"),
            }
            const tName = _.get(response, "user.name", "").split(" ")
            this.setState({
                ...response,
                ...company,
                displayName: tName.length > 1 ? `${tName[0].charAt(0)} ${tName[1].charAt(0)}` : _.get(response, "user.name", "").charAt(0)
            })
            console.log("response", { ...response })
        } catch (error) {
            console.log(error)
        } finally {
            this.setState({ isCancel: false })
        }
    }

    onClickResetPassword = async () => {
        console.log(this.state)
        message.loading()
        const email = _.get(this.state, "user.email")
        try {
            const res = await this.props.UserStore.forgotPassword(email)
            message.success("Şifre Sıfırlama Linki Mail Adresinize Gönderildi!")

        } catch (error) {
            console.log(error)
        }
        finally {
            //  message.destroy()
        }
    }

    onSubmit = async () => {
        const file = this.state.file
        let data = {
            name: this.state.companyName,
            phone: this.state.companyPhone,
            address: this.state.companyAddress,
            visiblePrice: this.state.visiblePrice

        }
        console.log("on submit updateCompany")
        //logo varsa
        if (file) {
            //            logo:this.state.companyLogo,
            let uImage = await this.uploadImage(file)
            console.log({ uImage })
            // const avatarfullpath = _.get(uImage,"data.fullpath")
            //v1 (güncellendi)
            const avatarfullpath = `${cdnUrl}/uploads/images/companylogo/${_.get(uImage, "data.name", "default.png")}`
            data.logo = avatarfullpath
            const serverData = await this.props.UserStore.updateCompany(data);
            console.log(serverData)
            this.setState({ editCompany: false })
        }
        //logo yoksa
        else {
            try {
                //setPriceVisible
                const serverData = await this.props.UserStore.updateCompany(data);
                console.log(serverData)
                this.setState({ editCompany: false })

            } catch (error) {
                console.log(error)
            }
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onClickVisiblePrice = async () => {
        const newVisible = !this.state.visiblePrice
        this.setState({ visiblePrice: newVisible })
        try {
            //setPriceVisible
            const serverData = await this.props.UserStore.setPriceVisible(newVisible);
            console.log(serverData)

        } catch (error) {
            console.log(error)
        }
    }

    uploadImage = (file) => {
        // let file = this.state.file
        let formData = new FormData()

        formData.append("image", file)
        formData.append("ornek", "örnek form içeriği")
        const url = `${cdnUrl}/upload/company-logo`
        const headers = {
            'content-type': 'multipart/form-data',
            token: "token",
            authorization: "tokencontent",
            admin: "ferdiozer",
            username: _.get(this.state, "user.username")
        }
        return axios.post(url, formData, { headers: headers })
    }


    onChangeImage = (e) => {
        let file = e.target.files[0]
        this.setState({
            file: file
        })
        this.getBase64(file, (img) => {
            this.setState({
                companyLogo: img
            })
        })
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    render() {
        const { t } = this.props
        const { loading, user } = this.props.UserStore

        const tName = _.get(user, "name", "").split(" ")
        const displayName = tName.length > 1 ? `${tName[0].charAt(0)} ${tName[1].charAt(0)}` : _.get(user, "name", "").charAt(0)

        return (
            <div>
                <Header />
                <div>

                    <div style={{
                        height: 240,
                        background: 'linear-gradient(87deg,rgba(18, 140, 126,0.6),rgba(18, 140, 126,1),rgba(18, 140, 126, 0.8))',
                    }}>

                        <Row>
                            <Col span={12}>
                                <div
                                    style={{
                                        position: 'relative',
                                        marginLeft: -20,
                                        marginTop: -20,
                                        height: 240,
                                        width: 240,
                                        backgroundColor: 'rgba(255,255,255,0.3)',
                                        borderRadius: 120,
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Avatar
                                        //   height={160}
                                        // width={160}
                                        style={{
                                            borderRadius: '80px',
                                            position: 'absolute', left: '50%', top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            // objectFit :'cover',
                                            boxShadow: '10px 10px 30px',
                                            height: 160,
                                            width: 160,
                                            backgroundColor: "#25D366",
                                            verticalAlign: 'middle',

                                        }}
                                    // src={_.get(this.state,"user.avatar")}
                                    >
                                        <div style={{ marginTop: 60, fontSize: 36 }}> {displayName} </div>
                                    </Avatar>
                                </div>
                            </Col>
                            <Col //style={{paddingTop:20}}
                            >
                                <h1 style={{ color: '#FFF', paddingTop: 50 }}>
                                    {_.get(user, "name")}
                                </h1>
                                <p>
                                    {_.get(user, "username")}
                                    {
                                        //  `${moment(_.get(this.state,"created")).fromNow()} üyeliğiniz sonlanıyor`
                                        //  `${moment(_.get(this.state,"user.packetEndDate")).fromNow()} üyeliğiniz sonlanıyor`
                                        // t('packetEndDate', { date: moment(_.get(this.state, "user.packet.endDate", new Date())).fromNow() })
                                    }
                                </p>

                            </Col>
                        </Row>
                    </div>

                    <Affix offsetTop={0}>
                        <Row style={{ padding: '5px 50px 5px 50px', backgroundColor: 'rgba(75, 101, 132, 0.9)' }}>
                            <Col span={24}>
                                <Button shape="circle"
                                    style={{
                                        marginRight: 10
                                    }}
                                    onClick={() => goBack()}
                                >
                                    <ArrowLeftOutlined />
                                </Button>

                                <Link target="_blank" to={`company/${_.get(user, "apikey")}`}>
                                    <Button icon={<HomeOutlined />}>Firma Sayfası</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Affix>

                    <div style={{ padding: '0 50px', paddingTop: 20 }}>
                        <Descriptions title={t('generalInfo')}>
                            <Descriptions.Item label={t('apiKey')}>{_.get(user, "apikey")}</Descriptions.Item>
                            <Descriptions.Item label={t('name')} >{_.get(user, "name")}</Descriptions.Item>
                            <Descriptions.Item label={t('username')}>{_.get(user, "username")}</Descriptions.Item>
                            <Descriptions.Item label={t('email')}>{_.get(user, "email")}</Descriptions.Item>
                            <Descriptions.Item label={t('createdDate')}>{new Date(_.get(user, "created")).toLocaleString()}</Descriptions.Item>

                            <Descriptions.Item>

                                <Button.Group>
                                    <Button onClick={() => this.onClickResetPassword()}>
                                        {t('resetPassword')}
                                    </Button>


                                </Button.Group>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>


                    <div style={{ textAlign: 'center' }}>

                        <QrCode text={`${siteInfo.panelUrl}/company/${_.get(user, "apikey")}`} width={128} />
                        <p>Firma Genel Karekodu</p>
                        <small>{`${siteInfo.panelUrl}/company/${_.get(user, "apikey")}`}</small>
                        <CopyToClipboard
                            text={`${siteInfo.panelUrl}/company/${_.get(user, "apikey")}`}
                            onCopy={() => this.setState({ copied: true })}>
                            <CopyOutlined title="Kopyala" onClick={() =>
                                message.info("Kopyalandı")
                            } />

                        </CopyToClipboard>
                    </div>


                </div>

                <Footer />
            </div>
        )
    }
}

export default withTranslation()(Profile)