import axios from "axios";
import { API_URL, testtoken, cdnConfig } from "../config";

const FormData = require('form-data')

export default class UserServices {
    constructor(token = testtoken) {
        this.token = token
        this.axiosConfig = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
    }

    myProfile() {
        const url = `${API_URL}/users/me`;
        return axios.get(url, this.axiosConfig);
    }
    logout() {
        const url = `${API_URL}/me/logout`;
        return axios.get(url, this.axiosConfig);
    }
    getMyRooms() {
        const url = `${API_URL}/users/me/rooms`;
        return axios.get(url, this.axiosConfig);
    }

    deleteMyRooms(id) {
        ///api/users/me/rooms/delete/:id
        const url = `${API_URL}/users/me/rooms/delete/${id}`;
        return axios.post(url, {}, this.axiosConfig);
    }

    ///PERSONEL
    loginStaff(data) {
        const url = `${API_URL}/admin/staff/login`
        return axios.post(url, data, this.axiosConfig);
    }
    myProfileStaff() {
        const url = `${API_URL}/admin/staff/me`;
        return axios.get(url, this.axiosConfig);
    }




    postUserAvatarImage(photo) {
        const url = `${cdnConfig.full_url}`;
        const formData = new FormData();
        formData.append('token', cdnConfig.token);
        formData.append('key', cdnConfig.key);
        formData.append('subkey', "useravatars");
        formData.append('file', photo);
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
        return axios.post(url, formData, headers)
    }
    updateMyProfile(data) {
        const url = `${API_URL}/users/me/update`;
        return axios.post(url, data, this.axiosConfig)
    }
    getUserProfile(userId) {
        const url = `${API_URL}/users/${userId}`;
        return axios.get(url, this.axiosConfig)
    }

    getUserProfileTest(userId) {
        const url = `${API_URL}/users/${userId}`;
        return axios.get(url, this.axiosConfig)
    }
    reportUser(data) {
        const url = `${API_URL}/home/feedback`;
        return axios.post(url, data, this.axiosConfig)
    }
    setPayment(data) {
        const url = `${API_URL}/users/me/set-payment`;
        return axios.post(url, data, this.axiosConfig)
    }
    updateMyProfile(data) {
        const url = `${API_URL}/users/me/update`;
        return axios.post(url, data, this.axiosConfig)
    }

}