



/*
// RN
import AsyncStorage from '@react-native-async-storage/async-storage';


export const getToken = async () => {
    return new Promise((resolve, reject) => {
        AsyncStorage.getItem('userToken').then(val => {
            return resolve(val)
        }).catch(err => {
            console.log(err)
            return reject(null)
        })
        return resolve(userToken)
    })

}
*/
export const getToken = localStorage.getItem('token');

export const companycode = localStorage.getItem('companycode');


export const setCompanycode = (companycode) => {
    return localStorage.setItem('companycode', companycode);
}
export const setClientId = (clientid) => {
    return localStorage.setItem('clientid', clientid);
}
export const getClientId = localStorage.getItem('clientid');
