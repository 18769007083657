import React, { Component } from 'react';

import New from './NewPromotion'
import { Row, Switch, Popconfirm, message, Table, Select, Dropdown, Menu, Divider, Tooltip, Checkbox, Icon, Button, Modal, Form, Input, Tag, Col } from 'antd'
import PageHeader from '../../components/PageHeader';

import { inject, observer } from 'mobx-react';


@inject('HomeStore', 'UserStore')
@observer
export default class Promotion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            modalNewVisible: false,
            detailItem: null
        };
    }

    componentDidMount() {
        this.itemList();
        this.props.UserStore.checkProfile()
    }

    setPage = (status = false) => {
        this.setState({ modalNewVisible: status, detailItem: null })
        //   this.itemList()
    }

    onClickDetail = (record) => {
        console.log(record)
        if (this.state.modalNewVisible) {
            this.setState({ modalNewVisible: false })
        }
        this.setState({ detailItem: record }, () => {
            this.setState({ modalNewVisible: true })
        })
    }

    delete = (record) => {
        this.props.HomeStore.removeLoyalty(record._id)
    }

    itemList = async () => {
        this.props.HomeStore.getLoyaltyList()
    }

    onChangeSwitchList = (checked, item) => {
        this.props.HomeStore.updateLoyalty(item._id, { action: "update-status", active: checked })
    }


    onClickButton = () => {
        this.setState({ modalNewVisible: !this.state.modalNewVisible }, () => {
            this.setState({ detailItem: null })
        })
    }

    switch = (key, value) => {
        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 500)
        this.props.UserStore.setCompanyLoyaltyActive(key, value)
    }

    render() {
        // const { data, loading } = this.state;
        const loading1 = this.state.loading
        const loading = this.props.HomeStore.loading
        const active = _.get(this.props.UserStore, "user.company.activePromotion")
        let data = _.get(this.props, "HomeStore.loyalties", [])

        data = _.filter(data, (o) => {
            return o.loyaltyType == "promotion"
        })
        //  const active = false

        const locale = {
            filterConfirm: 'Tamam',
            filterReset: 'Sıfırla',
            emptyText: 'Veri Yok'
        }

        const columns = [
            {
                title: 'Oluşturulma zamanı',
                key: 'created',
                render: (text, record) => (
                    <span>
                        <Tag color={'blue'}>{new Date(record.created).toLocaleDateString()}</Tag>
                    </span>
                )
                ,
            },
            {
                title: 'Kupon kodu',
                key: 'coupon',
                render: (text, record) => (
                    <span>
                        <b>{record.coupon}</b>
                    </span>
                )
            },
            {
                title: 'Başlık',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'Durum',
                key: 'active',
                render: (text, record) => (
                    <span>
                        <Switch size={'small'}
                            onChange={(e) => this.onChangeSwitchList(e, record)}
                            checkedChildren="Aktif" unCheckedChildren="Pasif" defaultChecked={record.active} />
                    </span>
                )
            },

            {
                title: 'İşlem',
                key: 'operation',
                render: (text, record) =>
                    <span>
                        <Button onClick={() => this.onClickDetail(record)} size="small">Detay</Button>
                        <Divider type="vertical" />
                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?"
                            onConfirm={() => this.delete(record)}>
                            <Button type="danger" size="small">Sil</Button>
                        </Popconfirm>
                    </span>
                ,
            },
        ];


        return (
            <div>
                <PageHeader
                    nopadding noback
                    subTitle={active ? "Aktif" : "Pasif"}
                    //  subTitle={JSON.stringify(active)}
                    title={this.state.modalNewVisible ? ((this.state.detailItem) ? 'Detay' : 'Yeni') : 'Promosyon Listesi'}
                    extra={
                        <span>
                            <Switch //ref={re => this.sw = re}
                                loading={loading1}
                                onChange={(e) => this.switch("activePromotion", e)}
                                checked={active}
                            />
                            <Divider type="vertical" />
                            <Button
                                onClick={() => this.onClickButton()}
                                type={this.state.modalNewVisible ? 'danger' : 'ghost'} >
                                {
                                    this.state.modalNewVisible ? 'KAPAT' : 'YENİ'
                                }
                            </Button>
                        </span>

                    }
                />

                <Row>
                    {
                        this.state.modalNewVisible && <New detailItem={this.state.detailItem} setPage={this.setPage} />
                    }
                </Row>

                <Table
                    locale={locale}
                    rowKey={'_id'}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                />
            </div>
        )
    }

}