
import React from "react";

import QRCodeComponent from "./QrCode";

import _ from "lodash"
import { siteInfo } from "../config";

export default ({
    data = [],
    qrcode,
    totalPrice,
    companyName = "Firma ismi",
    companyLogo = siteInfo.logoIcon,
    created = new Date()
}) => {
    return (
        <div style={{
            color:'#000'
        }}>
            <div style={{
                fontSize:20
            }} id="invoice-POS">
                <center id="top">
                    <div className="logo">
                        <img style={{ maxHeight: 54 }} src={companyLogo} />
                    </div>
                    <div className="info">
                        <h2>{companyName}</h2>
                    </div>{/*End Info*/}
                </center>{/*End InvoiceTop*/}
                <div id="mid">
                    <div className="info">
                        <h2>Fiş Bilgileri</h2>
                        <p>
                            Tarih : {new Date(created).toLocaleDateString()} - {new Date(created).toLocaleTimeString()}<br />
                            Fiş No   :  {new Date(created).getTime()}<br />
                        </p>
                    </div>
                </div>{/*End Invoice Mid*/}
                <div id="bot">
                    <div id="table">
                        <table>
                            <tbody>
                                <tr style={{
                                    fontSize:20,
                                    fontWeight:'bold'
                                    }} 
                                    className="tabletitle">
                                    <td className="item"><h2>Ürün</h2></td>
                                    <td className="Hours"><h2>Miktar</h2></td>
                                    <td className="Rate"><h2>Toplam</h2></td>
                                </tr>

                                {
                                    data.map((v, i) => {
                                        return (

                                            <tr style={{
                                                fontSize:20,
                                                fontWeight:'bold'
                                            }} key={i} className="service">
                                                <td className="tableitem"><p className="itemtext">{v.title}</p></td>
                                                <td className="tableitem"><p className="itemtext">{v.count}</p></td>
                                                <td className="tableitem"><p className="itemtext">{(_.get(v, "price") * v.count).toFixed(2)} ₺</p></td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr style={{
                                    fontSize:20,
                                    fontWeight:'bold'
                                }} className="tabletitle">
                                    <td />
                                    <td className="Rate">Toplam</td>
                                    <td style={{fontWeight:900}} className="payment">{totalPrice}₺</td>
                                </tr>
                            </tbody></table>
                    </div>{/*End Table*/}
                </div>{/*End InvoiceBot*/}
                <div className="invoice-POS-footer">
                    <center>
                        <QRCodeComponent
                            text={qrcode}
                            width={64}
                        />
                        <p style={{ fontSize: 10, }}>{siteInfo.appName}</p>
                    </center>

                </div>
            </div>{/*End Invoice*/}
        </div>
    )
}

