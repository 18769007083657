
import GeneralState from './General';
import UserState from './User';
import ProductState from './Product';
import Company from './Company';
import QrCodeState from './QrCode';
import OrderState from './Order'
import HomeState from './Home'

const stores = {
  GeneralStore: new GeneralState(),
  OrderStore: new OrderState(),
  UserStore: new UserState,
  ProductStore: new ProductState(),
  CompanyStore: new Company(),
  QrCodeStore: new QrCodeState(),
  HomeStore: new HomeState()

};

export default stores;