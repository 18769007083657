import React, { useState, useEffect } from 'react';

import Promotion from './Promotion'
import ScratchAndWin from './ScratchAndWin'
import WinAsYouEat from './WinAsYouEat'
import PunchCard from './PunchCard'

import { Tabs } from 'antd'
import { useHistory, useLocation } from 'react-router';
import { push } from 'react-router-redux';

const queryString = require('query-string');

const { TabPane } = Tabs;





export default ({ history }) => {

    const [page, setPage] = useState("Promotion")


    useEffect(() => {
        const parsedHash = queryString.parse(location.search);
        if (parsedHash.page) {
            setPage(parsedHash.page)
        }
    }, [])



    const onChangeTab = (key) => {
        setPage(key)

        history.push({
            pathname: '/loyalty',
            search: "?" + new URLSearchParams({ page: key }).toString()
        })

        // const location = window.location
        // const pathname = window.location.pathname
        // const origin = window.location.origin
        // const search = window.location.search
        // console.log({ search, pathname, origin })
        // window.location.assign(`${origin}${pathname}?a=${key}`)

        // window.history.replaceState({ de: "de" }, search)
        //const location = useLocation();
        //const history = useHistory();
        //const params = new URLSearchParams({ deneme: value });
        //history.replace({ pathname: location.pathname, search: params.toString() });
    }

    return (
        <div style={{ padding: 24 }}>
            <Tabs activeKey={page} onChange={(key) => onChangeTab(key)}>
                <TabPane tab="Promosyon" key="Promotion">
                    <Promotion />
                </TabPane>
                <TabPane tab="Kazı Kazan" key="ScratchAndWin">
                    <ScratchAndWin />
                </TabPane>
                <TabPane tab="Yedikçe Kazan" key="WinAsYouEat">
                    <WinAsYouEat />
                </TabPane>
                <TabPane tab="Punch Card" key="PunchCard">
                    <PunchCard />
                </TabPane>
            </Tabs>
        </div>
    )

}





/*


import React, { useState } from 'react';

import Promotion from './Promotion'
import ScratchAndWin from './ScratchAndWin'
import WinAsYouEat from './WinAsYouEat'
import { Tabs } from 'antd'
import { useHistory, useLocation } from 'react-router';

const queryString = require('query-string');

const { TabPane } = Tabs;

export default class Loyalty extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "Promotion"
        };

    }

    componentDidMount() {
        const parsedHash = queryString.parse(location.search);
        const a = _.get(parsedHash, "a")
        this.setState({ page: a })
    }


    onChangeTab = (key) => {
        console.log(key)
        this.setState({ page: key })

        const history = useHistory();
        const location = useLocation();

        const params = new URLSearchParams({ deneme: value });
        history.replace({ pathname: location.pathname, search: params.toString() });
    }

    render() {
        const { page } = this.state
        return (
            <div style={{ padding: 24 }}>
                <Tabs activeKey={page} onChange={(key) => this.onChangeTab(key)}>
                    <TabPane tab="Promosyon" key="Promotion">
                        <Promotion />
                    </TabPane>
                    <TabPane tab="Kazı Kazan" key="ScratchAndWin">
                        <ScratchAndWin />
                    </TabPane>
                    <TabPane tab="Yedikçe Kazan" key="WinAsYouEat">
                        <WinAsYouEat />
                    </TabPane>
                </Tabs>
            </div>
        )
    }

}


*/