
import axios from "axios";
import { API_URL, testtoken, cdnConfig } from "../config";

export default class Service {
    constructor({ token = testtoken, companycode = testtoken, clientid = testtoken }) {
        // this.init()
        //   this.token = testtoken
        this.axiosConfig = { headers: { companycode, clientid, Authorization: token, 'Content-Type': 'application/json' } }
    }


    getCompany() {
        let url = `${API_URL}/public/company/companycode`;
        return axios.get(url, this.axiosConfig);
    }

    getHomeSliders() {
        let url = `${API_URL}/public/homeslider`;
        return axios.get(url, this.axiosConfig);
    }

    getHomeCategoryWithProducts() {
        let url = `${API_URL}/public/category-products`;
        return axios.get(url, this.axiosConfig);
    }

    createOrder(data) {
        let url = `${API_URL}/public/everyone/order`;
        return axios.post(url, data, this.axiosConfig);
    }



}


