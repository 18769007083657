import React, { Component } from 'react';
import { Row, Switch, Popconfirm, message, Table, Select, Dropdown, Menu, Divider, Tooltip, Checkbox, Icon, Button, Modal, Form, Input, Tag, Col } from 'antd'
import PageHeader from '../../components/PageHeader';
import New from './NewPunchCard';
import { inject, observer } from 'mobx-react';


@inject('HomeStore', 'UserStore')
@observer
export default class PunchCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalNewVisible: false,
            loading: false
        }
    }

    setPage = (status = false) => {
        this.setState({ modalNewVisible: status, detailItem: null })
        //   this.itemList()
    }
    onClickDetail = (record) => {
        console.log(record)
        if (this.state.modalNewVisible) {
            this.setState({ modalNewVisible: false })
        }
        this.setState({ detailItem: record }, () => {
            this.setState({ modalNewVisible: true })
        })
    }
    onClickButton = () => {
        this.setState({ modalNewVisible: !this.state.modalNewVisible }, () => {
            this.setState({ detailItem: null })
        })
    }
    onChangeSwitchList = (checked, item) => {
        this.props.HomeStore.updateLoyalty(item._id, { action: "update-status", active: checked })
    }

    delete = (record) => {
        this.props.HomeStore.removeLoyalty(record._id)
    }

    switch = (key, value) => {
        this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 500)
        this.props.UserStore.setCompanyLoyaltyActive(key, value)
    }

    render() {
        const loading1 = this.state.loading
        const { modalNewVisible } = this.state
        const { loading } = this.props.HomeStore
        const active = _.get(this.props.UserStore, "user.company.activePunchCard")

        let data = _.get(this.props, "HomeStore.loyalties", [])

        data = _.filter(data, (o) => {
            return o.loyaltyType == "punchCard"
        })

        const columns = [
            {
                title: 'Oluşturulma zamanı',
                key: 'created',
                render: (text, record) => (
                    <span>
                        <Tag color={new Date() > new Date(record.to) ? 'red' : 'green'}>{new Date(record.created).toLocaleDateString()}</Tag>
                    </span>
                )
                ,
            },
            {
                title: 'Kupon kodu',
                key: 'coupon',
                render: (text, record) => (
                    <span>
                        <b>{record.coupon}</b>
                    </span>
                )
            },
            {
                title: 'Başlık',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'Durum',
                key: 'active',
                render: (text, record) => (
                    <span>
                        <Switch size={'small'}
                            onChange={(e) => this.onChangeSwitchList(e, record)}
                            checkedChildren="Aktif" unCheckedChildren="Pasif" defaultChecked={record.active} />
                    </span>
                )
            },

            {
                title: 'İşlem',
                key: 'operation',
                render: (text, record) =>
                    <span>
                        <Button onClick={() => this.onClickDetail(record)} size="small">Detay</Button>
                        <Divider type="vertical" />
                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?"
                            onConfirm={() => this.delete(record)}>
                            <Button type="danger" size="small">Sil</Button>
                        </Popconfirm>
                    </span>
                ,
            },
        ];


        return (
            <div>
                <PageHeader
                    nopadding noback
                    subTitle={`Hediye Kartları (${active ? "Aktif" : "Pasif"})`}
                    title='Punch Card Durumu'

                    extra={
                        <span>
                            <Switch
                                loading={loading1}
                                onChange={(e) => this.switch("activePunchCard", e)}
                                checked={active}
                            />
                            <Divider type="vertical" />
                            <Button
                                onClick={() => this.onClickButton()}
                                type={this.state.modalNewVisible ? 'danger' : 'ghost'} >
                                {
                                    this.state.modalNewVisible ? 'KAPAT' : 'YENİ'
                                }
                            </Button>
                        </span>

                    }
                />


                <Row>
                    {
                        modalNewVisible && <New detailItem={this.state.detailItem} setPage={this.setPage} />
                    }
                </Row>

                <Table
                    rowKey={'_id'}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                />

            </div>
        )
    }

}