import React, { Component } from "react";

import { Link } from 'react-router-dom'; // Link,

import { goPage, goBack } from '../historyConfig'
import { Avatar, Divider, Layout, Menu, Dropdown, Row, Col, Button, message, Badge, Modal, Checkbox, Input, Carousel, notification, ConfigProvider, List, Card } from 'antd';

import { isMobile } from 'react-device-detect';

import { SettingOutlined, WarningOutlined, LogoutOutlined, UserOutlined, LineChartOutlined, GlobalOutlined, NotificationOutlined, SmileOutlined, MenuOutlined, FileOutlined, UserSwitchOutlined } from '@ant-design/icons';

import { inject, observer } from 'mobx-react';
import { setDefaultLanguage, getDefaultLanguage } from "../config";

import i18n from 'i18next'
import { withTranslation } from "react-i18next";

import moment from "moment";


// import {joinRoom} from "../utils/socket"

const { Header: HeaderContent } = Layout;
const { SubMenu } = Menu;

const topColor = "#33d9b2"

@inject('UserStore')
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showHeaderMenu: false,
      user: null,
      avatar: "",
      displayName: "",

      notificationTypes: [
        { key: "callWaiter", url: "call-waiter", title: "Garson Çağır", color: "#ff4d4f" },
        { key: "newOrder", url: "new-order", title: "Yeni Sipariş", color: "#399893" }
      ],

    }
  }

  componentDidMount = async () => {


    //dil
    //  const finedLang = _.find(this.state.languages,{key:getDefaultLanguage()})
    //   this.setState({selectedLanguage:_.get(finedLang,"title")})

    //   this.getProfile()
    //   this.getOperators()
  }




  onClickUserMenu = (item, type = "default") => {
    console.log(item)
    if (item == "logout") {
      message.loading(undefined, 0)
      //   this.setState({loading:true})
      this.props.UserStore.logout();
      setTimeout(() => {
        message.destroy()
        goPage("login")
      }, 2000)
    }
    else if (item == "profile") {
      //  goPage("profile")
    }
    else if (type == "lang") {

      setDefaultLanguage(item)
      i18n.changeLanguage(item)
    }
    else {
      goBack()
    }
    /*
    if(item.length==24){
        goPage(`botmenu/${item}`)
    }
    */
  }

  onClickNotification = (v) => {
    console.log("on click notif", v)
  }

  render() {

    const { t } = this.props

    const { notifications } = this.props.UserStore
    const { notificationTypes } = this.state



    const menuUser = (
      <Menu>
        <Menu.Item key="profile">
          <Link to="/profile">
            <UserOutlined /> {t('profile')}
          </Link>
        </Menu.Item>

        <Menu.Item key="staff">
          <Link to="/loyalty">
            <UserSwitchOutlined />  Sadakat
          </Link>
        </Menu.Item>

        <Menu.Item key="reservation">
          <Link to="/reservation">
            <FileOutlined /> {t('reservation')}
          </Link>
        </Menu.Item>



        <Menu.Item key="report">
          <Link to="/report">
            <LineChartOutlined /> {t('report')}
          </Link>
        </Menu.Item>


        <SubMenu key="submenu" title={<span>  <SettingOutlined /> {t('settings')}</span>}>
          <Menu.Item key="0">
            <Link to="/settings">
              Genel
            </Link>
          </Menu.Item>
          {
            //  <Menu.Item key="1">
            //  <Link to="/settings/company">
            //    Firma
            //  </Link>
            //</Menu.Item>
          }
          <Menu.Item key="2">
            <Link to="/settings/slider">
              Slider
            </Link>
          </Menu.Item>
          <Menu.Item key="staff">
            <Link to="/settings/staff">
              Personel
            </Link>
          </Menu.Item>
        </SubMenu>


        {
          /*
                  <SubMenu title={<span>
                    <GlobalOutlined /> {t(getDefaultLanguage())}
                  </span>}>
                    <Menu.Item key="en" onClick={(e) => this.onClickUserMenu(e.key, "lang")}>
                      {t("en")}
                    </Menu.Item>
                    <Menu.Item key="tr" onClick={(e) => this.onClickUserMenu(e.key, "lang")}>
                      {t("tr")}
                    </Menu.Item>
                  </SubMenu>
                  */
        }
        <Menu.Divider />
        <Menu.Item key="logout" onClick={(e) => this.onClickUserMenu(e.key)}>
          <div>
            <LogoutOutlined /> {t('logout')}
          </div>

        </Menu.Item>
      </Menu>
    )


    const menuNotification = (
      notifications.length > 0
        ?
        <div>
          <Menu>
            {
              notifications.map((v, i) => {
                const nType = _.find(notificationTypes, { key: v.type })
                return (
                  <Menu.Item
                    onClick={() => this.onClickNotification(v)}
                    //danger={v.type === "callWaiter"} 
                    key={i}>
                    <Link to={`/order?qr=${_.get(v, "qr")}&type=${_.get(nType, "url", "")}`}>
                      <span>
                        <WarningOutlined
                        // color={_.get(nType,"color",undefined)}
                        />
                        {` `}
                        <b>{_.get(v, "title", "")}</b>
                        {` `}(
                        {moment(v.date).fromNow()}
                        )
                        {` `}
                        <small style={{ color: _.get(nType, "color", undefined) }}>
                          {_.get(_.find(notificationTypes, { key: v.type }), "title", "")}
                        </small>
                      </span>
                    </Link>
                  </Menu.Item>
                )
              })
            }
          </Menu>
          <Button onClick={() => this.props.UserStore.removeNotification(null, "deleteAll")} type="danger" block size="small">Tümünü Sil</Button>
        </div>
        :
        <div style={{ padding: 40, backgroundColor: '#FFF' }}>
          {`${t('notFoundNotification')} `}
          <SmileOutlined />
        </div>
    )
    //#ff3f34
    return (
      <HeaderContent className="Header"
        style={this.props.fixed ? {
          backgroundColor: topColor,
          position: 'fixed',
          zIndex: 100,
          width: '100%'
        } : {
          backgroundColor: '#399893',
        }}>

        <Row justify="space-between" type="flex">

          <Col>
            <Row justify="start">
              <Link to="/">
                <img style={{
                  maxHeight: 50,
                  width: 'auto',
                  // float: 'left',
                  marginRight: '18px'
                }}
                  src="https://pistatic.piyanos.com/uploads/smartcafe/smartcafelogo-name-white.png"
                  alt="Logo" />
              </Link>

              {
                /*
                                          <Menu //theme="dark"
                                          mode="horizontal" defaultSelectedKeys={['2']}>
                                          <Menu.Item key="1">nav 1</Menu.Item>
                                          <Menu.Item key="2">nav 2</Menu.Item>
                                          <Menu.Item key="3">nav 3</Menu.Item>
                                        </Menu>
                                        */
              }

            </Row>

          </Col>

          <Col>
            <Dropdown overlay={menuNotification}>
              <Badge
                count={notifications.length}>
                <Button
                  type="ghost"
                  shape="circle">
                  <NotificationOutlined style={{ color: '#FFF' }} />
                </Button>
              </Badge>
            </Dropdown>
            {` `}
            <Dropdown trigger="click" overlay={menuUser}>
              <Button
                type="ghost"
                shape="circle">
                <MenuOutlined style={{ color: '#FFF' }} />
              </Button>
            </Dropdown>
          </Col>









        </Row>

      </HeaderContent>
    )
  }
}


export default withTranslation()(Header)
/*
<Avatar
icon={<MenuOutlined />}
style={{
  backgroundColor: "#62B2AE",
  verticalAlign: 'middle',
  cursor: 'pointer',
  marginLeft: 20
}}
size="default">
</Avatar>

*/