


import React, { Component } from 'react';
import {
    message,
    Table,
    Select,
    Drawer,
    Switch,
    Row,
    Button,
    Input,
    Form,
    Modal,
    Popconfirm,
    Tooltip,
    notification,
    Radio,
    Col,
    Divider,
    Card,
    Upload,
    Avatar,
    Typography,
    Tag

} from 'antd';

import { Link } from 'react-router-dom';

import { PlusOutlined, DeleteOutlined, ReloadOutlined, UploadOutlined, InfoOutlined } from '@ant-design/icons';


import { inject, observer } from 'mobx-react';

import moment from 'moment'

import axios from "axios"

import _ from "lodash"

import { cdnConfig, cdnUrl } from '../../config';

const { Search, TextArea } = Input;

const { Option } = Select;
const { Text } = Typography;




@inject('HomeStore')
@observer
class Restaurants extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpenCreate: false
        }
    }

    componentDidMount = () => {
        this.getItemList()
    }

    //#liste
    getItemList = async () => {
        this.props.HomeStore.getRestaurantList();
        const {
            masterUsers
        } = this.props.HomeStore

        if (masterUsers.length == 0) {
            this.props.HomeStore.getUsersForMaster();
        }
    }

    resetState() {
        this.setState({
            modalOpenCreate: false,
            _id: '',
            title: '',
            description: '',
            phone: '',
            address: '',
            fileCoverImage: null,
            fileLogo: null,
            logo: null,
            coverImage: null,
            rank: null,
            userIds: []
        })
    }

    reload = () => {
        this.getItemList()
    }


    //#ekle
    handleCreate = async () => {

        /*
           _id: idObject,
                title: _.trim(_.get(obj, 'title', '')),
                description: _.get(obj, 'description', ''),
                address: _.get(obj, 'address', ''),
                phone: _.get(obj, 'phone', ''),
                logo: obj.logo ? obj.logo : undefined,
                coverImage: obj.coverImage ? obj.coverImage : undefined,
                active: _.get(obj, 'active', true),
                apikey: idObject,
                rank,
                userId: userIdObject,
                userIds,
                created: new Date(),

                */
        const {
            title,
            address,
            phone,
            description,
            fileCoverImage,
            fileLogo,
            rank,
            _id,
            logo,
            coverImage,
            userIds
        } = this.state

        console.log("handleCreate:state", this.state)
        if (!title) {
            message.error("Başlık zorunlu")
            return
        }

        let data = {
            title,
            address,
            phone,
            description,
            fileCoverImage,
            fileLogo,
            userIds,
        }

        //yeni kayıt//////////////
        if (!_id) {
            await this.props.HomeStore.createRestaurant(data)
            this.resetState()
        }
        ///////Güncelleme//////////////////
        else {
            data.rank = rank
            data.logo = logo
            data.coverImage = coverImage
            await this.props.HomeStore.updateRestaurant(_id, data)
            this.resetState()
        }
    }

    //#sil
    confirmDelete = (item) => {
        this.props.HomeStore.removeRestaurant(item._id)
    }

    //düzenle click
    clickEdit = (item) => {
        console.log(item)
        this.setState({
            ...item
        }, () => {
            this.setState({ modalOpenCreate: true })
        })
    }




    handleCancel() {
        this.resetState()
    }




    onChangeImage = (e, key) => {
        console.log(e)
        console.log({ file: e.target.files })
        let file = e.target.files[0]
        this.setState({
            [key]: file
        })
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeSwitchList = (id, { active, type }) => {
        this.props.HomeStore.updateRestaurant(id, { type, active })
    }

    handleChangeSelect = (userIds) => {
        this.setState({ userIds });
    }


    render() {
        const { userIds } = this.state
        const {
            restaurantList: data,
            loading,
            loadingSwitch,
            masterUsers
        } = this.props.HomeStore



        const handleFocus = (event) => event.target.select();
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16, offset: 0 },
            },
        };


        const columns = [
            {
                title: '',
                key: 'logo',
                render: (text, record) =>
                    <span>
                        {
                            record.logo ? <Avatar shape="square" src={record.logo} /> : <Avatar shape="square">{_.get(record, "title", "").charAt(0)}</Avatar>
                        }
                    </span>
            },
            {
                title: 'Başlık',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Açıklama',
                key: 'description',
                render: (text, record) => <span>
                    <Text type="secondary" >{record.description}</Text>
                </span>
            },
            {
                title: 'Oluşturulma',
                key: 'created',
                render: (text, record) => <span>
                    {new Date(record.created).toLocaleDateString()}
                </span>
            },
            {
                title: 'Favori',
                key: 'inFavori',
                render: (text, record) => <span>
                    <Switch loading={loadingSwitch} onChange={(e) => this.onChangeSwitchList(record._id, { type: "inFavori", active: e })} defaultChecked={record.inFavori} />
                </span>
            },
            {
                title: 'Durum',
                key: 'active',
                render: (text, record) => <span>
                    <Switch loading={loadingSwitch} onChange={(e) => this.onChangeSwitchList(record._id, { type: "active", active: e })} defaultChecked={record.active} />
                </span>
            },
            {
                title: 'İşlem', key: 'action', render: (record) => {
                    return (
                        <span>
                            <Button onClick={() => this.clickEdit(record)} size="small">
                                Düzenle
                            </Button>
                            <Divider type="vertical" />
                            <Popconfirm okText="Evet" cancelText="Hayır" title={`Emin misiniz? ${_.get(record, "products", []).length > 0 ? `Kategoriye ait ${_.get(record, "products", []).length} adet ürün de silinecektir!!` : ""}`}
                                onConfirm={() => { this.confirmDelete(record) }}>
                                <Button type="danger" size="small">
                                    Sil
                                </Button>
                            </Popconfirm>
                        </span>
                    )
                }
            },
        ];


        const children = [];
        _.map(masterUsers, (v, i) => {
            children.push(<Option key={v._id}>{v.name}</Option>);
        }
        )
        // for (let i = 10; i < 36; i++) {
        //   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
        // }


        return (
            <div>
                <Card
                    loading={loading}
                    title={'Restoran Listesi'}
                    extra={<span>

                        <Button
                            icon={<PlusOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => this.setState({ modalOpenCreate: true, modalTitle: "Yeni" })} />
                        <Button type="primary"
                            shape="circle"
                            icon={<ReloadOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={() => this.reload()} />
                    </span>}
                >


                    <div>

                        <Table
                            rowKey={'_id'}
                            columns={columns}
                            pagination={false}
                            size="small"
                            dataSource={data}
                            loading={loading}
                        />
                    </div>

                </Card>


                <Drawer
                    title={this.state._id ? "Düzenle" : "Yeni"}
                    placement="left"
                    width={720}
                    visible={this.state.modalOpenCreate}
                    onClose={(e) => this.handleCancel(e)}
                    bodyStyle={{ paddingBottom: 80 }}
                >

                    <Form {...formItemLayout}>
                        <Form.Item
                            label="Başlık"
                            required >
                            <Input
                                value={this.state.title}
                                onChange={(e) => this.onChange(e)}
                                name="title" />
                        </Form.Item>

                        <Form.Item
                            label="Telefon">
                            <Input
                                value={this.state.phone}
                                onChange={(e) => this.onChange(e)}
                                name="phone" />
                        </Form.Item>

                        <Form.Item
                            label="Açıklama"
                        >
                            <TextArea
                                onChange={(e) => this.onChange(e)}
                                name="description"
                                value={this.state.description}
                                autosize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Adres"
                        >
                            <TextArea
                                onChange={(e) => this.onChange(e)}
                                name="address"
                                value={this.state.address}
                                autosize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Form.Item>
                        {
                            this.state._id
                            &&
                            <Form.Item
                                label="Sıra"
                            >
                                <Input
                                    onFocus={handleFocus}
                                    value={this.state.rank}
                                    onChange={(e) => this.onChange(e)}
                                    name="rank"
                                    type="number" />
                            </Form.Item>
                        }


                        <Form.Item
                            label="Logo Medya"
                            help=".png uzantılı en fazla 256px çözünürlüğe sahip görsel tavsiye edilir."
                        >
                            <span>
                                {
                                    this.state.logo ? <Button block type="danger" size="small" onClick={() => this.setState({ logo: null })}>Sil <DeleteOutlined /></Button> :
                                        <input onChange={(e) => this.onChangeImage(e, "fileLogo")} accept="image/*" type="file" placeholder="Fotoğraf seç" />
                                }
                            </span>
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                {this.state.logo && <img src={this.state.logo} style={{ maxWidth: "100%" }} alt="logo" />}
                            </div>
                        </Form.Item>

                        <Form.Item
                            label="Kapak Medya"
                            help=".png veya .jpguzantılı en fazla 1080px çözünürlüğe sahip görsel tavsiye edilir."
                        >
                            <span>
                                {
                                    this.state.coverImage ? <Button block type="danger" size="small" onClick={() => this.setState({ coverImage: null })}>Sil <DeleteOutlined /></Button> :
                                        <input onChange={(e) => this.onChangeImage(e, "fileCoverImage")} accept="image/*" type="file" placeholder="Fotoğraf seç" />
                                }
                            </span>
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                {this.state.coverImage && <img src={this.state.coverImage} style={{ maxWidth: "100%" }} alt="logo" />}
                            </div>
                        </Form.Item>

                        <Form.Item
                            label="Yetkili Kullanıcı veya kullanıcılar"
                        >
                            <Select value={this.state.userIds}
                                // mode="tags" 
                                mode="multiple"
                                value={userIds}
                                style={{ width: '100%' }}
                                onChange={(e) => this.handleChangeSelect(e)}>
                                {children}
                            </Select>
                        </Form.Item>

                    </Form>

                    <div
                        style={{
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 0',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
                            İptal
                        </Button>
                        <Button onClick={() => this.handleCreate()} type="primary">
                            Kaydet
                        </Button>
                    </div>
                </Drawer>

            </div>
        )
    }
}

export default Restaurants