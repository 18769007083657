
import { observable, action, computed, toJS } from 'mobx';


import _ from "lodash"


import axios from 'axios';

import { API_URL } from '../config'

export default class QrCode {


    //   @observable demo = { firstName: "1212", lastName: "46465465" }
    @observable token = null
    @observable loading = false
    @observable qrcode = null
    @observable initprint = false
    @observable data = { results: [] }


    //logları listele
    @action
    getItems = async (limit = 10, offset = 0) => {

        let url = `${API_URL}/qr-code?limit=${limit}&offset=${offset}`;
        try {
            const data = (await axios.get(url, this.getHeader())).data;
            this.data = data
            console.log("!!get:items:qrcodeStore", data)
            return Promise.resolve(data)
        } catch (error) {
            Promise.reject(error)
        }

    }

    //qr kod ekle //oluştur
    @action async insert(data) {
        const url = `${API_URL}/qr-code`
        return axios.post(url, data, this.getHeader());
    }
    // aktif pasif durumu
    @action async setActive(id, active) {
        let api = `${API_URL}/qr-code/update/${id}`;
        return axios.post(api, { type: "active", active }, this.getHeader());
    }
    // aktif pasif durumu Tümü için
    @action async setActiveAll(id, active) {
        let api = `${API_URL}/qr-code/update/${id}`;
        return axios.post(api, { type: "activeall", active }, this.getHeader());
    }
    // güncelle
    @action async update(id, data) {
        let api = `${API_URL}/qr-code/update/${id}`;
        return axios.post(api, data, this.getHeader());
    }
    // sil
    @action async delete(data) {
        let api = `${API_URL}/qr-code/delete`;
        return axios.post(api, data, this.getHeader());
    }
    //tüm masa için qr kod oluştur
    @action async createAll(data) {
        let api = `${API_URL}/qr-code/create-with-desk`;
        return axios.post(api, data, this.getHeader());
    }

    // qr set et
    @action async setqr(data) {
        this.qrcode = data
        return data
    }

    getToken() {
        const token = localStorage.getItem('token');
        this.token = token;
        return token;

    }
    getHeader() {
        this.getToken();
        return { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
    }



}

