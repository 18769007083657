import React,{Component} from 'react'
import {
    Form,
    Input,
    Tooltip,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button,
    AutoComplete,
    message,
    Spin
 } from 'antd';
import {goPage} from '../historyConfig'

import { Link } from 'react-router-dom';

import _ from 'lodash'

import Header from '../components/Header'
import Footer from '../components/Footer'

import { QuestionCircleOutlined } from '@ant-design/icons';
import { inject} from 'mobx-react';

@inject('UserStore')
export default class RecoveryPassword extends Component{
    constructor(props) {
        super(props);
        this.state = {
          resetPasswordKey:'',
          password: '',
          captcha:'',
          loading:false,
          isLogin:false,
          randomNumber : Math.floor(Math.random() * 9999) + 1000
        };
      }

      componentDidMount=()=>{
  
          try {
          //id
          const id = this.props.match.params.id//||""
              this.props.UserStore.getUserWithResetPasswordKey(id).then((res)=>res.data).then((response)=>{
                  console.log("response",{response})
                  const resetPasswordKey = _.get(response,"resetPasswordKey")
                  this.setState({resetPasswordKey})
                  if(!resetPasswordKey){
                    goPage("404")
                  }
              }).catch(()=>{
                goPage("404")
              })
              
          } catch (error) {
              goPage("404")
              console.log(error)
          }
      }


      onChange=(e)=>{
        this.setState({ [e.target.name]: e.target.value });
      }

      newRandomNumber=()=>{
          this.setState({
            randomNumber: Math.floor(Math.random() * 9999) + 1000
          })
      }

       onFinish =async values => {

        this.setState({
            loading:true
        })
           //burdan apiye git
           // eğer formdan başarılı geçilirse
           if(this.state.captcha!=this.state.randomNumber){
               message.error("Doğrulama kodu hatalı")
               return
           }

           let data = {
               resetPasswordKey : this.state.resetPasswordKey,
               password : this.state.password,
           }

           this.props.UserStore.recoveryPassword(data).then((res)=>{
              message.success("Şifre sıfırlama başarılı. Şimdi giriş yapabilirsiniz.")
              goPage("login")

           }).catch((err)=>{
               console.log(err)
               message.error()
           }).finally(()=>this.setState({loading:false}))
      };

      onChange=(e)=>{
        this.setState({ [e.target.name]: e.target.value });
      //  this.props.init();
      }



//linear-gradient(87deg,#11cdef,#1171ef)!important;
    render(){
        const BackgroundImageUrl = 'https://source.unsplash.com/random/900*700/?social,friends'


        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };
          const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            },
          };

          const usernameRegex = /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/;

        return(
            <div 
            
            style={{

            backgroundImage:`url(${BackgroundImageUrl})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
         
            }}
            
            >
                <div style={{
                     background: 'linear-gradient(87deg,rgba(17, 205, 239,0.5),rgba(17, 113, 239, 0.5))',
                    display: 'flex',  
                    justifyContent:'center', 
                    alignItems:'center', 
                    height: '100vh',
                }}
                    >
            <div style={
                {
                padding:'10px 40px',
                backgroundColor:'rgba(255,255,255,0.6)',
                borderRadius:10
                }}>

{
    this.state.loading &&
    <div style={{justifyContent:'center',textAlign:'center',padding:'0 10px'}}>
    <Spin/>
</div>
}

                     <Form
                     size="small"
                                {...formItemLayout}
                                name="register"
                                onFinish={()=>this.onFinish()}
                                scrollToFirstError
                                >
                               
                                <Form.Item
                                    name="password"
                                    label="Şifre"
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen şifrenizi giriniz!',
                                    },
                                    {
                                        min: 4,
                                        message: 'Şifre en az 4 karakter olmalı',
                                    },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                            name="password"
                                            onChange={(e)=>this.onChange(e)}
                                     />
                                </Form.Item>

                                <Form.Item
                                    name="confirm"
                                    label="Şifre Tekrarı"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen şifre tekrarı giriniz!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Şifreler uyuşmuyor!');
                                        },
                                    }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item label="Doğrulama Kodu" extra="Robot olmadığınıza emin olmalıyız.">
                                    <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item
                                        name="captcha"
                                        noStyle
                                        rules={[
                                            { 
                                                required: true, 
                                                message: 'Lütfen doğrulama kodunu giriniz' 
                                            }
                                        ]}
                                        >
                                        <Input
                                          name="captcha"
                                         onChange={(e)=>this.onChange(e)}
                                         />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Button block onClick={()=>this.newRandomNumber()}>{this.state.randomNumber}</Button>
                                    </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item {...tailFormItemLayout}>
                                    <Button type="primary" htmlType="submit" block>
                                    KAYDET
                                    </Button>
                                    <Link to="/login">Şifremi Biliyorum</Link>
                                </Form.Item>
                                </Form>  

            </div>
            </div>
        </div>
        )
    }
}