import React, { Component } from 'react';

import { Form, Input, Button, Icon, message, Select, Checkbox, Row, Col, PageHeader, Switch, Tooltip, Radio, DatePicker } from 'antd';

import moment from 'moment'

import _ from 'lodash'

import { customAlphabet } from 'nanoid'

import { inject, observer } from 'mobx-react';
import QrContainer from '../../components/QrContainer';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;



@inject('HomeStore')
export default class NewPromotion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            branchList: [],
            productList: [],
            loading: false,
            deliveryTypeList: [
                {
                    id: "address_delivery",
                    title: "Adrese Teslim",
                    active: true,
                    minute: ""
                },
                {
                    id: "come_get_it",
                    title: "Gel Al",
                    active: true,
                    minute: ""
                },
                {
                    id: "come_eat",
                    title: "Gel Ye",
                    active: true,
                    minute: ""
                },
                {
                    id: "table",
                    title: "Masa",
                    active: true,
                    minute: ""
                }
            ],
            channelList: [
                { id: 'mobil', title: 'Mobil' },
                { id: 'web', title: 'Web Site' }
            ],
            dayList: [
                { i: 0, day: 'Pazar' },
                { i: 1, day: 'Pazartesi' },
                { i: 2, day: 'Salı' },
                { i: 3, day: 'Çarşamba' },
                { i: 4, day: 'Perşembe' },
                { i: 5, day: 'Cuma' },
                { i: 6, day: 'Cumartesi' }
            ],
            existCoupon: false,

            promotionType: 'percent',
            title: '',
            description: '',
            coupon: '',
            priceValue: null,
            minPrice: null,
            allProduct: true,
            from: null,
            to: null,
            days: [],
            workType: [],
            products: [],
            branch: [],
            deliveryType: [],
            channel: [],
            isDetail: false

        }
    }

    //   (!_.includes(this.state.workType,3))

    componentDidMount() {
        if (this.props.detailItem) {
            this.setState({
                ...this.props.detailItem,
                isDetail: true
            })
        }
    }



    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onChangePromotionType = (e) => {
        this.setState({ promotionType: e.target.value })
    }
    handleChangeDays = (e) => {
        this.setState({ days: e })
    }

    createRandomCoupon = () => {
        const nanoid = customAlphabet('1234567890zxcvbnmlkjhgfdsaqwertyuıop', 5)
        const created = nanoid().toUpperCase(); //=> "4f90d13a42"
        this.setState({
            coupon: created
        })
    }



    //API GET işlemleri



    getBranchList = async () => {
    }
    getProductList = async () => {
    }

    handleChangeProductSelected = (e) => {
        this.setState({ products: e })
    }

    onChangeCheckboxIsAll = (e) => {
        console.log(`checked = ${e.target.checked}`);
        this.setState({ allProduct: e.target.checked })
    }

    handleChangeBranchSelected = (branch) => {
        this.setState({ branch })
    }


    onChangeRangePicker = (value, dateString) => {
        //defaultValue: [moment('00:00:00', 'HH:mm:ss')
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
        console.log('Formatted Selected Time11111: ', dateString[0]);
        console.log('DATE Selected Time11111: ', new Date(dateString[0]));
        this.setState({ from: dateString[0], to: dateString[1] })

    }

    handleChangeChannel = (e) => {
        this.setState({ channel: e })
    }
    //teslimat tipleri on change
    handleChangeDeliveryType = (e) => {
        console.log(e)
        this.setState({ deliveryType: e })
    }
    optionsPaymentTypes = (e) => {
        this.setState({ paymentTypes: e });
    }

    //Geçerlilik türü
    onChangeOptionsWorkType = (e) => {
        //disabled={(_.includes(this.state.workType,3)) ? true : false} 
        this.setState({ workType: e })
        if (_.includes(e, 3)) {
            this.setState({ workType: [3] })
        }

    }

    onChangeHttp = (e) => {
        console.log(e);
        this.setState({ http: e })
    }

    submitForm = async () => {
        const { isDetail, _id, coupon, title, from, to, description, minPrice, priceValue, promotionType } = this.state

        const postData = {
            loyaltyType: "promotion",
            title,
            coupon,
            description,
            minPrice: minPrice ? parseFloat(minPrice) : 0,
            promotionType,
            priceValue: priceValue ? parseFloat(priceValue) : 0,
            from,
            to
        }
        //güncelle
        if (isDetail) {
            postData.action = "update"
            try {
                await this.props.HomeStore.updateLoyalty(_id, postData)
                this.props.setPage(false)
                message.success()
                window.scrollTo(0, 0)
            } catch (error) {
                console.log(error)
            }
        } else {
            //YENI      
            if (postData.from && postData.to && postData.title && postData.coupon) {
                try {
                    //aynı koda sahip başka bir promosyon varsa devam etme
                    if (_.find(this.props.HomeStore.loyalties, (o) => {
                        return o.coupon == coupon
                    })) {
                        message.warn("Kupon kodunu daha önce kullandınız. Lütfen farklı bir kod ile tekrar deneyiniz.")
                        return;
                    }
                    await this.props.HomeStore.createLoyalty(postData)
                    this.props.setPage(false)
                    message.success()
                    window.scrollTo(0, 0)
                } catch (error) {
                    console.log(error)
                    message.error()
                }
            }
            else {
                message.error("Zorunlu alanları kontrol ediniz");
            }
        }
    }


    render() {
        const { loading, isDetail, coupon } = this.state;

        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const optionsWorkTypes = [
            { label: 'Sadece ilk sipariş', value: 1 },
            { label: 'Müşteri başına bir kez', value: 2 },
            { label: 'Her siparişte', value: 3 },
        ]

        const buttonItemLayout = {
            wrapperCol: { span: 16, offset: 8 },
        };


        return (
            <div>

                {
                    isDetail
                    &&
                    <QrContainer download copy text={coupon} />
                }


                <Form layout={"horizontal"}  {...formItemLayout}>

                    <Form.Item
                        help={this.state.existCoupon ? 'Girdiğiniz kupon kodunu daha önce kullandınız. Lütfen farklı bir kupon kodu giriniz.' : undefined}
                        validateStatus={this.state.existCoupon ? 'warning' : undefined} required={true} label="Kupon Kodu">
                        <Input
                            disabled={(isDetail) ? true : false}
                            value={this.state.coupon} onChange={this.onChange} name="coupon" />
                        {
                            !isDetail
                            &&
                            <Button block type="link" onClick={() => this.createRandomCoupon()}>Rasgele Kod Oluştur</Button>
                        }
                    </Form.Item>

                    <Form.Item required={true} label="Başlık">
                        <Input value={this.state.title} onChange={this.onChange} name="title" />
                    </Form.Item>

                    <Form.Item label="Açıklama ">
                        <TextArea
                            value={this.state.description}
                            onChange={this.onChange}
                            name="description"
                            autosize={{ minRows: 2, maxRows: 6 }}
                        />
                    </Form.Item>

                    <Form.Item help="Herhangi birşey girmediğinizde 0 (sıfır) olarak ayarlanacaktır." required={true} label="Minimum Tutar" {...formItemLayout}>
                        <Input type="number" placeholder="En az sepet tutarı" value={this.state.minPrice} onChange={this.onChange} name="minPrice" />
                    </Form.Item>

                    <Form.Item label="Promosyon Türü ">
                        <Radio.Group onChange={this.onChangePromotionType} name="promotionType" value={this.state.promotionType}>
                            <Radio.Button value="percent">Yüzde</Radio.Button>
                            <Radio.Button value="price">Sabit Miktar</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item required={true} label={(this.state.promotionType == 1) ? 'Yüzdelik indirim oranı' : 'İndirim tutarı'} {...formItemLayout}>
                        <Input type="number" value={this.state.priceValue} onChange={this.onChange} name="priceValue" />
                    </Form.Item>

                    {
                        // <Form.Item required={true} label="Geçerlilik Türü" >
                        //     <Checkbox.Group style={{ width: '100%' }}>
                        //         <Row>
                        //             <Checkbox.Group value={this.state.workType} options={optionsWorkTypes} onChange={this.onChangeOptionsWorkType}>
                        //             </Checkbox.Group>
                        //         </Row>
                        //     </Checkbox.Group>
                        // </Form.Item>
                    }

                    {
                        // <Form.Item required={true} label="Ürünler ">
                        //     {
                        //         (!this.state.allProduct) ?
                        //             <Select
                        //                 notFoundContent="Bulunmadı"
                        //                 optionFilterProp="children"
                        //                 mode="multiple"
                        //                 size={'default'}
                        //                 placeholder="Ürün veya ürünleri seçin (İsme göre arama yapabilirsiniz)"
                        //                 value={this.state.products}
                        //                 onChange={this.handleChangeProductSelected}
                        //                 style={{ width: '100%' }}
                        //             >
                        //                 {
                        //                     this.state.productList.map((v) => {
                        //                         return (
                        //                             <Option key={v._id}>{v.title}</Option>
                        //                         )
                        //                     })
                        //                 }
                        //             </Select>
                        //             :
                        //             null
                        //     }
                        //     <Checkbox checked={(this.state.allProduct) ? true : false} onChange={this.onChangeCheckboxIsAll}>Tümü</Checkbox>
                        // </Form.Item>
                    }



                    <Form.Item required={true} label="Tarih Aralığı">
                        <RangePicker
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            placeholder={['Başlangıç', 'Bitiş']}
                            onChange={this.onChangeRangePicker}
                            value={(this.state.from && this.state.to) ? [moment(this.state.from), moment(this.state.to)] : undefined}
                            onOk={this.onOkRangePicker}
                        />
                    </Form.Item>

                    <Form.Item {...buttonItemLayout}>
                        <Button
                            block
                            onClick={this.submitForm}
                            loading={loading}
                            type="primary"
                            disabled={loading || this.state.existCoupon}
                        >
                            {
                                (this.state.isDetail) ? 'GÜNCELLE' : 'OLUŞTUR'
                            }
                        </Button>
                    </Form.Item>

                </Form>



            </div>
        )
    }
}
