
import { observable, action, computed, toJS } from 'mobx';


import _ from "lodash"


import axios from 'axios';

import { API_URL } from '../config'

class General {

    /*
    constructor(){
		this.getToken();
		autorun(() => {
		})
	}
*/

    //   @observable demo = { firstName: "1212", lastName: "46465465" }
    @observable token = null
    @observable logs = []


    //logları listele
    @action async getLogs(date = null) {
        let url = `${API_URL}/logs${date ? `?date=${date}` : ""}`;
        return axios.get(url, this.getHeader());
    }

    //dashboard için logları getir
    //
    @action async getLogsForDashboard() {
        let url = `${API_URL}/logs?type=dashboard`;
        return axios.get(url, this.getHeader());
    }

    //dashboard chart
    @action async getDashboardChart() {
        const url = `${API_URL}/dashboard/chart`
        return axios.get(url, this.getHeader());
    }


    //dashboard kısayol sayıları
    @action async getDashboardCount() {
        const url = `${API_URL}/dashboard/count`
        return axios.get(url, this.getHeader());
    }






    //#masa
    //masa bölge ekle
    @action async insertDeskArea(data) {
        const url = `${API_URL}/desk-area`
        const headers = { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
        return axios.post(url, data, headers);
    }
    //masa bölge sil
    @action async deleteDeskArea(data) {
        const url = `${API_URL}/desk-area/delete`
        return axios.post(url, data, this.getHeader());
    }
    //masaları listele
    @action async getDesks() {
        let url = `${API_URL}/desk`;
        return axios.get(url, this.getHeader());
    }
    //masaları bölgeleri listele
    @action async getDeskArea() {
        let url = `${API_URL}/desk-area`;
        return axios.get(url, this.getHeader());
    }
    //masa ekle
    @action async insertDesk(data) {
        const url = `${API_URL}/desk`
        return axios.post(url, data, this.getHeader());
    }
    //masa sil
    @action async deleteDesk(data) {
        const url = `${API_URL}/desk/delete`
        return axios.post(url, data, this.getHeader());
    }
    //masa güncelle
    @action async updateDesk(data) {
        const url = `${API_URL}/desk/update`
        return axios.post(url, data, this.getHeader());
    }

    //STAFF

    //masaları listele pos
    @action async getDesksForPos() {
        let url = `${API_URL}/pos-desks`;
        return axios.get(url, this.getHeader());
    }
    @action async createDeskOrder(data) {
        const url = `${API_URL}/admin/staff/deskorder`
        return axios.post(url, data, this.getHeader());
    }
    @action async getDeskOrder(deskId) {
        const url = `${API_URL}/admin/staff/deskorder/${deskId}`
        return axios.get(url, this.getHeader());
    }
    @action async deletePreOrderByDesk(deskId) {
        const url = `${API_URL}/admin/staff/deskorder/${deskId}`
        return axios.delete(url, this.getHeader());
    }
    @action async setPaidDeskOrder(data) {
        const url = `${API_URL}/admin/staff/deskorder/set-paid`
        return axios.post(url, data, this.getHeader());
    }
    //STAFF




    @action async getToken() {
        const token = localStorage.getItem('token');
        this.token = token;
        return token;

    }

    getHeader() {
        this.getToken();
        return { headers: { Authorization: this.token, 'Content-Type': 'application/json' } }
    }



}
export default General;
