import React, { Component } from 'react';

import { Form, Input, Button, Icon, message, Select, Checkbox, Row, Col, PageHeader, Switch, Tooltip, Radio, DatePicker } from 'antd';

import moment from 'moment'

import _ from 'lodash'

import { customAlphabet } from 'nanoid'

import { inject, observer } from 'mobx-react';

import {
    CoffeeOutlined,
    StarOutlined,
    DollarOutlined,
    ShoppingOutlined,
    SmileOutlined,
    FireOutlined,
    HeartOutlined,
    AppstoreAddOutlined
} from '@ant-design/icons';
import QrContainer from '../../components/QrContainer';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;



@inject('HomeStore', 'ProductStore')
export default class NewItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            branchList: [],
            productList: [],
            loading: false,

            title: '',
            description: '',
            coupon: '',
            priceValue: null,
            minPrice: null,
            allProduct: true,
            from: null,
            no: null,
            days: [],
            workType: [],
            products: [],
            branch: [],
            deliveryType: [],
            channel: [],
            isDetail: false

        }
    }

    //   (!_.includes(this.state.workType,3))

    componentDidMount() {
        if (this.props.detailItem) {
            this.setState({
                ...this.props.detailItem,
                isDetail: true
            })
        }

        this.getIProductList()
        this.createRandomCoupon()
    }



    //#liste
    getIProductList = async () => {
        this.setState({ loading: true })
        try {
            let serverData = await this.props.ProductStore.getProducts("withcategory");


            let dataList = _.get(serverData, "data.results")

            dataList = dataList.map((v, i) => {
                v.rank = dataList.length - i
                return {
                    ...v,
                    index: i
                }
            })

            this.setState({
                productList: dataList
            })
        } catch (error) {
            console.log(error)
        }
        finally {
            this.setState({ loading: false })
        }
    }


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onChangePromotionType = (e) => {
        this.setState({ promotionType: e.target.value })
    }
    handleChangeDays = (e) => {
        this.setState({ days: e })
    }

    createRandomCoupon = () => {
        const nanoid = customAlphabet('1234567890zxcvbnmlkjhgfdsaqwertyuıop', 12)
        const created = nanoid().toUpperCase(); //=> "4f90d13a42"
        this.setState({
            coupon: created
        })
    }



    //API GET işlemleri



    getBranchList = async () => {
    }
    getProductList = async () => {
    }

    handleChangeProductSelected = (e) => {
        this.setState({ productId: e })
    }

    onChangeCheckboxIsAll = (e) => {
        console.log(`checked = ${e.target.checked}`);
        this.setState({ allProduct: e.target.checked })
    }

    handleChangeBranchSelected = (branch) => {
        this.setState({ branch })
    }


    onChangeRangePicker = (value, dateString) => {
        this.setState({ from: dateString[0], to: dateString[1] })

    }

    handleChangeChannel = (e) => {
        this.setState({ channel: e })
    }
    //teslimat tipleri on change
    handleChangeDeliveryType = (e) => {
        console.log(e)
        this.setState({ deliveryType: e })
    }
    optionsPaymentTypes = (e) => {
        this.setState({ paymentTypes: e });
    }

    //Geçerlilik türü
    onChangeOptionsWorkType = (e) => {
        //disabled={(_.includes(this.state.workType,3)) ? true : false} 
        this.setState({ workType: e })
        if (_.includes(e, 3)) {
            this.setState({ workType: [3] })
        }

    }

    onChangeHttp = (e) => {
        console.log(e);
        this.setState({ http: e })
    }


    submitForm = async () => {
        const {
            isDetail,
            _id,
            coupon,
            title,
            from,
            to,
            description,
            priceValue,
            promotionType,
            selectedFile,
            productId,
            count
        } = this.state

        const postData = {
            loyaltyType: "punchCard",
            title,
            coupon,
            description,
            promotionType,
            //  priceValue: priceValue ? parseFloat(priceValue) : 100,
            priceValue: parseInt(priceValue) || 100,
            from,
            to,
            file: selectedFile,
            productId,
            count: parseInt(count) || 5
        }
        //güncelle
        if (isDetail) {
            //GÜNCELLE      
            postData.action = "update"
            try {
                await this.props.HomeStore.updateLoyalty(_id, postData)
                this.props.setPage(false)
                message.success()
                window.scrollTo(0, 0)
            } catch (error) {
                console.log(error)
            }
        } else {
            //YENI      
            if (postData.from && postData.to && postData.title && postData.coupon && postData.file && postData.productId) {
                try {

                    await this.props.HomeStore.createPunchCard(postData)
                    this.props.setPage(false)
                    message.success()
                    window.scrollTo(0, 0)
                } catch (error) {
                    console.log(error)
                    message.error()
                }
            }
            else {
                message.error("Zorunlu alanları kontrol ediniz");
            }
        }
    }



    onChangeFile = (event) => {
        this.setState({
            selectedFile: event.target.files[0]
        })
    }




    render() {
        const { loading } = this.state;
        const {
            productList,
            priceValue,
            productId,
            iconName,
            count,
            img_url,
            coupon,
            isDetail
        } = this.state;

        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };


        const buttonItemLayout = {
            wrapperCol: { span: 16, offset: 8 },
        };


        return (
            <div>
                {
                    isDetail
                    &&
                    <QrContainer download copy text={coupon} />
                }
                <Form layout={"horizontal"}  {...formItemLayout}>

                    <Form.Item required={true} label="Başlık">
                        <Input value={this.state.title} onChange={this.onChange} name="title" />
                    </Form.Item>

                    <Form.Item label="Açıklama ">
                        <TextArea
                            value={this.state.description}
                            onChange={this.onChange}
                            name="description"
                            autosize={{ minRows: 2, maxRows: 6 }}
                        />
                    </Form.Item>

                    <Form.Item help="Standart Olarak 100 dür." label={'İndirim oranı (%)'} {...formItemLayout}>
                        <Input type="number" value={priceValue} onChange={this.onChange} name="priceValue" />
                    </Form.Item>

                    <Form.Item label="Miktar"
                        help={`Ürün sayısı girdiğiniz değere ulaştığında bir sonraki ürün kullanıcıya %${priceValue || 100} indirimli verilecektir.(5)`}
                        {...formItemLayout}>
                        <Input disabled={isDetail} type="number" value={count} onChange={this.onChange} name="count" />
                    </Form.Item>



                    <Form.Item label="Görsel" required={true} {...formItemLayout}>
                        {
                            img_url
                            &&
                            <img src={img_url} style={{ height: 120 }} />
                        }
                        <input accept="image/*" type="file" onChange={this.onChangeFile} />
                    </Form.Item>

                    <Form.Item required={true} label="Tarih Aralığı">
                        <RangePicker
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            placeholder={['Başlangıç', 'Bitiş']}
                            onChange={this.onChangeRangePicker}
                            value={(this.state.from && this.state.to) ? [moment(this.state.from), moment(this.state.to)] : undefined}
                            onOk={this.onOkRangePicker}
                        />
                    </Form.Item>

                    <Form.Item required={true} label="Ürün">
                        <Select
                            notFoundContent="Bulunmadı"
                            optionFilterProp="children"
                            // mode="multiple"
                            placeholder="Ürün seçin"
                            value={productId}
                            onChange={this.handleChangeProductSelected}
                            style={{ width: '100%' }}
                        >
                            {
                                productList.map((v) => {
                                    return (
                                        <Option key={v._id}>{v.title}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>

                    {
                        //<Form.Item required={true} label="İcon">
                        //    <Select
                        //        value={iconName}
                        //        // onChange={this.handleChangeProductSelected}
                        //        style={{ width: '100%' }}
                        //    >
                        //        <Option key="CoffeeOutlined"><CoffeeOutlined /></Option>
                        //        <Option key="CoffeeOutlined"><CoffeeOutlined /></Option>
                        //    </Select>
                        //</Form.Item>
                    }


                    <Form.Item {...buttonItemLayout}>
                        <Button
                            block
                            onClick={this.submitForm}
                            loading={loading}
                            type="primary"
                            disabled={loading || this.state.existCoupon}
                        >
                            {
                                (this.state.isDetail) ? 'GÜNCELLE' : 'OLUŞTUR'
                            }
                        </Button>
                    </Form.Item>

                </Form>



            </div>
        )
    }
}
