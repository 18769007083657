
import React from 'react';


import { HashRouter, BrowserRouter, Route, Link, Redirect, Switch } from 'react-router-dom';

import { isMobile } from 'react-device-detect';


import Container from "../components/Container"
import ContainerPos from "../components/ContainerPos"
import ContainerStuff from "../components/ContainerStuff"

import HomePage from '../pages/Home'

import LoginPage from '../pages/Login'
import RegisterPage from '../pages/Register'
import NoFoundPage from '../pages/NoFound'

import ProfilePage from '../pages/Profile'

import PolicyPage from '../pages/Policy'

import RecoveryPassword from '../pages/RecoveryPassword'

import { history } from "../historyConfig"

import ProductPage from '../pages/Product';
import CategoryPage from '../pages/Category';
import DeskPage from '../pages/Desk'
import CustomerPage from '../pages/Customer'
import QrCodePage from '../pages/QrCode'
import LogsPage from '../pages/Logs'
import SettingsPage from '../pages/Settings'
import CompanySettings from '../pages/CompanySettings'
import OrderPage from '../pages/Order'
import LiveOrderPage from '../pages/LiveOrder'
import OrderDetail from '../pages/OrderDetail'
import ReportPage from '../pages/Report'
import CreateOrder from '../pages/CreateOrder'
import SearchPage from '../pages/Search'

import CompanyHome from '../pages/CompanyHome';
import CompanyCategory from '../pages/CompanyCategory';
import CompanyBasket from '../pages/CompanyBasket';

import HomeSlider from '../pages/HomeSlider';
import Reservation from '../pages/Reservation';
import Pos from '../pages/Pos/index';
import OrderWaiter from '../pages/OrderWaiter';
import OrderKitchen from '../pages/OrderKitchen';
import UserStaff from '../pages/UserStaff';
import Ride from '../pages/ride/Ride';
import Loyalty from '../pages/loyalty';
import Master from '../pages/Master';

import Desks from '../pages/Desks';
import DesksDetail from '../pages/Desks/Detail';


export default () => {
    return (
        <BrowserRouter
            // basename="/panel"
            //forceRefresh={true}
            history={history} >
            <Switch >
                <Route exact path="/master"
                    render={(props) => <Master {...props} />}
                />
                {
                    //   <Route path="/:id"
                    //       render={(props) => <CompanyHome {...props} />}
                    //   // children={<CompanyHome />}
                    //   />
                }

                <Route exact path="/company/:companykey"
                    render={(props) => <CompanyHome {...props} />}
                />
                <Route exact path="/company/:companykey/category/:categoryid"
                    render={(props) => <CompanyCategory {...props} />}
                />
                <Route exact path="/company/:companykey/basket"
                    render={(props) => <CompanyBasket {...props} />}
                />
                <Route exact path="/"
                    component={() => <Container headType="fixed"><HomePage /></Container>}
                />
                <Route exact path="/product"
                    component={() => <Container><ProductPage /></Container>}
                />
                <Route exact path="/category"
                    component={() => <Container><CategoryPage /></Container>}
                />
                <Route exact path="/table"
                    component={() => <Container><DeskPage /></Container>}
                />
                <Route exact path="/customer"
                    component={() => <Container><CustomerPage /></Container>}
                />
                <Route exact path="/qrcode"
                    component={() => <Container><QrCodePage /></Container>}
                />
                <Route exact path="/logs"
                    component={() => <Container><LogsPage /></Container>}
                />
                <Route exact path="/settings"
                    component={() => <Container><SettingsPage /></Container>}
                />
                <Route exact path="/settings/slider"
                    component={() => <Container> <HomeSlider /></Container>}
                />
                <Route exact path="/settings/company"
                    component={() => <Container><CompanySettings /></Container>}
                />
                <Route exact path="/settings/staff"
                    component={() => <Container> <UserStaff /></Container>}
                />
                <Route exact path="/order"
                    component={() => <Container><OrderPage /></Container>}
                />
                <Route exact path="/live"
                    component={() => <Container><LiveOrderPage /></Container>}
                />
                <Route exact path="/order/:id"
                    render={(props) => <Container><OrderDetail  {...props} /></Container>}
                />
                <Route exact path="/report"
                    component={() => <Container><ReportPage /></Container>}
                />
                <Route exact path="/reservation"
                    component={() => <Container><Reservation /></Container>}
                />
                <Route exact path="/pos"
                    component={() => <ContainerPos><Pos /></ContainerPos>}
                />
                <Route exact path="/waiter"
                    component={() => <ContainerStuff><OrderWaiter /></ContainerStuff>}
                />
                <Route exact path="/kitchen"
                    component={() => <ContainerStuff><OrderKitchen /></ContainerStuff>}
                />
                <Route exact path="/ride"
                    component={() => <ContainerStuff><Ride /></ContainerStuff>}
                />
                <Route exact path="/loyalty"
                    // component={() => <Container><Loyalty /></Container>}
                    render={(props) => <Container><Loyalty  {...props} /></Container>}
                />

                <Route exact path="/search"
                    component={() => <Container><SearchPage /></Container>}
                />

                <Route exact path="/create"
                    component={() => <Container><CreateOrder /></Container>}
                />

                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/register" component={RegisterPage} />
                <Route exact path="/recovery/:id" component={RecoveryPassword} />
                <Route exact path="/policy" component={PolicyPage} />
                <Route exact path="/profile/" component={ProfilePage} />
                <Route exact path="/user/:id" component={ProfilePage} />

                <Route exact path="/desks"
                    render={(props) => <Desks {...props} />}
                />
                 <Route exact path="/desks/:id"
                    render={(props) => <DesksDetail {...props} />}
                />

                <Route path="/404" component={NoFoundPage} />

                <Redirect
                    //from='*' 
                    //  to='/:username'
                    to='/404' />
            </Switch>
        </BrowserRouter>
    )
}


