import React, { PureComponent } from 'react';
import moment from 'moment';
import { Map, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';
import API from './api/index';
import { GOOGLE_MAPS_API, siteInfo } from '../../config';

import './index.css';

class App extends PureComponent {
    state = {
        activeRides: [],
        completedRides: [],
        currentRide: null,
        timeElapsed: null,
        location: {},
    }

    componentDidMount() {
        this.center();
        this.getRides();
    }

    getRides = async () => {
        const response = await API.getRides();
        const { rides } = await response.json();

        this.setState({
            completedRides: rides.filter(item => item.completed),
            activeRides: rides.filter(item => !item.completed),
        });
    }

    select = ride => () => this.setState({ currentRide: ride }, () => {
        this.autoUpdate();
        this.timeElapsed();
        this.center();
    });

    autoUpdate(ride) {
        const { currentRide } = this.state;

        if (currentRide.completed) return;

        const interval = setInterval(async () => {
            const response = await API.getRide(currentRide._id);
            const { ride } = await response.json();

            this.setState(state => ({
                currentRide: {
                    ...state.currentRide,
                    locations: ride.locations,
                    completed: ride.completed,
                }
            }));

            if (ride.completed) {
                clearInterval(interval);

                this.setState(state => ({
                    activeRides: state.activeRides.filter(item => (
                        item._id !== currentRide._id
                    )),
                    completedRides: state.completedRides.concat([ride]),
                }));
            }
        }, 5000)
    }

    timeElapsed = () => {
        const { currentRide } = this.state;

        const interval = setInterval(() => {
            const created = moment(currentRide.createdAt);
            const elapsed = moment().diff(created);
            const formatted = moment.utc(moment(elapsed)).format('HH:mm:ss');

            this.setState({ timeElapsed: formatted });

            if (this.state.currentRide.completed) clearInterval(interval);
        }, 1000);
    }

    getCurrentPosition = () => new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    })

    center = async () => {
        const { currentRide } = this.state;

        if (!currentRide) {
            const position = await this.getCurrentPosition();
            const { latitude, longitude } = position.coords;

            return this.setState({
                location: {
                    lat: latitude,
                    lng: longitude
                },
            });
        }

        const { locations } = currentRide;
        const lastLocation = locations[locations.length - 1];

        return this.setState({
            location: {
                lat: lastLocation.latitude,
                lng: lastLocation.longitude,
            },
        });
    }

    formatLocations = locations => {
        if (!locations) return [];

        return locations.map(item => ({
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
        }));
    }

    render() {
        const {
            completedRides,
            activeRides,
            currentRide,
            timeElapsed,
            location,
        } = this.state;

        if (!location) return null;

        return (
            <main className="application">
                <div className="left">
                    <img src={siteInfo.logo} style={{ maxHeight: 40 }} />
                    <h1 className="title">Kurye</h1>

                    <div className="live">
                        <h2 className="sub-title">Aktif teslimatlar</h2>

                        <ul>
                            {activeRides.length ? activeRides.map((item, index) => (
                                <li key={index} onClick={this.select(item)}>
                                    {item.name}
                                </li>
                            )) : (
                                <li className="empty">Şu anda aktif sürüş yok.</li>
                            )}
                        </ul>
                    </div>

                    <div className="completed">
                        <h2 className="sub-title">Tamamlanan teslimatlar</h2>

                        <ul>
                            {completedRides.length ? completedRides.map((item, index) => (
                                <li key={index} onClick={this.select(item)}>
                                    {item.name}
                                </li>
                            )) : (
                                <li className="empty">Tamamlanmış sürüş yok.</li>
                            )}
                        </ul>
                    </div>
                </div>

                <div className="right">
                    {(currentRide && timeElapsed) && (
                        <div className="time-elapsed">
                            <strong className={`${currentRide.completed ? '' : 'active'}`}>
                                {timeElapsed}
                            </strong>
                        </div>
                    )}
                    <Map
                        google={this.props.google}
                        zoom={14}
                        center={location}
                    >
                        {currentRide && (
                            <Polyline
                                path={this.formatLocations(currentRide.locations)}
                                strokeColor="#007235"
                                strokeOpacity={0.8}
                                strokeWeight={4}
                            />
                        )}

                        {currentRide && (
                            <Marker
                                name="Start location"
                                position={{
                                    lat: currentRide.startPoint.latitude,
                                    lng: currentRide.startPoint.longitude,
                                }}
                                icon={{
                                    url: require('./images/restaurant.png'),
                                    anchor: new window.google.maps.Point(18, 18),
                                    scaledSize: new window.google.maps.Size(28, 28)
                                }}
                            />
                        )}

                        {currentRide && (
                            <Marker
                                name="Destination"
                                position={{
                                    lat: currentRide.destination.latitude,
                                    lng: currentRide.destination.longitude,
                                }}
                                icon={{
                                    url: require('./images/home.png'),
                                    anchor: new window.google.maps.Point(18, 18),
                                    scaledSize: new window.google.maps.Size(28, 28)
                                }}
                            />
                        )}

                        {currentRide && (
                            <Marker
                                name="Current location"
                                position={{
                                    lat: (
                                        currentRide.locations[
                                            currentRide.locations.length - 1
                                        ].latitude
                                    ),
                                    lng: (
                                        currentRide.locations[
                                            currentRide.locations.length - 1
                                        ].longitude
                                    )
                                }}
                                icon={{
                                    url: require('./images/scooter.png'),
                                    anchor: new window.google.maps.Point(32, 32),
                                    scaledSize: new window.google.maps.Size(40, 40),
                                }}
                            />
                        )}
                    </Map>
                </div>
            </main>
        );
    }
};

export default GoogleApiWrapper({
    apiKey: GOOGLE_MAPS_API,
})(App)
