


import React, { useRef } from 'react';


import { Card, Avatar } from 'antd';

import QRCodeComponent from "./QrCode";

import { useReactToPrint,PrintContextConsumer } from 'react-to-print';

import { inject, observer } from 'mobx-react';

const { Meta } = Card;

@inject('QrCodeStore')
@observer
class ComponentToPrint extends React.Component {
    constructor(props){
        super(props)
    }
    render() {
      //  const {qrcode} = this.props
      const qrcode = this.props.QrCodeStore.qrcode
        const width = qrcode.width || 300
        console.log("ComponentToPrint Hook. props",this.props)
      return (
          <div>

                <Card
                    style={{ width: width }}
                    cover={
                        <QRCodeComponent 
                        text={`https://menu.cafeotomasyonu.com/?qr=${_.get(qrcode,"_id")}`} 
                        width={512}
                        />
                    }
                    actions={[
                        <span>
                            {_.get(qrcode,"extra")}
                        </span>,
                    ]}
                >
                    <Meta
                    avatar={<Avatar src="https://www.piyanos.com/logo/logo1024.png" />}
                    title={_.get(qrcode,"title")}
                    description={_.get(qrcode,"description")}
                    />
                </Card>
          </div>
      );
    }
  }

  const PrintHook = ({table,initFirst=false}) => {

    

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    if(initFirst){
        handlePrint()
    }

  //  handlePrint()
   
    return (
      <div style={{display:'none'}}>
        <ComponentToPrint
       //   qrcode={table}
          ref={componentRef} >
        <PrintContextConsumer>
            {({ handlePrint }) => (
              <button onClick={handlePrint}>Print this out! PrintContextConsumer</button>
            )}
          </PrintContextConsumer>
        </ComponentToPrint>
      </div>
    );
  };


  /*

 export default function PrintHook() {

  const [count, setCount] = useState(0);

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
}
*/

export default PrintHook