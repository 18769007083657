

import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { inject, observer } from 'mobx-react';

import Loading from "./Loading"

import { ConfigProvider } from 'antd';
import { Route, Link, Redirect } from 'react-router-dom';

import { getDefaultLanguage } from "../config";

import localeEN from 'antd/es/locale/en_US'
import localeTR from 'antd/es/locale/tr_TR'





@inject('UserStore')
@observer
class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const mainLocale = getDefaultLanguage() == "en" ? localeEN : localeTR
        const { loading } = this.props.UserStore
        const token = this.props.UserStore.token
        if (loading) {
            return (<Loading />)
        }
        else {
            return (
                <ConfigProvider locale={mainLocale} >
                    {
                        (!token) && <Redirect to="/login" />
                    }
                    {this.props.children}
                </ConfigProvider >
            )
        }
    }


}


export default withTranslation()(Container)