

import React from 'react';

import CompanyHome from '../components/CompanyHome'

export default ({ match }) => {
    console.log("companykey:CompanyHome:page", match.params.companykey)
    return (
        <CompanyHome companykey={match.params.companykey} />
    )
}