
import { observable, action, computed, autorun } from 'mobx';

import { getToken as token, companycode, setCompanycode, getClientId, setClientId } from "../services/Auth";
import CompanyService from "../services/Company"

import { v4 as uuidv4 } from 'uuid';

export default class Company {
    constructor() {
        this.init();
        autorun(() => {
            //   console.log("after autorun")
        })
    }

    @observable loading = false
    @observable companyInfo = {}
    @observable homeSliders = []
    @observable homeCategoryWithProducts = []
    @observable basketItems = []


    @action
    init = () => {
        if (!getClientId) {
            setClientId(uuidv4())
        }
    }

    //anasayfa slider listesini getir
    @action
    getCompanyByApikey = async (code) => {
        this.loading = true;
        try {
            const sendcode = code ? code : companycode
            if (!sendcode || sendcode.length != 24) {
                this.loading = false;
                throw new Error("must company code")
            }

            //if (this.homeSliders.length == 0 || this.homeCategoryWithProducts.length == 0) {
            //    this.getHomeSliders()
            //    this.getHomeCategoryWithProducts()
            //}

            //eğer veriler varsa servera gitme!
            if (this.companyInfo.name && sendcode == companycode) {
                console.log("!!!!!!in:cache");
                this.loading = false;
                return
            }
            const service = new CompanyService({ token: token, companycode: sendcode, clientid: getClientId })
            const company = (await service.getCompany()).data
            setCompanycode(company.apikey)
            this.companyInfo = company
            //  this.companyInfo = JSON.parse(JSON.stringify(this.companyInfo))
            //   this.companyInfo = {
            //     apikey: company.apikey,
            //     lang: company.lang,
            //     name: company.name,
            //     username: company.username,
            //     ...company.company
            // }
            console.log("!company:getCompanyByApikey:store!!!!!!!!!!!!:", company)
            this.getHomeSliders(company.apikey)
            this.getHomeCategoryWithProducts(company.apikey)
        } catch (error) {
            console.log(error)
        } finally {
            this.loading = false;
        }
    }

    @action
    getHomeSliders = async (apikey) => {
        this.loading = true;
        try {
            const service = new CompanyService({ token: token, companycode: apikey ? apikey : companycode, clientid: getClientId })
            const serverData = (await service.getHomeSliders()).data
            console.log("!getHomeSliders:!", serverData)
            this.homeSliders = serverData
        } catch (error) {
            console.log(error)
        } finally {
            this.loading = false;
        }
    }
    @action
    getHomeCategoryWithProducts = async (apikey) => {
        this.loading = true;
        try {
            const service = new CompanyService({ token: token, companycode: apikey ? apikey : companycode, clientid: getClientId })
            const serverData = (await service.getHomeCategoryWithProducts()).data
            console.log("!getHomeCategoryWithProducts:!", serverData)
            this.homeCategoryWithProducts = serverData
        } catch (error) {
            console.log(error)
        } finally {
            this.loading = false;
        }
    }
    /////////////////////////
    @computed get getApikey() {
        return companycode
    }
    //////////////////////

    /////////// -----  SEPET  ---------   ///////////////////////
    @action
    setBasketItems = () => {
        this.basketItems = _.orderBy(this.basketItems, ['created'], ['desc'])
        return true
    }
    //sepete ürün ekle
    @action
    addBasket = (item) => {
        item.created = new Date()
        this.basketItems.push(item)
        this.setBasketItems()
        return item
    }

    //sepetteki ürün adedi
    @action
    getItemCountInBasket = (_id) => {
        return _.filter(this.basketItems, (o) => {
            return o._id == _id
        }).length
    }

    //sepetteki ürün adedi ARTTIR 
    @action
    basketItemInc = (_id) => {
        const fI = this.basketItems.findIndex(v => v._id === _id)
        const pushed = this.basketItems.push(this.basketItems[fI])
        this.setBasketItems()
        return pushed
    }
    //sepetteki ürün adedi AZALT
    @action
    basketItemDec = (_id) => {
        const fI = this.basketItems.findIndex(v => v._id === _id)
        const spliced = this.basketItems.splice(fI, 1);
        this.setBasketItems()
        return spliced
    }

    // sepeti boşalt
    @action
    clearBasketItems = () => {
        this.basketItems = []
        return []
    }

    //sepetteki toplam tutarı getir
    @action
    getBasketTotalSum = () => {
        let totalPrice = _.sumBy(this.basketItems, (o) => {
            return o.price;
        })
        return totalPrice
    }
    /////////// -----  SEPET  -end---------   ///////////////////////

    // siparişi gönder
    @action
    createOrder = async (data) => {
        this.loading = true;
        try {
            const service = new CompanyService({ token: token, companycode: companycode, clientid: getClientId })
            const serverData = (await service.createOrder(data)).data
            console.log("!createOrder:store:!", serverData)
            return Promise.resolve(serverData)
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        } finally {
            this.loading = false;
        }
    }





}