import React, { Component } from "react";

import { Row, Col, message, Select, Form, Input, Drawer, Table, Popconfirm, Divider, Upload, Switch, Button, Menu, Dropdown } from 'antd'
import { inject, observer } from 'mobx-react';

import PageHeader from "../components/PageHeader";


import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
    DeleteOutlined,
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
    MessageOutlined,
    EditOutlined,
    CheckOutlined,
    CameraOutlined,
    StopOutlined,
    ReloadOutlined,
    MoreOutlined,
    MenuOutlined,
} from '@ant-design/icons';
import _ from "lodash";


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const { Option } = Select

@inject('HomeStore')
@observer
export default class UserStaff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            show: false,
        };
    }


    componentDidMount = () => {
        this.itemList();
    }


    itemList = () => {
        this.props.HomeStore.getUserStaffs()
    }



    delete = async (item) => {
        try {
            await this.props.HomeStore.deleteUserStaffs(item._id)
            message.success()
        } catch (error) {
            console.log(error)
        }

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    handleCreate = async () => {
        console.log(this.state)
        const { name, username, email, password, userType } = this.state;
        //!username || 
        if (!name || !email || !password || !userType) {
            message.warning("Gerekli yerleri doldurup tekrar deneyiniz!");
            return;
        }
        try {
            const postData = {
                name,
                //    username,
                email,
                password,
                userType
            }
            await this.props.HomeStore.createUserStaffs(postData)
            this.handleCancel()
        } catch (error) {
            console.log({ error })
            message.error(_.get(error, "response.data.message", ""))
        }


    }



    handleCancel = () => {
        this.setState({
            show: false,
            _id: null
        })
    }


    render() {
        const { show } = this.state;

        const data = _.get(this.props, "HomeStore.staffList", [])
        const { staffTypes } = this.props.HomeStore
        const { loading } = this.props.HomeStore

        const menuMore = (
            <Menu>
                <Menu.Item key="0"
                    onClick={() => { this.setState({ show: !show }) }}
                >
                    Yeni
                </Menu.Item>

            </Menu>
        );



        const columns = [
            {
                title: 'isim',
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: 'Email',
                key: 'email',
                render: (text, record) =>
                    <span>
                        {record.email}
                    </span>,
            },
            {
                title: 'Kullanıcı Türü',
                key: 'userType',
                render: (text, record) =>
                    <span>
                        {_.get(_.find(staffTypes, { key: record.userType }), "title")}
                    </span>,
            },
            {
                title: '',
                key: 'action',
                render: (text, record) =>
                    <span>
                        {
                            //   <Switch defaultChecked={record.active} onChange={(e) => this.onChangeSwitch(e, record)} checkedChildren="Aktif" unCheckedChildren="Pasif" />
                            //   <Divider type="vertical" />
                        }
                        <Popconfirm okText="Evet" cancelText="Hayır" title="Silmek istediğinize emin misiniz ?" onConfirm={() => this.delete(record)}>
                            <Button size='small' type='danger'>
                                Sil
                            </Button>
                        </Popconfirm>
                    </span>
                ,
            },
        ];

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16, offset: 0 },
            },
        };



        return (
            <div>

                <PageHeader subTitle={data.length} title="Personel Listesi"
                    extra={
                        <>
                            <Button
                                onClick={() => this.itemList()}
                                shape="circle-outline"
                                style={{ marginLeft: 5 }}
                                icon={<ReloadOutlined />}
                            />
                            <Dropdown trigger={"click"} overlay={menuMore}
                            >
                                <Button
                                    shape="circle-outline"
                                    style={{ marginLeft: 5 }}
                                    icon={<MoreOutlined />}
                                />
                            </Dropdown>
                        </>

                    }
                />



                <div style={{ height: 20 }} />

                <Table
                    rowKey={'_id'}
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                />




                <Drawer
                    title={this.state._id ? "Düzenle" : "Yeni"}
                    placement="left"
                    width={720}
                    visible={this.state.show}
                    onClose={(e) => this.handleCancel(e)}
                    bodyStyle={{ paddingBottom: 80 }}
                >

                    <Form {...formItemLayout}>
                        <Form.Item
                            label="İsim"
                            required
                        >
                            <Input
                                value={this.state.name}
                                onChange={(e) => this.onChange(e)}
                                name="name" />
                        </Form.Item>



                        <Form.Item
                            label="Kullanıcı Tipi"
                            required
                        >
                            <Select
                                value={this.state.userType} onChange={(e) => this.setState({ userType: e })}>
                                {
                                    staffTypes.map((v, i) => {
                                        return (
                                            <Option key={v.key} value={v.key}>{v.title}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        {
                            //  <Form.Item
                            //      label="Kullanıcı Adı"
                            //      required
                            //  >
                            //      <Input
                            //          value={this.state.username}
                            //          onChange={(e) => this.onChange(e)}
                            //          name="username" />
                            //  </Form.Item>
                        }
                        <Form.Item
                            label="Mail"
                            required
                        >
                            <Input
                                value={this.state.email}
                                onChange={(e) => this.onChange(e)}
                                name="email" />
                        </Form.Item>
                        <Form.Item
                            label="Şifre"
                            required
                        >
                            <Input
                                value={this.state.password}
                                onChange={(e) => this.onChange(e)}
                                name="password" />
                        </Form.Item>





                    </Form>

                    <div
                        style={{
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 0',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={(e) => this.handleCancel(e)} style={{ marginRight: 8 }}>
                            İptal
                        </Button>
                        <Button disabled={loading} onClick={() => this.handleCreate()} type="primary">
                            Kaydet
                        </Button>
                    </div>
                </Drawer>

            </div>
        )
    }

}







